import React from 'react'
import styled from 'styled-components'
import { Col, Row, Container } from 'styled-bootstrap-grid'
import ShopTextWraper from '../Common/ShopTextWraper'
import { Button } from '../Collapse/EducationCollapse'
import { CommonEnum } from '../../utils'
import { palette } from '../../styled/colors'
import { media } from 'styled-bootstrap-grid'

const ShopPleasure = () => {

    return (

        <>
            <ShopTextWraper
                heading={"YOUR PLEASURE IS A PRIORITY"}
                text={`A lot can get in the way of your self-love journey, but we want to help you keep it a priority. You come first in life, love—and in bed, so help make sure that happens with our Kip vibrator, powered by Dame.`} />
            <Container fluid>
                <CenterWraper>
                    <Row>
                        <Col xl={6} lg="12" md="12" sm="12" >
                            <Image src='./shop-image.webp' alt='why' />
                        </Col>

                        <Col xl={6} lg="12" md="12" sm="12" >
                            <FlexWraper>
                                <Heading> <h2><span>{CommonEnum.AppName}</span> kip <br /> Vibrator powered <br /> by dame</h2></Heading>
                                <Text>
                                    
                                        We all deserve good vibes, so get yourself one with the {CommonEnum.AppName} Kip vibrator powered by Dame. It’s whisper-quiet and travel-friendly, so you can come first no matter where you are (as you should).
                                    
                                </Text>
                                <Button style={{ width: 'max-content' }}>SHOP NOW</Button>
                            </FlexWraper>
                        </Col>
                    </Row>
                </CenterWraper>
            </Container>

            <MovieHeroWraper>
                <MovieText>MAKE THE <br /> FIRST MOVE</MovieText>
            </MovieHeroWraper>

            <ShopTextWraper
                heading={"DRESS TO MAKE MOVES"}
                text={"When you look good, you feel good. And when you feel good? You make moves. This collection was designed with you in mind. Designed to empower you to Make the First Move in life."} />
        </>
    )
}

const FlexWraper = styled.div`
display: flex;
flex-direction: column;
align-items:left;
height: 100%;
justify-content: center;
`
const CenterWraper = styled.div``
const MovieHeroWraper = styled.div`
display:flex;
justify-content:center;
align-items:left;
background-image: url(./make-moves.webp);
height: 100vh;
width: 100%;
align-items:center;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
margin-top: 5rem;
`

const MovieText = styled.h1`
color: white;
font-size: 120px;
text-align:center;
font-style: italic;
@media(max-width:760px){
    font-size: 90px;
}
@media(max-width:760px){
    font-size: 70px;
}
@media(max-width:450px){

    font-size: 50px;
}
@media(max-width:450px){

    font-size: 30px;
}
`
const Heading = styled.div`
    font-weight: 600;
    color: ${palette.white};
    font-size: 1.5rem;
    line-height: 1.5;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`

const Text = styled.div`
    line-height: 2;
    font-size: 20px;
    font-size: 16px;
    padding-top:1rem;
    color: ${palette.white};
    ${media.sm`
        width: 95%;
    `}
    ${media.md`
        padding-top:1.5rem;
        width: 05%;
        font-size: 18px;
    `}
    ${media.lg`
        padding-top:2rem;
        width: 85%;
        font-size: 24px;
`}
`

const Image = styled.img`
border-radius: 20px;
width: 100%;
`

export default ShopPleasure