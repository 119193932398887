import React, { useState } from 'react'
import styled from 'styled-components'
import { useCollapse } from 'react-collapsed'
import { Flexed, Spacer, CustomSwitch, Text } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { IoIosArrowForward } from "react-icons/io";
import AdvanceFilterList from './AdvanceFilterList/AdvanceFilterList.js'

const AdvanceFilters = ({ mode, verifiedOnly, applyFilters, setApplyFilters, handleCheckboxChange, verifiedOnlyAff, advanceFilterInfo, isActive, getFilterData, setRecall, recall }) => {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    return (
        <Box isExpanded={isExpanded} {...getToggleProps({
            onClick: () => { !isExpanded && setExpanded(!isExpanded) },
        })}>
            <BoxHeader justify='space-between' align='center' {...getToggleProps({
                onClick: () => setExpanded((prevExpanded) => !prevExpanded),
            })}>
                <div>
                    <Heading>Advanced filters</Heading>
                    <div style={{ fontSize: '0.8rem', margin: '0.4rem 0rem 0rem 0rem', }}>
                        Add up to 2 filters you would like your potential {mode} <br /> matches to have
                    </div>
                </div>
                <DownArrow active={isExpanded} />
            </BoxHeader>

            <InnerContent {...getCollapseProps()}>
                <Spacer height={2} />
                <Text type='normal' color='text'>Filter by:</Text>
                <Choose>
                    <Flexed align='center' gap={0.4}>
                        <Icons src='/icons/badge_editProfile_bff_verified.png' alt='badge_verified' />
                        <Text type='normal' color='text'>Verified profiles only</Text>
                        <InfoIcon data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm0-7.273a2.182 2.182 0 01-2.182-2.181V16a2.182 2.182 0 114.364 0v6.546A2.182 2.182 0 0116 24.727zm0-13.09a2.182 2.182 0 110-4.364 2.182 2.182 0 010 4.363z" fill="currentColor"></path></InfoIcon>
                    </Flexed>
                    <CustomSwitch checked={verifiedOnly}>
                        <input type="checkbox" checked={verifiedOnly} onClick={handleCheckboxChange} />
                        <div></div>
                    </CustomSwitch>
                </Choose>
                {advanceFilterInfo?.map((data) => {
                    return (
                        <>
                            <AdvanceFilterList mode={mode} isActive={isActive} data={data} getFilterData={getFilterData} setRecall={setRecall} recall={recall} />
                        </>
                    )
                })}
            </InnerContent>
        </Box>
    )
}

const DownArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    color: ${palette.light_gray};
	transform: ${({ active }) => active ? 'rotate(-90deg)' : 'rotate(90deg)'};
	transition: all 0.3s ease;
`;


const Choose = styled.div`
	position: relative;
	cursor: pointer;
	border: 1px solid ${palette.yellow_border};
	border-radius: 20px;
	padding: 0.1rem 1rem;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${palette.black};
	margin-top: 0.7rem;
	&:hover {
		border: 1px solid ${palette.yellow_border};
		background: ${palette.yellow_gradient};
	}
	&:hover ${DownArrow} {
		color: ${palette.text};
	}
`


export const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	border: 0.5px solid ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	&:hover {
		border: 0.5px solid ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}

`

const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${DownArrow}{
		color: ${({ isExpanded }) => isExpanded ? '' : palette.text};
	}
`

const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
	font-size: 20px;
`

const InnerContent = styled.section`
	position:relative;
`;

const Icons = styled.img`
	width: 24px;
    height: 24px;
`

const InfoIcon = styled.svg`
    width: 16px;
    height: 16px;
    color: ${palette.light_gray};
`;


export default AdvanceFilters