import React from 'react'
import { useCountdown } from '../hooks'
import DateTimeDisplay from './DateTimeDisplay'

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <>
            {hours !== 0 && <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />}
            {(hours === 0 && minutes !== 0) && <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />}
            {(hours === 0 && minutes === 0 && seconds !== 0) && <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />}
        </>
    )
}

const CountdownTimer = ({ targetDate, isLaunchpadClosed, updateList }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate)
    if (days + hours + minutes + seconds <= 0 || isLaunchpadClosed) {
        if (updateList !== undefined) {
            updateList()
        }
        return null
    } else {
        return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
    }
}
export default CountdownTimer
