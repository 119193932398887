import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components'; import { palette } from '../../styled/colors';
import { CommonEnum } from '../../utils';
import { media } from 'styled-bootstrap-grid'

const CarouselSlider = () => {

    const slides = [
        {
            image: './carousel-1.webp',
            heading: "Make the First Move",
            text: "Women talk first to set an equal tone from the start."

        },
        {
            image: './carousel-2.webp',
            heading: "Kiss Catfishing Goodbye",
            text: `${CommonEnum.AppName} Photo Verification is a promise that the person you’re talking with is who they say they are. Look for the blue check mark on other users’ profiles.`
        },

    ];

    return (

        <>

            <Carousel

                showArrows={true}
                showIndicators={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                interval={3000}
                stopOnHover={true}
            >
                {slides.map((slide, index) => (
                    <Wraper key={index}>
                        <CarouselContent>

                            <Heading>{slide.heading}</Heading>
                            <Text>
                                <p>{slide.text} </p>
                            </Text>
                            <Button>Learn More</Button>
                        </CarouselContent>
                        <Image src={slide.image} alt={slide.caption} />
                    </Wraper>
                ))}


            </Carousel>


        </>
    )
}

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Wraper = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    ${media.sm` 
    height: 30rem;
    `}
    ${media.md` 
    height: 30rem;
    `}
    ${media.lg`
    height: calc(100vh - 64px);
    `}
`;
const CarouselContent = styled.div`
    background: whitesmoke;
    flex-direction: column;
    position: absolute;
    padding: 2rem;
    width: 90%;
    ${media.lg`
    width: 40rem;
    `}
`
const Heading = styled.div`
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`
const Text = styled.div`
    line-height: 1.5rem;
    font-weight: 300;
    margin-top: 1rem;
    text-align: justify;
    text-align: center;
    ${media.sm`
        line-height: 1.8rem;
    `}
    ${media.md`
        line-height: 1.8rem;
    `}

`

const Button = styled.div`
    display: inline-block;
    margin: auto;
    text-align: center;
    border-radius: 4px;
    background: ${palette.btn_gradient};
    padding: .8rem 3rem;
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-weight: 700;
    margin-top: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`



export default CarouselSlider