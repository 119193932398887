import React from 'react'
import Modal, { ModalProvider } from 'styled-react-modal'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { media } from 'styled-bootstrap-grid'

const ProfileImageDeleteModal = ({ isOpen, onClose, onDelete, isOpenUploadModal }) => {
    return (
        <>
            <ModalProvider>
                <StyledModal isOpen={isOpen} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
                    <Text>Are you sure you want to delete your photo?</Text>
                    <Button onClick={isOpenUploadModal}>Upload a new picture</Button>
                    <Delete onClick={onDelete}>Delete photo</Delete>
                </StyledModal>
            </ModalProvider>
        </>
    )
}

const StyledModal = Modal.styled`
    width: 90%;
    min-height: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    gap:1.25rem;
    padding: 2rem;
    background-color: ${palette.black};
    border: 1px solid ${palette.yellow_border};
    ${media.sm`
    width: 480px;
    `}
`

const Text = styled.div`
    font-size: 24px;
    color: ${palette.text};
    text-align: center;
    font-weight: 500;
    line-height: 2rem;
`

const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

const Delete = styled.div`
	color: ${palette.hover_color};
    font-size: 1rem;
    cursor: pointer;

`

export default ProfileImageDeleteModal
