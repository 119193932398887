import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import { getUser } from '../../../apis/apis'
import { palette } from '../../../styled/colors'
import { Flexed, Spacer, Text } from '../../../styled/shared'
import HeightSlider from '../../Common/HeightSlider'

const BasicList = ({ data, getBasicData, setRecall, recall }) => {
	const [isOpen, setIsOpen] = useState(false)
	const JobModal = (e) => setIsOpen(!isOpen)
	const [user, setUser] = useState('')
	const [heightFrom, setHeightFrom] = useState(90)

	const getUserProfile = async () => {
		const response = await getUser()
		setUser(response?.data?.data?.user)
	}

	useEffect(() => {
		getUserProfile()
	}, [])


	useEffect(() => {
		if (data?.title === 'Height' && data?.selected !== '' && data?.selected !== undefined) {
			setHeightFrom(data?.selected)
		}
	}, [data])

	const mybasic = async (text, heading) => {
		JobModal();
		if (heading === 'Do you work out?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						exercise: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'What’s your education?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						education_level: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'Do you drink?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						drinking: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'Do you smoke?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						smoking: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}

		if (heading === 'What do you want from your dates?') {
			if (user?.mode === 'aff') {
				if (text === 'Skip') {
					text = ''
				}

				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
						{
							looking_for_aff: text
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			} else {
				if (text === 'Skip') {
					text = ''
				}

				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
						{
							looking_for_date: text
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
		}
		if (heading === 'What are your ideal plans for children?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						kid: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'What’s your zodiac sign?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						star_sign: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'What are your political leanings?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						politic: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'Do you identify with a religion?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						religion: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'What is your Height?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						height: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}

		if (heading === 'Do you have Kids?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						have_kids: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}

		if (heading === 'What is your relationship status?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						relation_ship: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
		if (heading === 'Are you new to the area?') {
			if (text === 'Skip') {
				text = ''
			}

			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						new_to_area: text
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}

		if (recall) {
			setRecall(false)
		} else {
			setRecall(true)
		}
	}

	const getIcons = (type) => {
		switch (type) {
			case 'Exercise':
				return '/icons/exercise.png'
			case 'Drinking':
				return '/icons/drinking.png'
			case 'Smoking':
				return '/icons/smoking.png'
			case 'Looking for':
				return '/icons/lookingFor.png'
			case 'Kids':
				return '/icons/kids.png'
			case 'Star sign':
				return '/icons/starSign.png'
			case 'Politics':
				return '/icons/politics.png'
			case 'Religion':
				return '/icons/religion.png'
			case 'haveKids':
				return '/icons/haveKids.png'
			case 'New To Area':
				return '/icons/newToArea.png'
			case 'Relationship':
				return '/icons/relationship.png'
			case 'Education level':
				return '/icons/education.png'
			case 'Height':
				return '/icons/height.png'
			default:
				return ''
		}
	}

	return (
		<>
			<Choose onClick={() => { getBasicData(data); JobModal(); }}>
				<Flexed align='center' gap={0.4}>
					<img height="25px" src={getIcons(data?.title)} alt={data?.title} />
					<Text type='normal' color='white'>{data?.title}</Text>
				</Flexed>
				<div>
					<Flexed align='center' >
						{data?.selected ? <Text type='normal' color='text'>{data?.selected == 91 ? '<' : data?.selected == 220 ? '>' : ''} {data?.selected} {data?.title === 'Height' && 'cm'}</Text> :
							<Add height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
							</Add>
						}
					</Flexed>
				</div>
			</Choose>
			<ModalProvider>
				<div>
					<StyledModal id="myModelBox" isOpen={isOpen} onBackgroundClick={JobModal} onEscapeKeydown={JobModal}>
						<div>
							<Icons width="50px" style={{ display: 'block', margin: 'auto', marginBottom: '7px' }} src={getIcons(data?.title)} alt="" />

							<ModelHeading>{data?.heading}</ModelHeading>
							{data?.heading === 'What is your Height?' ?
								<>
									<SliderWraper>
										<HeightSlider valueFrom={heightFrom} setValueFrom={setHeightFrom} />
									</SliderWraper>
									<ChooseList onClick={() => mybasic(heightFrom, data?.heading)}>
										<Flexed align='center' justify='space-between'>
											<Span>Yup, that’s how tall I am</Span>
										</Flexed>
									</ChooseList>

									<ChooseList onClick={() => mybasic('', data?.heading)}>
										<Flexed align='center' justify='space-between'>
											<Span>Skip</Span>
										</Flexed>
									</ChooseList>

								</>
								:
								<>
									{data?.data?.map((text) => (
										<ChooseList selected={data?.selected === text} onClick={() => mybasic(text, data?.heading)}>
											<Flexed align='center' justify='space-between'>
												<Span>{text}</Span>
											</Flexed>
										</ChooseList>
									))}
								</>
							}
							<Spacer height={2} />
						</div>
					</StyledModal>
				</div>
			</ModalProvider>
		</>
	)
}

const Add = styled.svg`
	cursor:pointer;
	color: ${palette.light_gray};
`;

const Choose = styled.div`
	cursor: pointer;
	border: 1px solid ${palette.yellow_border};
	border-radius: 20px;
	padding: 0.1rem 1rem;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${palette.black};
	margin-top: 0.7rem;
	&:hover{
		border: 1px solid ${palette.yellow_border};
		background: ${palette.yellow_gradient};
	}
	 &:hover ${Add}{
		color: ${palette.text};
	 }
`
const ChooseList = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	border-radius: 18px;
	cursor: pointer;
	padding: 0.6rem 1rem;
	// width: 20rem;
	border: 1px solid ${({ selected }) => selected ? palette.yellow_border : palette.light_gray};
	background: ${({ selected }) => selected ? palette.yellow_gradient : ''};
	text-align: center;
	margin-top: 0.6rem;
	&:hover{
		border:  1px solid ${({ selected }) => selected ? palette.yellow_border : palette.hover_color};
		background: ${palette.yellow_gradient};
		color: ${palette.white};
	}
`

const Span = styled.p`
	font-size: 15px;
	text-align: center;
`
const StyledModal = Modal.styled`
	max-height: 480px;
	width: 90%;
	overflow: auto;
	padding: 2rem;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	background-color: ${palette.black};
	border: 1px solid ${palette.yellow_border};
	${media.sm`
    width: 480px;
    `}
`

const ModelHeading = styled.h3`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 500;
	color: ${palette.yellow_border};
`

const Icons = styled.img`
	filter: invert(125%) sepia(121%) saturate(2508%) hue-rotate(388deg) brightness(200%) contrast(104%);
`;

const SliderWraper = styled.div`
	padding: 1.4rem 1rem 1.8rem 1rem;
	width: 400px;
	border: 1px solid ${palette.yellow_border};
	background: ${palette.black};
	border-radius: 20px;
	gap: 1rem;
	display: inline-grid;
	:hover {
		border: 1px solid ${palette.yellow_border};
		background: ${palette.black};
	}
	${media.xs`
	width: 270px;
    `}
`

export default BasicList
