import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCollapse } from 'react-collapsed'
import { IoIosArrowForward } from 'react-icons/io'
import styled from 'styled-components'
import { getUser } from '../../../apis/apis'
import { palette } from '../../../styled/colors'
import { Flexed, Spacer, Text } from '../../../styled/shared'
import Switch from '../../Common/Switch'
import { ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import { Box } from '../AdvanceFilters'
import { CommonEnum } from '../../../utils'
import { media } from 'styled-bootstrap-grid'

const NotificationSettings = () => {
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [user, setUser] = useState([])
	const [newMessage, setNewMassage] = useState(true)
	const [newMsgEmail, setNewMsgEmail] = useState(false)
	const [admirers_push_notification, setadmirers_push_notification] = useState(true)
	const [admire_email, setadmire_email] = useState(false)
	const [new_match_push, setnew_match_push] = useState(true)
	const [new_match_email, setnew_match_email] = useState(false)
	const [expire_match_push, setexpire_match_push] = useState(true)
	const [expire_match_email, setexpire_match_email] = useState(false)
	const [bumble_event_email, setbumble_event_email] = useState(false)
	const [the_good_stuff_email, setthe_good_stuff_email] = useState(false)
	const [notificationSound, setNotificationSound] = useState(false)
	const [isEmailModelOpen, setIsEmailModelOpen] = useState(false)
	const [emailAddress, setEmailAddress] = useState('')
	const [verifyEmailCheck, setVerifyEmailCheck] = useState(false)
	const [emailSendCheck, setEmailSendCheck] = useState(false)
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
	const [isMianloading, setIsMianloading] = useState(false);


	const getUserInfo = async () => {
		let response = await getUser()
		setEmailAddress(response?.data?.data?.user?.email)
		setUser(response?.data?.data?.user?.notification_setting)
		setNewMassage(response?.data?.data?.user?.notification_setting[0]?.new_msg_push_notification)
		setNewMsgEmail(response?.data?.data?.user?.notification_setting[0]?.new_msg_email)
		setadmirers_push_notification(response?.data?.data?.user?.notification_setting[0]?.admirers_push_notification)
		setadmire_email(response?.data?.data?.user?.notification_setting[0]?.admire_email)
		setnew_match_push(response?.data?.data?.user?.notification_setting[0]?.new_match_push)
		setnew_match_email(response?.data?.data?.user?.notification_setting[0]?.new_match_email)
		setexpire_match_push(response?.data?.data?.user?.notification_setting[0]?.expire_match_push)
		setexpire_match_email(response?.data?.data?.user?.notification_setting[0]?.expire_match_email)
		setbumble_event_email(response?.data?.data?.user?.notification_setting[0]?.bumble_event_email)
		setthe_good_stuff_email(response?.data?.data?.user?.notification_setting[0]?.the_good_stuff_email)
		setNotificationSound(response?.data?.data?.user?.notification_setting[0]?.notification_sound)
	}

	useEffect(() => {
		getUserInfo()
	}, [])

	const emailModelToggle = async (e) => {
		setIsEmailModelOpen(!isEmailModelOpen)
	}

	const saveEmail = async () => {
		setIsMianloading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					email: emailAddress
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					setIsMianloading(false)
					getUserInfo();
					setTimeout(senEmailNotice, 5000);
				}
			})
			.catch((error) => {
				setIsMianloading(false)
				console.error(error)
			})
	}

	const senEmailNotice = () => {
		setEmailSendCheck(true);
	}
	return (
		<>
			<Box isExpanded={isExpanded} {...getToggleProps({
				onClick: () => { !isExpanded && setExpanded(!isExpanded) },
			})}>
				<BoxHeader justify='space-between' align='center' {...getToggleProps({
					onClick: () => setExpanded((prevExpanded) => !prevExpanded),
				})}>
					<div>
						<Heading>Notification settings</Heading>
					</div>
					<DownArrow active={isExpanded} />
				</BoxHeader>

				<InnerContent  {...getCollapseProps()}>
					<Spacer height={1} />
					<Text type='normal' color='text'>New messages</Text>
					<Switch
						text={'By browser notifications'}
						value={newMessage}
						handleChange={async (e) => {
							setNewMassage(e.target.checked)
							const _token = sessionStorage.getItem('authorization')
							const config = {
								headers: { authorization: `bearer ${_token}` }
							}
							await axios
								.post(
									`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
									{
										new_msg_push_notification: e.target.checked
									},
									config
								)
								.then((response) => {
									if (response.data.success === true) {
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}}
					/>
					<Switch
						text={'By email'}
						value={newMsgEmail}
						handleChange={async (e) => {
							if (emailAddress) {
								setNewMsgEmail(e.target.checked)
								setIsEmailModelOpen(true);
								const _token = sessionStorage.getItem('authorization')
								const config = {
									headers: { authorization: `bearer ${_token}` }
								}

								await axios
									.post(
										`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
										{
											new_msg_email: e.target.checked
										},
										config
									)
									.then((response) => {
										if (response.data.success === true) {
										}
									})
									.catch((error) => {
										console.error(error)
									})
							}
							else {
								emailModelToggle()
							}

						}}
						para={'Turning off this notification means you will miss out on new messages from your connections.'}
					/>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>New admirers</Text>
					<Switch
						text={'By browser notifications'}
						value={admirers_push_notification}
						handleChange={async (e) => {
							setadmirers_push_notification(e.target.checked)
							const _token = sessionStorage.getItem('authorization')
							const config = {
								headers: { authorization: `bearer ${_token}` }
							}

							await axios
								.post(
									`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
									{
										admirers_push_notification: e.target.checked
									},
									config
								)
								.then((response) => {
									if (response.data.success === true) {
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}}
					/>
					<Switch
						text={'By email'}
						para={'Turning off this notification means you will miss out on new messages from your connections.'}
						value={admire_email}
						handleChange={async (e) => {
							if (emailAddress) {
								setadmire_email(e.target.checked)
								const _token = sessionStorage.getItem('authorization')
								const config = {
									headers: { authorization: `bearer ${_token}` }
								}

								await axios
									.post(
										`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
										{
											admire_email: e.target.checked
										},
										config
									)
									.then((response) => {
										if (response.data.success === true) {
										}
									})
									.catch((error) => {
										console.error(error)
									})
							}
							else {
								emailModelToggle()
							}
						}}
					/>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>New matches</Text>
					<Switch
						text={'By browser notifications'}
						value={new_match_push}
						handleChange={async (e) => {
							setnew_match_push(e.target.checked)
							const _token = sessionStorage.getItem('authorization')
							const config = {
								headers: { authorization: `bearer ${_token}` }
							}

							await axios
								.post(
									`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
									{
										new_match_push: e.target.checked
									},
									config
								)
								.then((response) => {
									if (response.data.success === true) {
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}}
					/>
					<Switch
						text={'By email'}
						value={new_match_email}
						para={'Turning off this notification means you will miss out on new messages from your connections.'}
						handleChange={async (e) => {
							if (emailAddress) {
								setnew_match_email(e.target.checked)
								const _token = sessionStorage.getItem('authorization')
								const config = {
									headers: { authorization: `bearer ${_token}` }
								}

								await axios
									.post(
										`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
										{
											new_match_email: e.target.checked
										},
										config
									)
									.then((response) => {
										if (response.data.success === true) {
										}
									})
									.catch((error) => {
										console.error(error)
									})
							}
							else {
								emailModelToggle()
							}
						}}
					/>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>Expiring matches</Text>
					<Switch
						value={expire_match_push}
						text={'By browser notifications'}
						handleChange={async (e) => {
							setexpire_match_push(e.target.checked)
							const _token = sessionStorage.getItem('authorization')
							const config = {
								headers: { authorization: `bearer ${_token}` }
							}

							await axios
								.post(
									`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
									{
										expire_match_push: e.target.checked
									},
									config
								)
								.then((response) => {
									if (response.data.success === true) {
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}}
					/>
					<Switch
						text={'By email'}
						value={expire_match_email}
						para={'Turning off this notification means you will miss out on new messages from your connections.'}
						handleChange={async (e) => {
							if (emailAddress) {
								setexpire_match_email(e.target.checked)
								const _token = sessionStorage.getItem('authorization')
								const config = {
									headers: { authorization: `bearer ${_token}` }
								}

								await axios
									.post(
										`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
										{
											expire_match_email: e.target.checked
										},
										config
									)
									.then((response) => {
										if (response.data.success === true) {
										}
									})
									.catch((error) => {
										console.error(error)
									})
							}
							else {
								emailModelToggle()
							}
						}}
					/>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>{CommonEnum.AppName} events</Text>
					<Switch
						text={'By email'}
						value={bumble_event_email}
						para={`Turning off this notification means you will not be notified of special ${CommonEnum.AppName} events held in your area.`}
						handleChange={async (e) => {
							if (emailAddress) {
								setbumble_event_email(e.target.checked)
								const _token = sessionStorage.getItem('authorization')
								const config = {
									headers: { authorization: `bearer ${_token}` }
								}

								await axios
									.post(
										`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
										{
											bumble_event_email: e.target.checked
										},
										config
									)
									.then((response) => {
										if (response.data.success === true) {
										}
									})
									.catch((error) => {
										console.error(error)
									})
							}
							else {
								emailModelToggle()
							}
						}}
					/>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>The good stuff</Text>
					<Switch
						text={'By email'}
						value={the_good_stuff_email}
						para={`Turning off this notification means you will not be notified of special ${CommonEnum.AppName} events held in your area.`}
						handleChange={async (e) => {
							if (emailAddress) {
								setthe_good_stuff_email(e.target.checked)
								const _token = sessionStorage.getItem('authorization')
								const config = {
									headers: { authorization: `bearer ${_token}` }
								}

								await axios
									.post(
										`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
										{
											the_good_stuff_email: e.target.checked
										},
										config
									)
									.then((response) => {
										if (response.data.success === true) {
										}
									})
									.catch((error) => {
										console.error(error)
									})
							}
							else {
								emailModelToggle()
							}
						}
						}
					/>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>Sounds</Text>
					<Switch
						text={'Notification sounds'}
						value={notificationSound}
						para={'Turning off sounds means you may miss out on new notifications.'}
						handleChange={async (e) => {
							setNotificationSound(e.target.checked)
							const _token = sessionStorage.getItem('authorization')
							const config = {
								headers: { authorization: `bearer ${_token}` }
							}
							await axios
								.post(
									`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_notification_setting`,
									{
										notification_sound: e.target.checked
									},
									config
								)
								.then((response) => {
									if (response.data.success === true) {
									}
								})
								.catch((error) => {
									console.error(error)
								})
						}}
					/>

				</InnerContent>
			</Box>
			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={isEmailModelOpen} onBackgroundClick={emailModelToggle} onEscapeKeydown={emailModelToggle}>
					<div>
						<ModelHeading>{verifyEmailCheck === false ? 'Change your email' : 'Confirm your email'}</ModelHeading>
						<Text isCentered type='normal' color='text' margin='0.5rem 0rem 2rem 0rem'>{(verifyEmailCheck === false && emailSendCheck === false) ? "Please enter the email you’d prefer to receive these notifications via." : emailSendCheck ? `We’ve sent a confirmation email to ${emailAddress}. Please check your inbox!` : `${emailAddress} Is this the email you want to receive updates on? If not, you can update it now`} </Text>
						{verifyEmailCheck === false && <Input placeholder='enter your email account here' type='text' value={emailAddress} onChange={(e) => { setEmailAddress(e.target.value) }} />}
						{(verifyEmailCheck === false && emailSendCheck === false) &&
							<Flexed justify='center' margin='1rem 0rem 0rem 0rem'>
								<Button disabled={!regex.test(emailAddress)} onClick={() => setVerifyEmailCheck(true)}>OK</Button>
							</Flexed>}

						{(verifyEmailCheck === true && emailSendCheck === false) &&
							<Flexed align='center' direction='column' margin='1rem 0rem 0rem 0rem' gap={0.8}>
								<Button onClick={saveEmail}>Confirm</Button>
								<CancelButton onClick={() => setVerifyEmailCheck(false)}>Change</CancelButton>
							</Flexed>
						}
						{emailSendCheck &&
							<Flexed justify='center' margin='1rem 0rem 0rem 0rem'>
								<Button onClick={() => {
									if (emailAddress) {
										setEmailSendCheck(false)
										setVerifyEmailCheck(false)
									}
									setIsEmailModelOpen(false)
								}}>OK</Button>
							</Flexed>
						}
					</div>
				</StyledModal>
			</ModalProvider>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const DownArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    color: ${palette.text};
	transform: ${({ active }) => active ? 'rotate(-90deg)' : 'rotate(90deg)'};
	transition: all 0.3s ease;
`;

const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${DownArrow}{
		color: ${({ isExpanded }) => isExpanded ? '' : palette.text};
	}
`;

const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
`;

const InnerContent = styled.section`
	position:relative;
`;

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
	color: ${palette.yellow_border};
`

const StyledModal = Modal.styled`
width: 90%;
	border-radius: 8px;
  	padding: 2rem !important;
  	background-color: ${palette.black};
	border: 1px solid ${palette.yellow_border};
	${media.md`
        width: 600px;
    `}
`

const Input = styled.input`
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	padding: 0.7rem 1rem;
	margin-top: 1rem;
	width: 100%;
	font-size: 1rem;
	outline: none;
	height: 42px;
	border: 1px solid #c6c6c6;
	background-color: ${palette.black};
	color:  ${palette.white};

	&:hover{
		border: 1px solid ${palette.yellow_border};
    }
	&:focus{
		border: 1px solid ${palette.hover_color};
	}
`

const Button = styled.button`
	padding: 0.7rem 1.8rem;
	border-radius: 20px;
	color: white;
	background: ${palette.btn_gradient};
	border: none;
	font-size: 16px;
	opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
	cursor: ${({ disabled }) => disabled ? 'no-drop' : 'pointer'};
		&:hover{
			background: ${palette.btn_hover_gradient};
		}
`

const Action = styled.div`
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
`

const CancelButton = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: ${palette.yellow_theme};
    background: transparent;
	cursor: pointer;
	border: none;
	font-size: 16px;
	min-width: 140px;
	height: 42px;
    &:hover{
        background: #00000003;
	}
 `

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
	left:0;
	right:0;
`

export default NotificationSettings
