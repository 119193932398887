import * as types from './types'

export const setUserDetails = (payload) => {
	return {
		type: types.USER_DETAILS,
		value: payload
	}
}

export const userWhoLikesMe = (payload) => {
	return {
		type: types.USER_WHO_LIKES_ME,
		value: payload
	}
}

export const appContentReload = (payload) => {
	return {
		type: types.APP_CONTENT_RECORD,
		value: payload
	}
}
