import React from 'react'
import styled from 'styled-components'

const Faq = () => {
    return (
        <MainParent>
            <Header>
                <Text>Contact and FAQs</Text>
            </Header>
            <Center>
                <h1>FAQ</h1>
            </Center>
        </MainParent>
    )
}

const MainParent = styled.div`
width: calc(100% - 400px);
height: 100vh;
`

const Header = styled.div`
display: flex;
justify-content:center;
align-items:center;
width: 100%;
height: 72px;
border-bottom: 1px solid lightgray;
margin-bottom: 2rem;
`

const Text = styled.p`
font-size: 18px;
text-align: center;
`


const Center = styled.div`

display: flex;
justify-content:center;
flex-direction: column;
align-items:center;

`

export default Faq