import React, { useEffect, useState } from 'react'
import Logo from '../components/Logo/Logo'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import axios from 'axios'
import RegisterStepper from '../components/RegisterStepper'
import { getUser } from '../apis/apis'
import { IoIosArrowForward } from 'react-icons/io'
import { ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import { FaRegCircle } from "react-icons/fa";
import { BorderWrapper, OverLay } from '../styled/shared'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export const genderList = ['Agender', 'Androgyne', 'Androgynes', 'Androgynous', 'Bigender', 'Cis', 'Cisgender', 'Enby', 'F2M', 'FTM', 'Female to Male', 'Female to male trans man', 'Female to male transgender man', 'Female to male transsexual man', 'Gender neutral', 'Gender nonconforming', 'Gender questioning', 'Gender variant', 'Genderfluid', 'Genderqueer', 'Intersex', 'Intersex man', 'Intersex person', 'Intersex woman', 'M2F', 'MTF', 'Male to Female', 'Male to female trans woman', 'Male to female transgender woman', 'Male to female transsexual woman', 'Neutrois', 'Nonbinary', 'Pangender', 'Polygender', 'T* Man', 'T* woman', 'Trans', 'Trans Female', 'Trans Male', 'Trans Person', 'Trans man', 'Trans woman', 'Trans*Female', 'Trans*Male', 'Trans*Man', 'Trans*Person', 'Trans*Woman', 'Transexual', 'Transexual Female', 'Transexual Male', 'Transexual Man', 'Transexual Person', 'Transexual Woman', 'Transfeminine', 'Transgender', 'Transgender Female', 'Transgender Male', 'Transgender Man', 'Transgender Person', 'Transgender Woman', 'Transmasculine', 'Two* person', 'Two-spirit', 'Two-spirit person', 'Cis Female', 'Cis Male', 'Cis man', 'Cis woman', 'Cisgender Female', 'Cisgender Male', 'Cisgender Man', 'Cisgender Woman', 'Man', 'Woman', 'I use another term']
const Gender = () => {
	const _navigate = useNavigate()
	const [gender, setGender] = useState('')
	const [genderMore, setGenderMore] = useState('')
	const [openGenderModal, setOpenGenderModal] = useState(false)

	const [isMianloading, setIsMianloading] = useState(false);

	const submitForm = async (e) => {
		e.preventDefault()
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}

		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					gender: gender ? gender : genderMore,
					last_screen: 'ChooseModeScreen'
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					_navigate('/chooseMode')
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const handleSelectChange = (val) => {
		setGender(val)
		setGenderMore('')
	}

	const getUserinfo = async () => {
		setIsMianloading(true)
		let user = await getUser()
		if (user?.data?.data?.user?.gender) {
			if (user?.data?.data?.user?.gender === 'Woman' || user?.data?.data?.user?.gender === 'Man') {
				setGender(user?.data?.data?.user?.gender)
			}
			else {
				setGenderMore(user?.data?.data?.user?.gender)
			}
		}
		setIsMianloading(false);
	}
	useEffect(() => {
		getUserinfo()
	}, [])

	const genderModalToggle = async (e) => {
		setOpenGenderModal(!openGenderModal)
	}
	return (<>
		<Wrapper>
		<OverLay/>
			<Container>
				<Row justifyContent="center">
				<Col sm={9} md={9} lg={7}>
						<BorderWrapper>
							<RegisterStepper progress={80} goBack={() => { _navigate('/enter-dob') }} />
							<Heading>And how do you identify?</Heading>
							<Center>
								<Box onClick={() => handleSelectChange('Woman')} active={gender === 'Woman'}>
									{gender === 'Woman' ?
										<Check data-origin="pipeline" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" fill={palette.hover_color}></path><path d="M17.407 7.532a.75.75 0 111.06 1.06L11.28 15.78a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 111.06-1.06l3.22 3.22 6.657-6.658z" fill="#fff"></path></Check>
										:
										<FaRegCircle fontSize={24} color={palette.yellow_border}/>
									}
									<label htmlFor="women">Woman</label>
								</Box>
								<Box onClick={() => handleSelectChange('Man')} active={gender === 'Man'}>
									{gender === 'Man' ?
										<Check data-origin="pipeline" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" fill={palette.hover_color}></path><path d="M17.407 7.532a.75.75 0 111.06 1.06L11.28 15.78a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 111.06-1.06l3.22 3.22 6.657-6.658z" fill="#fff"></path></Check>
										:
										<FaRegCircle fontSize={24} color={palette.yellow_border}/>
									}
									<label htmlFor="men">Man</label>
								</Box>
								<Choose
									onClick={() => setOpenGenderModal(true)}>
									{genderMore ? genderMore : 'More options...'}
									<RightArrow />
								</Choose>
							</Center>
							<Button disabled={gender === '' && genderMore === ''} type="submit" onClick={submitForm}>
								Continue
							</Button>
						</BorderWrapper>
					</Col>
				</Row>
			</Container>
			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={openGenderModal} onBackgroundClick={genderModalToggle} onEscapeKeydown={genderModalToggle}>
					<div>
						<ModelHeading>Select your gender</ModelHeading>
						{genderList.map((gender) => {
							return (
								<ChooseGender active={gender == genderMore} onClick={() => { setGenderMore(gender); setGender(''); setOpenGenderModal(false) }}>
									{gender}
								</ChooseGender>
							)
						})}

					</div>
				</StyledModal>
			</ModalProvider>
		</Wrapper>
		{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
	</>
	)
}

const Wrapper = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	background: url('/home_cover.webp');
	width: 100%;
	background-size: cover;
	background-position: center;
	background-reapete: center;
	background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
`

const Heading = styled.div`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
	margin-bottom: 1.5rem;
	line-height:1.5;
	color: ${palette.yellow_border};
	${media.xs`
		font-size: 18px;
	`}
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 1rem;
`

const Wrap = styled.div`
	margin-top: 3.5rem;
`

const Check = styled.svg`
	height: 24px;
`
const UnCheck = styled(Check)`
`

const Box = styled.div`
	cursor: pointer;
	height: 4rem;
	border-radius: 20px;
	width: 70%;
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
	color: ${palette.text};
	${media.xs`width: 100%;`}
	border: 1px solid  ${({ active }) => active ? palette.yellow_border : palette.yellow_border};
	min-height: 56px;
	background: ${({ active }) => active ? palette.yellow_gradient : palette.black};
	&:hover{
        background: ${palette.yellow_gradient};
        border: 1px solid ${palette.yellow_border};
    }
`

const Button = styled.button`
	padding: 0.8rem 1rem;
	position: relative;
	border-radius: 20px;
	color: white;
	margin: 2rem auto 1rem;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
	height: 40px;
	width: 170px;
	opacity: ${({ disabled, isLoading }) => isLoading ? '1' : disabled ? '0.5' : '1'};
    cursor: ${({ disabled, isLoading }) => isLoading ? 'pointer' : disabled ? 'no-drop' : 'pointer'};
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const RightArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    color: ${palette.light_gray};
`;

const Choose = styled.div`
	width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1rem;
	margin-top: 1rem;
	cursor: pointer;
	border: 1px solid ${palette.yellow_border};
	background: ${palette.black};
    height: 40px;
    color: ${palette.text};
    font-weight: 500;
    &:hover{
        background: ${palette.yellow_gradient};
        border: 1px solid ${palette.hover_color};
    }
    &:hover ${RightArrow}{
        color: ${palette.text};
    }
	${media.xs`width: 100%;`}
`

const ChooseGender = styled(Choose)`
	width: 100%;
`

const StyledModal = Modal.styled`
	width: 90%;
	max-height: 500px;
	border-radius: 8px;
  	padding: 2rem !important;
  	background-color: ${palette.black};
	border: 1px solid ${palette.yellow_border};
	overflow: auto;
	${media.md`
		width: 500px;
    `}
`

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
	line-height: 2.3rem;
	color: ${palette.yellow_border};
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

export default Gender
