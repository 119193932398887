import {useEffect, useState} from 'react'
import {NavLink, useLocation, Link, useNavigate} from 'react-router-dom'
import './navbar.css'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {CommonEnum} from '../../utils'
import {palette} from '../../styled/colors'
import {useSelector} from 'react-redux'
import UserDropdown from './UserProfile'
import {Flexed} from '../../styled/shared'

function NavBar() {
	const [click, setClick] = useState(false)
	const _navigate = useNavigate()
	const [showRoute, setShowRoute] = useState(false)
	const handleClick = () => setClick(!click)
	const Close = () => setClick(false)
	const {pathname} = useLocation()

	const _token = sessionStorage.getItem('authorization')
	const _loginUser = useSelector((state) => state.auth.userDetails)
	useEffect(() => {
		if (_loginUser || _token) {
			if (_loginUser?.last_screen == 'login') setShowRoute(true)
		} else {
			setShowRoute(false)
		}
	}, [_loginUser, _token])

	return (
		<>
			<Wrapper>
				<MainContainer className={click ? 'main-container' : ''} onClick={() => Close()} />

				<Container fluid>
					<Navbar className="navbar" onClick={(e) => e.stopPropagation()}>
						<NavContainer>
							<NavLink exact to="/">
								<LogoWrapper>
									<Logo src={CommonEnum.logo} alt={CommonEnum.AppName} /> {CommonEnum.AppName}
								</LogoWrapper>
								{/* edating */}
							</NavLink>

							<NavMenu>
								{/* <NavList active={pathname == '/'}>
									<NavLinks>
										<NavLink style={{color: 'white'}} exact to="/" activeClassName="active" onClick={click ? handleClick : null}>
											Home
										</NavLink>
									</NavLinks>
								</NavList> */}
								{showRoute && (
									<NavList active={pathname == '/app'}>
										<NavLinks>
											<NavLink
												style={{color: 'white'}}
												exact
												to="/app"
												// activeClassName="active"
												onClick={click ? handleClick : null}>
												Our App
											</NavLink>
										</NavLinks>
									</NavList>
								)}
								<NavList active={pathname == '/demo'}>
									<NavLinks>
										<NavLink style={{color: 'white'}} exact to="/demo" onClick={click ? handleClick : null}>
											Demo
										</NavLink>
									</NavLinks>
								</NavList>
								<NavList active={pathname == '/shop'}>
									<NavLinks>
										<NavLink
											style={{color: 'white'}}
											exact
											to="/shop"
											// activeClassName="active"
											onClick={click ? handleClick : null}>
											Shop (static page only)
										</NavLink>
									</NavLinks>
								</NavList>
								<NavList active={pathname == '/contact-us'}>
									<NavLinks>
										<NavLink
											style={{color: 'white'}}
											exact
											to="/contact-us"
											// activeClassName="active"
											onClick={click ? handleClick : null}>
											Contact Us
										</NavLink>
									</NavLinks>
								</NavList>
							</NavMenu>

							<NavMenuForMobile active={click} className={click ? 'nav-menu active' : 'nav-menu'}>
								<NavList
									active={pathname == '/'}
									className="nav-item"
									onClick={() => {
										_navigate('/')
										if (click) {
											handleClick()
										}
									}}>
									<NavLinks>Home</NavLinks>
								</NavList>
								{showRoute && (
									<NavList
										active={pathname == '/app'}
										className="nav-item"
										onClick={() => {
											_navigate('/app')
											if (click) {
												handleClick()
											}
										}}>
										<NavLinks>Our App</NavLinks>
									</NavList>
								)}
								<NavList
									active={pathname == '/shop'}
									className="nav-item"
									onClick={() => {
										_navigate('/shop')
										if (click) {
											handleClick()
										}
									}}>
									<NavLinks>Shop</NavLinks>
								</NavList>

								<NavList
									active={pathname == '/demo'}
									className="nav-item"
									onClick={() => {
										_navigate('/demo')
										if (click) {
											handleClick()
										}
									}}>
									<NavLinks>Demo</NavLinks>
								</NavList>

								<NavList
									active={pathname == '/contact-us'}
									className="nav-item"
									onClick={() => {
										_navigate('/contact-us')
										if (click) {
											handleClick()
										}
									}}>
									<NavLinks>Contact Us</NavLinks>
								</NavList>
							</NavMenuForMobile>
							<Flexed align="center" gap={1}>
								{showRoute ? (
									<UserDropdown user={_loginUser} />
								) : (
									<LoginButton>
										<NavLink style={{color: 'white'}} exact to="/get-started" activeClassName="active" onClick={click ? handleClick : null}>
											{'Login /'}
										</NavLink>{' '}
										&nbsp;
										<NavLink style={{color: 'white'}} exact to="/get-started" activeClassName="active" onClick={click ? handleClick : null}>
											Signup
										</NavLink>
									</LoginButton>
								)}
								<Bars onClick={handleClick}>
									<i className={click ? 'fa fa-times' : 'fa fa-bars'}></i>
								</Bars>
							</Flexed>
						</NavContainer>
					</Navbar>
				</Container>
			</Wrapper>
			<BodyContent />
		</>
	)
}

const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000;
	//   box-shadow:${palette.hover_color} 0px 0px 8px 0.5px !important ;
	border-bottom: 1px solid ${palette.yellow_border};
	background-color: ${palette.black};
`
const BodyContent = styled.div`
	margin-top: 60px; /* Adjust this value based on your navbar height */
`

const LogoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${palette.hover_color};
	font-size: 2rem;
	${media.xs`font-size: 1.5rem;`}
`

const Logo = styled.img`
	margin-right: 0.5rem;
	height: 2.5rem;
	${media.xs`height: 2rem;`}
`

const Navbar = styled.div`
	height: 70px;
	display: flex;
	position: sticky;
	top: 0;
	left: 0;
	/* justify-content: space-evenly; */
	align-items: center;
	z-index: 10000;
	position: sticky;
`
const NavMenu = styled.div`
	list-style: none;
	text-align: center;
	justify-content: center;
	border-radius: 20px !important;
	background: ${palette.hover_color};
	-webkit-transition: -webkit-box-shadow 0.3s ease-out;
	transition: -webkit-box-shadow 0.3s ease-out;
	transition: box-shadow 0.3s ease-out;
	transition: box-shadow 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
	justify-content: space-between;
	color: white !important;
	display: flex;
	@media screen and (max-width: 960px) {
		display: none;
	}
`

const NavMenuForMobile = styled.div`
	list-style: none;
	text-align: center;
	/* justify-content: center; */
	border-radius: 20px !important;
	background: ${palette.hover_color};
	-webkit-transition: -webkit-box-shadow 0.3s ease-out;
	transition: -webkit-box-shadow 0.3s ease-out;
	transition: box-shadow 0.3s ease-out;
	transition: box-shadow 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
	/* justify-content: space-between; */
	color: white !important;
	display: none;
	@media screen and (max-width: 960px) {
		display: flex;
		opacity: ${({active}) => (active ? '1' : '0')};
	}
`
const NavLinks = styled.div`
	color: white !important;
	font-weight: 600;
	text-decoration: none;
	font-size: 14px;
`
const NavList = styled.div`
	text-align: left;
	border-radius: ${({active}) => active && '20px'};
	color: ${({active}) => (active ? palette.white : palette.white)} !important;
	background: ${({active}) => (active ? palette.btn_gradient : '')};
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 0;
	height: 40px;
	padding: 4px 20px;
	transition: color 0.2s;
`

const NavContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`

const Bars = styled.div`
	cursor: pointer;
	display: none;
	color: ${palette.white};
	font-size: 2rem;
	@media screen and (max-width: 960px) {
		display: block;
		font-size: 1.5rem;
	}
`

const LoginButton = styled.div`
	text-align: left;
	border-radius: 20px;
	background: ${palette.btn_gradient};
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 0;
	height: 40px;
	padding: 4px 20px;
	transition: color 0.2s;
	&:hover {
		background: ${palette.btn_hover_gradient};
	}
`
const MainContainer = styled.div`
	.main-container {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0, 0, 0, 0.3);
	}
`

export default NavBar
