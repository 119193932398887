import React from 'react'
import { Col, Container, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import Logo from '../Logo/Logo'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { palette } from '../../styled/colors'
import { BsFacebook, BsLinkedin } from 'react-icons/bs'
import { CommonEnum } from '../../utils'
import { TbWorld } from 'react-icons/tb'
import { media } from 'styled-bootstrap-grid'

const Footer = () => {

    return (
        <>
            <FooterWraper>
                <Container fluid>
                    <Row>
                        <Col xl={2} lg={4} md={4} sm={6}>
                            <LogoWrapper>
                                <Link to={"/"}><Li> <Logo /> </Li></Link>
                                <Img onClick={()=>{ window.open(CommonEnum.appStoreUrl, '_blank');}} src='/App_Store_Badge.svg' alt='App_Store_Badge'/>
                                <Img onClick={()=>{ window.open(CommonEnum.googleStoreUrl, '_blank');}} src='/Google_Play_Store.svg' alt='Google_Play_Store'/>
                            </LogoWrapper>
                        </Col>

                        <Col xl={3} lg={4} md={4} sm={6}>
                            <TopSpace>
                                <Top>SHOP</Top>
                                <Ul>
                                    <Link to={"#"}><Li>WEDDING & CELEBRATIONS <br /> COLLECTION</Li></Link>
                                    <Link to={"#"}><Li><span style={{ textTransform: 'uppercase' }}>{CommonEnum.AppName}</span> CAPSULE COLLECTION</Li></Link>
                                    <Link to={"#"}><Li>BABY COLLECTION</Li></Link>
                                </Ul>
                            </TopSpace>
                        </Col>

                        <Col xl={3} lg={4} md={4} sm={6}>
                            <TopSpace>
                                <Top>HELP</Top>
                                <Ul>
                                    <Link to={"/contact-us"}><Li>CONTACT US</Li></Link>
                                    <Link> <Li>RETURNS & EXCHANGES</Li></Link>
                                    <Link to={"#"}><Li>FAQS</Li></Link>
                                    <Link to={"#"}><Li>DO NOT SELL MY PERSONAL <br /> INFORMATION</Li></Link>
                                </Ul>
                            </TopSpace>
                        </Col>
                        <Col xl={2} lg={4} md={4} sm={6}>
                            <TopSpace>
                                <br />
                                <Ul>
                                    <Link to={"#"}><Li>TERMS & CONDITIONS</Li></Link>
                                    <Link to={"#"}><Li>PRIVACY POLICY</Li></Link>
                                    <Link to={"#"}><Li>COOKIE POLICY</Li></Link>
                                </Ul>
                            </TopSpace>
                        </Col>
                        <Col xl={2} lg={4} md={4} sm={6}>
                            <TopSpace>
                                <Top>More</Top>
                                <Ul>
                                    <Link to={"#"}><Li>ABOUT US</Li></Link>
                                    <Link> <Li>THE BUZZ</Li></Link>
                                    <Link to={"#"}><Li>CAREERS</Li></Link>
                                </Ul>
                            </TopSpace>
                        </Col>
                    </Row>


                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col xl={12} lg="12" md="4" sm="12" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <Link to={CommonEnum.fbUrl} aria-label='facebook' target='_blank'>
                                <BsFacebook style={{ color: palette.hover_color, fontSize: '1.5rem', marginLeft: '1.5rem' }} />
                            </Link>

                            <Link to={CommonEnum.linkedinUrl} aria-label='linkedin' target='_blank'>
                                <BsLinkedin style={{ color: palette.hover_color, fontSize: '1.5rem', marginLeft: '1.5rem' }} />
                            </Link>

                            <Link to={CommonEnum.websiteUrl} aria-label='website' target='_blank'>
                                <TbWorld style={{ color: palette.hover_color, fontSize: '1.5rem', marginLeft: '1.5rem' }} />
                            </Link>

                        </Col>
                        <Col xl={12} lg="12" md="4" sm="12" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <Text>© {moment().format('YYYY')} {CommonEnum.AppName}. All rights reserved.</Text>
                        </Col>
                    </Row>
                </Container>
            </FooterWraper>
        </>
    )
}


const FooterWraper = styled.div`
    padding: 2rem;
    background:${palette.black};
    height: auto;
`

const TopSpace = styled.div`
    margin-top: 2rem;
    ${media.xs` 
        display: flex;
        flex-direction: column;
        align-items: center;
    `}
`

const Top = styled.div`
    font-size: 14px;
    color: ${palette.hover_color};
    margin-bottom: 1.2rem;
    
`
const Text = styled.p`
    font-size: 12px;
    color: white;
`
const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
`

const Ul = styled.div`
    margin-top: 2rem;
    ${media.xs` 
    margin-top: 0rem;
        `}
`

const Li = styled.div`
    font-size: 13px;
    color: white;
    line-height: 2.6;
    &:hover{

        color: ${palette.hover_color};
    }
`

const Img = styled.img`
    cursor: pointer;
    object-fit: contain;
    width: 9rem;
}
`
export default Footer