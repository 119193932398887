import React from 'react'
import { pdfjs } from 'react-pdf'
import styled from 'styled-components'
import { palette } from '../styled/colors'
import FirstStep from '../components/frontPage/FirstStep'
import SecondStep from '../components/frontPage/SecondStep'
import ThirdStep from '../components/frontPage/ThirdStep'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const Features = () => {
    return (
        <div style={{ overflow: 'hidden' }}>
            {/* <Wrapper>
                <div style={{ width: '100%', maxWidth: '1000px' }}>
                    <Worker workerUrl={`//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                        <Viewer fileUrl="./Feature_set_of_Dating.docx.pdf" />
                    </Worker>
                </div>
            </Wrapper> */}
            <FirstStep />
			<SecondStep />
			<ThirdStep/>
        </div>
    )
}

const Wrapper = styled.div`
	padding: 1rem;
	border: 1px solid ${palette.stroke};
	border-radius: 0.5rem;
	transition: border 0.1s ease 0.1s;
	display: flex;
	justify-content: center;
`

export default Features
