
import { Col, Container, Row } from 'styled-bootstrap-grid'
import React from 'react'
import ShopCard from './ShopCard'
import styled from 'styled-components'
import { palette } from '../../styled/colors'

const ShopCardWraper = () => {

    const shopInfo = [
        { name: "VARSITY SWEATER", price: 79, image: "./shop-images/clothes1.webp", },
        { name: "TAPED CREWNECK", price: 68, image: "./shop-images/clothes2.avif.avif" },
        { name: "CLASSIC T-SHIRT", price: 30, image: "./shop-images/clothes3.avif.avif" },
        { name: "EMBROIDERED TOP", price: 28, image: "./shop-images/clothes7.webp" },
        { name: "PERFORMANCE ROPE HAT", price: 25, image: "./shop-images/clothes5.avif.avif" },
        { name: "PLAYING CARDS", price: 15, image: "./shop-images/clothes6.jpeg" },
    ]

    return (

        <>
            <ShopWraper>
                <Container fluid>
                    <Row>
                        {
                            shopInfo?.map((card, index) => {
                                return (
                                    <Col xl={4} lg="4" md="4" sm="12">
                                        <ShopCard card={card} key={index} />
                                    </Col>
                                )
                            })
                        }
                        <Button>SEE FULL COLLECTION</Button>
                    </Row>
                </Container>
            </ShopWraper>

        </>
    )
}


const ShopWraper = styled.div`
margin-top: 2rem;

`

const Button = styled.div`
display: block;
margin: auto;
border: 2 solid transparent;
border-radius: 4px;
background: ${palette.btn_gradient};
width: fit-content;
padding: 15px 30px;
cursor: pointer;
border-radius: 40px;
margin-top: 3rem;
color: white;
text-decoration: none;
font-weight: 400;
&:hover{
    background: ${palette.btn_hover_gradient};
}

`


export default ShopCardWraper