import styled from 'styled-components'
import { palette } from './colors';

const handleParagraphFontSize = (type) => {
	switch (type) {
		case 'large':
			return '1.25rem'
		case 'medium':
			return '1.125rem'
		case 'normal':
			return '1rem'
		case 'small':
			return '0.875rem'
		case 'xsmall':
			return '0.75rem'
		default:
			return '0.875rem'
	}
}

const handleParagraphLineHeight = (type) => {
	switch (type) {
		case 'large':
			return '1.688rem'
		case 'medium':
			return '1.563rem'
		case 'normal':
			return '1.5rem'
		case 'small':
			return '1.125rem'
		case 'xsmall':
			return '0.938rem'
		default:
			return '1.125rem'
	}
}

// Paragraph Levels
//             fontSize              LineHight
// large    20px || 1.25rem     .      27px || 1.688rem
// medium   18px || 1.125rem    .      25px || 1.563rem
// normal   16px || 1rem        .      24px || 1.5rem
// small    14px || 0.875rem    .      18px || 1.125rem
// xsmall    12px || 0.875rem    .      18px || 1.125rem

export const Text = styled.div`
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
	font-size: ${({ type, fontSize }) => (fontSize ? `${fontSize}rem` : handleParagraphFontSize(type))};
	line-height: ${({ type, lineHeight }) => (lineHeight ? `${lineHeight}rem` : handleParagraphLineHeight(type))};
	color: ${({ color }) => (color ? palette[color] : palette.white)};
	margin: ${({ margin }) => `${margin}`};
	text-align: ${({ isCentered }) => (isCentered ? `center` : 'left')};
	cursor: ${({ pointer }) => (pointer ? `pointer` : '')};
	opacity: ${({ opacity }) => (opacity ? opacity : '1')};
	text-transform: ${({ textTransform }) => (textTransform ? textTransform : '')};
	text-decoration: ${({ textDecoration }) => textDecoration};
	text-underline-offset: ${({ textDecoration }) => (textDecoration ? '2.9px' : '')};
	white-space: ${({ whiteSpaces }) => whiteSpaces};
	&:hover{
		color: ${({ color, isHover }) => (color && isHover && palette.black)};
	}
`

export const Divider = styled.div`
	height: 1px;
	width: 100%;
	background: gray;
	margin: ${({ margin }) => `${margin}`};
`

export const Spacer = styled.div`
	height: ${({ height }) => `${height}rem`};
`

export const Flexed = styled.div`
	display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
	flex-direction: ${({ direction }) => direction};
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	margin: ${({ margin }) => `${margin}`};
	gap: ${({ gap }) => `${gap}rem`};
	cursor: ${({ pointer }) => (pointer ? `pointer` : '')};
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${({ height }) => (height ? height : 'auto')};
	flex-wrap: ${({ flexWrap }) => `${flexWrap}`};
`

export const Error = styled.div`
    font-size: 12px;
    color: ${palette.red};
    text-align: center;
`;

export const CustomSwitch = styled.label`
	display: inline-block;
	position: relative;
	font-size: 20px;
	height: 10px;
	width: 2.3rem;
	background: ${({ checked }) => checked ? '#fde1a0' : palette.light_gray};
	border-radius: 5px;
	& div {
		height: 18px;
		width: 18px;
		border-radius: 1em;
		background: ${({ checked }) => checked ? palette.brown_rgb : palette.white};
		border: 1px solid ${({ checked }) => checked ? palette.brown_rgb : palette.light_gray};
		-webkit-transition: all 300ms;
		-moz-transition: all 300ms;
		transition: all 300ms;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	& input {
		position: absolute;
		opacity: 0;
	}
	& input:checked+div {
		-webkit-transform: translate3d(100%, 0, 0);
		-moz-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
`;

export const OverLay = styled.div`
	position: absolute;
	background: ${palette.overlay};
	width: 100%;
	top:0;
	bottom:0;
	left:0;
	right:0;
`

export const BorderWrapper = styled.div`
	border: 1px solid ${palette.yellow_border};
	border-radius: 0.5rem;
	margin-top:5rem;
	padding: 2rem 2rem;
`;
