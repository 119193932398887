import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import Modal from 'styled-react-modal'

import axios from 'axios'
import { BorderWrapper, Flexed, OverLay, Spacer, Text } from '../styled/shared'
import AccountVerificationModal from '../components/modals/AccountVerificationModal'
import useRouter from '../components/Common/UseRouterHook'
import { CommonEnum } from '../utils'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Verification = () => {
    const [openAccountVerificationModal, setOpenAccountVerificationModal] = useState(false)
    const router = useRouter()

    const _token = sessionStorage.getItem('authorization')

    useEffect(() => {
        if (!_token) {
            router.navigate('/')
        }
    }, [_token])
    return (
        <>
            <Wrapper>
            <OverLay/>
                <Container>
                    <Row justifyContent="center">
                    <Col sm={9} md={9} lg={7}>
                            <BorderWrapper>
                                {/* <Spacer height={3} /> */}
                                <Center>
                                    <Image src='/icons/default_profile.png' alt='' />
                                </Center>

                                <Heading>Now let's get you verified</Heading>
                                <Spacer height={1.3} />
                                <Text type='normal' color='text' isCentered>
                                    Help us keep {CommonEnum.AppName} safe and honest by verifying yourself with a one-time selfie. We'll only use your photo to confirm you're you. It doesn't have to be your best.
                                </Text>
                                <Spacer height={0.2} />
                                <Info>
                                    This photo will never be uploaded to your profile. See our <span>Privacy Policy</span> or contact <span>Support</span> for more info.
                                </Info>

                                <Spacer height={2} />

                                <Flexed justify='center'>
                                    <Button onClick={() => { setOpenAccountVerificationModal(true) }}>Get verified</Button>
                                </Flexed>
                            </BorderWrapper>
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
            <AccountVerificationModal isOpen={openAccountVerificationModal} onClose={() => { setOpenAccountVerificationModal(false) }} />
        </>
    )
}

const Wrapper = styled.div`
    position: relative;
    padding: 0rem 0rem 1rem 0rem;
    background: url('/home_cover.webp');
    width: 100%;
    background-size: cover;
    background-position: center;
    background-reapete: center;
    background-repeat: no-repeat;
    min-height: calc(100vh - 60px);
`

const Heading = styled.div`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
    color: ${palette.yellow_border};
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Image = styled.img`
    border-radius: 100%;
`;

const Wrap = styled.div`
margin-top: 3.5rem;
`
const progressBarAnimation = keyframes`
  0% {
    width: 100%;
  }
  25% {
    width: 75%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 25%;
  }
  100% {
    width: 0%;
  }
`;

const Info = styled.div`
    margin-top: 1rem;
    font-size: 16px;
    color: ${palette.text};
    text-align: center;
    line-height: 1.3rem;
    & span{
        text-decoration: underline;
        text-underline-offset: 5px;
        text-decoration-color: ${palette.yellow_border};
        color: ${palette.yellow_border};
        font-weight: bold;
        cursor: pointer;
    }
    & span:hover{
        text-decoration-color: ${palette.yellow_theme};
        cursor: pointer;
    };
`;

const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

export default Verification
