import React, { useEffect, useState, useRef } from 'react'
import { useCollapse } from 'react-collapsed'
import styled from 'styled-components';
import { palette } from '../../../styled/colors';
import { Flexed } from '../../../styled/shared'
import { FaFacebook } from "react-icons/fa6";
import { IoLogoLinkedin } from "react-icons/io5";
import { getUser } from '../../../apis/apis';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import { toastError } from '../../../styled/toastStyle';
import { setUserDetails } from '../../../redux/actions/authActions';
import useRouter from '../../Common/UseRouterHook';

const AccountCollapse = () => {
    const _dispatch = useDispatch()
    const [isExpanded, setExpanded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [user, setUser] = useState(null)
    const router = useRouter()

    const getUserProfile = async () => {
        const response = await getUser()
        setUser(response?.data?.data?.user)
    }

    useEffect(() => {
        getUserProfile()
    }, [])

    const onSave = async (isVerifyFb,isVerifyInstagram,isVerifyLinkedin) => {
        setIsLoading(true)
        const _token = sessionStorage.getItem('authorization')
        if (_token) {
            const config = {
                headers: { authorization: `bearer ${_token}` }
            }
            await axios
                .post(
                    `${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
                    {
                        is_verify_fb: isVerifyFb,
                        is_verify_instagram: isVerifyInstagram, 
                        is_verify_linkedin: isVerifyLinkedin
                    },
                    config
                )
                .then((response) => {
                    if (response.data.success === true) {
                        _dispatch(setUserDetails(response.data.data.userdata))
                    }
                    getUserProfile()
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                    setIsLoading(false)
                })
        } else {
            toastError('User not found')
            router.navigate('/')
            setIsLoading(false)
        }
    }
    return (
        <>
            <Box isExpanded={isExpanded} {...getToggleProps({
                onClick: () => { !isExpanded && setExpanded(!isExpanded) },
            })}>
                <BoxHeader justify='space-between' align='center' {...getToggleProps({
                    onClick: () => setExpanded((prevExpanded) => !prevExpanded),
                })}>
                    <Heading>Linked Accounts</Heading>
                </BoxHeader>
                <InnerContent  {...getCollapseProps()}>
                    {isLoading && <Loading ><div class="dots-loading"></div></Loading>}
                    <Connent isLoading={isLoading}>
                        <FaceBook onClick={()=>onSave(!user?.is_verify_fb,user?.is_verify_instagram,user?.is_verify_linkedin)}>
                            <Flex>
                                <FaFacebook fontSize={20} />
                                <span>Connect your facebook</span>
                            </Flex>
                            <Flex>
                                {user?.is_verify_fb ?
                                    <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                                            fill="currentColor"></path>
                                    </svg>
                                    :
                                    <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></svg>
                                }
                            </Flex>
                        </FaceBook>
                        <Instagram onClick={()=>onSave(user?.is_verify_fb,!user?.is_verify_instagram,user?.is_verify_linkedin)}>
                            <Flex>
                                <svg data-origin="pipeline" height="22px" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.006 0c4.345 0 4.892.013 6.598.093 1.325.028 2.637.28 3.878.747 1.053.4 1.946.96 2.84 1.84a7.84 7.84 0 011.838 2.84c.4 1.013.667 2.187.747 3.88.08 1.707.093 2.267.093 6.6 0 4.347-.013 4.893-.093 6.6a11.737 11.737 0 01-.747 3.88c-.4 1.053-.96 1.947-1.839 2.84a7.837 7.837 0 01-2.839 1.84c-1.013.4-2.186.667-3.878.747-1.706.08-2.266.093-6.598.093-4.345 0-4.891-.013-6.597-.093a11.726 11.726 0 01-3.879-.747 7.836 7.836 0 01-2.838-1.84 7.84 7.84 0 01-1.84-2.84 11.737 11.737 0 01-.746-3.88C.011 20.401-.02 18.2.013 16c0-4.347.013-4.893.093-6.6.028-1.326.28-2.638.746-3.88.4-1.053.96-1.947 1.84-2.84A7.836 7.836 0 015.53.84 11.725 11.725 0 019.41.093C11.115.013 11.675 0 16.006 0zm0 2.667c-4.345 0-4.851.013-6.57.093a8.993 8.993 0 00-3.013.56 5.028 5.028 0 00-1.865 1.213A5.114 5.114 0 003.33 6.4a9.003 9.003 0 00-.56 3.027A112.91 112.91 0 002.679 16c0 4.347.014 4.853.094 6.573.08 1.587.333 2.44.56 3.014a5.03 5.03 0 001.212 1.866A5.11 5.11 0 006.41 28.68c.587.227 1.44.48 3.026.56 1.719.08 2.225.093 6.57.093 4.345 0 4.852-.013 6.571-.093a8.993 8.993 0 003.012-.56 5.026 5.026 0 001.866-1.213 5.114 5.114 0 001.226-1.867c.227-.587.48-1.44.56-3.027.08-1.72.093-2.226.093-6.573s-.013-4.853-.093-6.573a9.002 9.002 0 00-.56-3.014 5.04 5.04 0 00-1.213-1.866 5.111 5.111 0 00-1.866-1.227 8.993 8.993 0 00-3.025-.56 112.78 112.78 0 00-6.57-.093zm0 5.333a7.998 7.998 0 017.997 8c0 4.418-3.58 8-7.997 8a7.998 7.998 0 01-7.997-8c0-4.418 3.58-8 7.997-8zm0 13.333A5.332 5.332 0 0021.338 16a5.332 5.332 0 10-10.663 0 5.332 5.332 0 005.331 5.333zM26.47 7.467a1.92 1.92 0 11-3.84 0 1.92 1.92 0 013.84 0z" fill="currentColor"></path></svg>
                                <span>Connect your instagram</span>
                            </Flex>
                            <Flex>
                                {user?.is_verify_instagram ?
                                    <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                                            fill="currentColor"></path>
                                    </svg>
                                    :
                                    <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></svg>
                                }
                            </Flex>
                        </Instagram>
                        <LinkedIn onClick={()=>onSave(user?.is_verify_fb,user?.is_verify_instagram,!user?.is_verify_linkedin)}>
                            <Flex>
                                <IoLogoLinkedin fontSize={21} />
                                <span>Connect your linkedin</span>
                            </Flex>
                            <Flex>
                            {user?.is_verify_linkedin ?
                                <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                                        fill="currentColor"></path>
                                </svg>
                                :
                                <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></svg>
                            }
                            </Flex>
                        </LinkedIn>
                    </Connent>
                </InnerContent>
            </Box>
        </>
    )
}


const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	border: 0.5px solid  ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color:  ${({ isExpanded }) => isExpanded ? palette.white : palette.white};
	&:hover {
		border: 0.5px solid ${palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.white : palette.white};
	}
`

const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	/* &:hover{
		color: ${({ isExpanded }) => palette.black};
	} */
`
const Flex = styled.div`
    display: flex;
    align-items:center;
    gap: 0.5rem;
`

const Heading = styled.h3`
    display: flex;
    justify-content:left;
    align-items:center;
    font-weight: 400;
    cursor: pointer;
`
const Social = styled.div`
    display: flex;
    gap: .5rem;
    align-items:center;
    justify-content:space-between;
    border-radius: 18px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    margin-top: 1.3rem;
    color: white;
`

const FaceBook = styled(Social)`
    background-image: linear-gradient(-226deg,#0052bb,#1877f2 51%,#415eff);
`

const Instagram = styled(Social)`
    background-image: linear-gradient(-226deg,#d0165f,#e1475b 51%,#ee592c);
`

const LinkedIn = styled(Social)`
    background-image: linear-gradient(-226deg,#006294,#0178b5 51%,#0093b8);
`

const Rounded = styled.div`
    border: 3px solid white;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
`

const InnerContent = styled.section`
	position:relative;
`;

const Loading = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Connent = styled.section`
	filter: ${({ isLoading }) => isLoading ? 'blur(1px)' : ''} ;
`;

export default AccountCollapse