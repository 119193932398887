import { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import styled from "styled-components";
import { useLocation } from 'react-router-dom'
import { palette } from "../../styled/colors";


const RangeSliderCustom = ({ valueFrom, valueTo, setValueFrom, setValueTo, saveFilter }) => {
    const { pathname } = useLocation()
    const [value, setValue] = useState([44, 92]);

    useEffect(() => {
        if (valueFrom && valueTo) {
            let _from = parseInt(valueFrom)
            let _to = parseInt(valueTo)
            setValue([_from, _to])
        }
    }, [valueFrom, valueTo])

    const setRangeSlider = (e) => {
        setValue(e)
        setValueFrom(e[0])
        setValueTo(e[1])
    }


    return (
        <>
            <TtleDiv>Between {value[0]} and {value[1]}</TtleDiv>
            {pathname === '/app' ?
                <RangeSlider id="range-slider-yellow" min={18} max={100} value={value} onInput={setRangeSlider} />
                :
                <RangeSlider id="range-slider-yellow" min={18} max={100} value={value} onInput={setRangeSlider} onThumbDragEnd={() => { saveFilter() }} />
            }
        </>
    );
}

const TtleDiv = styled.div`
color: ${palette.white};
`
export default RangeSliderCustom