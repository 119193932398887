
import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { IoClose } from "react-icons/io5";
import { useNavigate } from 'react-router-dom'

const StickyHeader = ({ headerText }) => {
    const _navigate = useNavigate()

    return (
        <Header>
            <Text>{headerText}</Text>
            <Close onClick={() => { _navigate('/app') }} />
        </Header>
    )
}

const Header = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    margin-bottom: 2rem;
    display: flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    height: 72px;
    background: white;
    padding: 0 20px;
    z-index: 3;
    background-color: ${palette.black};
    border-bottom : 1px solid ${palette.yellow_border};
    overflow: scroll;
`

const Text = styled.p`
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    color: ${palette.hover_color};
`

const Close = styled(IoClose)`
    color: ${palette.white};
    font-size: 30px;
    position: absolute;
    right:20px;
    cursor:pointer;
`

export default StickyHeader