import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App.js'
import "./index.css";
import { Provider } from 'react-redux'
import rootReducer from './redux/reducers/index'
import { createStore } from 'redux'
import { ThemeProvider } from 'styled-components'
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ToastContainer } from 'react-toastify'
const store = createStore(rootReducer)


const gridTheme = {
  gridColumns: 12,
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 780,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 15, // default 15
  },
  col: {
    padding: 15, // default 15
  },
  container: {
    padding: 15, // default 15
    maxWidth: { // defaults below
      xxl: 1400,
      xl: 1200,
      lg: 960,
      md: 720,
      sm: 720,
      xs: 540,
    },
  },
};

const styledTheme = {
  mainColor: 'purple'
}


ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={styledTheme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <App />
        </GridThemeProvider>
        <ToastContainer position='top-center' />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)
