import React, { useState } from "react";
import styled from 'styled-components'
import { IoIosArrowForward } from "react-icons/io";
import { palette } from "../../styled/colors";
import PreviewProfileModal from "../modals/PreviewProfileModal";

const PreviewProfile = ({ user }) => {
    const [openPreviewProfileModal, setOpenPreviewProfileModal] = useState(false)
    return (
        <>
            <Box
                onClick={() => setOpenPreviewProfileModal(true)}>
                Preview profile
                <RightArrow />
            </Box>
            {openPreviewProfileModal && <PreviewProfileModal userData={user} isOpen={openPreviewProfileModal} onClose={() => setOpenPreviewProfileModal(false)} />}
        </>
    );
}

const RightArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    color: ${palette.text};
`;

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1rem;
	margin-top: 1rem;
	cursor: pointer;
	border: 0.5px solid ${palette.yellow_border};
    height: 40px;
    color: ${palette.white};
    font-weight: 500;
    &:hover{
        background: ${palette.yellow_gradient};
        border: 1px solid ${palette.yellow_border};
        color: ${palette.text};
    }
    &:hover ${RightArrow}{
        color: ${palette.text};
    }
`

export default PreviewProfile