import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import { BorderWrapper, Error, OverLay, Spacer, Text } from '../styled/shared'
import axios from 'axios'
import RegisterStepper from '../components/RegisterStepper'
import { getUser } from '../apis/apis'
import { CommonEnum } from '../utils'
import { media } from 'styled-bootstrap-grid'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const EnterName = () => {
	const _navigate = useNavigate()
	const [name, setName] = useState('')
	const [nameError, setNameError] = useState('')
	const [isMianloading, setIsMianloading] = useState(false);
	const submitForm = async (e) => {
		e.preventDefault()
		if (name?.trim().length === 0) {
			setNameError('Please enter your name')
		} else {
			setNameError('')
			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}
			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
					{
						first_name: name,
						last_screen: 'UserAddImages'
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
						_navigate('/upload-photo')
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	const getUserinfo = async () => {
		setIsMianloading(true)
		let user = await getUser()
		if (user?.data?.data?.user?.first_name) {
			setName(user?.data?.data?.user?.first_name)
		}
		setIsMianloading(false);
	}

	useEffect(() => {
		getUserinfo()
	}, [])

	return (
		<>
			<Wrapper>
			<OverLay/>
				<Container>
					<Row justifyContent="center">
					<Col sm={9} md={9} lg={7}>
							<BorderWrapper>
								<RegisterStepper progress={50} goBack={() => { _navigate('/confirm-phone') }} />
								<Heading>Nice one! So, what do you like to be called?</Heading>
								<form>
									<Center>
										<Input
											name="name"
											type="text"
											placeholder="Your name"
											value={name}
											onChange={(e) => {
												setName(e.target.value)
											}}
										/>
									</Center>

									{nameError ? <Error margin='0.5rem 0rem 0rem 0rem'>{nameError}</Error> : <Spacer height={0.9} />}

									<Spacer height={0.5} />

									<Text color='text' isCentered>This is how you'll appear on {CommonEnum.AppName}</Text>

									<Spacer height={1} />

									<Button props={name?.trim().length !== 0} onClick={submitForm} type="submit">
										Continue
									</Button>
								</form>
							</BorderWrapper>
						</Col>
					</Row>
				</Container>
			</Wrapper>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const Wrapper = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	background: url('/home_cover.webp');
	width: 100%;
	background-size: cover;
	background-position: center;
	background-reapete: center;
	background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
`

const Heading = styled.p`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
	margin-bottom: 1.5rem;
	line-height:1.5;
	color: ${palette.yellow_border};
	${media.xs`
    font-size: 18px;
    `}
`
const Wrap = styled.div`
margin-top: 3.5rem;
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
export const Input = styled.input`
border: 1px solid ${palette.light_gray};
outline: none;
border-radius: 20px;
font-size: 16px;
width: 25rem;;
padding:.6rem 1rem;
-webkit-transition: 0.5s;
  transition: 0.5s;
  background: ${palette.black};
  color: ${palette.white};
&:focus{
    border: 1px solid ${palette.yellow_border};
}
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	cursor: pointer;
	background: ${palette.btn_gradient};
	color: ${palette.white};
	margin: auto;
	padding: 0.8rem 1rem;
	width: 150px;
	border-radius: 20px;
	border: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	opacity: ${({ props }) => (props ? '1' : '0.5')};
	cursor: ${({ props }) => (props ? 'pointer' : 'default')};
	pointer-events: ${({ props }) => (props ? 'default' : 'none')};
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

export default EnterName
