import React from 'react'
import styled from 'styled-components'
import { Flexed } from '../../styled/shared'
import { CommonEnum } from '../../utils'
import { palette } from '../../styled/colors'

const Logo = () => {
    return (
        <>
            <Wrapper>
                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: palette.hover_color, fontSize: '2rem' }}><img src={CommonEnum.logo} alt={CommonEnum.AppName} width={'30%'} style={{ marginRight: '0.5rem' }} /> {CommonEnum.AppName}</span>
            </Wrapper>
        </>
    )
}


const Wrapper = styled(Flexed)`
    margin-top: 3rem !important;
    justify-content: center;
`

export default Logo