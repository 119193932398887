import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useCollapse } from 'react-collapsed'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Text } from '../../styled/shared'
import { IoIosArrowForward } from "react-icons/io";
import { getUser } from '../../apis/apis'
import { toastError } from '../../styled/toastStyle'

const AboutCollapse = ({ title }) => {
	const textAreaRef = useRef(null);
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [myBio, setMyBio] = useState('')
	const [onCancelMyBio, setOnCancelMyBio] = useState()
	const [user, setUser] = useState([])
	const [inputLength, setInputLength] = useState(0);
	const [isEdit, setIsEdit] = useState(false);

	const addBio = async (e) => {
		e.preventDefault()
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				user?.mode == 'aff' ? {
					bio_aff: myBio
				} : {
					bio_date: myBio
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					setIsEdit(false);
					getUserProfile();
				}
			})
			.catch((error) => {
				console.error(error)
				setIsEdit(false);
			})
	}

	const getUserProfile = async () => {
		const response = await getUser()

		if (response?.data?.data?.user) {
			setUser(response?.data?.data?.user)
			setMyBio(response?.data?.data?.user?.mode == 'aff' ? response?.data?.data?.user.bio_aff : response?.data?.data?.user.bio_date)
			setOnCancelMyBio(response?.data?.data?.user?.mode == 'aff' ? response?.data?.data?.user.bio_aff : response?.data?.data?.user.bio_date)
		} else if (response?.message) {
			toastError(response?.message)
		} else {
			toastError('Auto login failed')
		}

	}

	useEffect(() => {
		getUserProfile()
	}, [])

	useEffect(() => {
		if (textAreaRef.current) {
			// Set the textarea's height to its scrollHeight
			textAreaRef.current.style.height = 'auto';
			textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
		}
	}, [myBio]);

	const onCancel = () => {
		setIsEdit(false);
		setMyBio(onCancelMyBio);
	}

	return (
		<>
			<Box isExpanded={isExpanded} {...getToggleProps({
				onClick: () => { !isExpanded && setExpanded(!isExpanded) },
			})}>
				<BoxHeader justify='space-between' align='center' {...getToggleProps({
					onClick: () => setExpanded((prevExpanded) => !prevExpanded),
				})}>
					<Heading>About Me</Heading>
					<RightArrow active={isExpanded} />
				</BoxHeader>
				<InnerContent {...getCollapseProps()}>
					<Wrapper check={!isEdit} onClick={() => { !isEdit && setIsEdit(true) }}>
						<Input
							ref={textAreaRef}
							type="text"
							maxlength="160"
							value={myBio}
							placeholder="A litle bit about you..."
							onChange={(e) => {
								if (e.target.value.length <= 300) {
									setMyBio(e.target.value)
									setInputLength(e.target.value.length);
								}
							}}
						/>
					</Wrapper>
					{isEdit && <Action margin='1rem 0rem 0rem 0rem' justify='space-between' align='center'>
						<CancelButton onClick={onCancel}>Cancel</CancelButton>
						{inputLength !== 0 && <Text color='black' type='normal' >{(300 - inputLength !== 0) ? 300 - inputLength + ' ' + 'left' : "That's it!"}</Text>}
						<Button onClick={addBio}>Save</Button>
					</Action>}
				</InnerContent>
			</Box>
		</>
	)
}


const RightArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    /* color: ${palette.light_gray}; */
	transform: ${({ active }) => active ? 'rotate(-90deg)' : 'rotate(90deg)'};
	transition: all 0.3s ease;
`;

const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	border: 0.5px solid  ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	&:hover {
		border: 0.5px solid  ${palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
`
const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	};
`

const Button = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: white;
	background: transparent;
    &:hover{
        background: ${palette.btn_hover_gradient}
    }
	border: none;
	font-size: 16px;
	cursor: pointer;
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
`
const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	padding: 0.8rem 1rem;
	margin-top: 1rem;
	border: 1px solid ${({ check }) => check ? palette.yellow_border : palette.yellow_border};
	min-height: 56px;
	background: ${palette.black};
	&:hover{
        // background: ${palette.gray_200};
		// border: 1px solid ${({ check }) => check ? '#c6c6c6' : palette.hover_color};
    }
	cursor: ${({ check }) => check ? 'pointer' : ''};

`

const Input = styled.textarea`
	width: 100%;
	outline: none !important;
	border: none !important;
	font-size: 1rem;
	background: transparent;
	resize: none;
	overflow: hidden;
	min-height: 50px;
  	max-height: 100%;
	color: ${palette.white};
`

const InnerContent = styled.section`
	position:relative;
`;
const Action = styled(Flexed)`
	width: 100%;
`;

const CancelButton = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: ${palette.text};
    background: transparent;
	cursor: pointer;
	border: none;
	font-size: 16px;
    &:hover{
        background: #00000003;
	}
 `

export default AboutCollapse
