import { useEffect, useState } from 'react'
import moment from 'moment'

const useCountdown = (targetDate, returnValues = true, status = false) => {
    const countDownDate = new Date(targetDate).getTime()
    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime())
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [countDownDate])

    return status == 'Closed' ? true : checkRemainingTime(countDown, returnValues)
}

const checkRemainingTime = (countDown, returnValues) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

    return returnValues ? [days, hours, minutes, seconds] : days + hours + minutes + seconds <= 0
}

const getPassedTime = (startingTime, closingTime) => {
    let totalTime = closingTime - startingTime
    let timePassed = moment(new Date()).valueOf() - startingTime
    let timeLeft = closingTime - moment(new Date()).valueOf()
    let percentTimePassed = (timePassed / totalTime) * 100
    if (percentTimePassed < 0) {
        percentTimePassed = 0
    }
    if (percentTimePassed >= 100) {
        percentTimePassed = 100
    }
    return percentTimePassed
}

export { useCountdown, getPassedTime }
