
import styled from 'styled-components'
import React from 'react'
import { Col, Row, Container, media } from 'styled-bootstrap-grid'
import CarouselSlider from '../Carousel/Carousel'
import { CommonEnum } from '../../utils'

const Why = () => {

    return (
        <>
            <Container fluid>
                <Height>
                    <Row>
                        <Col xl={7} lg="12" md="12" sm="12" >
                            <Image src='./why.webp' alt='why' />
                            <Wrapper>

                                <Heading>Why {CommonEnum.AppName}?</Heading>

                                <Text>
                                    <p>
                                        {CommonEnum.AppName} was first founded to challenge the antiquated rules of dating. Now, {CommonEnum.AppName} empowers users to connect with confidence whether dating, networking, or meeting friends online. We’ve made it not only necessary but acceptable for women to make the first move, shaking up outdated gender norms. We prioritise kindness and respect, providing a safe online community for users to build new relationships.
                                    </p>
                                </Text>
                            </Wrapper>
                        </Col>
                    </Row>

                </Height>


                <CarouselSlider />
            </Container>
        </>
    )
}



const Image = styled.img`
width: 100%;
`

const Wrapper = styled.div`
background-color: rgba(200, 200, 200, 0.5);
background: whitesmoke;
flex-direction: column;
position: relative;
top: 0;
bottom:0;
max-width: 45rem;
right:0;
left:0;
margin:auto !important;
height: fit-content;
margin-right: 2rem;
padding: 2rem;
${media.xl` max-width: 45rem;right:-69%;left:auto; position: absolute;`}

`
const Height = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem;
    ${media.sm`
    padding: 2.5rem 0rem;
    `}
    ${media.md`
    padding: 4rem 0rem;
    `}
    ${media.lg`
    padding: 6rem 0rem;
    `}

`
const Heading = styled.div`
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`
const Text = styled.div`
line-height: 1.8rem;
font-weight: 300;
margin-top: 1rem;
text-align: justify;


`
export default Why;
