import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components'; import { palette } from '../../styled/colors';
import { media } from 'styled-bootstrap-grid'
import { Text } from '../../styled/shared';

const ShopCarousel = () => {
    const slides = [
        {
            image: './shope-1.webp',
            heading: "You come first",
            button: "Let's Vibe"

        },
        {
            image: './shope-2.webp',
            heading: "You come first",
            button: "Let's Vibe"


        },
        {
            image: './shope-3.webp',
            heading: "You come first",
            button: "Let's Vibe"
        },
    ];

    return (

        <div id='shopCarousel'>
            <Carousel
                showArrows={true}
                showIndicators={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                stopOnHover={true}
            >
                {slides.map((slide, index) => (
                    <div key={index}>
                        <CarouselContent>
                            <CustomHeading>{slide.heading}</CustomHeading>
                            <Button>Learn More</Button>
                        </CarouselContent>
                        <Image>  <img src={slide.image} alt="slides" /></Image>
                    </div>
                ))}
            </Carousel>

        </div>
    )
}

const Image = styled.div`
width: 100%;
height: 100%;
`
const CarouselContent = styled.div`
flex-direction: column;
position: absolute;
top: 50%;
left:50%;
transform: translate(-50%, -50%);
padding: 2rem 0rem;

`
const CustomHeading = styled(Text)`
    font-size:24px;
    line-height:2rem;
    font-weight:600;
    text-align: center;
    margin-bottom: 1rem;
    ${media.sm`
    font-size:2.5rem;
    line-height:4rem;
    font-weight:600;
    text-align: left;
    `};
    ${media.md`
    font-size:3.2rem;
    line-height:4rem;
    font-weight:600;
    text-align: left;
    `};
    ${media.lg`
    font-size:3.6rem;
    line-height:4.5rem;
    font-weight:800;
    text-align: left;
    `};
`;

export const Button = styled.div`
/* border: 2px solid transparent; */
display: inline-block;
margin: auto;
text-align: center;
border-radius: 4px;
background: ${palette.btn_gradient};
padding: .8rem 2rem;
cursor: pointer;
color: white;
text-decoration: none;
border-radius: 3rem;
margin-top: 1rem;
&:hover{
    background: ${palette.btn_hover_gradient};
}

`



export default ShopCarousel