import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import '../../styled/profile.css'
import { useState } from 'react'
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import { Flexed, Text } from '../../styled/shared'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { palette } from '../../styled/colors'
import { TbAdjustmentsHorizontal } from 'react-icons/tb'
import { BsFlag, BsPerson } from 'react-icons/bs'
import { addSnozeData, doGenerateAiResponse, getAllInterests, getAllUserProfile, getUser, likeUserProfile, reportUser, unLikeProfile } from '../../apis/apis'
import Loader from '../Common/Loader'
import RangeSliderCustom from '../Common/RangeSlider'
import { Col, Row, media } from 'styled-bootstrap-grid'
import ImgsViewer from "react-images-viewer";
import SnoozeModeWrapper from '../SnoozeModeWrapper'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { appContentReload } from '../../redux/actions/authActions'
import { Input } from '../../pages/EnterName'
import Switch from '../Common/Switch'
import InputSelect from '../Common/ReactSelect'

const Profile = () => {
	const _dispatch = useDispatch()
	const _ref = useRef(null)

	const fileInputRef = useRef(null)
	const card = useRef()
	const [user, setUser] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [isFilterModelOpen, setIsFilterModelOpen] = useState(false)
	const [swapIndex, setSwapIndex] = useState(0)
	const [accept, setAccept] = useState(false)
	const [reject, setReject] = useState(false)
	const [allUser, setAllUser] = useState([])
	const [page, setPage] = useState(1)
	const [mode, setMode] = useState('')
	const [loading, setLoading] = useState(false)
	const [saveReason, setSaveReason] = useState({ id: null, reason: '' })
	const [viewImageCursor, setViewImageCursor] = useState(false)
	const [openLightBoxt, setOpenLightBox] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(0)
	const [interestFilter, setInterestFilter] = useState([])
	const [ageFrom, setAgeFrom] = useState(18)
	const [ageTo, setAgeTo] = useState(80)
	const JobModal = (e) => { setIsOpen(!isOpen); setSaveReason({ id: null, reason: '' }) }
	const _userLikesMe = useSelector((state) => state.auth.userLikesMe)
	const appContentReloads = useSelector((state) => state.auth.appContentReloads)
	const [perfectMatch, setPerfectMatch] = useState(null)
	const [filterMatchUsers, setFilterMatchUsers] = useState([])
	const [openCatchupModel, setOpenCatchupModel] = useState(false)
	const [isSuperSwipe, setIsSuperSwipe] = useState(false)
	const [isAvailableForDate, setIsAvailableForDate] = useState(false)
	const [interests, setInterests] = useState([])
	const [selectedInterests, setSelectedInterests] = useState([])
	const [image, setImage] = useState([])

	const _token = sessionStorage.getItem('authorization')

	const getUserinfo = async () => {
		setLoading(true)
		let user = await getUser()
		if (user?.success) {
			setUser(user?.data?.data?.user)
			setLoading(false)
			setAgeFrom(user?.data?.data?.user.ageFrom)
			setAgeTo(user?.data?.data?.user.ageTo)
			setInterestFilter(user?.data?.data?.user?.dete_gender)
			setMode(user?.data?.data?.user?.mode)
			if (user?.data?.data?.user?.interests?.length) {
				let interests = user?.data?.data?.user?.interests?.map(res => ({
					label: res?.interestsData?.title,
					value: res?.interestsData?.id

				}))
				setInterests(interests)
			}

		}
	}
	useEffect(() => {
		if (_token) {
			getUserinfo()
			setSelectedInterests([])
			setImage('')
			setIsAvailableForDate(false)
			getAllUserProfileInfo(page)
			// getAllInterestsData()
		}

	}, [_token])

	const genderFilter = async (gander) => {
		let _gender_list = []
		if (gander === 'Men') {
			_gender_list.push('Man')
			setInterestFilter(_gender_list)
		}
		if (gander === 'Women') {
			_gender_list.push('Woman')
			setInterestFilter(_gender_list)
		}
		if (gander === 'Everyone') {
			_gender_list.push('Man', 'Woman', 'Nonbinary')
			setInterestFilter(_gender_list)
		}
	}

	const saveFilter = async (gander) => {
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					ageFrom: ageFrom,
					ageTo: ageTo,
					dete_gender: gander
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					getAllUserProfileInfo(page)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const deleteSnoze = async () => {
		let user = await getUser()
		let snozedata = user.data.data.user.snoze_setting
		if (snozedata.length > 0) {
			await addSnozeData()
		}
		getUserinfo()
	}

	const getAllUserProfileInfo = async (page, filters) => {
		setLoading(true)

		let user = await getAllUserProfile(page, '', filters,)
		if (user.success) {
			setLoading(false)
			if (user?.data?.matchingUsers?.length && user?.data?.all_users?.rows?.length) {
				if (user?.data?.matchingUsers?.includes(user?.data?.all_users?.rows[swapIndex]?.id) == false) {
					setOpenCatchupModel(true)
				}
			} else if (user?.data?.matchingUsers?.length == 0 && user?.data?.all_users?.rows?.length == 0) {
				setOpenCatchupModel(true)
			}
			setFilterMatchUsers(user?.data?.matchingUsers)
			setAllUser(user?.data?.all_users?.rows)
		} else {
			setLoading(false)
		}
	}

	const getAllInterestsData = async () => {
		try {
			let res = await getAllInterests()
			if (res?.data?.length) {
				let arr = []
				for (let i = 0; i < res?.data?.length; i++) {
					arr.push({ label: res?.data[i]?.title, value: res?.data[i]?.id })
				}
				console.log('======arr', arr)
				setInterests(arr)

			}

		} catch (error) {

		}

	}

	const handlyRejectedUser = () => {
		handlyCardOpacityAnimation()
		setReject(true)
		setTimeout(handlyCardScaleAnimation, 200)
		setTimeout(rejectedAnimation, 300)
	}

	const handlyAcceptedUser = () => {
		handlyCardOpacityAnimation()
		setAccept(true)
		setTimeout(handlyCardScaleAnimation, 200)
		setTimeout(acceptedAnimation, 300)
	}

	const handlyCardOpacityAnimation = () => {
		if (card.current) {
			card.current.style.opacity = '0.5'
			card.current.style.transition = 'all 0.2s'
		}
	}

	const handlyCardScaleAnimation = () => {
		if (card.current) {
			card.current.style.transform = 'scale(0.95)'
			card.current.style.transition = 'all 0.2s'
		}
	}

	const rejectedAnimation = () => {
		if (card.current) {
			card.current.style.transform = 'scale(1)'
			card.current.style.opacity = '1'
			if (filterMatchUsers?.length && allUser?.length && allUser[swapIndex + 1]?.id) {
				if (filterMatchUsers?.includes(allUser[swapIndex + 1]?.id) == false) {
					setOpenCatchupModel(true)
				}
			}
			setSwapIndex(swapIndex + 1 == 3 ? 0 : swapIndex + 1)
			setReject(false)
		}
	}

	const acceptedAnimation = () => {
		if (card.current) {
			card.current.style.transform = 'scale(1)'
			card.current.style.opacity = '1'
			if (filterMatchUsers?.length && allUser?.length && allUser[swapIndex + 1]?.id) {
				if (filterMatchUsers?.includes(allUser[swapIndex + 1]?.id) == false) {
					setOpenCatchupModel(true)
				}
			}
			setSwapIndex(swapIndex + 1 == 3 ? 0 : swapIndex + 1)
			setAccept(false)
		}
	}

	let BasickList = [
		{
			id: 1,
			icon: '/heart.svg',
			name: 'Using DFF to date'
		},
		{
			id: 2,
			icon: '/hide.svg',
			name: 'I don’t want them to see me'
		},
		{
			id: 3,
			icon: '/uncomfert.svg',
			name: 'Made me uncomfortable'
		},
		{
			id: 4,
			icon: '/warning.svg',
			name: 'Inappropriate content'
		},
		{
			id: 5,
			name: 'Stolen photo',
			icon: '/stolen-photo.svg'
		}
	]

	const mybasic = async (id, reason) => {
		await reportUser(id, reason)
		setSaveReason({ id: null, reason: '' })
		JobModal()
		await getAllUserProfileInfo(page)
	}

	const CustomReportModalBackground = styled(BaseModalBackground)`
	background-color: rgba(0,0,0,.5);
	backdrop-filter: blur(10px);
	${media.lg`
	left: 25rem;
    `}
  `;

	let images = allUser[swapIndex]?.images?.map(res => ({ src: process.env.REACT_APP_PUBLIC_IMAGE_URL + '/' + res, }))

	useEffect(() => {
		function handleClickOutside(event) {
			if (_ref.current && !_ref.current.contains(event.target)) {
				setIsFilterModelOpen(false)
			}
		}

		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])


	const handleLinkClick = () => {
		// Trigger the file input when the link is clicked
		if (fileInputRef?.current) {
			fileInputRef.current.click()
		}
	}

	const handleFileChange = async (event) => {
		if (event.target.files?.length) {
			let res = await fileToGenerativePart(event.target.files[0])
			setImage(res?.inlineData?.data)
			getAllUserProfileInfo(page, { image: res?.inlineData?.data, interests: selectedInterests?.map(res => res.value), is_available: isAvailableForDate })
		}
	}

	const fileToGenerativePart = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()

			reader.onloadend = () => {
				const data = reader.result.split(',')[1] // Extract base64 data
				const mimeType = file.type

				resolve({
					inlineData: {
						data,
						mimeType
					}
				})
			}

			reader.onerror = reject

			reader.readAsDataURL(file)
		})
	}

	return (
		<>
			{loading && <Loader />}
			{_token ? <>
				<MainParent>
					<Header>
						<Nav>
							<FilterWrapper ref={_ref} align="center" gap={0.3}>
								<FilterWrapperInner active={isFilterModelOpen} gap={0.3} onClick={() => setIsFilterModelOpen(!isFilterModelOpen)}>
									<TbAdjustmentsHorizontal fontSize="1.2rem" />
									<Filter >Filters</Filter>

								</FilterWrapperInner>
								{isFilterModelOpen && <DropDown active={isFilterModelOpen}>
									<div>
										<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<CrossIcon src={'/cros-icon.svg'} onClick={() => setIsFilterModelOpen(!isFilterModelOpen)}></CrossIcon>
										</div>

										{mode === 'date' && <>
											<AgeHeading>{'I’m interested in...'}</AgeHeading>
											<InterestedInWrapper>
												<InterestedInLabel active={interestFilter?.includes('Woman') && interestFilter?.length === 1} onClick={() => { genderFilter('Women'); }}>Women</InterestedInLabel>
												<InterestedInLabel active={(interestFilter?.includes('Man') || interestFilter?.includes('Nonbinary')) && interestFilter?.length === 1} onClick={() => { genderFilter('Men'); }}>Men</InterestedInLabel>
												<InterestedInLabel active={(interestFilter?.includes('Woman') || interestFilter?.includes('Man') || interestFilter?.includes('Nonbinary')) && interestFilter?.length > 1} onClick={() => { genderFilter('Everyone'); }}>Everyone</InterestedInLabel>
											</InterestedInWrapper>
										</>}

										<AgeHeading>{'Age'} ({ageFrom}, {ageTo}) </AgeHeading>
										<SliderWraper>
											<RangeSliderCustom valueFrom={ageFrom} setValueFrom={setAgeFrom} valueTo={ageTo} setValueTo={setAgeTo} />
										</SliderWraper>
										<FooterButtons>
											<CancelFilterButton onClick={() => setIsFilterModelOpen(!isFilterModelOpen)}>Cancel</CancelFilterButton>
											<AddFilterButton onClick={() => { saveFilter(interestFilter); setIsFilterModelOpen(!isFilterModelOpen) }}>Apply</AddFilterButton>
										</FooterButtons>
									</div>
								</DropDown>}

							</FilterWrapper>
							{/* <Input style={{ width: '100%' }} placeholder='Search Profiles' /> */}
							<div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
								<div style={{ width: '21rem' }}>
									<InputSelect label={'Search as per your interests'} options={interests} noOptionsMessage={{ inputValue: 'Please update your interests first' }} isMulti={true} value={selectedInterests} onChange={(e) => { setSelectedInterests(e); getAllUserProfileInfo(page, { interests: e?.map(res => res.value), is_available: isAvailableForDate }) }} />

								</div>
								<ContinueButton opacity={'1'} onClick={() => handleLinkClick()}>{'Search Using Profile'}</ContinueButton>
								<input type="file" accept=".jpg, .png, .jpeg" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />

								<Switch text={'Available today for date'} value={isAvailableForDate} isProfile={true} handleChange={(e) => { setIsAvailableForDate(e.target?.checked); getAllUserProfileInfo(page, { is_available: e.target?.checked, interests: selectedInterests?.map(res => res.value) }) }} />
							</div>
						</Nav>
					</Header>
					{user?.snoze_setting?.length ?
						<SnoozeModeWrapper snooz={user?.snoze_setting} cancelSzoonMode={deleteSnoze} />
						:
						<>
							{allUser?.length > 0 && allUser[swapIndex] ? (
								<div style={{ position: 'relative' }}>
									<ImageGrid ref={card} id={"profileCarousel"}>
										<Carousel axis={'vertical'} infiniteLoop={false} showArrows={true} showStatus={false} stopOnHover={true} swipeable={true} useKeyboardArrows={true} emulateTouch={true} verticalSwipe={'standard'} dynamicHeight={'true'}>
											<Wrapper>
												<Grid divide={true}>
													<Card1 lg='6'>
														{allUser[swapIndex]?.mode == 'aff' && <ModelTitle>dff</ModelTitle>}
														<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(0); setOpenLightBox(true); }}>
															<img src='/view.svg' alt='' />
														</ViewUserPics>
														{swapIndex === 0 && <FixedImageContainer>
															<Image src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[0]}`} alt="Your Image" />
														</FixedImageContainer>}
														{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
														{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
													</Card1>

													<Card2 lg='6'>
														<Flexed direction="column" gap={0.5}>
															<Flexed gap={0.4} align="center">
																<Heading>{allUser[swapIndex]?.first_name},</Heading>
																<Heading>{allUser[swapIndex]?.age_group}</Heading>
																{allUser[swapIndex]?.is_verify_admin && (
																	<>
																		<Flexed align="center">
																			<VerifiedIcon>
																				<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
																					<path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16z" fill={palette.hover_color}></path>
																					<path
																						fill-rule="evenodd"
																						clip-rule="evenodd"
																						d="M10.586 14.443a1.795 1.795 0 00-2.539-.106 1.802 1.802 0 00-.106 2.542l4.535 4.94c.698.76 1.89.777 2.61.036l8.938-9.2a1.801 1.801 0 00-.034-2.545 1.795 1.795 0 00-2.541.035l-7.614 7.837-3.25-3.539z"
																						fill="#fff"></path>
																				</svg>
																			</VerifiedIcon>
																			<VerifiedText>
																				Photo <br /> verified
																			</VerifiedText>
																		</Flexed>
																	</>
																)}
															</Flexed>
															<QuoteIcon>
																<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
																	<path
																		fill-rule="evenodd"
																		clip-rule="evenodd"
																		d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
																		fill="currentColor"></path>
																</svg>
															</QuoteIcon>
														</Flexed>
													</Card2>
												</Grid>
											</Wrapper>

											<Wrapper>
												<UserInfo>
													<Flexed justify="center" width="100%" gap={0.4}>
														<QuoteIcon>
															<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
																	fill="currentColor"></path>
															</svg>
														</QuoteIcon>
														<InfoHeading>About {allUser[swapIndex]?.first_name}</InfoHeading>
													</Flexed>
													{allUser[swapIndex]?.mode === 'aff' ? <InfoText>{allUser[swapIndex]?.bio_aff ? 'dff' : allUser[swapIndex]?.bio_aff}</InfoText> : <InfoText>{allUser[swapIndex]?.bio_date}</InfoText>}
													<Flexed margin="1rem 0rem 0rem 0rem" flexWrap="wrap" gap={0.5} justify="center">
														{allUser[swapIndex]?.height && mode == 'date' ? <Tags gap={0.2} align="center">
															<TagsIcon src="./height.png" alt="tagIcons" />

															<TagText>{allUser[swapIndex]?.height} cm</TagText>
														</Tags> : ''}
														{allUser[swapIndex]?.exercise ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="./exercise.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.exercise}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.education_level && mode == 'date' ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="./education.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.education_level}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.have_kids ? (
															<Tags gap={0.2} align="center">
																<TagText>{allUser[swapIndex]?.have_kids}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.kid && mode == 'date' ? (
															<Tags gap={0.2} align="center">
																<TagText>{allUser[swapIndex]?.kid}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.drinking ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="./drinking.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.drinking}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.politic ? (
															<Tags gap={0.2} align="center">
																<TagText>{allUser[swapIndex]?.politic}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.smoking ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="/icons/smoking.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.smoking}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.gender && allUser[swapIndex]?.show_gender ? (
															<Tags gap={0.2} align="center">

																<BsPerson />
																<TagText>{allUser[swapIndex]?.gender}</TagText>
															</Tags>
														) : null}

														{allUser[swapIndex]?.star_sign ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="./starSign.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.star_sign}</TagText>
															</Tags>
														) : null}
														{allUser[swapIndex]?.religion ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="./religion.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.religion}</TagText>
															</Tags>
														) : null}

														{allUser[swapIndex]?.relation_ship && mode == 'aff' && (
															<Tags gap={0.2} align="center">
																<TagText>{allUser[swapIndex]?.relation_ship}</TagText>
															</Tags>
														)}
														{allUser[swapIndex]?.new_to_area && mode == 'aff' && (
															<Tags gap={0.2} align="center">
																<TagText>{allUser[swapIndex]?.new_to_area}</TagText>
															</Tags>
														)}
														{mode === 'aff' ? (
															allUser[swapIndex]?.looking_for_aff ? (
																<Tags gap={0.2} align="center">
																	<TagsIcon src="./religion.png" alt="tagIcons" />
																	<TagText>{allUser[swapIndex]?.looking_for_aff}</TagText>
																</Tags>
															) : null
														) : allUser[swapIndex]?.looking_for_date ? (
															<Tags gap={0.2} align="center">
																<TagsIcon src="./religion.png" alt="tagIcons" />
																<TagText>{allUser[swapIndex]?.looking_for_date}</TagText>
															</Tags>
														) : null}
													</Flexed>
												</UserInfo>
											</Wrapper>
											{(allUser[swapIndex]?.images[1] || allUser[swapIndex]?.profile_prompt?.filter((user) => user.mode === mode)[0]) ?
												<Wrapper>
													<Grid divide={allUser[swapIndex]?.images[1] && allUser[swapIndex]?.profile_prompt
														?.filter((user) => user.mode === mode)[0]}>
														{(allUser[swapIndex]?.images[1]) &&
															<Card1 lg={allUser[swapIndex]?.profile_prompt?.filter((user) => user.mode === mode)[0] ? '6' : '12'}>
																<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(1); setOpenLightBox(true); }}>
																	<img src='/view.svg' alt='' />
																</ViewUserPics>
																{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[1]}`} width={'100%'} height={'100%'} alt="" />}
																{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[1]}`} width={'100%'} height={'100%'} alt="" />}
																{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[1]}`} width={'100%'} height={'100%'} alt="" />}
															</Card1>
														}
														{allUser[swapIndex]?.profile_prompt
															?.filter((user) => user.mode === mode)[0] && <Card2 lg='6'>
																<Flexed direction="column" align="center" width="100%">
																	<Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
																		<Section>
																			<MiniHeading>{allUser[swapIndex]?.profile_prompt
																				?.filter((user) => user.mode === mode)[0]?.profile_prompt_data?.profile_prompts}</MiniHeading>
																			<Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
																				{allUser[swapIndex]?.profile_prompt
																					?.filter((user) => user.mode === mode)[0]?.ans}
																			</Text2>
																		</Section>
																	</Flexed>

																</Flexed>
															</Card2>
														}
													</Grid>
												</Wrapper>
												:
												<Wrapper>
													<Card1 lg='12'>
														<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(0); setOpenLightBox(true); }}>
															<img src='/view.svg' alt='' />
														</ViewUserPics>
														{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
														{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
														{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
													</Card1>
												</Wrapper>
											}

											{(allUser[swapIndex]?.images[3] && allUser[swapIndex]?.images[4]) ?
												<Wrapper>
													<Grid divide={true}>
														<Card1 lg='6'>
															<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(3); setOpenLightBox(true); }}>
																<img src='/view.svg' alt='' />
															</ViewUserPics>

															{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[3]}`} width={'100%'} height={'100%'} alt="" />}
															{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[3]}`} width={'100%'} height={'100%'} alt="" />}
															{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[3]}`} width={'100%'} height={'100%'} alt="" />}
														</Card1>
														<Card1 lg='6'>
															<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(4); setOpenLightBox(true); }}>
																<img src='/view.svg' alt='' />
															</ViewUserPics>

															{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[4]}`} width={'100%'} height={'100%'} alt="" />}
															{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[4]}`} width={'100%'} height={'100%'} alt="" />}
															{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[4]}`} width={'100%'} height={'100%'} alt="" />}
														</Card1>
													</Grid>
												</Wrapper>
												:
												(allUser[swapIndex]?.images[3] || allUser[swapIndex]?.profile_prompt?.filter((user) => user.mode === mode)[2]) ?
													<Wrapper>
														<Grid divide={allUser[swapIndex]?.images[3] && allUser[swapIndex]?.profile_prompt
															?.filter((user) => user.mode === mode)[2]}>
															{allUser[swapIndex]?.images[3] && <Card1 lg='6'>
																<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(3); setOpenLightBox(true); }}>
																	<img src='/view.svg' alt='' />
																</ViewUserPics>

																{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[3]}`} width={'100%'} height={'100%'} alt="" />}
																{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[3]}`} width={'100%'} height={'100%'} alt="" />}
																{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[3]}`} width={'100%'} height={'100%'} alt="" />}
															</Card1>}
															<Card2 lg='6'>
																<Flexed direction="column" align="center" width="100%">
																	<Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
																		<Section>
																			<MiniHeading>{allUser[swapIndex]?.profile_prompt
																				?.filter((user) => user.mode === mode)[2]?.profile_prompt_data?.profile_prompts}</MiniHeading>
																			<Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
																				{allUser[swapIndex]?.profile_prompt
																					?.filter((user) => user.mode === mode)[2]?.ans}
																			</Text2>
																		</Section>
																	</Flexed>

																</Flexed>
															</Card2>
														</Grid>
													</Wrapper>
													:
													(allUser[swapIndex]?.images[5] && allUser[swapIndex]?.images[6]) ?
														<Wrapper>
															<Grid divide={true}>
																<Card1 lg='6'>
																	<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(5); setOpenLightBox(true); }}>
																		<img src='/view.svg' alt='' />
																	</ViewUserPics>

																	{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[5]}`} width={'100%'} height={'100%'} alt="" />}
																	{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[5]}`} width={'100%'} height={'100%'} alt="" />}
																	{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[5]}`} width={'100%'} height={'100%'} alt="" />}
																</Card1>
																<Card1 lg='6'>
																	<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(6); setOpenLightBox(true); }}>
																		<img src='/view.svg' alt='' />
																	</ViewUserPics>

																	{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[6]}`} width={'100%'} height={'100%'} alt="" />}
																	{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[6]}`} width={'100%'} height={'100%'} alt="" />}
																	{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[6]}`} width={'100%'} height={'100%'} alt="" />}
																</Card1>
															</Grid>
														</Wrapper>
														:
														(allUser[swapIndex]?.images[5] && allUser[swapIndex]?.profile_prompt?.filter((user) => user.mode === mode)?.length) ?
															<Wrapper>
																<Grid divide={allUser[swapIndex]?.images[5] && allUser[swapIndex]?.profile_prompt
																	?.filter((user) => user.mode === mode)[2]}>
																	{allUser[swapIndex]?.images[5] && <Card1 lg='6'>
																		<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setCurrentIndex(5); setOpenLightBox(true); }}>
																			<img src='/view.svg' alt='' />
																		</ViewUserPics>

																		{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[5]}`} width={'100%'} height={'100%'} alt="" />}
																		{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[5]}`} width={'100%'} height={'100%'} alt="" />}
																		{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[5]}`} width={'100%'} height={'100%'} alt="" />}
																	</Card1>}
																	<Card2 lg='6'>
																		<Flexed direction="column" align="center" width="100%">
																			<Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
																				<Section>
																					<MiniHeading>{allUser[swapIndex]?.profile_prompt
																						?.filter((user) => user.mode === mode)[2]?.profile_prompt_data?.profile_prompts}</MiniHeading>
																					<Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
																						{allUser[swapIndex]?.profile_prompt
																							?.filter((user) => user.mode === mode)[2]?.ans}
																					</Text2>
																				</Section>
																			</Flexed>

																		</Flexed>
																	</Card2>
																</Grid>
															</Wrapper>
															:
															<Wrapper>
																<UserInfo>
																	<Flexed justify="center" width="100%" gap={0.4}>
																		<QuoteIcon>
																			<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
																				<path
																					fill-rule="evenodd"
																					clip-rule="evenodd"
																					d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
																					fill="currentColor"></path>
																			</svg>
																		</QuoteIcon>
																		<InfoHeading>A positive attitude, a balanced extroversion and confidence </InfoHeading>
																	</Flexed>
																</UserInfo>
															</Wrapper>

											}

											<Wrapper>
												<UserInfo prompt={allUser[swapIndex]?.profile_prompt
													?.filter((user) => user.mode == mode)?.length == 4}>
													<Flexed justify="center" width="100%" gap={0.4}>
														<QuoteIcon>
															<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
																	fill="currentColor"></path>
															</svg>
														</QuoteIcon>
														<InfoHeading>Profile Prompts {allUser[swapIndex]?.first_name}</InfoHeading>
														<br></br>
													</Flexed>
													{allUser[swapIndex]?.profile_prompt
														?.filter((user) => user.mode == mode)
														?.map((info, index) => {
															return (
																<>
																	<Section isTotal={allUser[swapIndex]?.profile_prompt
																		?.filter((user) => user.mode == mode)?.length == 4}>
																		<MiniHeading>{info?.profile_prompt_data?.profile_prompts}</MiniHeading>
																		<Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
																			{info.ans}
																		</Text2>
																	</Section>
																</>
															)
														})}
													<br></br>
													<Flexed margin="1rem 0rem 0rem 0rem" flexWrap="wrap" gap={0.5} justify="center"></Flexed>
												</UserInfo>
											</Wrapper>

											<Wrapper>
												<Grid divide={true}>
													<Card1 lg='6'>

														{swapIndex === 0 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[0]?.images[5] ? allUser[0]?.images[5] : allUser[0]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
														{swapIndex === 1 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[1]?.images[5] ? allUser[1]?.images[5] : allUser[1]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
														{swapIndex === 2 && <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${allUser[2]?.images[5] ? allUser[2]?.images[5] : allUser[2]?.images[0]}`} width={'100%'} height={'100%'} alt="" />}
														<ViewUserPics onMouseEnter={() => setViewImageCursor(true)} onMouseLeave={() => setViewImageCursor(false)} onClick={() => { setOpenLightBox(true); setCurrentIndex(0) }}>
															<img src='/view.svg' alt='' />
														</ViewUserPics>
													</Card1>
													<Card2 lg='6'>
														<Flexed direction="column" align="center" width="100%">
															<Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
																<QuoteIcon>
																	<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
																		<path
																			fill-rule="evenodd"
																			clip-rule="evenodd"
																			d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zM9.608 13.72c-.259 3.7 5.772 10.168 6.141 10.194.37.026 6.825-5.569 7.084-9.27.259-3.7-2.492-6.906-6.144-7.161-3.652-.256-6.822 2.537-7.08 6.237zM16 16a2.133 2.133 0 110-4.267A2.133 2.133 0 0116 16z"
																			fill="currentColor"></path>
																	</svg>
																</QuoteIcon>
																<InfoHeading>{`${allUser[swapIndex]?.first_name}'s location`}</InfoHeading>
															</Flexed>
															<UserLocation>{allUser[swapIndex]?.location}</UserLocation>
															{allUser[swapIndex]?.distance ? <UserLocation>~ {allUser[swapIndex]?.distance?.toFixed(2)} km away</UserLocation> : ''}
														</Flexed>
													</Card2>
												</Grid>
											</Wrapper>
										</Carousel>
										<ReportText onClick={() => {
											JobModal()
										}}>
											<Flexed gap={0.3} align={'center'}>
												<BsFlag />
												<BlockUserButtonn
												>
													Block and report
												</BlockUserButtonn>
											</Flexed>
										</ReportText>
									</ImageGrid>

									<Accept visible={accept} title="Like">
										<svg color={palette.hover_color} height={'40px'} data-origin="pipeline" viewBox="0 0 78 78" fill="none">
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M35.759 43.024l11.82-11.82a2.387 2.387 0 113.376 3.378L37.448 48.089a2.388 2.388 0 01-3.377 0l-6.754-6.752a2.388 2.388 0 013.377-3.377l5.065 5.065z"
												fill="currentColor"></path>
										</svg>
									</Accept>
									<Reject visible={reject} title="Pass">
										<svg color="darkgray" height="40px" data-origin="pipeline" viewBox="0 0 78 78" fill="none">
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M38.523 36.1l5.065-5.065a2.388 2.388 0 013.377 3.377l-5.066 5.066 5.066 5.065a2.388 2.388 0 01-3.377 3.377l-5.066-5.065-5.065 5.065a2.388 2.388 0 11-3.377-3.377l5.065-5.066-5.065-5.065a2.388 2.388 0 113.377-3.377l5.066 5.065z"
												fill="currentColor"></path>
										</svg>
									</Reject>
									<BottomIcons>
										<Icon
											width="4.875"
											height="4.875"
											title="Pass"
											onClick={async () => {
												await unLikeProfile(allUser[swapIndex]?.id)
												swapIndex < 20 ? handlyRejectedUser() : setSwapIndex(0)
												if (swapIndex == 2) {
													setPage(page + 1)
													await getAllUserProfileInfo(page + 1)
													setSwapIndex(0)

												}
											}}>
											<svg color="darkgray" height="40px" data-origin="pipeline" viewBox="0 0 78 78" fill="none">
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M38.523 36.1l5.065-5.065a2.388 2.388 0 013.377 3.377l-5.066 5.066 5.066 5.065a2.388 2.388 0 01-3.377 3.377l-5.066-5.065-5.065 5.065a2.388 2.388 0 11-3.377-3.377l5.065-5.066-5.065-5.065a2.388 2.388 0 113.377-3.377l5.066 5.065z"
													fill="currentColor"></path>
											</svg>
										</Icon>
										<SwapIcon
											title="SuperSwip"
											onClick={async () => {
												let res = await likeUserProfile(allUser[swapIndex]?.id, allUser[swapIndex]?.mode, true)
												if (res?.success) {

													const check = _userLikesMe?.some(obj => obj?.u_id === res?.data?.like_data?.profile_id);
													if (check) {
														setPerfectMatch(res?.data?.like_data?.profile_id);
														_dispatch(appContentReload(!appContentReloads))
													}
												}
												setIsSuperSwipe(true)
												swapIndex < 3 ? handlyAcceptedUser() : setSwapIndex(0)
												if (swapIndex == 2) {
													setPage(page + 1)
													await getAllUserProfileInfo(page + 1)
													setSwapIndex(0)

												}
											}}>
											<svg data-origin="pipeline" viewBox="0 0 96 96" fill="none">

												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M2 32.717c0-5.304 3.764-11.674 8.41-14.23L39.59 2.443c4.645-2.554 12.174-2.555 16.82 0l29.18 16.046c4.645 2.554 8.41 8.914 8.41 14.23v29.849c0 5.304-3.745 11.726-8.357 14.34L56.357 93.498c-4.615 2.615-12.102 2.613-16.714 0L10.357 76.906C5.742 74.291 2 67.882 2 62.566V32.718z"
													fill={palette.hover_color}></path>
											</svg>
											<svg id="star" style={{ position: 'absolute' }} color="#ffffff" height="40px" data-origin="pipeline" viewBox="0 0 96 96" fill="none">
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M50.958 29.827l3.934 7.917a3.296 3.296 0 002.483 1.793l8.797 1.27c2.706.39 3.786 3.694 1.828 5.59l-6.365 6.162a3.263 3.263 0 00-.948 2.9l1.502 8.703c.462 2.677-2.367 4.718-4.787 3.455l-7.867-4.11a3.32 3.32 0 00-3.07 0l-7.867 4.11c-2.42 1.263-5.25-.778-4.787-3.455l1.502-8.703a3.261 3.261 0 00-.948-2.9L28 46.396c-1.958-1.895-.877-5.2 1.828-5.59l8.796-1.27a3.295 3.295 0 002.484-1.792l3.934-7.917c1.21-2.436 4.706-2.436 5.916 0z"
													fill="currentColor"></path>
											</svg>
										</SwapIcon>
										<Icon
											width="4.875"
											height="4.875"
											title="Like"
											onClick={async () => {
												let res = await likeUserProfile(allUser[swapIndex]?.id, allUser[swapIndex]?.mode)
												if (res?.success) {
													const check = _userLikesMe?.some(obj => obj?.u_id === res?.data?.like_data?.profile_id);
													if (check) {
														setPerfectMatch(res?.data?.like_data?.profile_id);
														_dispatch(appContentReload(!appContentReloads))
													}
												}

												swapIndex < 3 ? handlyAcceptedUser() : setSwapIndex(0)

												if (swapIndex == 2) {
													setPage(page + 1)
													await getAllUserProfileInfo(page + 1)
													setSwapIndex(0)

												}
											}}>
											<svg color={palette.hover_color} height={'40px'} data-origin="pipeline" viewBox="0 0 78 78" fill="none">
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M35.759 43.024l11.82-11.82a2.387 2.387 0 113.376 3.378L37.448 48.089a2.388 2.388 0 01-3.377 0l-6.754-6.752a2.388 2.388 0 013.377-3.377l5.065 5.065z"
													fill="currentColor"></path>
											</svg>
										</Icon>
									</BottomIcons>
								</div>
							) :
								<EmptyGrid>
									<Text isCentered color='white'>You are all catch up, change your filter to find more.</Text>
								</EmptyGrid>
							}
						</>
					}

				</MainParent>
				<ModalProvider backgroundComponent={CustomReportModalBackground}>
					<div>
						<StyledModal id="modelWrap" isOpen={isOpen} onBackgroundClick={JobModal} onEscapeKeydown={JobModal}>
							<div>

								<ModelHeading>What’s wrong with this profile?</ModelHeading>
								<ModelDescription>Help us keep the Hive safe by telling us why you’re reporting or blocking this user. Don’t worry, this is anonymous.</ModelDescription>

								{BasickList?.map((text) => (
									<ChooseList active={saveReason.reason == text?.name}>
										<Flex >
											<ModelIcons src={text.icon} alt={text?.name} />
											<Span onClick={() => setSaveReason({ id: allUser[swapIndex]?.id, reason: text?.name })}>{text?.name}</Span>
										</Flex>
									</ChooseList>
								))}
								<ButtonDiv>
									<ContinueButton opacity={saveReason.id && saveReason.reason ? 1 : 0.5} onClick={() => saveReason.id && saveReason.reason && mybasic(saveReason.id, saveReason.reason)}>Continue</ContinueButton>
								</ButtonDiv>
							</div>

						</StyledModal>
					</div>
				</ModalProvider>
				{openCatchupModel && <ModalProvider backgroundComponent={CustomReportModalBackground}>
					<StyledModal id="modelWrap" isOpen={openCatchupModel}>
						<div>

							<ModelHeading>You are all caught up</ModelHeading>
							<ModelDescription>Update your filters to see more profiles related to your filters.</ModelDescription>
							<ButtonDiv>
								<ContinueButton opacity={'1'} onClick={() => setOpenCatchupModel(false)}>Continue</ContinueButton>
							</ButtonDiv>
						</div>

					</StyledModal>
				</ModalProvider>}

				{isSuperSwipe && <ModalProvider backgroundComponent={CustomReportModalBackground}>
					<StyledModal id="modelWrap" isOpen={isSuperSwipe}>
						<div>

							<ModelHeading>Super Swipe</ModelHeading>
							<ModelDescription>Super like not in the match queue of normal liked users.</ModelDescription>
							<ButtonDiv>
								<ContinueButton opacity={'1'} onClick={() => setIsSuperSwipe(false)}>Ok</ContinueButton>
							</ButtonDiv>
						</div>

					</StyledModal>
				</ModalProvider>}

				{
					openLightBoxt && (
						<ImgsViewer
							imgs={images}
							currImg={currentIndex}
							isOpen={openLightBoxt}
							onClickPrev={(e) => setCurrentIndex(currentIndex - 1)}
							onClickNext={(e) => setCurrentIndex(currentIndex + 1)}
							onClose={() => setOpenLightBox(false)}
							showThumbnails={true}
							onClickThumbnail={(e) => setCurrentIndex(e)}
							showImgCount={false}
						/>
					)
				}

			</> : ''}
		</>
	)
}

const FixedImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const InterestedInWrapper = styled.div`
	box-shadow: inset 0 0 0 1px ${palette.yellow_border};
	border-radius: 20px !important;
	background: ${palette.black};
	-webkit-transition: -webkit-box-shadow .3s ease-out;
	transition: -webkit-box-shadow .3s ease-out;
	transition: box-shadow .3s ease-out;
	transition: box-shadow .3s ease-out,-webkit-box-shadow .3s ease-out;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
`

const InterestedInLabel = styled.label`
box-shadow:  ${({ active }) => active && `inset 0 0 0 1px ${palette.yellow_border}`};
border-radius:  ${({ active }) => active && '20px'};
color:  ${({ active }) => active ? palette.white : palette.white};
background:${({ active }) => active && palette.yellow_gradient};
display: flex;
align-items: center;
    justify-content: center;
    flex: 0 1 100%;
    min-width: 0;
    height: 40px;
    padding: 4px 20px;
    transition: color .2s;
`

const ButtonDiv = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`

const CrossIcon = styled.img`
	cursor: pointer;
	height: 1.3rem;
	width: 1.3rem;
`

const SliderWraper = styled.div`
	padding: 1.4rem 1rem 1.8rem 1rem;
	width: 100%;
	border: 1px solid ${palette.yellow_border};
	background: ${palette.black};
	border-radius: 20px;
	gap: 1rem;
	display: inline-grid;
	:hover {
		border: 1px solid ${palette.gray_300};
		background: ${palette.black};
	}
`

const BlockUserButtonn = styled.button`
	border: none;
	background: transparent;
	color: ${palette.text};
	cursor: pointer;
	&:hover{
	opacity: 0.7;
	}
`
const ModelDescription = styled.span`
	margin: 1rem;
	text-align: center;
	display: flex;
	justify-content: center;
	opacity: 0.8;
	color: ${palette.text};
`
const ModelTitle = styled.span`
	width: 2.9rem;
	height: 1.5rem;
	border-radius: 1rem;
	background: ${palette.btn_gradient};
	position: absolute;
	/* padding: 0.3rem; */
	top: 0.8rem;
	left: 1rem;
	color: white;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	position: absolute;
`

const ViewUserPics = styled.div`
	width: 1.5rem !important;
	height: 1.5rem;
	/* border-radius: 1rem; */
	/* background-color: #009abf; */
	position: absolute !important;
	/* padding: 0.3rem; */
	top: 0.8rem;
	right: 1rem;
	color: white;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
	cursor: pointer !important;
	z-index: 2 !important;
`

const ModelIcons = styled.img`
	width: 1.1rem;
	height: 1.1rem;
	/* filter:  grayscale(1000%) brightness(500%); */
`

const ReportText = styled.div`
	position: absolute;
	bottom: -0.5rem;
	padding-left: 0.5rem;
	color: ${palette.text};
	font-size: 11px;
	cursor: pointer;
	&:hover svg{
	color: ${palette.text};
	cursor: pointer;
	opacity: 0.7;
	}
	${media.xs`
	bottom: -3.5rem;
`}
`

const FilterWrapperInner = styled(Flexed)`
	cursor: pointer;
	font-size: 16px;
	color: ${({ active }) => (active ? palette.yellow_border : palette.text)};
	&:hover{
		color: ${palette.yellow_border};
	}
`
const Filter = styled.div`
	cursor: pointer;
	font-size: 16px;
`

const Span = styled.p`
	font-size: 15px;
	text-align: center;
	color: ${palette.white};
`
const Flex = styled.div`
	display: flex;
	justify-content: start;
	align-items: start;
	gap: 0.5rem;
`

const ChooseList = styled.div`
	position: relative;
	display: flex;
	/* justify-content: center; */
	border-radius: 40px;
	cursor: pointer;
	padding: 0.65rem 1.5rem;
	/* width: 20rem; */
	border: 1px solid ${({ active }) => active ? `${palette.yellow_border}` : `${palette.light_gray}`};
	text-align: center;
	margin-top: 0.6rem;
	overflow: scroll;
	text-align: center;
	align-items: center;
	&:hover{
        background: ${palette.yellow_gradient};
        border: 1px solid ${palette.yellow_border};
    }
`

const MainParent = styled.div`
width: 100%;
	height: 100vh;
	margin-top: 4.5rem;
	background: ${palette.black};
	${media.xs`
	width: 100%;
	overflow-x: scroll;
`}
${media.sm`
overflow-x: scroll;
`}
${media.lg`
width: calc(100% - 400px);
`}
`
const Header = styled.div`
	width: 100%;
	height: 72px;
	color: white;
	border-bottom: 1px solid ${palette.yellow_border};
`
const Nav = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 90%;
	margin: auto;
	height: 72px;
	gap: 2rem;
	${media.xs`
	width: 80%;
`}
${media.sm`
width: 80%;
`}

${media.md`
width: 90%;
`}
${media.lg`
width: 90%;
`}
`
const ImageGrid = styled.div`
	width: 77%;
	margin: 2rem auto 0rem auto;
	// border-radius: 14px;
	border-radius: 20px;
`

const EmptyGrid = styled(ImageGrid)`
	border: 1px solid ${palette.yellow_border};
	height: calc(100vh - 170px);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
`

export const Grid = styled(Row)`
  align-items: center;
  height: 100%;
`

export const Card1 = styled(Col)`
	height: 100%;
	position: relative;
	height: calc(100vh - 170px) !important;
	padding: 0;
	${media.xs`
	width: 100%;
	height: 50% !important;
`}
${media.sm`
height: 50% !important;
`}

${media.md`
height: calc(100vh - 170px) !important;
`}
${media.lg`
height: calc(100vh - 170px) !important;
`}
`

const QuoteIcon = styled.div`
	width: 12px;
	height: 12px;
`

const VerifiedIcon = styled.div`
	width: 20px;
	height: 20px;
`

const VerifiedText = styled.div`
	font-weight: 600;
	margin-left: 8px;
	color: ${palette.hover_color};
	text-align: left;
	font-size: 11px;
	line-height: 1.09;
`

export const Card2 = styled(Col)`
	display: flex;
	background: ${palette.yellow_border};
	padding: 2.2rem;
	height: 100%;
	align-items: center;
	height: calc(100vh - 170px) !important;
	${media.xs`
	width: 100%;
	height: 50% !important;
`}
${media.sm`
height: 50% !important;
`}

${media.md`
height: calc(100vh - 170px) !important;
`}
${media.lg`
height: calc(100vh - 170px) !important;
`}
`

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	background: ${palette.yellow_border};
	padding: 2.2rem;
	height: ${({ prompt }) => prompt ? prompt : '100%'};
	justify-content: center;
	gap: 1rem
`

const InfoHeading = styled.div`
	font-weight: 600;
	font-size: 14px;
	color: #454650;
	line-height: 1.3;
	${media.xs`
	font-size: 0.7rem;
`}
${media.sm`
font-size: 0.7rem;
`}

${media.md`
	font-size: 14px;
`}
${media.lg`
	font-size: 14px;
`}

`

const InfoText = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 1.2;
	overflow: hidden;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	max-width: 100%;
	min-height: 0;
	text-align: center;
	color: #454650;
`

const AgeHeading = styled.div`
	padding: 1rem;
	padding-top: 0rem;
	font-weight: 400;
	color: ${palette.yellow_border};
`

const ModelHeading = styled.h2`
	text-align: center;
	font-weight: 400;
	color: ${palette.yellow_border};
`
const BottomIcons = styled.div`
	position: absolute;
	bottom: -1.2rem;
	left: 0;
	right: 0;
	width: fit-content;
	display: flex;
	gap: 1rem;
	align-items: center;
	margin: auto;
	z-index: 3;
`
const Icon = styled.div`
	padding: 0.6rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	width: ${({ width }) => `${width}rem`};
	height: ${({ height }) => `${height}rem`};
	border-radius: 50%;
	border: 1px solid lightgray;
	cursor: pointer;
	transition: 0.2s;
	& svg {
		transform: scale(1.8);
	}
	&:hover {
		transform: scale(1.2);
	}
`

const SwapIcon = styled.div`
	padding: 0.6rem;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 4.875rem;
	height: 4.875rem;
	cursor: pointer;
	transition: 0.2s;
	& svg {
		transform: scale(1.5);
	}
	& svg#star {
		transform: scale(2);
	}
	&:hover {
		transform: scale(1.15);
	}
`

const StyledModal = Modal.styled`
  width: 90%;
  /* height: 18rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 0.5rem;
  position: relative;
  background-color: ${palette.black};
	border:1px solid ${palette.yellow_border};
	${media.md`
	width: 30rem;
    `}
	${media.lg`
		left: -12rem;
	`}

`

const StatusIcon = styled.div`
	padding: 0.6rem;
	display: flex;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
	bottom: ${({ visible }) => (visible ? '0rem' : '-5rem')};
	opacity: ${({ visible }) => (visible ? '1' : '0')};
	margin: auto;
	justify-content: center;
	align-items: center;
	background: white;
	width: 5.2rem;
	height: 5.2rem;
	border-radius: 50%;
	cursor: pointer;
	transform: scale(1.5);
	transition: bottom 0.2s;
	& svg {
		transform: scale(1.8);
	}
`

const Accept = styled(StatusIcon)`
	border: 3.5px solid #f2bc27;
`

const Reject = styled(StatusIcon)`
	border: 3.5px solid lightgray;
`

const Heading = styled.div`
	letter-spacing: -0.02em;
	font-size: min(24px + 8 * (100vw - 480px)/960, 32px);
	font-weight: 500;
	color: #454650;
	${media.xs`
	font-size: min(14px + 8 * (100vw - 480px)/960, 32px);
`}
${media.sm`
font-size: min(14px + 8 * (100vw - 480px)/960, 32px);
`}
`

const Tags = styled(Flexed)`
	color: white;
	background: ${palette.btn_gradient};
	height: 28px;
	padding: 0 12px 0 7px;
	border-radius: 14px;
`

const TagsIcon = styled.img`
	width: 20px !important;
	height: 20px;
	color: white;
	filter:  grayscale(0%) brightness(500%);
`

const TagText = styled.div`
	font-size: 12px;
	line-height: 1.334;
	${media.xs`
	font-size: 0.7rem;
`}
${media.sm`
font-size: 0.7rem;
`}

${media.md`
	font-size: 12px;
`}
${media.lg`
font-size: 12px;
`}

`
const UserLocation = styled.div`
	color: #282828;
	font-size: 20px;
	line-height: 1.2;
	margin-bottom: 0.2rem;
`

const Wrapper = styled.div`
	position: relative;
	background: black;
	height: calc(100vh - 170px) !important;
	border-radius: 24px;
	overflow: hidden;
	overflow-y: scroll;
`

const Section = styled.div`
	background: ${palette.yellow_border};
	padding: ${({ isTotal }) => (isTotal ? '0.3rem' : '1rem')};

	&:not(last-child) {
		margin-bottom: 1px;
	}
`
const MiniHeading = styled.div`
	font-size: 14px;
	color: #454650;
	line-height: 1.3;
`
const Text2 = styled.div`
	font-size: 16px;
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
	color: #454650;
	line-height: 1.5;
	margin: ${({ margin }) => margin};
`
const AddFilterButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
	min-width: 40px;
	min-height: 40px;
	height: 40px;
	padding: 5px 20px;
	border-radius: calc(40px / 2);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	background: ${palette.btn_gradient};
	border: none;
	color: white;
	font-size: 1rem;
	:hover{
	background:  ${palette.btn_hover_gradient};
    text-decoration: none;
	}
`

const CancelFilterButton = styled.button`
    background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
	min-width: 40px;
	min-height: 40px;
	height: 40px;
	padding: 5px 20px;
	border-radius: calc(40px / 2);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	border: none;
	color: ${palette.text};
	font-size: 1rem;
`
const FooterButtons = styled.div`
   margin-top: 2rem;
   display: flex;
   justify-content: flex-end;
   gap: 0.3rem;
`

const ContinueButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
	min-width: 40px;
	min-height: 40px;
	height: 40px;
	padding: 5px 20px;
	border-radius: calc(40px / 2);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	opacity: ${({ opacity }) => opacity ? opacity : 0.3};
	background: ${palette.btn_gradient};
	border: none;
	color: white;
	font-size: 1rem;
	:hover{
		background: ${palette.btn_hover_gradient};
    text-decoration: none;
	}
`

const FilterWrapper = styled(Flexed)`
position: relative;
`;

const DropDown = styled.div`
	width: 448px;
	display: ${({ active }) => (active ? 'block' : 'none')};
	position: absolute;
	z-index: 10;
	top: 1.5rem;
	left: 0;
	overflow: hidden;
	border: 1px solid ${palette.yellow_border};
	border-radius: 16px;
	background: ${palette.black};
	padding: 1rem 2.5rem;
	box-shadow: 0 8px 24px rgba(0,0,0,.1);
	${media.xs`
	width: 280px;
	padding: 0.8rem ;
`}
`

export default Profile
