import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { palette } from '../../../styled/colors'
import { Flexed, Text } from '../../../styled/shared'
import HeightSliderAdvanceFilter from '../../Common/HeightSliderAdvanceFilter'

const AdvanceFilterList = ({ mode, data, getFilterData, isActive, setRecall, recall }) => {
	let height = ''
	const _ref = useRef(null)
	const [isShow, setIsShow] = useState(false)
	const handleClick = () => setIsShow(!isShow)
	const [heightFrom, setHeightFrom] = useState(100)
	const [heightTo, setHeightTo] = useState(220)

	if (data?.title === 'Height' && data?.selected != null && data?.selected != '' && data?.selected != undefined) {
		height = data?.selected.split(' ')
		if (height[0] == 100 && height[1] == 220) {
			data.selected = ''
		}
	}
	const addFiletrdata = async (info, title) => {
		if (mode === 'aff') {
			if (title === 'Exercise') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							exercise: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Drinking') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							drinking: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}

			if (title === 'Smoking') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							smoking: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}

			if (title === 'Star sign') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							star_sign: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Religion') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							religion: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Relationship') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							relation_ship: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'New To Area') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							new_to_area: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}

			if (title === 'Have Kids') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							have_kids: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Looking for') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
						{
							looking_for_aff: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
		} else {
			if (title === 'Exercise') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							exercise: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Drinking') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							drink: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}

			if (title === 'Smoking') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							smoke: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}

			if (title === 'Star sign') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							star_sign: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Religion') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							religion: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Kids') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							kid: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Politics') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							politics: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Education level') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							education_level: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Looking for') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}

				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							looking_for_data: info
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
			if (title === 'Height') {
				if (info === 'No preference') {
					info = ''
				}
				const _token = sessionStorage.getItem('authorization')
				const config = {
					headers: { authorization: `bearer ${_token}` }
				}
				await axios
					.post(
						`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
						{
							min_height: info ? heightFrom : 100,
							max_height: info ? heightTo : 220
						},
						config
					)
					.then((response) => {
						if (response.data.success === true) {
						}
					})
					.catch((error) => {
						console.error(error)
					})
			}
		}
		if (recall) {
			setRecall(false)
		} else {
			setRecall(true)
		}
	}
	const getIcons = (type) => {
		switch (type) {
			case 'Exercise':
				return '/icons/exercise.png'
			case 'Drinking':
				return '/icons/drinking.png'
			case 'Smoking':
				return '/icons/smoking.png'
			case 'Looking for':
				return '/icons/lookingFor.png'
			case 'Kids':
				return '/icons/kids.png'
			case 'Star sign':
				return '/icons/starSign.png'
			case 'Politics':
				return '/icons/politics.png'
			case 'Religion':
				return '/icons/religion.png'
			case 'Have Kids':
				return '/icons/haveKids.png'
			case 'New To Area':
				return '/icons/newToArea.png'
			case 'Relationship':
				return '/icons/relationship.png'
			case 'Education level':
				return '/icons/education.png'
			case 'Height':
				return '/icons/height.png'
			default:
				return ''
		}
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (_ref.current && !_ref.current.contains(event.target)) {
				if (data?.title !== 'Height') {
					setIsShow(false)
				}
			}
		}

		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<>
			{(data?.title === 'Height' ? (data?.selected != null && data?.selected != '' && data?.selected != undefined ? false : !isShow) : true) && (
				<>
					<Choose
						ref={_ref}
						onClick={() => {
							getFilterData(data)
							handleClick()
						}}>
						<Flexed align="center">
							<div style={{ display: 'flex', alignItems: 'center', gap: '0.6rem' }}>
								<img height="25px" src={getIcons(data?.title)} alt={data?.title} />
								<Text type="normal">
									{data?.title}
								</Text>
							</div>
						</Flexed>

						<div>
							<Flexed align="center">
								{data?.selected ? (
									<Text type="normal" color="text">
										{data?.selected}
									</Text>
								) : (
									<Add height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
									</Add>
								)}
							</Flexed>
						</div>
						{isShow && data?.title !== 'Height' && (
							<FilterBox>
								<Ul>
									{data?.data?.map((info, index) => (
										<Li onClick={() => addFiletrdata(info, data?.title)}>{info}</Li>
									))}
								</Ul>
							</FilterBox>
						)}
					</Choose>
				</>
			)}

			{(data?.selected != null && data?.selected != '' && data?.selected != undefined ? true : isShow) && data?.title === 'Height' && (
				<SliderWraper>
					<HeightSliderAdvanceFilter
						valueFrom={height[0]}
						setValueFrom={setHeightFrom}
						valueTo={height[1]}
						setValueTo={setHeightTo}
						saveFilter={() => {
							addFiletrdata(heightFrom + ' ' + heightTo, data?.title)
						}}
						onClose={() => {
							setIsShow(false)
							addFiletrdata('No preference', 'Height')
						}}
					/>
				</SliderWraper>
			)}
		</>
	)
}
const Add = styled.svg`
	cursor: pointer;
	color: ${palette.light_gray};
`

const Choose = styled.div`
	position: relative;
	cursor: pointer;
	border: 1px solid ${palette.yellow_border};
	border-radius: 20px;
	padding: 0.1rem 1rem;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${palette.black};
	margin-top: 0.7rem;
	&:hover {
		border: 1px solid ${palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${palette.yellow_border};
	}
	&:hover div {
		color: ${palette.text};
	}
	&:hover ${Add} {
		color: ${palette.text};
	}
`

const FilterBox = styled.div`
	position: absolute;
	top: 1.6rem;
	right: 0;
	z-index: 999;
	margin: auto;
	width: 12rem;
	height: auto;
	background-color: black;
	padding: 0.5rem 0;
	border-radius: 12px;
	margin-top: 1rem;
	border: 1px solid ${palette.yellow_border};
`
const Ul = styled.ul`
	top: 10rem;
`

const Li = styled.li`
	padding: 0.6rem 1rem;
	font-size: 14px;
	cursor: pointer;
	&:hover {
		background: ${palette.yellow_gradient};
	}
`

const SliderWraper = styled.div`
	padding: 1.4rem 1rem 1.8rem 1rem;
	width: 100%;
	border: 1px solid ${palette.yellow_border};
	background: ${palette.black};
	border-radius: 20px;
	gap: 1rem;
	display: inline-grid;
	margin-top: 0.7rem;
	:hover {
		border: 1px solid ${palette.yellow_border};
		background: ${palette.black};
	}
`

export default AdvanceFilterList
