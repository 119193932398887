import styled from 'styled-components'
import './editProfile.css'
import AboutCollapse from '../components/Collapse/AboutCollapse'
import EducationCollapse, { Button } from '../components/Collapse/EducationCollapse'
import StickyHeader from '../components/StickyHeader/StickyHeader'
import MyBasicCollapse from '../components/Collapse/MyBasicCollapse/MyBasicCollapse'
import { useEffect, useState } from 'react'
import { getAllInterests, getUser, removeUserImage, updateUserInterests } from '../apis/apis'
import ProfilePrompt from '../components/Collapse/MyBasicCollapse/ProfilePrompt'
import { palette } from '../styled/colors'
import { IoClose } from "react-icons/io5";
import 'react-image-lightbox/style.css'
import ProfileImageUploadModal from '../components/modals/ProfileImageUploadModal'
import ProfileImageDeleteModal from '../components/modals/ProfileImageDeleteModal'
import AccountVerificationModal from '../components/modals/AccountVerificationModal'
import { Text } from '../styled/shared'
import VerificationCompleteModal from '../components/modals/VerificationCompleteModal'
import PreviewProfile from '../components/Profile/PreviewProfile'
import ImgsViewer from "react-images-viewer";
import { useDispatch } from 'react-redux'
import { setUserDetails } from '../redux/actions/authActions'
import { Row, media } from 'styled-bootstrap-grid'
import AccountCollapse from '../components/Collapse/MyBasicCollapse/AccountCollapse'
import useWindowSize from '../hooks/useWindowSize'
import Switch from '../components/Common/Switch'
import axios from 'axios'
import InputSelect from '../components/Common/ReactSelect'

const Profile = () => {
    const _windowSize = useWindowSize()
    const [user, setUser] = useState(null)
    const [lightBoxOpen, setLightBoxOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)
    const [openImageUploadModal, setOpenImageUploadModal] = useState(false)
    const [specificImgIndex, setSpecificImgIndex] = useState(null)
    const [openImageDeleteModal, setOpenImageDeleteModal] = useState(false)
    const [openAccountVerificationModal, setOpenAccountVerificationModal] = useState(false)
    const [deleteImg, setDeleteImg] = useState(null)
    const [openVerifyCompleteModal, setOpenVerifyCompleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const _dispatch = useDispatch()
    const [interests, setInterests] = useState([])
    const [selectedInterests, setSelectedInterests] = useState([])
    const [useDefaultInterests, setUseDefaultInterests] = useState(false)

    const getUserinfo = async () => {
        setIsLoading(true)
        let user = await getUser()
        setUser(user?.data?.data?.user)
        setIsLoading(false);
        setSpecificImgIndex(null);
        _dispatch(setUserDetails(user?.data?.data?.user))
        if (user?.data?.data?.user?.interests?.length) {
            let interests = user?.data?.data?.user?.interests?.map(res => ({
                label: res?.interestsData?.title,
                value: res?.interestsData?.id

            }))
            setSelectedInterests(interests)
        }

        if (user?.data?.data?.user?.is_verify_admin && user?.data?.data?.user?.user_model_check === false) {
            setOpenVerifyCompleteModal(true)
        }

    }
    useEffect(() => {
        getUserinfo()
        getAllInterestsData()
    }, [])


    const getAllInterestsData = async () => {
        try {
            let res = await getAllInterests()
            if (res?.data?.length) {
                let arr = []
                for (let i = 0; i < res?.data?.length; i++) {
                    arr.push({ label: res?.data[i]?.title, value: res?.data[i]?.id })
                }
                setInterests(arr)
            }

        } catch (error) {

        }
    }

    const removeImageHandler = async (image) => {
        if (image !== null) {
            let image_array = []
            image_array.push(image)
            await removeUserImage(image_array)
            await getUserinfo()
            setDeleteImg(null)
        }
    }

    let images = user?.images?.map(res => ({ src: process.env.REACT_APP_PUBLIC_IMAGE_URL + '/' + res, }))

    const updateAvailability = async (e) => {
        const _token = sessionStorage.getItem('authorization')
        const config = {
            headers: { authorization: `bearer ${_token}` }
        }
        await axios
            .post(
                `${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
                {
                    is_available: e
                },
                config
            )
            .then((response) => {
                if (response.data.success === true) {
                    getUserinfo()
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const updateUserInterest = async (interests) => {
        try {
            interests = interests?.map(res => res.value)
            let res = await updateUserInterests(interests)
            if (res) {
                getUserinfo()
            }
            console.log('======res', interests)
        } catch (error) {

        }
    }
    return (
        <>
            <MainParent>
                <StickyHeader headerText={`${user?.mode == 'aff' ? 'DFF profile' : 'Date profile'}`} />
                <Content>
                    <ImageGrid>
                        <Card1>
                            {(user?.images?.length > 1 && user?.images[0]) ? <Delete onClick={() => { setDeleteImg(user?.images[0]); setOpenImageDeleteModal(true); setSpecificImgIndex(0) }}><Close /></Delete> : null}
                            <ImageUploadWraper width={_windowSize?.width < 481 ? '180px' : '260px'} height={_windowSize?.width < 481 ? '180px' : '260px'} border='16px' htmlFor="block-file-upload">
                                {(user?.images?.length && user?.images[0]) ? (
                                    <MainImage src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user.images[0]}`} alt="User Profile" width="300px" height="290" onClick={() => { setLightBoxOpen(true); setPhotoIndex(0) }} />
                                ) : (
                                    <Upload onClick={() => { setOpenImageUploadModal(true); setSpecificImgIndex(0) }}>
                                        <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                        </svg>
                                    </Upload>
                                )}
                            </ImageUploadWraper>
                        </Card1>

                        <Flexed>
                            <Card2>
                                {(user?.images?.length && user?.images[1]) ? <Delete onClick={() => { setDeleteImg(user?.images[1]); setOpenImageDeleteModal(true); setSpecificImgIndex(1) }}><Close /></Delete> : null}
                                <ImageUploadWraper width={_windowSize?.width < 481 ? '80px' : '119px'} height={_windowSize?.width < 481 ? '80px' : '119px'} border='10px' htmlFor="block-file-upload1">
                                    {(user?.images?.length && user?.images[1]) ? (
                                        <SamllImage src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[1]}`} alt="User Profile" width="300px" height="140" onClick={() => { setLightBoxOpen(true); setPhotoIndex(1) }} />
                                    ) : (
                                        <Upload onClick={() => { setOpenImageUploadModal(true); setSpecificImgIndex(1) }}>
                                            <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                            </svg>
                                        </Upload>
                                    )}
                                </ImageUploadWraper>
                            </Card2>

                            <Card2>
                                {(user?.images?.length && user?.images[2]) ? <Delete><Close onClick={() => { setDeleteImg(user?.images[2]); setOpenImageDeleteModal(true); setSpecificImgIndex(2) }} /></Delete> : null}
                                <ImageUploadWraper width={_windowSize?.width < 481 ? '80px' : '119px'} height={_windowSize?.width < 481 ? '80px' : '119px'} border='10px' htmlFor="block-file-upload2">
                                    {(user?.images?.length && user?.images[2]) ? (
                                        <SamllImage src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[2]}`} alt="User Profile" width="300px" height="140" onClick={() => { setLightBoxOpen(true); setPhotoIndex(2) }} />
                                    ) : (
                                        <Upload onClick={() => { setOpenImageUploadModal(true); setSpecificImgIndex(2) }}>
                                            <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                            </svg>
                                        </Upload>
                                    )}
                                </ImageUploadWraper>
                            </Card2>
                        </Flexed>
                    </ImageGrid>
                    <ImageGrid2>
                        <Card2>
                            {(user?.images?.length && user?.images[3]) ? <Delete><Close onClick={() => { setDeleteImg(user?.images[3]); setOpenImageDeleteModal(true); setSpecificImgIndex(3) }} /></Delete> : null}
                            <ImageUploadWraper width={_windowSize?.width < 481 ? '80px' : '119px'} height={_windowSize?.width < 481 ? '80px' : '119px'} border='10px' htmlFor="block-file-upload3">
                                {(user?.images?.length && user?.images[3]) ? (
                                    <SamllImage src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[3]}`} alt="User Profile" width="300px" height="140" onClick={() => { setLightBoxOpen(true); setPhotoIndex(3) }} />
                                ) : (
                                    <Upload onClick={() => { setOpenImageUploadModal(true); setSpecificImgIndex(3) }}>
                                        <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                        </svg>
                                    </Upload>
                                )}
                            </ImageUploadWraper>
                        </Card2>
                        <Card2>
                            {(user?.images?.length && user?.images[4]) ? <Delete><Close onClick={() => { setDeleteImg(user?.images[4]); setOpenImageDeleteModal(true); setSpecificImgIndex(4) }} /></Delete> : null}
                            <ImageUploadWraper width={_windowSize?.width < 481 ? '80px' : '119px'} height={_windowSize?.width < 481 ? '80px' : '119px'} border='10px' htmlFor="block-file-upload4">
                                {(user?.images?.length && user?.images[4]) ? (
                                    <SamllImage src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[4]}`} alt="User Profile" width="300px" height="140" onClick={() => { setLightBoxOpen(true); setPhotoIndex(4) }} />
                                ) : (
                                    <Upload onClick={() => { setOpenImageUploadModal(true); setSpecificImgIndex(4) }}>
                                        <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                        </svg>
                                    </Upload>
                                )}
                            </ImageUploadWraper>
                        </Card2>
                        <Card2>
                            {(user?.images?.length && user?.images[5]) ? <Delete><Close onClick={() => { setDeleteImg(user?.images[5]); setOpenImageDeleteModal(true); setSpecificImgIndex(5) }} /></Delete> : null}
                            <ImageUploadWraper width={_windowSize?.width < 481 ? '80px' : '119px'} height={_windowSize?.width < 481 ? '80px' : '119px'} border='10px' htmlFor="block-file-upload5">
                                {(user?.images?.length && user?.images[5]) ? (
                                    <SamllImage src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[5]}`} alt="User Profile" width="300px" height="140" onClick={() => { setLightBoxOpen(true); setPhotoIndex(5) }} />
                                ) : (
                                    <Upload onClick={() => { setOpenImageUploadModal(true); setSpecificImgIndex(5) }}>
                                        <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                        </svg>
                                    </Upload>
                                )}
                            </ImageUploadWraper>
                        </Card2>
                    </ImageGrid2>

                    <PreviewProfile user={user} />

                    <VerrificationList>
                        <Text type='normal' color='white' margin='0rem 0rem 0rem 1rem'>Verification</Text>
                        <AccountVerifiedBox verified={user?.is_verify_admin} onClick={() => { user?.is_verify_admin ? setOpenAccountVerificationModal(false) : setOpenAccountVerificationModal(true) }}>
                            Verified Your Account
                            {user?.is_verify_admin ?
                                <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                                        fill="currentColor"></path>
                                </svg>
                                :
                                <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></svg>
                            }
                        </AccountVerifiedBox>
                    </VerrificationList>
                    <VerrificationList>
                        {/* <Text type='normal' color='white' margin='0rem 0rem 0rem 1rem'>Is Available?</Text> */}
                        <Switch isProfile={true} value={user?.is_available} text={'Available For Date?'} handleChange={(e) => updateAvailability(e?.target?.checked)} />
                    </VerrificationList>

                    <VerrificationList>
                        <InputSelect label={'Interests'} options={interests} isMulti={true} value={selectedInterests} disableOptions={selectedInterests.length == 5} onChange={(e) => { e?.length <= 5 && setSelectedInterests(e); setUseDefaultInterests(true) }} />

                    </VerrificationList>
                    {selectedInterests?.length && useDefaultInterests && <div style={{ padding: '0.5rem', textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}><Button style={{ marginTop: '0.5rem', cursor: 'pointer' }} onClick={() => updateUserInterest(selectedInterests)}> Save</Button></div>}
                    <AccountCollapse />
                    <ProfilePrompt />
                    <AboutCollapse />
                    <EducationCollapse />
                    <MyBasicCollapse />
                </Content>
            </MainParent>

            {lightBoxOpen && (
                <ImgsViewer
                    imgs={images}
                    currImg={photoIndex}
                    isOpen={lightBoxOpen}
                    onClickPrev={(e) => setPhotoIndex(photoIndex - 1)}
                    onClickNext={(e) => setPhotoIndex(photoIndex + 1)}
                    onClose={() => setLightBoxOpen(false)}
                    showThumbnails={true}
                    onClickThumbnail={(e) => setPhotoIndex(e)}
                    showImgCount={false}
                // backdropCloseable={true}
                />
            )}
            <AccountVerificationModal isOpen={openAccountVerificationModal} onClose={() => { setOpenAccountVerificationModal(false) }} />
            <ProfileImageUploadModal isOpen={openImageUploadModal} onClose={() => { setOpenImageUploadModal(false) }} images={user?.images} getUserinfo={getUserinfo} specificImgIndex={specificImgIndex} />
            <ProfileImageDeleteModal isOpen={openImageDeleteModal} onClose={() => { setOpenImageDeleteModal(false) }} onDelete={() => { removeImageHandler(deleteImg); setOpenImageDeleteModal(false) }} isOpenUploadModal={() => { setOpenImageDeleteModal(false); setOpenImageUploadModal(true) }} />
            <VerificationCompleteModal isOpen={openVerifyCompleteModal} onClose={() => { setOpenVerifyCompleteModal(false) }} verifiedImg={user?.images?.length ? user?.images[0] : ''} />
            {isLoading && <Loading ><div class="dots-loading"></div></Loading>}
        </>
    )
}

const MainParent = styled.div`
width: 100%;
	overflow: scroll;
    background-color: ${palette.black};
    margin-top:4.48rem;

${media.sm`
position: relative;
`}
${media.md`
width: 100%;
position: static;
`}
${media.lg`
width: calc(100% - 400px);
position:static;
`}
`

const Content = styled.div`
    margin: 0 auto;
    padding: 0 32px 32px 32px;
width: 480px;
@media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100% !important;
  }
`;



const VerrificationList = styled.div`
	margin-top: 1.5rem;
`;

const AccountVerifiedBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1rem;
    height: 40px;
	cursor: ${({ verified }) => verified ? '' : 'pointer'};
	background: ${palette.black};
	color: white;
	margin-top: 0.3rem;
    border: 1px solid ${palette.yellow_border};
    &:hover{
        background: ${palette.yellow_gradient};
    }
`

const ImageGrid = styled(Row)`
  display: grid;
  gap: 0.4rem;
  height: auto;
  margin: auto;
    grid-template-columns: 2fr 1fr;
    @media screen and (min-width: 0px) and (max-width: 480px) {
        grid-template-columns: 1fr 1fr !important;
      }
`;

const Flexed = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
    ${media.xs`
    flex-direction: column;

`}
${media.sm`
flex-direction: column;

`}
${media.md`
flex-direction: column;
`}
${media.lg`
flex-direction: column;
`}
`

const ImageGrid2 = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 0.4rem;
	height: auto;
	margin: 0.4rem 0rem;
    @media screen and (min-width: 0px) and (max-width: 480px) {
        display: flex;
        gap: 0.4rem;
      }
`
const Card1 = styled.div`
	border: 1px solid ${palette.yellow_border};
	padding: 0.3rem;
	width: 274px;
	height: 274px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 193px !important;
    height: 194px !important;
  }
`
const Card2 = styled.div`
	border: 1px solid ${palette.yellow_border};
	padding: 0.3rem;
	width: 133px;
	height: 133px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	&:hover {
		border: 1px solid ${palette.yellow_theme};
	}
    @media screen and (min-width: 0px) and (max-width: 480px) {
        width: 93px !important;
        height: 93px !important;
      }
`
const Upload = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	background: ${palette.black};
	cursor: pointer;
    &:hover{
        background: ${palette.yellow_border};
    }
    & svg{
        color: ${palette.yellow_border};
    }
    &:hover svg{
        color: ${palette.black} !important;
    }
`
const ImageUploadWraper = styled.label`
	background: ${palette.black};
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	border-radius: ${({ border }) => border};
	display: flex;
	justify-content:center;
	align-items:center;
	cursor: pointer;
    &:hover{
        background: ${palette.yellow_border};
    }
`

const MainImage = styled.img`
	width: 260px;
	height: 260px;
	border-radius: 16px;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 180px !important;
	height: 180px !important;
  }
`

const SamllImage = styled.img`
	width: 119px;
	height: 119px;
	border-radius: 10px;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 80px !important;
	height: 80px !important;
  }
`

const Delete = styled.div`
	width: 28px;
	height: 28px;
	border-radius: 10rem;
	background-color: ${palette.yellow_theme};
	position: absolute;
	top: 4px;
	right: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;

`;

const Close = styled(IoClose)`
    color: ${palette.black};
    font-size: 18px;
    right:20px;
    cursor:pointer;
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
`
export default Profile