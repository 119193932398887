import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { palette } from '../../../styled/colors'
import { Flexed, Text } from '../../../styled/shared'

const AddProfilePrompt = ({ prompt, setPrompt, setShowInput, promptAns, setPromptAns, saveProfilePromptHandler }) => {
    const textAreaRef = useRef(null);
    const maxCharacters = 160;
    const [inputLength, setInputLength] = useState(0);

    useEffect(() => {
        if (textAreaRef.current) {
            // Set the textarea's height to its scrollHeight
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
        }
    }, [promptAns]);

    return (<>
        <Wrapper>
            <Text margin='0rem 0rem 0.5rem 0rem' color='hover_color'>{prompt}</Text>
            <Input
                ref={textAreaRef}
                maxlength="160"
                type="text"
                value={promptAns}
                onChange={(e) => {
                    if (e.target.value.length <= maxCharacters) {
                        setPromptAns(e.target.value)
                        setInputLength(e.target.value.length);
                    }
                }}
            />
        </Wrapper>
        <Flexed margin='1rem 0rem 0rem 0rem' align='center' justify='space-between'>
            <CancelButton onClick={() => { setPrompt(''); setPromptAns(''); setShowInput(false) }}>Cancel</CancelButton>
            {inputLength > 110 && <Text color='gray' type='normal' >{(160 - inputLength !== 0) ? 160 - inputLength + ' ' + 'characters left' : "That's it!"}</Text>}
            <Button disabled={promptAns?.length < 3} onClick={() => { saveProfilePromptHandler() }}>Save</Button>
        </Flexed>
    </>
    )
}

const Button = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: white;
    background: transparent;
    opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
    cursor: ${({ disabled }) => disabled ? 'no-drop' : 'pointer'};
	border: none;
	font-size: 16px;
    &:hover{
        background: ${palette.btn_hover_gradient}
    }
`
const CancelButton = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: ${palette.text};
    background: transparent;
	cursor: pointer;
	border: none;
	font-size: 16px;
    &:hover{
        background: #00000003;
    }
`

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	padding: 0.8rem 1rem;
	margin-top: 1rem;
	border: 1px solid ${palette.yellow_border};
	min-height: 56px;
    background: ${palette.black};
    &:hover{
        background: ${palette.black};
        border: 1px solid ${palette.black};
    }
`
const Input = styled.textarea`
	width: 100%;
	border: none !important;
	outline: none !important;
	font-size: 1rem;
	background: transparent;
	resize: none;
	overflow: hidden;
  	min-height: 50px;
  	max-height: 100%;
    color: ${palette.white};
`

export default AddProfilePrompt
