import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { Spacer } from '../../../styled/shared'

const BumbleCard = () => {
    const bumbleImg = [
        { image: "./bumble-img/image1.jpeg", },
        { image: "./bumble-img/image2.jpeg" },
        { image: "./bumble-img/image3.webp" },
        { image: "./bumble-img/image5.jpeg" },
    ]

    return (
        <>
            <Row>
                {
                    bumbleImg?.map((img) => {
                        return (
                            <Col xl={3} lg={4} md={4} sm={6} style={{marginBottom:'1rem'}}>
                                <Image src={img?.image} />
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
}

const Image = styled.img`
height: 100%;
width: 100%;
border-radius: 10px;
`

export default BumbleCard