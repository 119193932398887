// components/ContactUs.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../pages/EnterName';
import { palette } from '../styled/colors';
import { contactUsEmail } from '../apis/apis';
import { toastError, toastSuccess } from '../styled/toastStyle';
import useRouter from './Common/UseRouterHook';
import { media } from 'styled-bootstrap-grid'
import PhoneInput from 'react-phone-input-2';
import Loader from './Common/Loader';

const ContactUs = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [message, setMessage] = useState('')
  const [contactInfo, setContactInfo] = useState()
  const [loading, setLoading] = useState()
  const router = useRouter()

  const sendEmail = async () => {
    if (Validate()) {
      setLoading(true)
      let res = await contactUsEmail({ name, email, businessName: message, contactInfo })
      if (res?.data == true) {
        setLoading(false)
        toastSuccess(`Thank you! We have received your message. We will get back to you shortly.`)
        router.navigate('/')
      } else {
        setLoading(false)
        toastError('Failed to send message. Please try again later.')
      }
    }

  }
  const Validate = () => {
    let validate = true
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name?.trim()?.length == 0 || !name || name == undefined) {
      toastError('Name is required')
      validate = false
    } else if (email?.trim()?.length == 0 || !email || name == undefined) {
      toastError('Email is required')
      validate = false
    } else if (emailRegex.test(email) == false) {
      toastError('Please enter valid email')
      validate = false
    }
    else if (message?.trim()?.length == 0 || !message || message == undefined) {
      toastError('message is required')
      validate = false
    }
    else if (contactInfo?.trim()?.length == 0 || !contactInfo || contactInfo == undefined) {
      toastError('contact info is required')
      validate = false
    }
    return validate
  }

  const isNumberKey = (evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode
    return !(charCode > 31 && (charCode < 48 || charCode > 57))
  }

  return (
    <>
      {loading && <Loader />}

      <ContactUsContainer>

        <Heading>Contact Us</Heading>
        <ContactForm >
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <Input style={{ width: '100%' }} placeholder='Your Name' type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input style={{ width: '100%' }} placeholder='Email' type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="contact">Contact Info</Label>
            <PhoneInput
              inputStyle={{ width: '100%' }}
              containerStyle={{ width: '100%' }}
              inputClass="phoneInputFieldsContact"
              country={'us'}
              enableAreaCodes={true}
              value={contactInfo}
              inputProps={{
                name: 'phone',
                country: 'us',
                required: true,
                autoFocus: false
              }}
              onChange={(phone) => {
                let number = '+' + phone
                if (isNumberKey(number)) {
                  setContactInfo(number)
                }
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="message">Message</Label>
            <TextArea id="message" rows="5" placeholder='Your Message' value={message} onChange={(e) => setMessage(e.target.value)}></TextArea>
          </FormGroup>

          <SubmitButton cursor={name == undefined || message == undefined || email == undefined} type="submit" onClick={() => sendEmail()} >Submit</SubmitButton>
        </ContactForm>
      </ContactUsContainer>
    </>
  );
};

const ContactUsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.div`
  font-size: 36px;
  color: ${palette.text};
  margin: 20px 0px 10px 0px;
  font-size: 1.5rem;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`;

const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.yellow_border};
  background-color: ${palette.black};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 18px;
  color: ${palette.white};
  margin-bottom: 8px;
`;

const TextArea = styled.textarea`
  padding: 12px;
  font-size: 16px;
  border: 1px solid ${palette.text};
  outline : none;
  background: ${palette.black};
  color: white;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  resize: vertical;
  width: 100%;

  &:focus {
    border-color:${palette.hover_color};
  }
  -webkit-transition: 0.5s;
  transition: 0.5s;
&:focus{
    border: 1px solid ${palette.hover_color};
}

&:focus-visible{
    border: 1px solid ${palette.hover_color};
}
`;

const SubmitButton = styled.button`
  background: ${palette.btn_gradient};
  color: #fff;
  padding: 14px;
  font-size: 18px;
  cursor: ${({ cursor }) => cursor ? 'not-allowed' : 'pointer'} ;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background: ${palette.btn_hover_gradient};
  }
`;

export default ContactUs;
