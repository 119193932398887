import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import { useJsApiLoader } from '@react-google-maps/api'
import { ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import RegisterStepper from '../components/RegisterStepper'
import { BorderWrapper, Flexed, OverLay, Spacer } from '../styled/shared'
import { CommonEnum } from '../utils'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const Location = () => {
	const _navigate = useNavigate()
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [location, setLocation] = useState('')
	const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
	const [isMianloading, setIsMianloading] = useState(false);

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDQBGN5bGpoPz86bJ0Mk-JLwWTgpm-bWhQ'
	})

	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setLat(position.coords.latitude)
				setLng(position.coords.longitude)
				await getCurrentAddress(position.coords.latitude, position.coords.longitude)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
					setIsLocationModalOpen(true);
					Timer()
				} else {
				}
			},
			{ timeout: 5000, enableHighAccuracy: true }
		)
	}

	const getCurrentAddress = async (lat, lng) => {
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {
				setLocation(response.data.results[6].formatted_address)
				doSaveLocation(response.data.results[6].formatted_address, lat, lng)
			})
			.catch((error) => {
				setLocation('')
			})
	}

	const doSaveLocation = async (location, lat, lng) => {
		setIsMianloading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					location: location,
					lat: lat,
					lng: lng,
					last_screen: 'login'
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					setIsMianloading(false)
					_navigate('/verification')
				}
			})
			.catch((error) => {
				setIsMianloading(false)
				console.error(error)
			})
	}

	const Timer = () => {
		setTimeout(autoHideLocationModal, 10000);
	}

	const autoHideLocationModal = () => {
		setIsLocationModalOpen(false);
	}

	return (
		<>
			<Wrapper>
			<OverLay/>
				<Container>
					<Row justifyContent="center">
					<Col sm={9} md={9} lg={7}>
							<BorderWrapper>
								<RegisterStepper progress={100} goBack={() => { _navigate('/chooseMode') }} />
								<Spacer height={3} />
								<Center>
									<svg width={'140px'} data-origin="pipeline" viewBox="0 0 144 144" fill="none">
										<path d="M72 0c39.765 0 72 32.236 72 72 0 39.765-32.235 72-72 72-39.764 0-72-32.235-72-72C0 32.236 32.236 0 72 0z" fill="#4A4F55"></path>
										<path fill-rule="evenodd" clip-rule="evenodd" d="M3 72C3 33.892 33.892 3 72 3c38.108 0 69 30.892 69 69 0 5.508-.645 10.866-1.865 16H4.865A69.19 69.19 0 013 72z" fill="#F9F5B0"></path>
										<path d="M138.057 92c-8.574 28.355-34.905 49-66.057 49S14.517 120.355 5.943 92h132.114z" fill="#FFCA36"></path>
										<path
											d="M72 3c29.579 0 54.81 18.612 64.623 44.763-7.249 2.54-13.87 4.07-19.865 4.595-19.627 1.717-35.83-10.64-56.437-12.919-19.6-2.167-37.247 4.803-53.824 10.817C15.604 22.803 41.489 3 72 3z"
											fill="#fff"
											fill-opacity="0.3"></path>
										<ellipse cx="71.788" cy="103.682" rx="18" ry="5" fill="#E3AA1F"></ellipse>
										<path
											d="M72 30.035c15.691 0 28.412 12.72 28.412 28.412 0 10.057-8.27 23.998-24.687 42.331a5 5 0 01-6.97.467l-.18-.161-.092-.09-.21-.219-.51-.57c-16.075-18.05-24.175-31.804-24.175-41.758 0-15.691 12.72-28.412 28.412-28.412z"
											fill="#4A4F55"></path>
										<path
											d="M72 33.035c-14.035 0-25.412 11.378-25.412 25.412 0 8.884 7.637 21.948 22.91 39.192l1.012 1.137.12.124c.375.352.856.534 1.34.542l.162-.004a1.99 1.99 0 001.358-.662l1.007-1.13c15.276-17.248 22.915-30.314 22.915-39.199 0-14.034-11.378-25.412-25.412-25.412z"
											fill="#F79B7F"></path>
										<path
											d="M76.682 69.467h-9.555a2.77 2.77 0 01-2.405-1.396l-4.356-7.624a2.77 2.77 0 010-2.749l4.356-7.623a2.77 2.77 0 012.405-1.396h9.52a2.77 2.77 0 012.433 1.443l4.373 8.017a2.77 2.77 0 01-.057 2.753l-4.338 7.23a2.77 2.77 0 01-2.376 1.345z"
											fill="#4A4F55"></path>
										<path d="M76.552 66.467l4.204-7.007-4.245-7.78H67.26l-4.225 7.393 4.225 7.394h9.292z" fill="#FAF8C7"></path>
										<path
											d="M24.988 72.812c-5.65 0-10.2 4.664-10.2 10.38 0 3.218 2.064 7.393 6.112 12.969l.3.41a4.696 4.696 0 007.57.008l.623-.857c3.842-5.36 5.795-9.402 5.795-12.53 0-5.716-4.55-10.38-10.2-10.38z"
											fill="#4A4F55"></path>
										<path
											d="M24.988 75.812c3.977 0 7.2 3.304 7.2 7.38 0 2.447-1.945 6.317-5.835 11.61a1.693 1.693 0 01-2.633.12l-.097-.12-.295-.404-.583-.813c-3.305-4.674-4.957-8.138-4.957-10.393 0-4.076 3.224-7.38 7.2-7.38z"
											fill="#F79B7F"></path>
										<path
											d="M112.659 70.694c-5.886 0-10.624 4.867-10.624 10.831 0 3.385 2.203 7.83 6.531 13.781l.306.42a4.696 4.696 0 007.568.006c4.537-6.168 6.842-10.747 6.842-14.207 0-5.964-4.738-10.83-10.623-10.83z"
											fill="#4A4F55"></path>
										<path
											d="M112.659 73.694c4.21 0 7.623 3.506 7.623 7.831 0 2.613-2.086 6.756-6.258 12.43a1.695 1.695 0 01-2.633.12l-.097-.12-.302-.413-.58-.808c-3.585-5.051-5.377-8.787-5.377-11.209 0-4.325 3.413-7.83 7.624-7.83z"
											fill="#F79B7F"></path>
										<path
											d="M121.912 76.157c-5.642-.296-10.43 4.123-10.729 9.832-.164 3.13 1.581 7.272 5.14 12.829l.563.868a4.696 4.696 0 007.56.403c4.55-5.557 6.937-9.745 7.109-13.032.299-5.709-4-10.605-9.643-10.9z"
											fill="#4A4F55"></path>
										<path
											d="M121.755 79.153c3.971.208 7.018 3.676 6.804 7.747-.128 2.443-2.273 6.206-6.434 11.288a1.694 1.694 0 01-2.636-.018l-.091-.124-.274-.42-.539-.842c-3.055-4.84-4.524-8.386-4.406-10.638.213-4.07 3.605-7.202 7.576-6.993z"
											fill="#F79B7F"></path>
									</svg>
								</Center>

								<Heading>We need your location to show who’s nearby</Heading>
								<Text>
									You will need to grant {CommonEnum.AppName} access to your location so we can show <br /> you awesome bees in your area.
								</Text>

								<Button type="submit" onClick={getCurrentLatLng}>
									Allow location access
								</Button>
							</BorderWrapper>
						</Col>
					</Row>
				</Container>
			</Wrapper>
			<ModalProvider>
				<LocationModal id="modelWrap" isOpen={isLocationModalOpen}>
					<div>
						<Body>
							<Spacer height={0.5} />
							<img src='/icons/location_permission.png' alt='location_permission' />
							<Spacer height={1.5} />
						</Body>
						<ProgressBar>
							<Bar />
						</ProgressBar>
						<Body>
							<ModelHeading>We need your permission</ModelHeading>
							<Text type='normal' color='text'>
								{"To enable your location please go to: 🔒 in your browser > Location > Allow"}
							</Text>
							<Flexed direction='column' align='center' margin='1rem 0rem 0rem 0rem' gap={1} >
								<CancelButton onClick={() => { setIsLocationModalOpen(false); }}>Got it</CancelButton>
							</Flexed>
						</Body>
					</div>
				</LocationModal>
			</ModalProvider>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const Wrapper = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	background: url('/home_cover.webp');
	width: 100%;
	background-size: cover;
	background-position: center;
	background-reapete: center;
	background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
`

const Heading = styled.div`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
	line-height:1.5;
	color: ${palette.yellow_border};
	${media.xs`
		font-size: 18px;
	`}
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Wrap = styled.div`
margin-top: 3.5rem;
`
const Text = styled.p`
	margin-top: 1rem;
	text-align: center;
	line-height: 1.6;
	color: ${palette.text};
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: ${palette.btn_gradient};
	margin: auto;
	padding: 0.8rem 1rem;
	width: 170px;
	color: ${palette.white};
	border-radius: 20px;
	border: none;
	margin-top: 2rem;
	margin-bottom: 1rem;
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const StyledModal = Modal.styled`
	width: 90%;
	border-radius: 8px;
  	padding: 2rem !important;
  	background-color: ${palette.white};
	${media.md`
		width: 600px;
    `}
`

const LocationModal = styled(StyledModal)`
	border-radius: 8px;
  	padding: 0rem !important;
  	background-color: ${palette.black};
	border: 1px solid ${palette.yellow_border};
`

const Body = styled.div`
	padding: 2rem !important;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const progressBarAnimation = keyframes`
  0% {
    width: 100%;
  }
  25% {
    width: 75%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 25%;
  }
  100% {
    width: 0%;
  }
`;

const ProgressBar = styled.div`
	width: 100%;
	height: 2px;
	background: ${palette.light_gray};

`;

const Bar = styled.div`
	width: 0%;
	height: 2px;
	background: ${palette.yellow_theme};
	animation: ${progressBarAnimation} 10s linear;
`;

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
	line-height: 2.3rem;
	color: ${palette.yellow_theme};
`

const CancelButton = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: ${palette.yellow_theme};
    background: transparent;
	cursor: pointer;
	border: none;
	font-size: 16px;
	min-width: 140px;
	height: 42px;
    &:hover{
        background: #00000003;
	}
 `

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

export default Location
