import React from 'react'
import styled from 'styled-components'
import Testimonial from '../components/Testimonial/Testimonial'
import { Col, Container, Row, media } from 'styled-bootstrap-grid'
import Footer from '../components/Footer/Footer'
import { palette } from '../styled/colors'
import { OverLay, Spacer, Text } from '../styled/shared'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const _navigate = useNavigate();
    return (
        <>
            <Spacer height={0.688} />
            <Cover>
            <OverLay/>
                <Container>
                    <Row>
                        <Col xl={7.5} lg={9}  >
                            <CustomHeading>
                                THE WORLD'S LARGEST MILLIONAIRE DATING SERVICE
                            </CustomHeading>
                            <Spacer height={1} />
                            <CustomText>
                                More Authentic & Quality Profiles Than Other Places
                            </CustomText>
                            <Spacer height={1} />
                            <CustomText fontSize={2} lineHeight={2.5} fontWeight={500}>
                                Meet 5,567,617+ High-Quality Singles and Build Serious Relationships
                            </CustomText>
                            <Spacer height={2} />
                            <Button onClick={() => _navigate('/get-started')}>
                                JOIN IF YOU ARE A RESPECTABLE AND ATTRACTIVE SINGLE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Cover>
            <Testimonial />
            <Footer />
        </>
    )
}

const Cover = styled.div`
    position: relative;
    background: url('/home_cover.webp');
    width: 100%;
    background-size: cover;
    background-position: center;
    background-reapete: center;
    height: 100%;
    background-repeat: no-repeat;
    padding: 2rem 0rem 2rem 0rem; 

    ${media.sm`
    padding: 4rem 0rem 4rem 0rem;  
    height: 100%;
    `};
    ${media.md`
    padding: 4rem 0rem 4rem 0rem;  
    height: 100%;
    `};
    ${media.xl`
    padding: 7.5rem 0rem 0rem 0rem; 
    height: 870px;
    `};
`;

const CustomHeading = styled(Text)`
    letter-spacing: -1.5px;
    font-size:24px;
    line-height:2rem;
    font-weight:600;
    text-align: center;
    letter-spacing: 0px;
    ${media.sm`
    font-size:2.5rem;
    line-height:4rem;
    font-weight:600;
    text-align: left;
    letter-spacing: -1.5px;
    `};
    ${media.md`
    font-size:3.2rem;
    line-height:4rem;
    font-weight:600;
    text-align: left;
    letter-spacing: -1.5px;
    `};
    ${media.lg`
    font-size:3.6rem;
    line-height:4.5rem;
    font-weight:800;
    text-align: left;
    letter-spacing: -1.5px;
    `};
`;

const CustomText = styled(Text)`
    letter-spacing: 0px;
    font-size:1rem;
    line-height:1.5rem;
    font-weight:500;
    text-align: center;
    ${media.sm`
    font-size:1.5rem;
    line-height:2rem;
    font-weight:500;
    text-align: left;
    `};
    ${media.md`
    font-size:1.5rem;
    line-height:2rem;
    font-weight:500;
    text-align: left;
    `};
    ${media.lg`
    font-size:2rem;
    line-height:2.5rem;
    font-weight:500;
    text-align: left;
    `};
`;

const Button = styled.div`
    background: ${palette.yellow_gradient};
    border-radius: 0.3rem;
    color: ${palette.text};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-weight: 400;
    cursor: pointer;
    line-height:1.4rem;
    padding: 0rem 1.5rem;
    text-align: center; 
    font-size: 14px;
    ${media.sm`
    font-size: 16px;
    width: 100%;
    `};
    ${media.md`
    font-size: 16px;
    width: 80%;
    `};
`;

export default Home