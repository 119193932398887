import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Text } from "../../styled/shared";
import Webcam from 'react-webcam'
import { media } from 'styled-bootstrap-grid';

const CameraModal = ({ isOpen, onClose, setWebCamImage }) => {
    const webcamRef = useRef(null)
    const [count, setCount] = useState(null);
    const [timerStart, setTimerStart] = useState(false)
    const capture = async () => {
        setTimerStart(true)
        setCount(0)
    }

    useEffect(() => {
        if (count !== null) {
            const interval = setInterval(() => {
                if (count <= 2) {
                    setCount(count => count + 1);
                }
                else if (count <= 3) {
                    setCount(count => count + 1);
                    const imageSrc = webcamRef.current.getScreenshot()
                    setWebCamImage(imageSrc);
                    onClose()
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [count]);

    return (
        <>
            <StyledModal>
                <Wrapper>
                    <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" style={{ width: '100%', height: '100%' }} />
                    <Cover />
                </Wrapper>
                <Body timerStart={timerStart}>
                    {!timerStart ? <>
                        <Text type='normal' isCentered color='text'>Take a selfie while copying this gesture</Text>
                        <Button onClick={() => { capture() }}>Take photo</Button>
                    </>
                        :
                        <>
                            <Text type='normal' isCentered color='white'>Get ready!</Text>
                            <Text fontSize={3} margin='0.5rem 0rem 0rem 0rem' fontWeight={600} color='white'>{count}</Text>
                        </>
                    }
                </Body>
            </StyledModal>
        </>
    )
}

const StyledModal = styled.div`
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: ${palette.black};
    border:1px solid ${palette.yellow_border};
    overflow: hidden;
    ${media.xs`
    width: 100%
    `}
    ${media.sm`
    width: 100%
    `}
    ${media.md`
    width: 600px;
    `}
    ${media.lg`
    width: 600px;
    `}
`

const Body = styled.div`
    padding: 1.5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.4rem;
    width: 100%;
    height: 142.77px;
    background: ${({ timerStart }) => timerStart ? palette.btn_gradient : palette.black};
`;

const Cover = styled.div`
    position: absolute;
    bottom: 0;
    left: 0.7rem;
    width: 104px;
    height: 144px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-image: url(/verification_img.png);
    background-position: 50%;
    background-size: cover;
    margin-bottom: 1rem;
`

const Wrapper = styled.div`
    position: relative;
    background: ${palette.black};
    padding: 0rem 2rem;
    height: 402px;
`

const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

export default CameraModal
