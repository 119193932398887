import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Logo from '../components/Logo/Logo'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import { HiOutlineX } from 'react-icons/hi'
import axios from 'axios'
import RegisterStepper from '../components/RegisterStepper'
import { BorderWrapper, Flexed, OverLay, Spacer, Text } from '../styled/shared'
import { FileUploader } from "react-drag-drop-files";
import { media } from 'styled-bootstrap-grid'
import { getUser, removeUserImage } from '../apis/apis'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
const fileTypes = ["JPG", "JPEG", "PNG", "WEBP"];

const UplodePhoto = () => {
	const [images, setImages] = React.useState([])
	const _navigate = useNavigate()
	const _inputRef = React.useRef(null)
	const [isImgloading, setIsImgLoading] = useState(false);
	const [isMianloading, setIsMianloading] = useState(false);

	const onDraggingStateChange = (file) => {
	};

	const onButtonClick = () => {
		_inputRef.current.click()
	}

	const handleFileChange = async function (file) {
		if (file) {
			let reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = async function () {
				setImages([...images, reader.result])
				await doSaveImages([...images, reader.result])
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}

		}
	};

	const handleChange = function (e) {
		e.preventDefault()
		if (e.target.files && e.target.files[0]) {
			let reader = new FileReader()
			reader.readAsDataURL(e.target.files[0])
			reader.onload = async function () {
				setImages([...images, reader.result])
				await doSaveImages([...images, reader.result])
			}
			reader.onerror = function (error) {
				console.log('Error: ', error)
			}
		}
	}

	const doSaveImages = async (image) => {
		setIsImgLoading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					images: image,
					last_screen: 'UserAddDateOfBirth'
				},
				config
			)
			.then(async (response) => {
				if (response.data.success === true) {
					await getUserinfo();
				}
				setIsImgLoading(false)
			})
			.catch((error) => {
				console.error(error)
				setIsImgLoading(false)
			})
	}

	const getUserinfo = async () => {
		let user = await getUser()
		if (user?.data?.data?.user?.images?.length > 0) {
			setImages(user?.data?.data?.user?.images)
		}
		setIsMianloading(false);
	}

	useEffect(() => {
		setIsMianloading(true)
		getUserinfo()
	}, [])

	const deleteSelectedImg = async (itemToRemove) => {
		setIsMianloading(true)
		setImages((prevArray) => prevArray.filter((item) => item !== itemToRemove))
		await removeUserImage(itemToRemove)
		await getUserinfo()
	}

	return (
		<>
			<Main>
			<OverLay/>
				<Container>
					<Row justifyContent="center">
					<Col sm={9} md={9} lg={7}>
							<BorderWrapper>
								<RegisterStepper progress={60} goBack={() => { _navigate('/enter-name') }} />
								<Heading>Now it’s time to upload some photos</Heading>
								<CustomText>
									Adding photos is a great way to show off more about yourself! You can <br /> drag your photos right from your desktop.
								</CustomText>
							
							<Spacer height={1} />
							<Flexed justify='center'>
								{images?.length === 0 &&
									<Wrapper>
										<FileUploader onDraggingStateChange={onDraggingStateChange} classes='drop_area drop_zone' label='Drag and drop photos here' handleChange={handleFileChange} name="file" types={fileTypes} />
									</Wrapper>
								}
							</Flexed>
							<ImageCenter>
								{images.length !== 0 &&
									images.map((img, index) => {
										return (
											<Cover isImgloading={(index === (images.length - 1)) && isImgloading}>
												<Delete
													onClick={() => {
														deleteSelectedImg(img)
													}}>
													<HiOutlineX fontSize="1.1rem" color="#000" />
												</Delete>
												<Image isImgloading={(index === (images.length - 1)) && isImgloading} key={img} src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${img}`} alt="" />
												{((index === (images.length - 1)) && isImgloading) && <ImgLoading><div class="dots-loading"></div></ImgLoading>}
											</Cover>
										)
									})}

								{images.length !== 0 && images.length < 6 && (
									<>
										{images.length < 2 && (
											<ImageUploadWraper isImgloading={isImgloading} htmlFor={isImgloading ? '' : 'block-file-upload'}>
												<FileInput ref={_inputRef} type="file" id="block-file-upload" multiple={true} onChange={handleChange} />
												<Upload isImgloading={isImgloading}>
													<svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
													</svg>
												</Upload>
											</ImageUploadWraper>
										)}
										<ImageUploadWraper isImgloading={isImgloading} htmlFor={isImgloading ? '' : 'block-file-upload'}>
											<FileInput ref={_inputRef} type="file" id="block-file-upload" multiple={true} onChange={handleChange} />
											<Upload isImgloading={isImgloading}>
												<svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
												</svg>
											</Upload>
										</ImageUploadWraper>
									</>
								)}
							</ImageCenter>


							<Spacer height={1} />
							<Text color='yellow_border' isCentered>We accept JPG and PNG.</Text>

							<Spacer height={1} />
							<SocialIcons>
								<Form>
									<UploadButton onClick={onButtonClick} isImgloading={isImgloading} title="Upload from computer">
										<Toltip>Upload from computer</Toltip>
										<FileInput ref={_inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
										<svg style={{ marginLeft: '1rem' }} height="60px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M16 0C7.163 0 0 7.164 0 16c0 8.837 7.163 16 16 16 8.836 0 16-7.163 16-16 0-8.836-7.164-16-16-16z" fill={palette.hover_color}></path>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M20.219 10.116a.333.333 0 00-.333-.333h-7.772a.333.333 0 100 .666h7.772c.184 0 .333-.15.333-.333zm.777 1a.333.333 0 110 .665h-9.992a.333.333 0 110-.666h9.992zm.307 1.331c.505 0 .914.41.914.915v7.94c0 .506-.41.915-.914.915H10.697a.914.914 0 01-.914-.914v-7.941c0-.505.41-.915.914-.915h10.606zm0 .915H10.697v4.881l.214-.208.344-.344a277.24 277.24 0 001.655-1.699.457.457 0 01.652-.005l2.447 2.447.946-.946a.457.457 0 01.646 0l.51.51.068-.069a.457.457 0 01.646 0l2.478 2.477v-7.044zm-1.306 1.972a.666.666 0 10-1.332 0 .666.666 0 001.332 0z"
												fill="#fff"></path>
										</svg>
									</UploadButton>
								</Form>
							</SocialIcons>
							<SocialButton onClick={() => { _navigate('/enter-dob') }} disabled={images?.length === 0}>
								Continue
							</SocialButton>
							</BorderWrapper>
						</Col>
					</Row>
				</Container>
			</Main>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const Main = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	background: url('/home_cover.webp');
	width: 100%;
	background-size: cover;
	background-position: center;
	background-reapete: center;
	background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
`

const Delete = styled.div`
	width: 1.3rem;
	height: 1.3rem;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${palette.yellow_border};
	display: none;
	cursor: pointer;
`

const Cover = styled.div`
	position: relative;
	border-radius: 15px;
	border: ${({ isImgloading }) => isImgloading ? `1px solid ${palette.yellow_theme};` : 'none'};
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	&:hover ${Delete} {
		display: flex;
	}
`

const Image = styled.img`
	width: 7rem;
	height: 7rem;
	border-radius: 1rem;
	cursor: pointer;
	background: ${palette.black};
`

const ImageCenter = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 1rem;
`

const Heading = styled.div`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
	margin-bottom: 1.5rem;
	line-height:1.5;
	color: ${palette.yellow_border};
	${media.xs`
		font-size: 18px;
	`}
`
const CustomText = styled.p`
	line-height: 1.6;
	text-align: center;
	color: ${palette.text};
`
const Wrap = styled.div`
	margin-top: 3.5rem;
`

const Form = styled.div`
	width: 360px;
	max-width: 100%;
	text-align: center;
	position: relative;
	display: block;
	margin: auto;
	height: 112px;
`
const SocialIcons = styled.div`
	display: flex;
	justify-content: center;
	gap: 2rem;
`

const Upload = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	background: ${palette.black};
	cursor: ${({ isImgloading }) => isImgloading ? 'no-drop' : 'pointer'};
	pointer-events : ${({ isImgloading }) => isImgloading ? 'none' : 'default'};
	& svg{
        opacity: 0.7;
        color: ${palette.yellow_border};
    }
    &:hover svg{
        opacity: 1;
		color: ${palette.black};
    }
    &:hover{
        background: ${palette.yellow_border};
    }
`

const ImageUploadWraper = styled.label`
	width: 7rem;
	height: 7rem;
	padding: 0.3rem;
	border-radius: 15px;
	border: 1px solid ${palette.hover_color};
	background: ${palette.black};
	cursor: ${({ isImgloading }) => isImgloading ? 'no-drop' : 'pointer'};
	pointer-events : ${({ isImgloading }) => isImgloading ? 'none' : 'default'};
	&:hover {
		border: 1px solid ${palette.hover_color};
	}
	&:hover ${Upload} {
		background: ${palette.yellow_border};
	}
`

const Toltip = styled.div`
    display: none;
	min-width:9rem;
    color: ${palette.white};
    background: ${palette.yellow_border};
    padding: 0.2rem 0.5rem;
    font-size: 12px;
    position: absolute;
    top:-44%;
    right:0;
    left:-28%;
    margin: auto;
    border-radius: 0.5rem;
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 47%;
        border-width: 5px;
        border-style: solid;
        border-color: ${palette.yellow_border} transparent transparent transparent;
    }
`

const UploadButton = styled.button`
	padding: 0.25rem;
	cursor: pointer;
	pointer-events : ${({ isImgloading }) => isImgloading ? 'none' : 'default'};
	font-size: 1rem;
	border: none;
	background-color: transparent;
	position: relative;
    &:hover ${Toltip} {
        display: block;
	}
`

const FileInput = styled.input`
	display: none;
`

const SocialButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	border: none;
	padding: 0 20px;
	border-radius: 20px;
	background: ${palette.btn_gradient};
	color: ${palette.white};
	width: 7rem;
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
	cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
	margin: auto;
	text-align: center;
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const Wrapper = styled.div`
	border: 1px solid ${palette.yellow_theme};
    padding: 4px;
    border-radius: 16px;
	& label{
        ${media.xs`
       width:250px;
	   min-width: auto !important;
	   max-width: auto !important;
        `}
        ${media.md`
        min-width: 100%;
        max-width: 500px;
        `}
    }
    & label svg{
        display: none !important;
    }
    & label div{
        justify-content: center !important;
        border: none !important;
        opacity: 1 !important;
        border-radius: 16px !important;
        background-color: ${palette.black}; !important;
    }
    & label div span.file-types{
        display: none !important;
    }
    & label div span{
       color: ${palette.yellow_theme};
       font-weight: 400 !important;
       text-decoration: none !important;
    }
`

const ImgLoading = styled.div`
	position: absolute;
	background: ${palette.black};
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
	bottom:0;
	right:0;
	left:0;
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

export default UplodePhoto
