import React from 'react';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { palette } from '../../styled/colors';
import { Text } from '../../styled/shared';

const InputSelect = ({
    defalutValue,
    value,
    isMulti,
    options,
    onChange,
    name,
    disabled,
    error,
    label,
    subLable,
    required,
    ref,
    disableOptions,
    noOptionsMessage
}) => {
    const _isDarkTheme = useSelector((state) => state.auth.isDarkTheme);
    let set = new Set();
    let values = [];
    value &&
        isMulti &&
        value?.forEach((item) => {
            if (!set.has(item?.label)) {
                set.add(item?.label);
                values.push(item);
            }
        });

    const colourStyles = {
        control: (provided, state) => {
            return {
                ...provided,
                color: palette.gray_400,
                borderColor: palette.hover_color,
                borderRadius: '20px',
                padding: '0.1rem',
                backgroundColor: 'transparent',
                boxShadow: 'none !important',
                '&:hover': {
                    borderColor: state.isFocused ? `${palette.hover_color} !important` : palette.hover_color,
                    boxShadow: 'none !important',
                },
            };
        },

        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                zIndex: 5,
                cursor: isDisabled ? 'not-allowed' : 'default',
                // backgroundColor: isSelected ? palette.danger : null,
                ':hover': {
                    backgroundColor: isDisabled ? null : _isDarkTheme ? palette.gray_400 : palette.black,
                    color: isDisabled ? null : palette.hover_color,
                },
                ':focus': {
                    backgroundColor: isSelected ? palette.hover_color : null,
                    color: isDisabled ? null : palette.hover_color,
                },
                ':active': {
                    backgroundColor: isSelected ? palette.hover_color : null,
                    color: isDisabled ? null : palette.hover_color,
                },
            };
        },
    };

    return (
        <>

            <Select
                // menuIsOpen={true}
                placeholder={label}
                styles={colourStyles}
                ref={ref}
                defaultValue={defalutValue}
                name={name}
                value={
                    isMulti
                        ? values
                        : options?.filter(
                            (option) =>
                                option.label === (value || defalutValue) || option.value === (value || defalutValue)
                        )
                }
                onChange={(e) => onChange && onChange(e)}
                options={options}
                isMulti={isMulti}
                isDisabled={disabled}
                required={required}
                // noOptionsMessage={noOptionsMessage && NoOptionsMessage()}
                loadingMessage={noOptionsMessage}
            />
            {error && !disabled && (
                <Text fontSize={0.7} type="small" color="danger">
                    {error?.message}
                </Text>
            )}
        </>
    );
};

const Label = styled(Text)`
  font-weight: 500;
  text-transform: capitalize;
`;
const SubLable = styled.span`
  font-size: 1rem;
  line-height: 2rem;
  color: ${palette.text_color};
  font-family: 'Roboto';
  text-transform: capitalize;
`;
const Mandatory = styled.span`
  color: ${palette.danger};
`;

export default InputSelect;
