import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Text } from "../../styled/shared";
import Modal, { ModalProvider } from 'styled-react-modal'
import axios from 'axios'

const VerificationCompleteModal = ({ isOpen, onClose, verifiedImg }) => {
    const onSave = async () => {
        const _token = sessionStorage.getItem('authorization')
        const config = {
            headers: { authorization: `bearer ${_token}` }
        }
        await axios
            .post(
                `${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
                {
                    user_model_check: true
                },
                config
            )
            .then((response) => {
                if (response.data.success === true) {
                    onClose();
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }
    return (
        <ModalProvider>
            <StyledModal isOpen={isOpen} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
                <Wrapper>
                    <Cover src={verifiedImg} />
                    <VerifiedIcon height="30px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                            fill={palette.hover_color}></path>
                    </VerifiedIcon>
                </Wrapper>
                <Body>
                    <Heading>Congratulate, you're verified!</Heading>
                    <Text type='normal' isCentered color='text'>Thanks for taking the time to verify your profile. Now people can be sure you're really you.</Text>
                    <Button onClick={() => onSave()}>Got It</Button>
                </Body>
            </StyledModal>
        </ModalProvider>
    )
}
const StyledModal = Modal.styled`
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap:0.8rem;
    padding:  ${({ openCameraModal }) => openCameraModal ? '0' : '2rem 3rem'};
    background-color: ${palette.black};
    border:1px solid ${palette.yellow_border};
`

const Body = styled.div`
    padding: 1.5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.4rem;
    width: 100%;
    background: ${palette.white};
`;

const Heading = styled.div`
    font-size: 24px;
    color: ${palette.yellow_border};
    text-align: center;
    font-weight: 500;
    line-height: 2rem;
`

const Wrapper = styled.div`
   position: relative;
`

const Cover = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: ${({ src }) => src ? `url(${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${src})` : '#eee'};
    background-position: 50%;
    background-size: cover;

`
const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

const VerifiedIcon = styled.svg`
	position: absolute;
    bottom: 0;
    right:0;
    background: white;
    border-radius: 100%;

`

export default VerificationCompleteModal
