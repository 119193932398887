export const palette = {
	white: '#ffffff',
	black: '#000000',
	gray: '#767676',
	dark_gray: '#282828',
	light_gray: '#DCDCDC',
	text: '#fafafa',
	gray_hover: '#fafafa',
	gray_100: '#f6f6f6',
	gray_200: '#fcfcfc',
	gray_300: '#c6c6c6',
	gray_400: '#b0b0b0',
	gray_500: '#eaeaea',
	silver: '#f1f1f1',
	yellow_theme: 'rgba(255,174,87,255)',
	yellow_theme_hover: 'rgba(255,174,87,255)',
	red: '#ec0a18',
	sea_green: '#35e2bd',
	blue: '#1E98F9',
	blue_hover: '#1d90ed',
	dark_sea_green: '#009abf',
	btn_bg: '#0d0c22',
	btn_hover: '#565564',
	btn_text: '#fff',
	default_theme: 'rgba(27,29,42,255)',
	active_list: 'rgba(221,128,73,255)',
	gold_background: 'rgba(255,211,91,255)',
	hover_color: 'rgba(255,174,87,255)',
	btn_gradient: 'linear-gradient(90deg, rgba(221,128,73,1) 0%, rgba(210,159,78,1) 100%, rgba(0,212,255,1) 100%)',
	btn_hover_gradient: 'linear-gradient(90deg, rgba(221,128,73,1) 0%, rgba(166,94,52,1) 100%, rgba(210,159,78,1) 100%)',
	brown_rgb: 'rgb(221,128,73)',
	home_nav_bg: 'rgba(230,239,255,255)',
	heart_color: '#fd3f3f',
	home_text_color: 'rgb(140 137 137)',
	left_circle_color: '#7657d0',
	right_circle_color: '#fb8bb3',
	yellow_border:'rgb(255, 174, 87)',
	yellow_gradient : 'linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%)',
	overlay:'rgb(0 0 0 / 81%)',
}
