import React from 'react'
import ShopCard from './ShopCard'
import styled from 'styled-components'

const ShopCardWraper = () => {
    return (
        <>
            <Wrapper>
                <ShopCard />
            </Wrapper>
        </>
    )
}



const Wrapper = styled.div`
    margin-top: 2rem;
`

export default ShopCardWraper