import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Text } from "../../styled/shared";
import { LuSearch } from "react-icons/lu";
import { useLocation, useNavigate } from 'react-router-dom'
import { CommonEnum } from '../../utils';
import { media } from 'styled-bootstrap-grid';


const VerificationProcessingModal = ({ onClose }) => {
    const { pathname } = useLocation()
    const _navigate = useNavigate()

    return (
        <>
            <StyledModal>
                <Wrapper>
                    <svg height="150px" data-origin="pipeline" viewBox="0 0 144 144" fill="none"><circle cx="72" cy="72" r="72" fill="#E6F4FF"></circle><mask id="csms__1694116706____a" maskUnits="userSpaceOnUse" x="0" y="0" width="144" height="144"><circle cx="72" cy="72" r="72" fill="#fff"></circle></mask><g mask="url(#csms__1694116706____a)"><rect x="8.702" y="55.63" width="83" height="128" rx="8.5" transform="rotate(-14 8.702 55.63)" fill="#fff" stroke="#4A4F55" stroke-width="3"></rect><rect x="26.589" y="32.74" width="83" height="128" rx="8.5" transform="rotate(-4 26.589 32.74)" fill="#fff" stroke="#4A4F55" stroke-width="3"></rect><rect x="33.526" y="38.77" width="70" height="84" rx="3" transform="rotate(-4 33.526 38.77)" fill="#1F98F9"></rect><mask id="csms__1694116706____b" maskUnits="userSpaceOnUse" x="33" y="34" width="77" height="89"><rect x="33.526" y="38.77" width="70" height="84" rx="3" transform="rotate(-4 33.526 38.77)" fill="#fff"></rect></mask><g mask="url(#csms__1694116706____b)"><path fill-rule="evenodd" clip-rule="evenodd" d="M80.75 84.535c7.201-3.81 11.86-11.482 11.266-19.97-.809-11.57-11.067-20.278-22.913-19.45-11.845.828-20.791 10.879-19.982 22.449.604 8.644 6.483 15.69 14.358 18.356-8.064 3.15-12.668 9.366-15.138 14.137-2.1 4.056-2.354 8.709-2.035 13.266l5.27 75.365 55.864-3.907-5.27-75.364c-.319-4.557-1.218-9.129-3.862-12.854-3.183-4.484-8.79-10.156-17.559-12.028z" fill="#fff" fill-opacity="0.15"></path></g><circle cx="72" cy="72" r="70.5" stroke="#4A4F55" stroke-width="3"></circle></g></svg>
                    <Search id="varification_processing_animation" />
                </Wrapper>
                <Body>
                    <Heading>Thank you for helping us keep {CommonEnum.AppName} safe!</Heading>
                    <Text type='normal' isCentered color='text'>Your submission is being processed. We'll let you know when it's finished.</Text>
                    <Button onClick={() => {
                        onClose(); if (pathname === '/verification') {
                            _navigate('/app')
                        }
                    }}>Got It</Button>
                </Body>
            </StyledModal>
        </>
    )
}

const StyledModal = styled.div`
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: ${palette.black};
    overflow: hidden;
    ${media.xs`
    width: 100%
    `}
    ${media.sm`
    width: 100%
    `}
    ${media.md`
    width: 600px;
    `}
    ${media.lg`
    width: 600px;
    `}
`

const Body = styled.div`
    padding: 1.5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.4rem;
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    margin-top:2rem;
`;

const Heading = styled.div`
    font-size: 24px;
    color: ${palette.yellow_border};
    text-align: center;
    font-weight: 500;
    line-height: 2rem;
`

const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

const Search = styled(LuSearch)`
	font-size: 90px;
    color: #4a4f55;
`

export default VerificationProcessingModal
