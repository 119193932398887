import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flexed } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { MdOutlineClose } from 'react-icons/md'
import 'react-image-lightbox/style.css'
import ImgsViewer from 'react-images-viewer'
import { BsPerson } from 'react-icons/bs'
import { UserLocation } from '../modals/PreviewProfileModal'
import { MdOutlineWatchLater, MdVerified } from "react-icons/md";
import { BiSolidCommentDetail } from "react-icons/bi";
import { IoEye } from "react-icons/io5";
import { IoMdHeart } from "react-icons/io";
import { FaGift } from "react-icons/fa";
import { FaUserCircle, FaFacebook } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { IoLogoLinkedin } from "react-icons/io5";
import moment from 'moment';
import { media } from 'styled-bootstrap-grid'

const ChatSideProfile = ({ open, close, user, currentUserMode, conversactionId }) => {
	const [lightBoxOpen, setLightBoxOpen] = useState(false)
	const [photoIndex, setPhotoIndex] = useState(0)
	const [memberSince, setMemberSince] = useState('')

	useEffect(() => {
		if (user?.createdAt) {
			setMemberSince(moment(user?.createdAt).format("MMM DD, YYYY"))
		}
	}, [user])

	let images = user?.images?.map(res => ({ src: process.env.REACT_APP_PUBLIC_IMAGE_URL + '/' + res, }))
	return (<>
		<Wrapper open={open}>
			<div style={{position:'relative'}}>
			<Action>
				<QuoteIcon cursor="pointer" onClick={() => { setLightBoxOpen(true); setPhotoIndex(0) }}>
					<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
							fill="#ffffff"></path>
					</svg>
				</QuoteIcon>
				<Close open={open} color="white" fontSize="1.9rem" cursor="pointer" onClick={close} />
			</Action>
			</div>
			{user?.images && user?.images.length && (
				<Profile src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[0]}`} onClick={() => { setLightBoxOpen(true); setPhotoIndex(0) }}>

					<PersonInfo>
						<Flexed gap={0.5} align="center">
							<Heading>{user?.first_name},</Heading>
							<Heading>{user?.age_group}</Heading>
							{user.is_verify_admin &&
								<Flexed align="center">
									<VerifiedIcon>
										<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
											<path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16z" fill="#1F98F9"></path>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M10.586 14.443a1.795 1.795 0 00-2.539-.106 1.802 1.802 0 00-.106 2.542l4.535 4.94c.698.76 1.89.777 2.61.036l8.938-9.2a1.801 1.801 0 00-.034-2.545 1.795 1.795 0 00-2.541.035l-7.614 7.837-3.25-3.539z"
												fill="#fff"></path>
										</svg>
									</VerifiedIcon>
								</Flexed>
							}
						</Flexed>
					</PersonInfo>
				</Profile>
			)
			}

			<Section>
				<Flexed gap={0.5}>
					<div>
						<QuoteIcon width="12px" height="12px">
							<svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
									fill="#282828"></path>
							</svg>
						</QuoteIcon>
					</div>
					<MiniHeading>{`about ${user?.first_name}`}</MiniHeading>
				</Flexed>
				<Flexed margin="1rem 0rem 0rem 0rem">{user?.mode === 'aff' ? <Text>{user?.bio_aff}</Text> : <Text>{user?.bio_date}</Text>}</Flexed>
				<Flexed margin="1rem 0rem 0.5rem 0rem" flexWrap="wrap" gap={0.5}>
					{(user?.mode == 'aff' && user?.height) ? (
						<Tags gap={0.2} align="center">
							<TagsIcon src="./height.png" alt="tagIcons" />
							<TagText>{user?.height}</TagText>
						</Tags>
					) : null}
					{user?.exercise && <Tags gap={0.2} align="center">
						<TagsIcon src="./exercise.png" alt="tagIcons" />
						<TagText>{user?.exercise}</TagText>
					</Tags>}
					{(user?.mode == 'date' && user?.education_level) ? (
						<Tags gap={0.2} align="center">
							<TagsIcon src="./education.png" alt="tagIcons" />
							<TagText>{user?.education_level}</TagText>
						</Tags>
					) : null}
					{user?.drinking && <Tags gap={0.2} align="center">
						<TagsIcon src="./drinking.png" alt="tagIcons" />
						<TagText>{user?.drinking}</TagText>
					</Tags>}
					{user?.gender && <Tags gap={0.2} align="center">
						<BsPerson />
						<TagText>{user?.gender}</TagText>
					</Tags>}
					{user?.relation_ship && <Tags gap={0.2} align="center">
						<TagsIcon src="./intentions.png" alt="tagIcons" />
						<TagText>{user?.relation_ship}</TagText>
					</Tags>}
					{user?.star_sign && <Tags gap={0.2} align="center">
						<TagsIcon src="./starSign.png" alt="tagIcons" />
						<TagText>{user?.star_sign}</TagText>
					</Tags>}
					{user?.religion && <Tags gap={0.2} align="center">
						<TagsIcon src="./religion.png" alt="tagIcons" />
						<TagText>{user?.religion}</TagText>
					</Tags>}
				</Flexed>
			</Section>
			{(user?.images && user?.images.length && (user?.images[1])) &&
				<ImgSection onClick={() => { setLightBoxOpen(true); setPhotoIndex(1) }}>
					<Profile src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[1]}`} alt='' />
				</ImgSection>
			}
			{(user?.images && user?.images.length && (user?.images[2])) &&
				<ImgSection onClick={() => { setLightBoxOpen(true); setPhotoIndex(2) }}>
					<Profile src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[2]}`} alt='' />
				</ImgSection>
			}
			{
				user?.profile_prompt
					?.filter((user) => user?.mode == currentUserMode)
					.map((info, index) => {
						return (
							<Section>
								<MiniHeading>{info.profile_prompt_data.profile_prompts}</MiniHeading>
								<Text fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
									{info.ans}
								</Text>
							</Section>
						)
					})
			}
			{(user?.images && user?.images.length && (user?.images[3])) &&
				<ImgSection onClick={() => { setLightBoxOpen(true); setPhotoIndex(3) }}>
					<Profile src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[3]}`} alt='' />
				</ImgSection>
			}
			{(user?.images && user?.images.length && (user?.images[4])) &&
				<ImgSection onClick={() => { setLightBoxOpen(true); setPhotoIndex(4) }}>
					<Profile src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[4]}`} alt='' />
				</ImgSection>
			}
			{(user?.images && user?.images.length && (user?.images[5])) &&
				<ImgSection onClick={() => { setLightBoxOpen(true); setPhotoIndex(5) }}>
					<Profile src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[5]}`} alt='' />
				</ImgSection>
			}

			<Section>
				<Flexed align='center' gap={0.5}>
					<svg height='12px' data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zM9.608 13.72c-.259 3.7 5.772 10.168 6.141 10.194.37.026 6.825-5.569 7.084-9.27.259-3.7-2.492-6.906-6.144-7.161-3.652-.256-6.822 2.537-7.08 6.237zM16 16a2.133 2.133 0 110-4.267A2.133 2.133 0 0116 16z" fill="currentColor"></path></svg>
					<Text>{user?.first_name}'s location</Text>
				</Flexed>
				<Text fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
					{user?.location}
					{user?.distance || user?.distance == 0 ? <UserLocation>~ {user?.distance?.toFixed(2)} km away</UserLocation> : ''}
				</Text>
			</Section>
			<Section>
				<Flexed align='center' gap={0.5} justify='space-between'>
					<Flexed align='center' gap={0.5}>
						<MdOutlineWatchLater />
						<Text>Member Since</Text>
					</Flexed>
					<Text>{memberSince}</Text>
				</Flexed>
			</Section>
			<Section>
				<Flexed align='center' gap={0.5} justify='space-between'>
					<Flexed align='center' gap={0.5}>
						<MdOutlineWatchLater />
						<Text>Last Active</Text>
					</Flexed>
					<Text>{user?.last_login ? moment(user?.last_login).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY')}</Text>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={1} justify='space-between'>
					<Flexed gap={0.5}>
						<svg height='12px' style={{ margin: '0.4rem 0rem 0rem 0rem' }} data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zM9.608 13.72c-.259 3.7 5.772 10.168 6.141 10.194.37.026 6.825-5.569 7.084-9.27.259-3.7-2.492-6.906-6.144-7.161-3.652-.256-6.822 2.537-7.08 6.237zM16 16a2.133 2.133 0 110-4.267A2.133 2.133 0 0116 16z" fill="currentColor"></path></svg>
						<Text>Recent</Text>
					</Flexed>
					<Text>{user?.location}</Text>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={0.5} justify='space-between'>
					<Flexed gap={0.5}>
						<VerifyMainIcon />
						<Text>Verifications</Text>
					</Flexed>
					<div>
						<Flexed align='center' gap={0.5}>
							<VerifyIcon active={user?.is_verified} />
							<VerifyUser active={user?.is_verified} />
							<VerifyImage active={user?.is_verify_admin} />
						</Flexed>
						<Flexed margin='0.5rem 0rem 0rem 0rem' align='center' gap={0.5}>
							<VerifyFaceBook active={user?.is_verify_fb} />
							<VerifyInsta active={user?.is_verify_instagram} />
							<VerifyLinkeIn active={user?.is_verify_linkedin} />
						</Flexed>
					</div>

				</Flexed>
			</Section>
			<Section>
				<GiftSection>
					<Flexed align='center' gap={0.5} justify='center'>
						<FaGift color='red' />
						<GiftText>Gift Wishlist</GiftText>
						<GiftCount>2</GiftCount>
					</Flexed>
				</GiftSection>

				{/* <NoteSection>
					<Flexed align='center' gap={0.5} justify='center'>
						<CustomText>User Notes</CustomText>
					</Flexed>
				</NoteSection> */}
			</Section>
			<Section>
				<Flexed align='center' gap={0.5} justify='space-between'>
					<CustomText>Recent Activity</CustomText>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={0.5}>
					<CommentIcon />
					<div>
						<Text>You message {user?.first_name}</Text>
						<Text fontSize='13px'>{user?.last_message ? moment(user?.last_message).fromNow() : 'No message yet'}</Text>
					</div>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={0.5}>
					<EyeIcon />
					<div>
						<Text>You last viewed {user?.gender == 'Man' ? 'him' : user?.gender == 'Woman' ? 'her' : 'him/her'} </Text>
						<Text fontSize='13px'>{user?.viewed_user ? moment(user?.viewed_user?.cteatedAt).fromNow() : 'Not yet'}</Text>
					</div>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={0.5}>
					<EyeIcon />
					<div>
						<Text>{user?.gender == 'Man' ? 'He' : user?.gender == 'She' ? 'her' : 'He/She'}  last viewed You</Text>
						<Text fontSize='13px'>{user?.viewed_from ? moment(user?.viewed_from?.cteatedAt).fromNow() : 'Not yet'} </Text>
					</div>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={0.5}>
					<HeartIcon />
					<div>
						<Text>You favorited {user?.gender == 'Man' ? 'him' : user?.gender == 'Woman' ? 'her' : 'him/her'}</Text>
						<Text fontSize='13px'>{conversactionId ? 'Yes' : 'Not yet'}</Text>
					</div>
				</Flexed>
			</Section>
			<Section>
				<Flexed gap={0.5}>
					<HeartIcon />
					<div>
						<Text>{user?.gender == 'Man' ? 'He' : user?.gender == 'She' ? 'her' : 'He/She'} favorited You</Text>
						<Text fontSize='13px'>{user?.is_liked ? 'Yes' : 'Not yet'}</Text>
					</div>
				</Flexed>
			</Section>
		</Wrapper>

		{lightBoxOpen && (
			<ImgsViewer
				imgs={images}
				currImg={photoIndex}
				isOpen={lightBoxOpen}
				onClickPrev={(e) => setPhotoIndex(photoIndex - 1)}
				onClickNext={(e) => setPhotoIndex(photoIndex + 1)}
				onClose={() => setLightBoxOpen(false)}
				showThumbnails={true}
				onClickThumbnail={(e) => setPhotoIndex(e)}
				showImgCount={false}
			// backdropCloseable={true}
			/>
		)}
	</>
	)
}

const Wrapper = styled.div`
	width: ${({ open }) => (open ? '302px' : '0px')};
	height: 100vh;
	overflow: auto;
	transition: all 0.3s;
	position: absolute;
	right: 0rem;
	margin-top: 4.6rem;
	background: ${palette.black};
	z-index:3;
	${media.lg`
		position: relative;
	`}
`

const Profile = styled.div`
	background: ${({ src }) => (src ? `url(${src})` : palette.yellow_border)};
	width: 100%;
	height: 434px;
	background-size: cover;
	position: relative;
	background-position: center;
`

const Action = styled.div`
	background-image: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.4) 0,
		rgba(0, 0, 0, 0.2952) 19%,
		rgba(0, 0, 0, 0.2164) 34%,
		rgba(0, 0, 0, 0.1528) 47%,
		rgba(0, 0, 0, 0.1112) 56.5%,
		rgba(0, 0, 0, 0.0776) 65%,
		rgba(0, 0, 0, 0.0504) 73%,
		rgba(0, 0, 0, 0.03) 80.2%,
		rgba(0, 0, 0, 0.0168) 86.1%,
		rgba(0, 0, 0, 0.0084) 91%,
		rgba(0, 0, 0, 0.0032) 95.2%,
		rgba(0, 0, 0, 0.0008) 98.2%,
		transparent
	);
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	left: 0;
	padding: 20px;
	display: flex;
`

const QuoteIcon = styled.div`
	width: ${({ width }) => (width ? width : '16px')};
	height: ${({ height }) => (height ? height : '16px')};
	cursor: ${({ cursor }) => cursor};
	position: relative;
	z-index: 2;
`

const PersonInfo = styled.div`
	background-image: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.4) 0,
		rgba(0, 0, 0, 0.2952) 19%,
		rgba(0, 0, 0, 0.2164) 34%,
		rgba(0, 0, 0, 0.1528) 47%,
		rgba(0, 0, 0, 0.1112) 56.5%,
		rgba(0, 0, 0, 0.0776) 65%,
		rgba(0, 0, 0, 0.0504) 73%,
		rgba(0, 0, 0, 0.03) 80.2%,
		rgba(0, 0, 0, 0.0168) 86.1%,
		rgba(0, 0, 0, 0.0084) 91%,
		rgba(0, 0, 0, 0.0032) 95.2%,
		rgba(0, 0, 0, 0.0008) 98.2%,
		transparent
	);
	position: absolute;
	z-index: 1;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 50px 20px 20px;
	word-wrap: break-word;
`

const Heading = styled.div`
	letter-spacing: -0.02em;
	font-size: 25px;
	font-weight: 500;
	color: ${palette.white};
`

const VerifiedIcon = styled.div`
	width: 16px;
	height: 16px;
	margin-left: 0.2rem;
`

const Section = styled.div`
	background: ${palette.yellow_border};
	padding: 1rem;
	&:not(last-child) {
		margin-bottom: 1px;
	}
`

const ImgSection = styled(Section)`
	padding: 0rem;
`

const GiftSection = styled(Section)`
	border : 2px solid red;
	cursor:pointer;
`

const NoteSection = styled(Section)`
	border : 2px solid gray;
	cursor:pointer;
	margin-top: 1rem;
`

const Tags = styled(Flexed)`
	color: white;
	background: ${palette.btn_gradient};
	height: 28px;
	padding: 0 12px 0 7px;
	border-radius: 14px;
`

const TagsIcon = styled.img`
	width: 20px !important;
	height: 20px;
	color: white;
	filter:  grayscale(0%) brightness(500%);
`

const TagText = styled.div`
	font-size: 12px;
	line-height: 1.334;
`

const MiniHeading = styled.div`
	font-size: 14px;
	color: #454650;
	line-height: 1.3;
`

const Text = styled.div`
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
	color: #454650;
	line-height: 1.5;
	margin: ${({ margin }) => margin};
`

const GiftText = styled(Text)`
	font-weight: 700;
	color: red;
`

const GiftCount = styled.div`
	font-size: 13px;
	color: ${palette.white};
	background: red;
	padding: 1.2px 3px;
	border-radius:0.3rem;
`

const CustomText = styled(Text)`
	font-weight: 700;
`

const VerifyMainIcon = styled(MdVerified)`
	margin-top: 0.3rem;
`

const VerifyIcon = styled(MdVerified)`
	margin-top: 0.3rem;
	opacity: ${({ active }) => (active ? '1' : '0.3')};
`

const VerifyUser = styled(FaUserCircle)`
	opacity: ${({ active }) => (active ? '1' : '0.3')};
`

const VerifyImage = styled(FaRegImage)`
	opacity: ${({ active }) => (active ? '1' : '0.3')};
`

const VerifyFaceBook = styled(FaFacebook)`
	opacity: ${({ active }) => (active ? '1' : '0.3')};
`

const VerifyInsta = styled(PiInstagramLogoFill)`
	opacity: ${({ active }) => (active ? '1' : '0.3')};
`

const VerifyLinkeIn = styled(IoLogoLinkedin)`
	opacity: ${({ active }) => (active ? '1' : '0.3')};
`

const CommentIcon = styled(BiSolidCommentDetail)`
	margin-top: 0.3rem;
`

const EyeIcon = styled(IoEye)`
	margin-top: 0.3rem;
`

const HeartIcon = styled(IoMdHeart)`
	margin-top: 0.3rem;
`

const Close = styled(MdOutlineClose)`
	position: absolute;
	top: 14px;
	right: 1rem;
	cursor: pointer;
	display: ${({ open }) => (open ? 'block' : 'none')};
	transition: all 1s;
`

export default ChatSideProfile
