import { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import styled from "styled-components";
import { Flexed } from "../../styled/shared";
import { IoClose } from "react-icons/io5";
import { palette } from "../../styled/colors";


const HeightSliderAdvanceFilter = ({ valueFrom, valueTo, setValueFrom, setValueTo, saveFilter, onClose }) => {
    const [value, setValue] = useState([100, 220]);

    useEffect(() => {
        if (valueFrom && valueTo) {
            setValue([valueFrom, valueTo])
        }
    }, [valueFrom, valueTo])

    const setRangeSlider = (e) => {
        setValue(e)
        setValueFrom(e[0])
        setValueTo(e[1])
    }

    return (
        <>
            <Flexed margin='0rem 0rem 0.6rem 0rem' align='center' gap={1} justify='space-between'>
                <Flexed align='center' gap={0.5}>
                    <img height="25px" src='/icons/height.png' alt='' />
                    {(value[0] == 100 && value[1] == 220) ? <TtleDiv>Any height is fine</TtleDiv> : (value[0] != 100 && value[1] == 220) ? <TtleDiv>Over {value[0]} cm</TtleDiv> : (value[0] == 100 && value[1] != 220) ? <TtleDiv>Under {value[1]} cm</TtleDiv> : <TtleDiv>Between {value[0]} cm and {value[1]} cm</TtleDiv>}
                </Flexed>
                <Close onClick={onClose} />
            </Flexed>
            <RangeSlider id="range-slider-yellow" min={100} max={220} value={value} onInput={setRangeSlider} onThumbDragEnd={() => { saveFilter() }} />
        </>
    );
}

const TtleDiv = styled.div`
`

const Close = styled(IoClose)`
    color: ${palette.text};
    font-size: 30px;
    position: absolute;
    right:20px;
    cursor:pointer;
    &:hover {
		color: ${palette.yellow_border};
	}
`

export default HeightSliderAdvanceFilter