import React, { useEffect, useState, useRef } from 'react'
import { useCollapse } from 'react-collapsed'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { addProfilePrompt, getUser, listOfProfilePrompt } from '../../../apis/apis'
import { palette } from '../../../styled/colors'
import { Flexed, Text } from '../../../styled/shared'
import InputProfilePrompt from './InputProfilePrompt'
import { IoIosArrowForward } from "react-icons/io";
import AddProfilePrompt from './AddProfilePrompt'

const ProfilePrompt = () => {
	const _ref = useRef(null)
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [mode, setMode] = useState('')
	const [user, setUser] = useState([])
	const [prompt, setPrompt] = useState('')
	const [showInput, setShowInput] = useState(false)
	const [promptList, setPromptList] = useState([])
	const [promptAns, setPromptAns] = useState('')
	const [promptId, setPromptId] = useState('')
	const [isShow, setIsShow] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const getUserProfile = async () => {
		const response = await getUser()
		setUser(response?.data?.data?.user)
		setMode(response?.data?.data?.user.mode)
	}

	useEffect(() => {
		getUserProfile()
	}, [])

	const profilePromptListData = async () => {
		let response = await listOfProfilePrompt()

		const filteredData = response?.data.filter((item) => item.mode == mode)

		setPromptList(filteredData)
	}
	useEffect(() => {
		profilePromptListData()
	}, [mode])

	const profilePrompt = (question) => {

		setPrompt(question.profile_prompts)
		setPromptId(question.id)
		setShowInput(true)
	}

	const saveProfilePromptHandler = async () => {
		setIsLoading(true)
		await addProfilePrompt(promptId, mode, promptAns)
		await getUserProfile()
		setShowInput(false)
		setPrompt('');
		setPromptAns('');
		setIsLoading(false)


	}

	let profile_prompt_array_length = user?.profile_prompt?.filter((user) => user.mode == mode)
	let length_prompt = profile_prompt_array_length?.length

	useEffect(() => {
		function handleClickOutside(event) {
			if (_ref.current && !_ref.current.contains(event.target)) {
				setIsShow(false)
			}
		}

		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<>
			<Box isExpanded={isExpanded} {...getToggleProps({
				onClick: () => { !isExpanded && setExpanded(!isExpanded) },
			})}>
				<BoxHeader justify='space-between' align='center' {...getToggleProps({
					onClick: () => setExpanded((prevExpanded) => !prevExpanded),
				})}>
					<div>
						<Heading>My Profile Prompts</Heading>
						<div style={{ margin: '0.4rem 0rem 0rem 0rem', fontSize: '0.8rem' }} >
							{length_prompt === 0 ?
								'You can add up to three Profile Prompts'
								:
								length_prompt === 2 ? 'One more to go!' : length_prompt === 3 ? 'Great job, your matches are bound to be inspired!' : ''}
						</div>
					</div>
					<RightArrow active={isExpanded} />
				</BoxHeader>

				<InnerContent  {...getCollapseProps()}>
					{isLoading && <Loading ><div class="dots-loading"></div></Loading>}
					<Connent isLoading={isLoading}>
						{(length_prompt !== 0 && length_prompt < 3) &&
							<InfoPrompt>
								You can add {length_prompt === 0 ? 'three' : length_prompt === 1 ? 'two' : 'one'} more
							</InfoPrompt>
						}
						<div>
							<Ul>
								{user?.profile_prompt
									?.filter((user) => user.mode == mode)
									.map((info, index) => {
										return (
											<InputProfilePrompt promptList={promptList} info={info} setIsLoading={setIsLoading} />
										)
									})}
							</Ul>
						</div>
						{showInput ? (
							<AddProfilePrompt prompt={prompt} setPrompt={setPrompt} setShowInput={setShowInput} promptAns={promptAns} setPromptAns={setPromptAns} saveProfilePromptHandler={saveProfilePromptHandler} />
						)
							:
							<>
								{length_prompt < 3 && (
									<Choose ref={_ref} onClick={() => { setIsShow(!isShow) }}>

										<>
											<Flexed justify='space-between' align='center' >
												<Text type='normal' color='text'>Add your {length_prompt === 0 ? 'first' : length_prompt === 1 ? 'second' : 'third'} Profile Prompt</Text>
												<svg style={{ color: 'white', cursor: 'pointer' }} height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
													<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
												</svg>
											</Flexed>
											<Placeholder width={96} margin='5px 0px 5px 0px' />
											<Placeholder width={80} margin='5px 0px 0px 0px' />
										</>

										{isShow && (
											<List>
												<Ul>
													{promptList.map((info, index) => {
														return <Li onClick={() => profilePrompt(info)}>{info.profile_prompts}</Li>
													})}
												</Ul>
											</List>
										)}
									</Choose>
								)}
							</>
						}
					</Connent>
				</InnerContent>
			</Box>
		</>
	)
}
const RightArrow = styled(IoIosArrowForward)`
    font-size: 22px;
	transform: ${({ active }) => active ? 'rotate(-90deg)' : 'rotate(90deg)'};
	transition: all 0.3s ease;
`;

const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	border: 0.5px solid  ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color:  ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	&:hover {
		border: 0.5px solid  ${palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
`

const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	};
	/* &:hover{
		color: ${({ isExpanded }) => palette.black};
	} */
`

const Button = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: white;
	background: ${palette.yellow_theme};
	cursor: pointer;
	border: none;
	font-size: 16px;
`

const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
	/* color: ${palette.white}; */

`

const Choose = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	cursor: pointer;
	padding: 0.8rem 1rem;
	margin-top: 1rem;
	border: 1px solid ${palette.yellow_border};
	min-height: 56px;
	background: ${palette.black};
	&:hover{
        background: ${palette.yellow_gradient};
        border: 1px solid ${palette.default_theme};
    }
`
const Placeholder = styled.div`
	width: ${({ width }) => width}%;
	height: 6px;
	border-radius: 3px;
	background: ${palette.yellow_border};
	margin: ${({ margin }) => margin};
`

const List = styled.div`
	position: absolute;
	top: 2rem;
	right: 0;
	z-index: 1000;
	margin: auto;
	width: 90%;
	height: auto;
	background-color: ${palette.black};
	padding: 0.5rem 0rem;
	border-radius: 16px;
	margin-top: 1rem;
	border: 1px solid ${palette.yellow_border};
	max-height: 176px;
	overflow: auto;
`

const Ul = styled.ul`
`

const Li = styled.li`
	padding: 0.5rem 1rem;
	font-size: 14px;
	cursor: pointer;
	&:hover{
		background:  ${palette.yellow_gradient};
	}
`

const InfoPrompt = styled.div`
	padding: 0.5rem 1rem;
	height: 36px;
	text-align: center;
	font-size: 14px;
	color: ${palette.white};
	background:  ${palette.hover_color};
	border-radius: 20px;
	margin-top: 1rem;
`

const Loading = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
`

const InnerContent = styled.section`
	position:relative;
`;

const Connent = styled.section`
	filter: ${({ isLoading }) => isLoading ? 'blur(1px)' : ''} ;
`;

export default ProfilePrompt
