import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { updateProfilePrompt } from '../../../apis/apis'
import { palette } from '../../../styled/colors'
import { Flexed, Text } from '../../../styled/shared'

const InputProfilePrompt = ({ info, setIsLoading, promptList }) => {
	const _ref = useRef(null)
	const textAreaRef = useRef(null);
	const maxCharacters = 160;

	const [prompt, setPrompt] = useState(info.ans);
	const [inputLength, setInputLength] = useState(0);
	const [isEdit, setIsEdit] = useState(false);
	const [isShow, setIsShow] = useState(false)

	const [newPromptId, setNewPromptId] = useState('');
	const [newPromptQuestion, setNewPromptQuestion] = useState('');
	const [newPromptAns, setNewPromptAns] = useState('');



	const updatePrompt = async () => {
		setIsLoading(true)
		if (newPromptQuestion) {
			await updateProfilePrompt(newPromptAns, info.id, newPromptId)
		}
		else {
			await updateProfilePrompt(prompt, info.id, '')
		}
		setIsEdit(false);
		setIsLoading(false)
	}

	const newProfilePrompt = async (prompt) => {
		setNewPromptId(prompt?.id);
		setNewPromptQuestion(prompt?.profile_prompts)
		setIsEdit(true)
		setIsShow(false)
	}


	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current.style.height = 'auto';
			textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
		}
	}, [prompt, newPromptAns]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (_ref.current && !_ref.current.contains(event.target)) {
				setIsShow(false)
			}
		}

		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (isEdit && newPromptQuestion) {
			if (textAreaRef.current) {
				textAreaRef.current.focus();
			}
		}
	}, [isEdit])

	const onCancel = () => {
		setIsEdit(false);
		setPrompt(info.ans);
		setNewPromptId('');
		setNewPromptQuestion('');
		setNewPromptAns('')
	}

	return (
		<Main ref={_ref}>
			{!isEdit && <EditIcon onClick={() => { setIsShow(!isShow) }} data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.933 22.4v.933h.934l10-10-.934-.933-10 10zM5.6 20L18 7.6a1.333 1.333 0 011.867 0l4.666 4.8a1.289 1.289 0 010 1.867L12.267 26.533a2.667 2.667 0 01-1.867.8H6.267A1.333 1.333 0 014.933 26v-4.133c0-.667.267-1.334.667-1.867zM22.267 3.467c.666-.8 2-.8 2.666 0L28.8 7.2A2 2 0 1126 10l-3.733-3.733c-.934-.934-.934-2.267 0-2.8z" fill="currentColor"></path></EditIcon>}
			{isShow && (
				<List>
					<Ul>
						{promptList.map((info, index) => {
							return <Li onClick={() => newProfilePrompt(info)}>{info.profile_prompts}</Li>
						})}
					</Ul>
				</List>
			)}
			<Wrapper check={!isEdit} onClick={() => { !isEdit && setIsEdit(true) }}>
				<Header justify='space-between' align='center'>
					<Text margin='0rem 0rem 0.5rem 0rem' color='hover_color'>{newPromptQuestion ? newPromptQuestion : info?.profile_prompt_data?.profile_prompts}</Text>
				</Header>
				{!isEdit ? <SaveText type='normal' color='text'>{newPromptAns ? newPromptAns : prompt}</SaveText>
					:
					<Input

						ref={textAreaRef}
						maxlength="160"
						type="text"
						value={newPromptQuestion ? newPromptAns : prompt}
						onChange={(e) => {
							if (e.target.value.length <= maxCharacters) {
								newPromptQuestion ? setNewPromptAns(e.target.value) :
									setPrompt(e.target.value);
								setInputLength(e.target.value.length);
							}



						}}
					/>
				}
			</Wrapper>
			{isEdit && <Flexed margin='1rem 0rem 0rem 0rem' align='center' justify='space-between'>
				<CancelButton onClick={onCancel}>Cancel</CancelButton>
				{inputLength > 110 && <Text color='gray' type='normal' >{(160 - inputLength !== 0) ? 160 - inputLength + ' ' + 'characters left' : "That's it!"}</Text>}
				{/* characters left */}
				<Button disabled={prompt?.length < 3} onClick={updatePrompt}>Save</Button>
			</Flexed>
			}
		</Main>
	)
}

const Button = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: white;
	background: transparent;

    &:hover{
        background: ${palette.btn_hover_gradient}
    }
	border: none;
	font-size: 16px;
	opacity: ${({ disabled }) => disabled ? '0.5' : '1'};
    cursor: ${({ disabled }) => disabled ? 'no-drop' : 'pointer'};
`

const CancelButton = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: ${palette.text};
    background: transparent;
	cursor: pointer;
	border: none;
	font-size: 16px;
    &:hover{
        background: #00000003;
    }
`

const EditIcon = styled.svg`
	color: lightgray;
	height: 24px;
	position: absolute;
	top:1rem;
	right:1rem;
	z-index: 1;
	cursor:pointer;

`

const Main = styled.div`
	position: relative;
	&:hover ${EditIcon}{
		color: ${palette.gray};
	}
`

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	padding: 0.8rem 1rem;
	margin-top: 1rem;
	// border: 1px solid ${({ check }) => check ? palette.light_gray : palette.hover_color};
	border: 1px solid ${({ check }) => check ? palette.yellow_border : palette.yellow_border};
	min-height: 56px;
	background: ${palette.black};
	&:hover{
        // background: ${palette.gray_200};
		// border: 1px solid ${({ check }) => check ? '#c6c6c6' : palette.hover_color};
    }
	cursor: ${({ check }) => check ? 'pointer' : ''};

`

const Header = styled(Flexed)`
	position: relative;
`;

const Input = styled.textarea`
	width: 100%;
	border: none !important;
	outline: none !important;
	font-size: 1rem;
	background: transparent;
	resize: none;
	overflow: hidden;
  	min-height: 50px;
  	max-height: 100%;
	color: ${palette.white};
`

const SaveText = styled(Text)`
  	min-height: 50px;
`

const List = styled.div`
	position: absolute;
	top: 2rem;
	right: 1rem;
	z-index: 1000;
	margin: auto;
	width: 90%;
	height: auto;
	background-color: ${palette.black};
	padding: 0.5rem 0rem;
	border-radius: 16px;
	margin-top: 1rem;
	border: 1px solid ${palette.yellow_border};
	max-height: 176px;
	overflow: auto;
`

const Ul = styled.ul`
`

const Li = styled.li`
	padding: 0.5rem 1rem;
	font-size: 14px;
	cursor: pointer;
	&:hover{
		background:  ${palette.yellow_gradient};
	}
`



export default InputProfilePrompt
