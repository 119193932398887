import { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import styled from "styled-components";

const HeightSlider = ({ valueFrom, setValueFrom, }) => {
    const [value, setValue] = useState([90, valueFrom]);

    useEffect(() => {
        if (valueFrom) {
            setValue([90, valueFrom])
        }
    }, [valueFrom])

    const setRangeSlider = (e) => {
        setValue(e)
        setValueFrom(e[1])
    }

    return (
        <>
            <TtleDiv>{value[1] == 90 ? 'I prefer not to say' : value[1] == 220 ? '> 220' : value[1] == 91 ? '< 91' : value[1]} {value[1] != 90 && 'cm'}</TtleDiv>
            <RangeSlider className="single-thumb" id="range-slider-yellow" min={90} max={220} thumbsDisabled={[true, false]} value={value} onInput={setRangeSlider} rangeSlideDisabled={true} />
        </>
    );
}

const TtleDiv = styled.div`
margin-bottom:0.6rem;
`
export default HeightSlider