import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { CustomSwitch, Text } from '../../styled/shared'
import { media } from 'styled-bootstrap-grid'

const Switch = ({ text, para, value, handleChange, isProfile }) => {
	return (
		<>
			<Choose checked={value} isProfile={isProfile}>
				<CustomText type='normal' color='text'>{text}</CustomText>
				<div className="container">
					<CustomSwitch checked={value}>
						<input type="checkbox" checked={value} onChange={(e) => {
							handleChange && handleChange(e)
						}} />
						<div></div>
					</CustomSwitch>
				</div>
			</Choose>
			<Info>{para}</Info>
		</>
	)
}

const CustomText = styled(Text)`
${media.xs`
font-size:0.7rem
`}
${media.sm`
font-size:0.7rem
`}
${media.md`
font-size:1rem
`}
${media.lg`
font-size:1rem
`}
`
const Choose = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1.2rem;
	margin-top:  ${({ isProfile }) => isProfile ? '' : '0.5rem'};
	cursor: pointer;
	border: 1px solid ${({ checked }) => checked ? palette.yellow_border : palette.yellow_border};
	height: 40px;
	color: ${palette.dark_gray};
	font-size: 1rem;
	font-weight: 500;
	background: ${({ checked, isProfile }) => isProfile ? palette.black : checked ? palette.yellow_gradient : palette.black};
	&:hover{
		background: ${palette.yellow_gradient};
		border: 1px solid ${({ checked }) => checked ? palette.yellow_border : palette.yellow_border};
	}
`

const Info = styled.p`
	font-size: 12px;
	margin-left: 0.5rem;
	margin-top: 0.7rem;
	color: ${palette.black};
`

export default Switch
