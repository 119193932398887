import React, {useState, useEffect, useRef} from 'react'
import {Routes, Route, useLocation} from 'react-router-dom'
import Home from './pages/Home.js'
import Join from './pages/Join.js'
import Shop from './pages/Shop.js'
import UplodePhoto from './pages/UplodePhoto.js'
import ConfirmPhone from './pages/ConfirmPhone.js'
import EnterName from './pages/EnterName.js'
import DateOfBirth from './pages/DateOfBirth.js'
import Gender from './pages/Gender.js'
import Location from './pages/Location.js'
import Profile from './components/Profile/Profile.js'
import EditProfile from './pages/EditProfile.js'
import Settings from './components/Settings/Settings.js'
import Faq from './components/FAQ/Faq.js'
import MainSidebaar from './components/Sidebaar/MainSidebaar.js'
import styled from 'styled-components'
import '@weavy/uikit-react/dist/css/weavy.css'
import WeavyChat from './pages/WeavyChat.js'
import Matches from './pages/Matches.js'
import {getUser} from './apis/apis'
import UnlikeLikst from './pages/UnlikeList.js'
import ExpireProfile from './pages/ExpireProfile.js'
import Verification from './pages/Verification.js'
import ChooseMode from './pages/ChooseMode.js'
import Connections from './pages/Connections.js'
import useRouter from './components/Common/UseRouterHook.js'
import ContactUs from './components/Contactus.js'
import NavBar from './components/Header/Navbar.js'
import Features from './components/Features.js'
import {useDispatch} from 'react-redux'
import {setUserDetails} from './redux/actions/authActions.js'
import {toastError} from './styled/toastStyle.js'
import AdBanner from './components/Common/adBanner/index.js'

const App = () => {
	const {pathname} = useLocation()
	const authToken = sessionStorage.getItem('authorization')
	const router = useRouter()
	const _dispatch = useDispatch()

	const getUserProfile = async () => {
		const response = await getUser()
		if (response?.data?.data) {
			_dispatch(setUserDetails(response.data.data.user))
		} else {
			if (response?.data?.message) {
				sessionStorage.removeItem('authorization')
				_dispatch(setUserDetails())
				toastError(response?.data?.message)
				router.navigate('/')
			} else {
				sessionStorage.removeItem('authorization')
				_dispatch(setUserDetails())
				router.navigate('/')
				toastError('Auto login failed')
			}
		}
	}

	useEffect(() => {
		if (authToken) {
			getUserProfile()
		}
	}, [authToken])
	const _token = sessionStorage.getItem('authorization')

	useEffect(() => {
		if (_token == undefined && (pathname == '/app' || pathname == '/edit-profile' || pathname == '/faq' || pathname == '/settings' || pathname == '/connections' || pathname == '/chat' || pathname == '/matches' || pathname == '/unlike_user_list' || pathname == '/expire_user_list')) {
			router.navigate('/')
		}
	}, [_token])

	return (
		<>
			<Flexbox id="/" active={pathname === '/app' || pathname === '/edit-profile' || pathname === '/faq' || pathname === '/settings' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/unlike_user_list' || pathname === '/expire_user_list'}>
				<NavBar />
				{_token != undefined && (pathname === '/app' || pathname === '/edit-profile' || pathname === '/faq' || pathname === '/settings' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/unlike_user_list' || pathname === '/expire_user_list') && <MainSidebaar />}
				<Routes>
					<Route path="/" element={<Features />} />
					{_token && (pathname === '/app' || pathname === '/edit-profile' || pathname === '/faq' || pathname === '/settings' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/unlike_user_list' || pathname === '/expire_user_list') ? (
						<>
							<Route path="/app" element={<Profile />} />
							<Route path="/edit-profile" element={<EditProfile />} />
							<Route path="/settings" element={<Settings />} />
							<Route path="/faq" element={<Faq />} />
							<Route path="/connections" element={<Connections />} />
							<Route path="/chat" element={<WeavyChat />} />
							<Route path="/matches" element={<Matches />} />
							<Route path="/unlike_user_list" element={<UnlikeLikst />} />
							<Route path="/expire_user_list" element={<ExpireProfile />} />
						</>
					) : (
						''
					)}
					<Route path="/confirm-phone" element={<ConfirmPhone />} />
					<Route path="/app" element={<Profile />} />
					<Route path="/enter-name" element={<EnterName />} />
					<Route path="/enter-dob" element={<DateOfBirth />} />
					<Route path="/gender" element={<Gender />} />
					<Route path="/location" element={<Location />} />
					<Route path="/chooseMode" element={<ChooseMode />} />
					<Route path="/verification" element={<Verification />} />
					<Route path="/shop" element={<Shop />} />
					<Route path="/upload-photo" element={<UplodePhoto />} />
					<Route path="/get-started" element={<Join />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/demo" element={<Home />} />
				</Routes>
			</Flexbox>
			<AdBanner />
		</>
	)
}

const Flexbox = styled.div`
	display: ${({active}) => (active ? 'flex' : '')};
`
export default App
