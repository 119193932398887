import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { MdArrowBackIos } from 'react-icons/md'
import AppContent from './AppContent'
import { getUser } from '../../apis/apis'
import { useDispatch, useSelector } from 'react-redux'
import { setUserDetails } from '../../redux/actions/authActions'
import { CommonEnum } from '../../utils'
import { media } from 'styled-bootstrap-grid'
import { RiArrowRightDoubleLine } from "react-icons/ri";

const MainSidebaar = () => {
	const _dispatch = useDispatch()
	const _loginUser = useSelector((state) => state.auth.userDetails)
	const { pathname } = useLocation()
	const [user, setUser] = useState('')
	const [openSideBar, setOpenSideBar] = useState(false)
	const _navigate = useNavigate()

	const getUserinfo = async () => {
		let user = await getUser()
		setUser(user?.data?.data?.user)
		_dispatch(setUserDetails(user?.data?.data?.user))

	}

	useEffect(() => {
		if (_loginUser) {
			setUser(_loginUser)
		} else {
			getUserinfo()
		}
	}, [_loginUser])

	const logout = () => {
		sessionStorage.removeItem('authorization')
		_dispatch(setUserDetails())
		_navigate('/')
	}

	return (
		<>
			<SidebarWraper open={openSideBar}>
				<Open onClick={()=>{setOpenSideBar(!openSideBar)}}>
					<Arrows open={openSideBar} color='black'/>
					</Open>
				<SideBaar pathname={pathname === '/chat'} open={openSideBar}>
					<Link to={pathname === '/app' ? '/edit-profile' : '/app'}>
						<TopProfile>
							<GoBack hide={pathname === '/app' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/expire_user_list'} />
							<Image pathname={pathname === '/app' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/expire_user_list'}>
								<img style={{ borderRadius: '50%' }} src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images ? user?.images[0] : ''}`} alt="" width="100%" height="100%" />
							</Image>
							<UserName hide={pathname === '/app' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/expire_user_list'}>{user?.first_name}</UserName>
						</TopProfile>
					</Link>
					<SidebarContent>
						{(pathname === '/app' || pathname === '/connections' || pathname === '/chat' || pathname === '/matches' || pathname === '/expire_user_list') && <AppContent />}
						{pathname !== '/app' && pathname !== '/connections' && pathname !== '/chat' && pathname !== '/matches' && pathname !== '/expire_user_list' && (
							<Ul>
								<Link>
									<Li >
										<div>
											<svg height="2rem" style={{ color: '#ffc629' }} data-origin="pipeline" viewBox="0 0 32 32" fill="none">
												<circle cx="16" cy="16" r="16" fill="currentColor"></circle>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M22.748 15.858c.139.042.14.247.001.29-1.12.343-3.838 1.24-4.646 2.08-.772.802-1.624 3.421-1.957 4.52-.042.14-.248.139-.29 0-.328-1.09-1.16-3.665-1.905-4.465-.81-.87-3.573-1.788-4.7-2.136-.14-.042-.138-.247 0-.289 1.105-.337 3.77-1.208 4.575-2.008.812-.806 1.691-3.489 2.03-4.599.043-.138.247-.139.29 0 .342 1.117 1.236 3.83 2.07 4.639.803.778 3.434 1.634 4.532 1.968z"
													fill="#fff"></path>
											</svg>
										</div>
										<Info>Activate Spotlight</Info>
									</Li>
								</Link>
								<Link>
									<Li >
										<div>
											<svg height="2rem" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
												<circle cx="16" cy="16" r="16" fill="#35E2BD"></circle>
												<path
													d="M17.022 6.86L10.17 16.13a.973.973 0 00-.093 1.023.98.98 0 00.877.54h3.583a.475.475 0 01.457.603l-1.708 5.98a.975.975 0 00.497 1.155.983.983 0 001.228-.29l6.81-8.837a.973.973 0 00.112-1.02.98.98 0 00-.866-.556l-3.273-.082a.48.48 0 01-.453-.586l1.43-6.417a.976.976 0 00-.559-1.09.983.983 0 00-1.189.308z"
													fill="#fff"></path>
											</svg>
										</div>
										<Info>Upgrade to {CommonEnum.AppName} Boost</Info>
									</Li>
								</Link>
								<Link>
									<Li>
										<div>
											<svg height="2rem" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
												<circle cx="16" cy="16" r="16" fill="#282828"></circle>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M17.022 6.86a.983.983 0 011.19-.308.976.976 0 01.57 1.022l-.013.068-1.43 6.417a.475.475 0 00.4.581l.054.005 3.273.082a.981.981 0 01.866.555.973.973 0 01-.068.961l-.043.06-6.81 8.837a.983.983 0 01-1.229.29.975.975 0 01-.515-1.09l.018-.066 1.708-5.979a.472.472 0 00-.402-.6l-.055-.003h-3.583a.98.98 0 01-.877-.54.973.973 0 01.05-.963l.043-.06 6.853-9.269zM9.044 19.151l.045.003a.318.318 0 01.257.205l.012.043.117.54c.174.788.78 1.408 1.56 1.61l.091.02.544.117.006-.01a.318.318 0 01.044.595l-.044.016-.544.116a2.15 2.15 0 00-1.629 1.54l-.022.09-.117.54a.32.32 0 01-.613.043l-.013-.042-.116-.54a2.152 2.152 0 00-1.551-1.609l-.09-.022-.544-.116a.318.318 0 01-.044-.595l.044-.017.544-.116a2.15 2.15 0 001.619-1.54l.022-.09.11-.53A.313.313 0 019 19.154l.045-.003zM22.843 6.914l-.045-.002-.045.002a.313.313 0 00-.267.248l-.12.54-.022.09a2.15 2.15 0 01-1.62 1.551l-.544.116-.042.013a.318.318 0 00.042.609l.544.116.09.022a2.152 2.152 0 011.551 1.609l.117.54.016.044a.32.32 0 00.6-.044l.116-.54.022-.09a2.15 2.15 0 011.62-1.54l.543-.117.043-.012a.318.318 0 00-.043-.61h.014l-.543-.116-.091-.022a2.142 2.142 0 01-1.55-1.619l-.117-.54-.012-.043a.318.318 0 00-.257-.205z"
													fill="#fff"></path>
											</svg>
										</div>
										<Info>Upgrade to {CommonEnum.AppName} Premium</Info>
									</Li>
								</Link>

								<Link to='/edit-profile' >
									<List active={pathname === '/edit-profile'}>Edit Profile</List>
								</Link>
								<Link to='/settings'>
									<List active={pathname === '/settings'}>Settings</List>
								</Link>
								{/* <Link to='/faq' >
									<List active={pathname === '/faq'}>Contact and FAQs</List>
								</Link> */}

								<List onClick={logout} active={pathname === '/logout'}>Logout</List>
							</Ul>
						)}
					</SidebarContent>
				</SideBaar>
			</SidebarWraper>
		</>
	)
}

const SidebarWraper = styled.div`
	height: 100vh;
	background-color: ${palette.black};
	position: absolute;
	z-index: 4;
	${media.xs`
		width: ${({ open }) => (open ? '300px' : '0')} ;
	`}
	${media.sm`
		width: ${({ open }) => (open ? '300px' : '0')} ;
	`}
	${media.lg`
	width: ${({ pathname }) => (pathname ? '395px' : '400px')} ;
	position: relative;
	`}
`

const SideBaar = styled.div`
	position: absolute;
	top: 4.5rem;
	left: 0;
	height: 100vh;
	background-color: ${palette.black};
	border-right: 1px solid ${palette.yellow_border};
	z-index: 9;
	${media.xs`
		width: ${({ open }) => (open ? '300px' : '0')} ;
	`}
		${media.sm`
		width: ${({ open }) => (open ? '300px' : '0')} ;
	`}
	${media.lg`
		width: ${({ pathname }) => (pathname ? '395px' : '400px')} ;
		position: fixed;
	`}
`

const Open = styled.div`
	background: ${palette.yellow_border};
    width: 2rem;
    position: absolute;
    right: -2rem;
    top: 9.5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-size: 1.4rem;
	cursor:pointer;
	z-index:2;
		${media.lg`
	display: none;
	`}
`;

const Arrows = styled(RiArrowRightDoubleLine)`
	transform: ${({ open }) => (open ? 'rotate(180deg)' : '0')} ;
`;

const UserName = styled.div`
	color:${palette.white};
	overflow: hidden;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 20px;
	line-height: 1.2;
	margin-left: 0.8rem;
	opacity: ${({ hide }) => (hide ? '1' : '0')};
	transition: opacity 0.2ss ease;
	${media.xs`
	display: none;
`}
${media.sm`
display: none;
`}


${media.md`
display: flex;
`}
${media.lg`
display: flex;
`}
`
const TopProfile = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 72px;
	padding: 0rem 1rem;
	// box-shadow:${palette.hover_color} 0px 0px 8px 0.5px !important ;
	border-bottom: 1px solid ${palette.yellow_border};
`
const Image = styled.div`
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin-left: ${({ pathname }) => (pathname ? '0rem' : '10rem')};
	transition: all 0.3s;
`

const SidebarContent = styled.div`
height: calc(100vh - 72px);
overflow: auto;
`

const GoBack = styled(MdArrowBackIos)`
	font-size: 1.2rem;
	position: absolute;
	left: 1rem;
	opacity: ${({ hide }) => (hide ? '0' : '1')};
	transition: all 0.2s;
`

const Ul = styled.ul`
	padding: 1rem;
`

const List = styled.li`
	text-align: center;
	padding: 1rem;
	margin-bottom: 0.5rem;
	font-size: 16px;
	color: ${({ active }) => active ? palette.active_list : palette.white};
	font-weight: 500;
	cursor: pointer;
	&:hover{
		color: ${palette.active_list};
	}
	${media.xs`
	display: flex;
	justify-content:center;
`}
${media.sm`
display: flex;
justify-content:center;
`}
${media.md`
display: flex;
justify-content:center;
`}
${media.lg`
display: flex;
justify-content:center;
`}
`
const Li = styled.li`
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 0.6rem;
	border-radius: 25px;
	margin-bottom: 0.5rem;
	text-align: center;
	background: ${palette.btn_gradient};
`
const Info = styled.p`
	font-size: 14px;
	text-align: center;
	color: ${palette.white};
	width: calc(100% - 115.2px);
	${media.xs`
	display: flex;
`}
${media.sm`
display: flex;
`}


${media.md`
display: flex;
`}
${media.lg`
display: flex;
`}
`

export default MainSidebaar
