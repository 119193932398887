import React, { useEffect, useState } from "react";
import Modal, { ModalProvider } from 'styled-react-modal'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { Flexed, Text } from "../../styled/shared";
import CameraModal from './CameraModal'
import axios from 'axios'
import VerificationProcessingModal from "./VerificationProcessingModal";
import { useDispatch } from 'react-redux'
import { setUserDetails } from "../../redux/actions/authActions";
import { toastError } from "../../styled/toastStyle";
import useRouter from "../Common/UseRouterHook";
import { CommonEnum } from "../../utils";
import { media } from 'styled-bootstrap-grid'

const AccountVerificationModal = ({ isOpen, onClose }) => {
    const _dispatch = useDispatch()
    const [isMianloading, setIsMianloading] = useState(false);
    const [openCameraModal, setOpenCameraModal] = useState(false)
    const [openProcessingModal, setOpenProcessingModal] = useState(false)
    const [webCamImage, setWebCamImage] = useState(null)
    const router = useRouter()

    useEffect(() => {
        setOpenCameraModal(false);
        setWebCamImage(null);
        setOpenProcessingModal(false)
    }, [isOpen])

    const onSave = async () => {
        setIsMianloading(true);
        const _token = sessionStorage.getItem('authorization')
        if (_token) {
            const config = {
                headers: { authorization: `bearer ${_token}` }
            }
            await axios
                .post(
                    `${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
                    {
                        poses_photos: webCamImage,
                        last_screen: 'login'
                    },
                    config
                )
                .then((response) => {
                    if (response.data.success === true) {
                        _dispatch(setUserDetails(response.data.data.userdata))
                        setIsMianloading(false);
                        setOpenProcessingModal(true)

                    }
                })
                .catch((error) => {
                    console.error(error)
                    setIsMianloading(false);
                })
        } else {
            toastError('User not found')
            router.navigate('/')

        }

    }
    return (
        <>
            <ModalProvider>
                <StyledModal isOpen={isOpen} openCameraModal={openCameraModal} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
                    {!openCameraModal ?
                        (!openProcessingModal) ?
                            <>
                                <Flexed gap={1}>
                                    < Cover src='/verification_img.png' />
                                    {webCamImage && < Cover src={webCamImage} />}
                                </Flexed>
                                {!webCamImage ? <Heading>Copy this pose and take a photo</Heading> : <Heading>Review and save your photo</Heading>}
                                {!webCamImage && <Text type='normal' isCentered color='text'>We’ll check this photo matches the person in your profile. It won’t be visible on your profile.</Text>}
                                {!webCamImage && <Text type='normal' isCentered color='yellow_border'>To verify successfully:</Text>}
                                <Content webCamImage={webCamImage} direction='column' margin='0rem 0rem 1rem 0rem'>
                                    {!webCamImage ?
                                        <>
                                            <Flexed gap={0.5} align='center'>
                                                <svg height="16px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                                                        fill={palette.hover_color}></path>
                                                </svg>
                                                <Text type='normal' color='yellow_border'>Your face must be clearly visible</Text>
                                            </Flexed>
                                            <Flexed gap={0.5} align='center'>
                                                <svg height="16px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z"
                                                        fill={palette.hover_color}></path>
                                                </svg>
                                                <Text type='normal' color='yellow_border'>You must be copying this pose exactly</Text>
                                            </Flexed>
                                        </>
                                        :
                                        <>
                                            <Flexed gap={0.5}>
                                                <span>
                                                    <Dot />
                                                </span>
                                                <Text type='normal' color='text'>{CommonEnum.AppName} will compare this photo with your profile photo, which may include the use of facial recognition technology</Text>
                                            </Flexed>
                                            <Flexed gap={0.5} margin='0.2rem 0rem 0rem'>
                                                <span>
                                                    <Dot />
                                                </span>
                                                <Text type='normal' color='text'>We’ll keep your photo and scans to verify your photos in the future</Text>
                                            </Flexed>
                                        </>
                                    }
                                </Content>
                                {!webCamImage ?
                                    <Button onClick={() => setOpenCameraModal(true)}>Verify now</Button>
                                    :
                                    <>
                                        <Button onClick={onSave}>Agree and submit</Button>
                                        <DefaultButton onClick={() => setOpenCameraModal(true)}>Retake</DefaultButton>
                                    </>
                                }
                                <Info>
                                    For more info on how we use, retain and protect your personal data, see the <span>Help Center</span> and read our <span>Privacy Policy</span>. You can withdraw consent via <span>Support</span>.
                                </Info>
                            </>
                            :
                            <VerificationProcessingModal onClose={onClose} />

                        : <CameraModal setWebCamImage={setWebCamImage} onClose={() => setOpenCameraModal(false)} />
                    }
                </StyledModal>

            </ModalProvider>
            {isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
        </>
    )
}

const StyledModal = Modal.styled`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap:0.8rem;
    padding:  ${({ openCameraModal }) => openCameraModal ? '0' : '2rem 1rem'};
    background-color: ${palette.black};
    border:1px solid ${palette.yellow_border};
    overflow: hidden;
    max-height: 35rem;
    overflow: auto;
    ${media.md`
        width: 600px;
        padding:  ${({ openCameraModal }) => openCameraModal ? '0' : '2rem 3rem'};
        max-height: 38rem;
    `}
`

const Cover = styled.div`
    width: 128px;
    height: 172px;
    border-radius: 8px;
    background-image: ${({ src }) => src ? `url(${src})` : '#eee'};
    background-position: 50%;
    background-size: cover;
    margin-bottom: 1rem;

`

const Heading = styled.div`
    font-size: 24px;
    color: ${palette.yellow_border};
    text-align: center;
    font-weight: 500;
    line-height: 2rem;
`

const Content = styled(Flexed)`
    padding: ${({ webCamImage }) => webCamImage ? '0rem 1rem' : '0'};
    ${media.md`
    padding: ${({ webCamImage }) => webCamImage ? '0rem 4rem' : '0'};
    `}
`;


const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

const DefaultButton = styled(Button)`
	color: ${palette.dark_gray};
	background: #f6f6f6;
    min-width: 165.41px;
    &:hover{
        background: #eaeaea;
    }
`

const Dot = styled.div`
    width: 6px;
    height: 6px;
    background: ${palette.yellow_border};
    border-radius: 100%;
    margin-top: 0.55rem;
`;

const Info = styled.div`
    margin-top: 1rem;
    font-size: 14px;
    color: ${palette.text};
    text-align: center;
    line-height: 1.3rem;
    & span{
        text-decoration: underline;
        text-underline-offset: 5px;
        text-decoration-color: ${palette.yellow_border};
        color: ${palette.yellow_border};
        cursor: pointer;
    }
    & span:hover{
        text-decoration-color: ${palette.yellow_border};
        cursor: pointer;
    };
`;

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

export default AccountVerificationModal
