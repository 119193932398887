
import styled from 'styled-components'
import React from 'react'
import Why from '../Shared/Why'
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { CommonEnum } from '../../utils';
import { palette } from '../../styled/colors';
import { media } from 'styled-bootstrap-grid'

const Testimonial = () => {

    return (

        <>
            <Wraper>
                <Container fluid>
                    <Heading>We’re not just for dating anymore</Heading>
                    <Row>
                        <Col lg="4" md="6" sm="6">
                            <Card>
                                <ImageWrapper>
                                <Image src='./dating5.jpeg' alt='card 1' />
                                <Tag1>
                                    <h4>{CommonEnum.AppName} Date</h4>
                                </Tag1>
                                </ImageWrapper>
                                
                                <Text>
                                    <p>
                                        On {CommonEnum.AppName}, women make the first move. We’re levelling the playing field and changing the dynamics of dating. We believe relationships should begin with</p>
                                </Text>
                                <Button>Learn More</Button>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card>
                            <ImageWrapper>
                                <Image src='./dating1.webp' alt='card 2' />
                                <Tag2>
                                    <h4>{CommonEnum.AppName} Bizz</h4>
                                </Tag2>
                                </ImageWrapper>
                                <Text>
                                    <p>
                                        Career opportunities come about when you expand your network. On {CommonEnum.AppName} Bizz, you can pursue a career change, meet team members, or become a mentor.</p>
                                </Text>
                                <Button>Learn More</Button>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card>
                            <ImageWrapper>
                                <Image src='./dating6.jpeg' alt='card 3' />
                                <Tag3>
                                    <h4>{CommonEnum.AppName} DFF</h4>
                                </Tag3>
                                </ImageWrapper>
                                <Text>
                                    <p>
                                        Whether you’re new to a city or looking to expand your social circle, {CommonEnum.AppName} DFF is a simplified way to create meaningful friendships.
                                    </p>
                                </Text>
                                <Button>Learn More</Button>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Why />
            </Wraper>
        </>
    )
}

const Wraper = styled.div`
max-width:1439px;
margin:0 auto;

`

const Heading = styled.div`
padding: 2rem 1rem;
    font-weight: 600;
    text-align: center;
    color: ${palette.text};
    font-size: 1.5rem;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`

const Tag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 3rem;
    padding: .7rem 2rem;
    font-size: 1.2rem;
    text-align: center;
`

const Tag1 = styled(Tag)`
    position: absolute;
    left: 0%;
    top: 50%;
  `
  
  const Tag2 = styled(Tag)`
    position: absolute;
    bottom: 10%;
    left: 0%;
    `
  
    const Tag3 = styled(Tag)`
    position: absolute;
    top: 10%;
    left: 0%;
    `

const Text = styled.p`
display: flex;
justify-content: center;
align-items: center;
line-height: 1.8rem;
text-align: center;
margin-top: 1rem;
color: ${palette.text};

`

const Button = styled.div`
display: inline-block;
margin: auto;
text-align: center;
border-radius: 4px;
background: ${palette.btn_gradient};
padding: .8rem 3rem;
cursor: pointer;
color: white;
text-decoration: none;
font-weight: 700;
margin-top: 1rem;
&:hover{
    background: ${palette.btn_hover_gradient};
}
`

const Card = styled.div`
text-align:center;
margin-bottom: 1.5rem;
position: relative;
`

const ImageWrapper = styled.div`
position: relative;
`
const Image = styled.img`
width: 100%;
`

export default Testimonial