import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, media } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import RegisterStepper from '../components/RegisterStepper'
import { BorderWrapper, OverLay, Spacer, Text } from '../styled/shared'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { addUserInChat, getUser } from '../apis/apis'
import { CommonEnum } from '../utils'
import { toast } from 'react-toastify'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const DateOfBirth = () => {
	const _monthRef = useRef(null)
	const _yearRef = useRef(null)
	const _dayRef = useRef(null)

	const _navigate = useNavigate()
	const [userName, setUserName] = useState('')
	const [day, setDay] = useState('')
	const [month, setMonth] = useState('')
	const [year, setYear] = useState('')

	const [openDayDrop, setOpenDayDrop] = useState(false)
	const [openMonthDrop, setOpenMonthDrop] = useState(false)
	const [openYearDrop, setOpenYearDrop] = useState(false)

	const [isMianloading, setIsMianloading] = useState(false);

	const monthList = [
		{ val: 1, month: 'Janaury' },
		{ val: 2, month: 'February' },
		{ val: 3, month: 'March' },
		{ val: 4, month: 'April' },
		{ val: 5, month: 'May' },
		{ val: 6, month: 'June' },
		{ val: 7, month: 'July' },
		{ val: 8, month: 'August' },
		{ val: 9, month: 'September' },
		{ val: 10, month: 'October' },
		{ val: 11, month: 'November' },
		{ val: 12, month: 'December' },

	]

	const dayList = [
		{ val: 1, day: 1 },
		{ val: 2, day: 2 },
		{ val: 3, day: 3 },
		{ val: 4, day: 4 },
		{ val: 5, day: 5 },
		{ val: 6, day: 6 },
		{ val: 7, day: 7 },
		{ val: 8, day: 8 },
		{ val: 9, day: 9 },
		{ val: 10, day: 10 },
		{ val: 11, day: 11 },
		{ val: 12, day: 12 },
		{ val: 13, day: 13 },
		{ val: 14, day: 14 },
		{ val: 15, day: 15 },
		{ val: 16, day: 16 },
		{ val: 17, day: 17 },
		{ val: 18, day: 18 },
		{ val: 19, day: 19 },
		{ val: 20, day: 20 },
		{ val: 21, day: 21 },
		{ val: 22, day: 22 },
		{ val: 23, day: 23 },
		{ val: 24, day: 24 },
		{ val: 25, day: 25 },
		{ val: 26, day: 26 },
		{ val: 27, day: 27 },
		{ val: 28, day: 28 },
		{ val: 29, day: 29 },
		{ val: 30, day: 30 },
		{ val: 31, day: 31 },
	]

	const yearList = [
		{ val: 1970, year: 1970 },
		{ val: 1971, year: 1971 },
		{ val: 1972, year: 1972 },
		{ val: 1973, year: 1973 },
		{ val: 1974, year: 1974 },
		{ val: 1975, year: 1975 },
		{ val: 1976, year: 1976 },
		{ val: 1977, year: 1977 },
		{ val: 1978, year: 1978 },
		{ val: 1979, year: 1979 },
		{ val: 1980, year: 1980 },
		{ val: 1981, year: 1981 },
		{ val: 1982, year: 1982 },
		{ val: 1983, year: 1983 },
		{ val: 1984, year: 1984 },
		{ val: 1985, year: 1985 },
		{ val: 1986, year: 1986 },
		{ val: 1987, year: 1987 },
		{ val: 1989, year: 1989 },
		{ val: 1990, year: 1990 },
		{ val: 1991, year: 1991 },
		{ val: 1992, year: 1992 },
		{ val: 1993, year: 1993 },
		{ val: 1994, year: 1994 },
		{ val: 1995, year: 1995 },
		{ val: 1996, year: 1996 },
		{ val: 1997, year: 1997 },
		{ val: 1998, year: 1998 },
		{ val: 1999, year: 1999 },
		{ val: 2000, year: 2000 },
		{ val: 2001, year: 2001 },
		{ val: 2002, year: 2002 },
		{ val: 2003, year: 2003 },
		{ val: 2004, year: 2004 },
		{ val: 2005, year: 2005 },
		{ val: 2006, year: 2006 },
		{ val: 2007, year: 2007 },
		{ val: 2008, year: 2008 },
		{ val: 2009, year: 2009 },
		{ val: 2010, year: 2010 },
		{ val: 2011, year: 2011 },
		{ val: 2012, year: 2012 },
		{ val: 2013, year: 2013 },
		{ val: 2014, year: 2014 },
		{ val: 2015, year: 2015 },
		{ val: 2016, year: 2016 },
		{ val: 2017, year: 2017 },
		{ val: 2018, year: 2018 },
		{ val: 2019, year: 2019 },
		{ val: 2020, year: 2020 },
		{ val: 2021, year: 2021 },
		{ val: 2022, year: 2022 },
		{ val: 2023, year: 2023 },
		{ val: 2024, year: 2024 },
		{ val: 2025, year: 2025 },
		{ val: 2026, year: 2026 },
		{ val: 2027, year: 2027 },
		{ val: 2028, year: 2028 },
		{ val: 2029, year: 2029 },
		{ val: 2030, year: 2030 },
		{ val: 2031, year: 2031 },
		{ val: 2032, year: 2032 },
		{ val: 2033, year: 2033 },
		{ val: 2034, year: 2034 },
		{ val: 2035, year: 2035 },
		{ val: 2036, year: 2036 },
		{ val: 2037, year: 2037 },
		{ val: 2038, year: 2038 },
		{ val: 2039, year: 2039 },
		{ val: 2040, year: 2040 },
		{ val: 2041, year: 2041 },
		{ val: 2042, year: 2042 },
		{ val: 2043, year: 2043 },
		{ val: 2044, year: 2044 },
		{ val: 2045, year: 2045 },
	]

	const getUserinfo = async () => {
		setIsMianloading(true)
		let user = await getUser()
		setUserName(user?.data?.data?.user?.first_name)
		let dateCollection = user?.data?.data?.user?.date_of_birth?.split('/')
		if (dateCollection?.length === 3) {
			setDay(dateCollection[0])
			setMonth(dateCollection[1])
			setYear(dateCollection[2])
		}
		setIsMianloading(false);
	}

	useEffect(() => {
		getUserinfo()
	}, [])

	const submitForm = async (e) => {
		setIsMianloading(true)
		e.preventDefault()
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		let _dataOfBirth = month + '/' + day + '/' + year
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					date_of_birth: _dataOfBirth,
					last_screen: 'UserGenderScreen'
				},
				config
			)
			.then(async (response) => {
				if (response.data.success === true) {
					await addUserInChat(response.data.data.userdata.id, response.data.data.userdata)
					_navigate('/gender')
				} else if (response.data.success === false) {
					toast.error(response.data?.message)
				}
				setIsMianloading(false);
			})
			.catch((error) => {
				console.error(error)
				setIsMianloading(false);
			})
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (_monthRef.current && !_monthRef.current.contains(event.target)) {
				setOpenMonthDrop(false)
			}
			if (_yearRef.current && !_yearRef.current.contains(event.target)) {
				setOpenYearDrop(false)
			}
			if (_dayRef.current && !_dayRef.current.contains(event.target)) {
				setOpenDayDrop(false)
			}
		}

		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])
	return (
		<>
			<Wrapper>
				<OverLay />
				<Container>
					<Row justifyContent="center">
						<Col sm={9} md={9} lg={7}>
							<BorderWrapper>
								<RegisterStepper progress={70} goBack={() => { _navigate('/upload-photo') }} />
								<Heading>Hey {userName}! When’s your birthday?</Heading>
								<form>
									<Center>
										<SelectWrapper >
											<SelectOverlay ref={_monthRef} onClick={() => {
												setOpenMonthDrop(!openMonthDrop)
											}} />
											<Select>
												{month ? monthList[month - 1].month : 'Month'}  {openMonthDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
											</Select>
											<DropDown active={openMonthDrop}>
												{monthList.map((data) => {
													return (
														<DropList
															active={data.val === month}
															onClick={() => { setMonth(data.val); setDay('') }}
														>
															{data.month}
														</DropList>
													)
												})}
											</DropDown>
										</SelectWrapper>

										<SelectWrapper >
											<SelectOverlay ref={_dayRef} onClick={() => {
												setOpenDayDrop(!openDayDrop)
											}} />
											<Select>
												{day ? day : 'Day'}
												{openDayDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
											</Select>
											<DropDown active={openDayDrop}>
												{dayList.map((data) => {
													return (
														<>
															{(data.val <= (month === 2 ? 29 : (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 3 || month === 10 || month === 12) ? 31 : 30)) && <DropList
																active={data.val === day}
																onClick={() => setDay(data.val)}
															>
																{data.day}
															</DropList>}
														</>)
												})}
											</DropDown>
										</SelectWrapper>

										<SelectWrapper >
											<SelectOverlay ref={_yearRef} onClick={() => {
												setOpenYearDrop(!openYearDrop)
											}} />
											<Select>
												{year ? year : 'Year'}

												{openYearDrop ? <IoIosArrowUp /> : <IoIosArrowDown
												/>}
											</Select>
											<DropDown active={openYearDrop}>
												{yearList.map((data) => {
													return (
														<>
															{(data.val <= (2023 - 17)) && <DropList
																active={data.val === year}
																onClick={() => setYear(data.val)}
															>
																{data.year}
															</DropList>}
														</>)
												})}
											</DropDown>
										</SelectWrapper>
									</Center>
									<Spacer height={1} />

									<Text color='text' isCentered>You must be at least 18 years old to use {CommonEnum.AppName}</Text>
									<Button props={day !== '' && month !== '' && year !== ''} type="submit" onClick={submitForm}>
										Continue
									</Button>
								</form>
								{/* <Center>
								<Link className="otpText" to={'#'}>
									Use Facebook instead
								</Link>
							</Center> */}
							</BorderWrapper>
						</Col>
					</Row>
				</Container>
			</Wrapper>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}
const Wrapper = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	background: url('/home_cover.webp');
	width: 100%;
	background-size: cover;
	background-position: center;
	background-reapete: center;
	background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
`

const Heading = styled.div`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
	margin-bottom: 1.5rem;
	line-height:1.5;
	color: ${palette.yellow_border};
	${media.xs`
		font-size: 18px;
	`}
`
const Wrap = styled.div`
	margin-top: 3.5rem;
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	${media.xs`flex-direction:column`};
	${media.sm`flex-direction:column`};
	${media.sm`flex-direction:row`};
`

const SelectWrapper = styled.div`position: relative;
`;

const SelectOverlay = styled.div`
	position: absolute;
	// background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 1;
	top:0;
	border-radius: 20px;
	cursor: pointer;
`

const Select = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${palette.yellow_border};
	border-radius: 20px;
	padding: 0.7rem 1rem;
	font-size: 1rem;
	height: 42px;
	cursor: pointer;
	color: ${palette.yellow_border};
	background: ${palette.black};
	&:hover{
		border: 1px solid ${palette.yellow_border};
	}
		width: 8rem;
		${media.xs`width: 12rem;`}

`
const Option = styled.option`
	outline: none;
	margin-left: 0.2rem;
	margin-top: 1rem;
	padding: 0.7rem 1rem;
	border-radius: 20px;
	width: 9rem;
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	cursor: pointer;
	background: ${palette.btn_gradient};
	margin: auto;
	padding: 0.8rem 1rem;
	width: 150px;
	border-radius: 20px;
	border: none;
	margin-top: 2rem;
	margin-bottom: 1rem;
	color: ${palette.white};
	opacity: ${({ props }) => (props ? '1' : '0.5')};
	cursor: ${({ props }) => (props ? 'pointer' : 'default')};
	pointer-events: ${({ props }) => (props ? 'default' : 'none')};
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const DropDown = styled.div`
	width: 100%;
	max-height: 13rem;
	display: ${({ active }) => (active ? 'block' : 'none')};
	position: absolute;
	z-index: 10;
	top: 3rem;
	left: 0;
	overflow: hidden;
	border: 1px solid ${palette.yellow_border};
	border-radius: 16px;
	background: ${palette.black};
	padding: 8px 0;
	overflow: auto;
`

const DropList = styled.span`
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 1.429;
	color: ${({ active }) => (active ? palette.hover_color : palette.white)};
	&:hover {
		background: linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%);
		cursor: pointer;
	}
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`



export default DateOfBirth
