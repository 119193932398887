import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { palette } from '../styled/colors'
import { Link, useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import VerificationInput from 'react-verification-input'
import Logo from '../components/Logo/Logo'
import { getUser, verifyRegisterCode } from '../apis/apis'
import RegisterStepper from '../components/RegisterStepper'
import { BorderWrapper, OverLay, Spacer, Text } from '../styled/shared'
import { toast } from 'react-toastify'
import { setUserDetails } from '../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import { media } from 'styled-bootstrap-grid'

const ConfirmPhone = () => {
	const _navigate = useNavigate()
	const _dispatch = useDispatch()
	const [phoneNo, setPhoneNo] = useState('')
	const [user, setUser] = useState([])
	const [verificationCode, setVerificationCode] = useState('')
	const [errorMsg, setErrorMsg] = useState('')
	const [isMianloading, setIsMianloading] = useState(false);

	const getUserinfo = async () => {
		setIsMianloading(true);
		let user = await getUser()
		setUser(user?.data?.data?.user)
		setPhoneNo(user?.data?.data?.user?.phone)
		setIsMianloading(false);
	}
	useEffect(() => {
		getUserinfo()
	}, [])
	const formSubmit = async () => {
		setIsMianloading(true);
		let _response = await verifyRegisterCode(phoneNo, verificationCode)
		if (_response.success === true) {
			_dispatch(setUserDetails(_response.data))
			if (user.last_screen === '') {
				_navigate('/enter-name')
			} else if (user.last_screen === 'login') {
				_navigate('/app')
			} else if (user.last_screen === 'UserGenderScreen') {
				_navigate('/gender')
			}
			else if (user.last_screen === 'UserAddName') {
				_navigate('/enter-name')
			}
			else if (user.last_screen === 'UserAddDateOfBirth') {
				_navigate('/enter-dob')
			}
			else if (user.last_screen === 'UserAddImages') {
				_navigate('/upload-photo')
			}
			else if (user.last_screen === 'UserLocation') {
				_navigate('/location')
			}
			else if (user.last_screen === 'ChooseModeScreen') {
				_navigate('/chooseMode')
			}
			else if (user.last_screen === 'VarificationScreen') {
				_navigate('/verification')
			}

		} else if (_response.success === false) {
			toast.error(_response?.message)
			setIsMianloading(false);
		}
		setIsMianloading(false);
	}

	return (
		<>
			<Wrapper>
			<OverLay/>
				<Container>
					<Row justifyContent="center">
					<Col sm={9} md={9} lg={7}>
							<BorderWrapper>
								<RegisterStepper progress={25} goBack={() => { _navigate('/get-started') }} />
								<Heading>Next, please enter the 6-digit code (123000) we just sent you</Heading>
								<Center>
									<VerificationInput
									autoFocus={true}
										validChars="0-9"
										classNames={{
											character: 'character',
											characterFilled: "character--filled",
											characterSelected: "character--selected",
										}}
										onChange={(e) => {
											setErrorMsg('')
											setVerificationCode(e)
										}}
										value={verificationCode}
									/>
								</Center>
								<Spacer height={0.5} />

								<Text color='text' isCentered>Text to {phoneNo} should arrive within few sec.</Text>

								<Spacer height={2} />

								<CustomText color='yellow_border' isCentered onClick={() => { _navigate('/get-started') }}>Change number</CustomText>

								<Spacer height={2} />
								<Button type="submit" onClick={formSubmit}>
									Continue
								</Button>
								<Center>
									<Link style={{ color: palette.hover_color }} to={'#'}>
										Send text again
									</Link>
								</Center>
							</BorderWrapper>
						</Col>
					</Row>
				</Container>
			</Wrapper>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const Wrapper = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	background: url('/home_cover.webp');
    width: 100%;
    background-size: cover;
    background-position: center;
    background-reapete: center;
    background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
`

const Heading = styled.p`
	font-size: 25px;
	text-align: center;
	font-weight: 400;
	margin-top: 2rem;
	margin-bottom: 1.5rem;
	line-height:1.5;
	color: ${palette.yellow_border};
	${media.xs`
    font-size: 18px;
    `}
`

const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const Wrap = styled.div`
	margin-top: 3.5rem;
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: ${palette.btn_gradient};
	margin: auto;
	padding: 0.8rem 1rem;
	width: 150px;
	border-radius: 20px;
	border: none;
	margin-bottom: 1rem;
	color: ${palette.white};
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const CustomText = styled(Text)`
	text-decoration: underline;
	text-underline-offset: 5px;
	cursor: pointer;
	text-decoration-color: ${palette.hover_color};
	&:hover{
		text-decoration-color: ${palette.dark_gray};
	}
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

export default ConfirmPhone
