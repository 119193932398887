import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { BsApple } from 'react-icons/bs'
import { palette } from '../styled/colors'
import { Flexed, Error, OverLay, BorderWrapper } from '../styled/shared'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { socialLogin, userRegister } from '../apis/apis'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { toastError } from '../styled/toastStyle'

const Join = () => {
	const [showPhoneContent, setPhoneContent] = useState(false)
	const [phoneNo, setPhoneNo] = useState('')
	const [phoneNoError, setPhoneNoError] = useState(false)
	const _navigate = useNavigate()

	const formSubmit = async (e) => {
		e.preventDefault()
		let _response = await userRegister(phoneNo)
		if (_response?.data && _response.data?.blocked == false) {
			sessionStorage.setItem('authorization', _response?.data?.auth_token)
			if (_response.success === true) {
				_navigate('/confirm-phone')
			}
		} else {
			if (_response?.message) {
				toastError(_response?.message)
			} else {
				toastError('Failed to login')
			}
		}
	}
	const isNumberKey = (evt) => {
		var charCode = evt.which ? evt.which : evt.keyCode
		return !(charCode > 31 && (charCode < 48 || charCode > 57))
	}
	const loginWithApple = async () => {
		let name
		// provider, social_id, email
		let response = await socialLogin('apple', 22, 's@s.com')
		sessionStorage.setItem('authorization', response.data.user.auth_token)
		if (response.data?.user && response.data?.user?.blocked == false) {
			sessionStorage.setItem('authorization', response.data?.user?.auth_token)
			if (response.data?.user?.last_screen === '') {
				_navigate('/enter-name')
			} else if (response.data?.user?.last_screen === 'login') {
				_navigate('/app')
			} else if (response.data?.user?.last_screen === 'UserGenderScreen') {
				_navigate('/gender')
			} else if (response.data?.user?.last_screen === 'UserAddName') {
				_navigate('/enter-name')
			} else if (response.data?.user?.last_screen === 'UserAddDateOfBirth') {
				_navigate('/enter-dob')
			} else if (response.data?.user?.last_screen === 'UserAddImages') {
				_navigate('/upload-photo')
			} else if (response.data?.user?.last_screen === 'UserLocation') {
				_navigate('/location')
			} else if (response.data?.user?.last_screen === 'ChooseModeScreen') {
				_navigate('/chooseMode')
			} else if (!name && response.message == 'User registered successfully with Apple') {
				_navigate('/enter-name')
			}
		} else {
			if (response?.message) {
				toastError(response.message)
			} else {
				toastError('Failed to login')
			}
		}
	}

	const loginWithGoogle = async (data) => {
		let name
		let response = await socialLogin('google', 25, 'k@k.com')
		if (response.data?.user && response.data?.user?.blocked == false) {
			sessionStorage.setItem('authorization', response.data?.user?.auth_token)
			if (response.data?.user?.last_screen === '') {
				_navigate('/enter-name')
			} else if (response.data?.user?.last_screen === 'login') {
				_navigate('/app')
			} else if (response.data?.user?.last_screen === 'UserGenderScreen') {
				_navigate('/gender')
			} else if (response.data?.user?.last_screen === 'UserAddName') {
				_navigate('/enter-name')
			} else if (response.data?.user?.last_screen === 'UserAddDateOfBirth') {
				_navigate('/enter-dob')
			} else if (response.data?.user?.last_screen === 'UserAddImages') {
				_navigate('/upload-photo')
			} else if (response.data?.user?.last_screen === 'UserLocation') {
				_navigate('/location')
			} else if (response.data?.user?.last_screen === 'ChooseModeScreen') {
				_navigate('/chooseMode')
			} else if (!name && response.message == 'User registered successfully with Apple') {
				_navigate('/enter-name')
			}
		} else {
			if (response?.message) {
				toastError(response.message)
			} else {
				toastError('Failed to login')
			}
		}
	}

	useEffect(() => {
		sessionStorage.removeItem('authorization')
	}, [])
	return (
		<Wrapper>
			<OverLay />
			<Container>
				<Row justifyContent="center">
					<Col sm={9} md={9} lg={7}>
						<BorderWrapper>
							<Text>Welcome! How do you want to get started?</Text>
							<SocialButton>
								<BsApple fontSize="1.2rem" />
								<div onClick={loginWithApple}>Continue with Apple </div>
							</SocialButton>

							<GoogleWrapperDiv onClick={loginWithGoogle}>
								<GoogleButtonWrapper clientId={'REACT_APP_GOOGLE_CLIENT_ID'}>
									<GoogleButtonDiv
										onSuccess={(e) => loginWithGoogle(e)}
									/>
								</GoogleButtonWrapper>
							</GoogleWrapperDiv>
							<JoinText>
								<Divider />
								<TextSpan>or</TextSpan>
								<Divider />
							</JoinText>
							{!showPhoneContent && (
								<Flexed justify="center">
									<LinkText
										onClick={() => {
											setPhoneContent(true)
										}}>
										Use cell phone number
									</LinkText>
								</Flexed>
							)}
							{showPhoneContent && (
								<Form>
									<Flexed direction="column" gap={0.5}>
										{phoneNoError ? <Error>Please check your number</Error> : <MiniText>Sign up with your number instead</MiniText>}
										<PhoneInput
											inputClass="phoneInputFields"
											country={'us'}
											enableAreaCodes={true}
											value={phoneNo}
											inputProps={{
												name: 'phone',
												country: 'us',
												required: true,
												autoFocus: true
											}}
											onChange={(phone) => {
												let number = '+' + phone
												if (isNumberKey(number)) {
													setPhoneNo(number)
												}
											}}
										/>
										<Button props={phoneNo?.trim().length > 3} onClick={formSubmit} type="submit">
											Continue
										</Button>
									</Flexed>
								</Form>
							)}
						</BorderWrapper>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

const GoogleWrapperDiv = styled.div`
	max-width: 350px !important;
	/* display: flex; */
	align-items: center;
	justify-content: center;
	display: flex;
	align-items: center;
	gap: 0.8rem;
	height: 40px;
	border-radius: 20px;
	font-size: 17px;
	cursor: pointer;
	margin: auto;
	margin-bottom: 1rem;
	text-align: center;
	& > div {
		width: 100% !important;
		border-radius: 20px !important;
		&:hover {
			border-color: ${palette.hover_color};
		}
		& > div {
			width: 100% !important;
			border-radius: 20px !important;

			&:hover {
				border-color: ${palette.hover_color};
			}
			& > div {
				&:hover {
					border-color: ${palette.hover_color};
				}


				& > div {
					border: 1px solid  ${palette.black};
					background:  ${palette.black};
					color: ${palette.white};
					border-radius: 20px !important;
					&:hover {
						border-color: ${palette.hover_color};
					}
					justify-content: center;
                    display: grid;
				}
			}
		}
	}
`

const GoogleButtonWrapper = styled(GoogleOAuthProvider)``

const GoogleButtonDiv = styled(GoogleLogin)``

const Wrapper = styled.div`
	position: relative;
	padding: 0rem 0rem 1rem 0rem;
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 60px);
	background: url('/home_cover.webp');
    width: 100%;
    background-size: cover;
    background-position: center;
    background-reapete: center;
    background-repeat: no-repeat;
`

const Button = styled.button`
	cursor: pointer;
	background: ${palette.btn_gradient};
	color: white;
	margin: auto;
	padding: 0.8rem 1rem;
	width: 100px;
	border-radius: 20px;
	border: none;
	margin-top: 1rem;
	opacity: ${({ props }) => (props ? '1' : '0.5')};
	cursor: ${({ props }) => (props ? 'pointer' : 'default')};
	pointer-events: ${({ props }) => (props ? 'default' : 'none')};
	&:hover {
		background: ${palette.btn_hover_gradient};
	}
`
const Text = styled.div`
	font-size: 25px;
	text-align: center;
	margin-bottom: 3rem;
	color: ${palette.yellow_border};
`

const MiniText = styled.div`
	font-size: 12px;
	text-align: center;
	color: ${palette.yellow_border};
`

const Form = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
`

const LinkText = styled.div`
	text-align: center;
	margin-top: 2rem;
	color: ${palette.hover_color};
	font-size: 1rem;
	height: 40px;
	padding: 0 20px;
	border-radius: 20px;
	width: fit-content;
	display: flex;
	justify-conntent: center;
	align-items: center;
	cursor: pointer;
	&:hover {
		background: #00000005;
	}
`

const JoinText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	gap: 1rem;
`
const TextSpan = styled.div`
	color: ${palette.yellow_border};
	font-size: 13px;
`
const Divider = styled.div`
	width: 5rem;
	height: 3px;
	border-bottom: 1px solid ${palette.yellow_border};
`

const SocialButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8rem;
	height: 40px;
	padding: 0 20px;
	border-radius: 20px;
	font-size: 17px;
	background-color: ${({ bgColor }) => (bgColor ? bgColor : palette.black)};
	color: ${palette.white};
	max-width: 350px;
	cursor: pointer;
	margin: auto;
	margin-bottom: 1rem;
	text-align: center;
	&:hover {
		border: 1px solid ${palette.hover_color};
	}

`

export default Join
