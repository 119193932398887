import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Flexed, Spacer } from '../styled/shared'
import { palette } from '../styled/colors'
import { MdOutlineClose } from 'react-icons/md'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { getAllExpireUserList } from '../apis/apis'
import ChatSideProfile from '../components/chat/ChatSideProfile'
import { useNavigate } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'

const ExpireProfile = () => {
	const _navigate = useNavigate()
	const [openSideProfile, setOpenSiddeProfile] = useState(false)
	const [intrestedUser, setIntrestedUser] = useState([])

	const [intrestedUserProfile, setIntrestedUserProfile] = useState({})
	const getIntrestedUser = async () => {
		const response = await getAllExpireUserList()

		setIntrestedUser(response?.data?.data?.user)
		//BasickList
		// if (response?.data) {
		// }
	}
	useEffect(() => {
		getIntrestedUser()
	}, [])

	return (
		<>
			<Wrapper open={openSideProfile}>
				<ChatHeader>
					<span></span>
					<Profile align="center" gap={0.8} margin="0.5rem 0rem 0.5rem 0rem">
						<Flexed direction="column" gap={0.2}>
							<UserName> All List of Expire User</UserName>
						</Flexed>
					</Profile>
					<Flexed gap={0.5}>
						<Close to="/app">
							<CloseIcon fontSize="1.9rem" cursor="pointer" />
						</Close>
					</Flexed>
				</ChatHeader>
				<Container>
					<Row justifyContent="center">
						<Section>
							<Content justify="center" align="center" margin="6rem 0rem 2rem 0rem">
								<Flexed direction="column" align="center">
									<MatchIcon>
										<img src="./mathes.png" alt="" />
									</MatchIcon>
									<Heading>Expire User</Heading>
									{/* <Text>{`Upgrade to edating Premium to see the 4 people who have already swiped right on you.`}</Text> */}
									{/* <Button>Upgrade to Premium</Button> */}
								</Flexed>
							</Content>
						</Section>
					</Row>
					<Row justifyContent="center">
						<Section>
							<MatchesWrapper>
								<Row justifyContent="center">
									{intrestedUser?.map((userInfo) => (
										<Col lg={4} md={6}>
											{userInfo?.profile_data_user?.images && userInfo?.profile_data_user?.images.length && (
												<MatchesCards
													onClick={() => {
														_navigate(`/connections?id=${userInfo.profile_data_user.id}`)

													}}
													src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${userInfo?.profile_data_user?.images[0]}`}
													alt=""
												/>
											)}

										</Col>
									))}
								</Row>
								<Spacer height={3} />
							</MatchesWrapper>
						</Section>
					</Row>
				</Container>
			</Wrapper>
			<ChatSideProfile
				user={intrestedUserProfile}
				open={openSideProfile}
				close={() => {
					setOpenSiddeProfile(!openSideProfile)
				}}
			/>
		</>
	)
}

const Wrapper = styled.div`
	width: ${({ open }) => (open ? '100%' : '100%')};
	overflow: scroll;
	height: 100vh;
	${media.lg`
		width: ${({ open }) => (open ? 'calc(100% - 702px)' : 'calc(100% - 400px)')};
	`}
	background: ${palette.black};
`

const ChatHeader = styled.div`
	position: sticky;
	top: 4.5rem;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 72px;
	border-bottom: 1px solid ${palette.yellow_border};
	padding: 0.5rem 1rem;
	background: ${palette.black};
	z-index: 3;
`

const UserName = styled.div`
	color: ${palette.yellow_border};
	font-size: 20px;
	font-weight: 500;
`

const Profile = styled(Flexed)``

const MatchesWrapper = styled.div`
	padding: 0;
`

const MatchesCards = styled.div`
	background: ${({ src }) => (src ? `url(${src})` : 'rgb(252, 244, 221)')};
	background-color: rgb(252, 244, 221);
	background-position:center;
	background-size: cover;
	width: 100%;
	min-height: 15rem;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 1.5rem;
`

const Close = styled(Link)`
	display: flex;
	align-items: center;
`

const CloseIcon = styled(MdOutlineClose)`
    color: ${palette.text};
    &:hover{
        color: ${palette.yellow_border};
    }
`

const Content = styled(Flexed)`
	padding: 1rem;
`

const MatchIcon = styled.div`
	width: 60px;
	height: 60px;
	position: relative;
	cursor: pointer;
	& img {
		width: 60px;
		height: 60px;
		border: 4px solid ${palette.white};
		border-radius: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
`

const Heading = styled.div`
	margin-top: 20px;
	color: ${palette.yellow_border};
	font-size: 22px;
	font-weight: 500;
`

const Section = styled.div`
	width: 632px;
	padding: 0 32px;
`
export default ExpireProfile
