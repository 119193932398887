import React, { useEffect, useState } from "react";
import Modal, { ModalProvider } from 'styled-react-modal'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { media } from 'styled-bootstrap-grid'
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios'
const fileTypes = ["JPG", "JPEG", "PNG", "WEBP"];

const ProfileImageUploadModal = ({ isOpen, onClose, images, getUserinfo, specificImgIndex }) => {
    const [newImages, setNewImages] = useState([]);
    const [isloading, setIsLoading] = useState(false);
    const onDraggingStateChange = (file) => { };

    useEffect(() => {
        setNewImages([])
    }, [isOpen])

    const handleFileChange = async function (file) {
        if (file) {
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async function () {
                let image_data = [...images]
                if (specificImgIndex) {
                    image_data.splice(specificImgIndex, 1)
                    image_data.splice(specificImgIndex, 0, reader.result)
                } else if (specificImgIndex == 0) {
                    image_data.splice(specificImgIndex, 1)
                    image_data.splice(specificImgIndex, 0, reader.result)
                }
                else {
                    image_data.splice(images?.length, 1)
                    image_data.splice(images?.length, 0, reader.result)
                }
                setNewImages([...newImages, reader.result])
                await doSaveImages(image_data)
            }
            reader.onerror = function (error) {
                console.log('Error: ', error)
            }

        }
    };

    const handleChange = async function (e) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader()
            reader.readAsDataURL(e.target.files[0])
            reader.onload = async function () {
                let image_data = [...images]
                if (specificImgIndex) {
                    image_data.splice(specificImgIndex, 1)
                    image_data.splice(specificImgIndex, 0, reader.result)
                } else if (specificImgIndex == 0) {
                    image_data.splice(specificImgIndex, 1)
                    image_data.splice(specificImgIndex, 0, reader.result)
                }
                else {
                    image_data.splice(images?.length, 1)
                    image_data.splice(images?.length, 0, reader.result)
                }
                setNewImages([...newImages, reader.result])
                await doSaveImages(image_data)
            }
            reader.onerror = function (error) {
                console.log('Error: ', error)
            }
        }
    };

    const doSaveImages = async (image_data) => {
        setIsLoading(true)
        const _token = sessionStorage.getItem('authorization')
        const config = {
            headers: { authorization: `bearer ${_token}` }
        }
        await axios
            .post(
                `${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
                {
                    images: image_data
                },
                config
            )
            .then(async (response) => {
                if (response.data.success === true) {
                    await getUserinfo()
                    setIsLoading(false)
                }
            })
            .catch((error) => {
                console.error(error)
                setIsLoading(false)
            })
    }

    return (
        <>
            <ModalProvider>
                <StyledModal isOpen={isOpen} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
                    {(6 - images?.length) === 0 ? specificImgIndex ? <Heading>Upload 1 photos</Heading> : <Heading>Great choices!</Heading> : <Heading>Upload {6 - images?.length} photos</Heading>}
                    {(6 - images?.length) === 0 ? specificImgIndex ? <Text>Adding photos is a great way to show off more about yourself! You can drag your photos right from your desktop.</Text> : <Text>You can move on if you’re happy with them.</Text> : <Text>Adding photos is a great way to show off more about yourself! You can drag your photos right from your desktop. </Text>}
                    {newImages?.length === 0 &&
                        <Wrapper>
                            <FileUploader onDraggingStateChange={onDraggingStateChange} classes='drop_area drop_zone' label='Drag and drop photos here' handleChange={handleFileChange} name="file" types={fileTypes} />
                        </Wrapper>
                    }
                    <ImageCenter>
                        {newImages.map((img, index) => {
                            return (
                                <Cover isloading={(index === (newImages.length - 1)) && isloading}>
                                    <Image isloading={(index === (newImages.length - 1)) && isloading} key={img} src={img} alt="img" />
                                    {((index === (newImages.length - 1)) && isloading) && <div class="dots-loading"></div>}
                                </Cover>
                            )
                        })}
                        {(newImages.length > 0 && (6 - images?.length) !== 0 && !isloading) &&
                            <ImageUploadWraper htmlFor="block-file-upload_1">
                                <FileInput type="file" id="block-file-upload_1" multiple={true} onChange={handleChange} />
                                <Upload>
                                    <svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
                                    </svg>
                                </Upload>
                            </ImageUploadWraper>
                        }
                    </ImageCenter>
                    {((6 - images?.length) !== 0 || specificImgIndex) ?
                        <SocialIcons>
                            <Form>

                                <UploadButton htmlFor="block-file-uploade_2">
                                    <Toltip>Upload from computer</Toltip>
                                    <FileInput type="file" id="block-file-uploade_2" multiple={true} onChange={handleChange} />
                                    <svg style={{ marginLeft: '1rem' }} height="60px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 0C7.163 0 0 7.164 0 16c0 8.837 7.163 16 16 16 8.836 0 16-7.163 16-16 0-8.836-7.164-16-16-16z" fill={palette.hover_color}></path>
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M20.219 10.116a.333.333 0 00-.333-.333h-7.772a.333.333 0 100 .666h7.772c.184 0 .333-.15.333-.333zm.777 1a.333.333 0 110 .665h-9.992a.333.333 0 110-.666h9.992zm.307 1.331c.505 0 .914.41.914.915v7.94c0 .506-.41.915-.914.915H10.697a.914.914 0 01-.914-.914v-7.941c0-.505.41-.915.914-.915h10.606zm0 .915H10.697v4.881l.214-.208.344-.344a277.24 277.24 0 001.655-1.699.457.457 0 01.652-.005l2.447 2.447.946-.946a.457.457 0 01.646 0l.51.51.068-.069a.457.457 0 01.646 0l2.478 2.477v-7.044zm-1.306 1.972a.666.666 0 10-1.332 0 .666.666 0 001.332 0z"
                                            fill="#fff"></path>
                                    </svg>
                                </UploadButton>
                            </Form>
                        </SocialIcons> : ''
                    }
                    {newImages?.length > 0 ? <Button disabled={isloading} onClick={onClose}>Continue</Button> : ''}
                </StyledModal>
            </ModalProvider>
        </>
    )
}

const StyledModal = Modal.styled`
width: 90%;
    min-height: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap:1rem;
    padding: 2rem 1rem;
    background-color: ${palette.black};
    border: 1px solid ${palette.yellow_border};
    ${media.md`
        width: 744px;
        padding: 2rem 6rem;
    `}
`

const Heading = styled.div`
    font-size: 24px;
    color: ${palette.yellow_border};
    text-align: center;
    font-weight: 500;
    line-height: 2rem;
`

const Text = styled.div`
    font-size: 16px;
    color: ${palette.text};
    text-align: center;
    font-weight: 500;
    line-height: 1.5rem;
`

const Button = styled.button`
	padding: 0.7rem 1.25rem;
	border-radius: 25px;
	color: white;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
    font-size: 1rem;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

const SocialIcons = styled.div`
	display: flex;
	justify-content: center;
	gap: 2rem;
`

const Form = styled.div`
	width: 360px;
	max-width: 100%;
	text-align: center;
	position: relative;
	display: block;
	margin: auto;
`

const Wrapper = styled.div`
	border: 1px solid ${palette.hover_color};
    padding: 4px;
    border-radius: 16px;
    & label{
        ${media.xs`
       width:250px;
       min-width: auto !important;
	   max-width: auto !important;
        `}
        ${media.md`
        min-width: 100%;
        max-width: 500px;
        `}
    }
    & label svg{
        display: none !important;
    }
    & label div{
        justify-content: center !important;
        border: none !important;
        opacity: 1 !important;
        border-radius: 16px !important;
        background-color: ${palette.black} !important;
    }
    & label div span.file-types{
        display: none !important;
    }
    & label div span{
       color: ${palette.yellow_border};
       font-weight: 400 !important;
       text-decoration: none !important;
    }
`

const FileInput = styled.input`
	display: none;
`

const Upload = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	border-radius: 15px;
	background: ${palette.black};
	cursor: pointer;
    & svg{
        opacity: 0.7;
        color: ${palette.yellow_border};
    }
    &:hover svg{
        opacity: 1;
    }
    &:hover{
        background: ${palette.yellow_border};
    }
    &:hover svg{
        color: ${palette.black};
    }
`

const ImageUploadWraper = styled.label`
	width: 7rem;
	height: 7rem;
	padding: 0.3rem;
	border-radius: 15px;
    display: flex;
	justify-content:center;
	align-items:center;
	border: 1px solid ${palette.yellow_border};
	background: black;
	cursor: pointer;
`

const ImageCenter = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 1rem;
`

const Cover = styled.div`
    position: relative;
    border-radius: 15px;
    border: ${({ isloading }) => isloading ? `1px solid ${palette.hover_color};` : `1px solid ${palette.hover_color};`};
    padding: ${({ isloading }) => isloading ? '0.3rem' : 'none'};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
	width: ${({ isloading }) => isloading ? '100.41px' : '112px'};
	height: ${({ isloading }) => isloading ? '100.41px' : '112px'};
	border-radius: 15px;
    filter: ${({ isloading }) => isloading ? 'blur(1px)' : ''};
    opacity: ${({ isloading }) => isloading ? '0.7' : ''};
`

const Toltip = styled.div`
    display: none;
	min-width:9rem;
    color: white;
    background: ${palette.yellow_border};
    padding: 0.2rem 0.5rem;
    font-size: 12px;
    position: absolute;
    top:-4.8rem;
    right:0;
    left:-35%;
    margin: auto;
    border-radius: 0.5rem;
    &::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 47%;
        border-width: 5px;
        border-style: solid;
        border-color: ${palette.yellow_border} transparent transparent transparent;
    }

`

const UploadButton = styled.label`
	cursor: pointer;
	font-size: 1rem;
	border: none;
	background-color: transparent;
    position: relative;
    &:hover ${Toltip} {
        display: block;
	}
`

export default ProfileImageUploadModal
