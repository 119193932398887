import React, { useState, useEffect, useRef } from 'react'
import { WeavyClient, WeavyProvider, MessengerProvider, Conversation } from '@weavy/uikit-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Flexed } from '../styled/shared'
import { palette } from '../styled/colors'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdOutlineClose } from 'react-icons/md'
import 'react-circular-progressbar/dist/styles.css'
import ChatSideProfile from '../components/chat/ChatSideProfile'
import { addUserInChat, getSpecficUserInfo, getUser, reportUser, unLikeProfile } from '../apis/apis'
import { useLocation, useNavigate } from 'react-router-dom'
import { appContentReload } from '../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { IoSend } from 'react-icons/io5'
import { media } from 'styled-bootstrap-grid'
import axios from 'axios'
import { toast } from 'react-toastify'
import { toastError } from '../styled/toastStyle'
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'

const WeavyChat = () => {
    const environment = process.env.REACT_APP_PUBLIC_WEAVY_URL
    const apiKey = process.env.REACT_APP_PUBLIC_WEAVY_APIKEY
    let clientRef = useRef(null)
    const [conversactionId, setConversationId] = useState(null)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const [openSideProfile, setOpenSiddeProfile] = useState(false)
    const [openActionDrop, setOpenActionDrop] = useState(false)
    const _ref = useRef(null)

    const [mode, setMode] = useState('')
    const [loginUser, setLoginUser] = useState('')
    const _navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const appContentReloads = useSelector((state) => state.auth.appContentReloads)
    const [domyToken, setDomeToken] = useState('')
    const [user, setUser] = useState([])
    const [openUnMatchModel, setOpenUnMatchModal] = useState(false)
    const [saveReason, setSaveReason] = useState({ id: null, reason: '' })
    const [loginUserDetails, setLoginUserDetails] = useState('')
    const JobModal = (e) => { setOpenUnMatchModal(!openUnMatchModel) }

    const getUserinfo = async () => {
        const response = await getSpecficUserInfo(id)
        if (response.success) {
            setUser(response?.data?.data?.user)
        }
    }

    const currentUserInfo = async () => {
        let response = await getUser()
        if (response.success) {
            setLoginUser(response?.data?.data?.user)
            setMode(response?.data?.data?.user?.mode)
        }
    }

    useEffect(() => {
        getUserinfo()
        currentUserInfo()
    }, [id])

    useEffect(() => {
        if (loginUser?.id) {
            initializeWeavyApp()
        }
    }, [loginUser, id])


    const initializeWeavyApp = async () => {
        const name = loginUser?.first_name
        const headers = {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
        }

        const url = `${environment}/api/apps/init`

        const data = {
            app: {
                uid: loginUser?.id + 'a',
                name: name,
                type: 'chat',
                display_name: name
            },
            user: {
                uid: loginUser?.id + 'a',
                name: name,
                display_name: name
            }
        }

        try {
            setLoading(true)
            const response = await axios.post(url, data, { headers })
            let userUrl = `${environment}/api/users/${response.data?.uid}/tokens`

            const userResponse = await axios.post(
                userUrl,
                {
                    name: name,
                    expires_in: 7200
                },
                { headers }
            )

            await axios
                .put(
                    `${environment}/api/users/${response.data?.uid}`,
                    {
                        name: name,
                        phone_number: loginUser?.phone,
                        presence: 'active',
                    },
                    { headers }
                )
                .then((res) => {
                    setLoginUserDetails(res.data)
                })
                .catch((err) => {
                    setLoading(false)
                    if (err?.response?.data?.detail) {
                        toast.error(err?.response?.data?.detail)
                    }
                })
            const updateHeader = {
                Authorization: `Bearer ${userResponse.data.access_token}`,
                'Content-Type': 'application/json'
            }

            if (id) {
                const url = `${environment}/api/conversations`
                const headers = {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userResponse.data.access_token}`
                }
                let userDetails = {}
                try {
                    userDetails = await axios.get(`${environment}/api/users/${id}a`, { headers })
                } catch (error) {
                    let getUser = getSpecficUserInfo(id)
                    if (getUser) {
                        await addUserInChat(id, getUser)
                        userDetails = await axios.get(`${environment}/api/users/${id}a`, { headers })
                    }

                }

                let conversation = await axios
                    .post(`${environment}/api/conversations`, { members: [userDetails.data.id] }, { headers: updateHeader })
                    .then((res) => {
                        return res
                    })
                    .catch((err) => {
                        console.log('======err', err)
                        setLoading(false)
                    })
                setConversationId(conversation?.data?.id)
            }
            setDomeToken(userResponse.data.access_token)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toastError('Chat initializing failed')
            console.error('App initialization failed:', error)
        }
    }



    const reportUserinfo = async (id) => {
        await reportUser(id)
        dispatch(appContentReload(!appContentReloads))
        _navigate('/app')
    }

    useEffect(() => {
        if (domyToken && environment) {
            const client = new WeavyClient({
                url: environment,
                tokenFactory: async () => domyToken
            })
            clientRef.current = client

            // getNotifications()
        }
    }, [domyToken, environment])

    useEffect(() => {
        if (conversactionId) {
            getConversationMessage(conversactionId)
        }
    }, [conversactionId])

    let unMatchSeasonList = [
        {
            id: 1,
            icon: '/heart.svg',
            name: 'I’m just not interested'
        },
        {
            id: 2,
            icon: '/heart.svg',
            name: 'Using DFF to date'
        },
        {
            id: 3,
            icon: '/uncomfert.svg',
            name: 'Made me uncomfortable'
        },
        {
            id: 4,
            icon: '/chat.png',
            name: 'Abusive or threatening'
        },
        {
            id: 5,
            name: 'Inappropriate content',
            icon: '/warning.svg'
        },
        {
            id: 6,
            name: 'Spam or scam',
            icon: '/flag.png'
        },

        {
            id: 7,
            name: 'Stolen photo',
            icon: '/stolen-photo.svg'
        }
    ]

    const mybasic = async (id, reason) => {
        await unLikeProfile(id)
        setSaveReason({ id: null, reason: '' })
        JobModal()
        dispatch(appContentReload(!appContentReloads))
        _navigate('/app')
    }

    const getConversationMessage = async (id) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${domyToken}`
            }

            let response = await axios.get(`${environment}/api/apps/${id}/messages?orderby=createdat+asec&skip=0&top=200`, { headers })
            if (response.data?.data?.length) {
                let check = response.data?.data?.filter(res => res?.created_by?.display_name == loginUserDetails?.display_name)
                if (check?.length) {
                    let lastItem = check.pop();
                    user.last_message = lastItem.created_at
                    setUser(user)
                }

            } else if (response.data?.data) {

            }


        } catch (error) {
            console.error('Error fetching user details:=======', error)
        }
    }
    return (
        <>
            <Wrapper open={openSideProfile}>
                <ChatHeader>
                    <Profile
                        pointer
                        align="center"
                        gap={0.8}
                        margin="0.5rem 0rem 0.5rem 0rem"
                        onClick={() => {
                            if (conversactionId) {
                                getConversationMessage(conversactionId)
                            }

                            setOpenSiddeProfile(!openSideProfile)
                        }}>
                        <ChatUserProfile>{user?.images && user.images.length && <Img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user.images[0]}`} alt="" />}</ChatUserProfile>
                        <Flexed direction="column" gap={0.2}>
                            <UserName> {user?.first_name}</UserName>
                        </Flexed>
                    </Profile>
                    <Flexed gap={0.5}>
                        <Action
                            align="center"
                            ref={_ref}
                            onClick={() => {
                                setOpenActionDrop(!openActionDrop)
                            }}>
                            <Dots active={openActionDrop} fontSize="1.5rem" cursor="pointer" />
                            <DropDown active={openActionDrop}>
                                <DropList onClick={() => setOpenUnMatchModal(true)}>Unmatch</DropList>
                                <DropList onClick={() => reportUserinfo(user?.id)}>Block and report</DropList>
                            </DropDown>
                        </Action>
                        <Close to="/app">
                            <CloseIcon fontSize="1.9rem" cursor="pointer" />
                        </Close>
                    </Flexed>
                </ChatHeader>

                <ChatContent justify="space-between">
                    {domyToken && environment ? (
                        // <WeavyProvider client={clientRef.current}>
                        // <MessengerProvider>
                        <ChatProvider client={clientRef.current}>
                            <MessengerProvider>
                                <ChatWrapper>
                                    {/* <ChatList>
                                        <ConversationList />
                                    </ChatList> */}
                                    <ChatWindow>
                                        <Conversation id={conversactionId} />
                                    </ChatWindow>
                                </ChatWrapper>
                            </MessengerProvider>
                        </ChatProvider>
                    ) : (
                        <div></div>
                    )}
                </ChatContent>
            </Wrapper>
            <ChatSideProfile
                user={user}
                currentUserMode={mode}
                open={openSideProfile}

                close={() => {
                    if (conversactionId) {
                        getConversationMessage(conversactionId)
                    }
                    setOpenSiddeProfile(!openSideProfile)
                }}
                conversactionId={conversactionId}
            />
            <ModalProvider backgroundComponent={CustomReportModalBackground}>
                <div>
                    <StyledModal id="modelWrap" isOpen={openUnMatchModel} onBackgroundClick={JobModal} onEscapeKeydown={JobModal}>
                        <div>
                            <ModelHeading>Everything OK?</ModelHeading>
                            <ModelDescription>Help us keep the Hive safe by telling us if there’s a reason why you’ve unmatched. Don’t worry, this is anonymous.</ModelDescription>
                            {unMatchSeasonList.map((text) => (
                                <ChooseList active={saveReason.reason == text?.name}>
                                    <Flexed gap={0.5} align='start'>
                                        <ModelIcons src={text.icon} alt={text?.name} />
                                        <Span onClick={() => setSaveReason({ id: text?.id, reason: text?.name })}>{text?.name}</Span>
                                    </Flexed>
                                </ChooseList>
                            ))}
                            <ButtonDiv>
                                <ContinueButton opacity={user.id && saveReason.reason ? 1 : 0.5} onClick={() => user?.id && saveReason.reason && mybasic(user?.id, saveReason?.reason)}>Continue</ContinueButton>
                            </ButtonDiv>
                        </div>

                    </StyledModal>
                </div>
            </ModalProvider>
            {loading && <Loading ><div class="dots-loading"></div></Loading>}
        </>
    )
}

const Wrapper = styled.div`
width: ${({ open }) => (open ? '100%' : '100%')};
overflow: scroll;
height: 100vh;
${media.lg`
	width: ${({ open }) => (open ? 'calc(100% - 702px)' : 'calc(100% - 400px)')};
`}
background: ${palette.black};
	transition: all 0.3s;
    position: relative;
    top: 4.46rem;
`

const ChatHeader = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 72px;
	// border-bottom: 1px solid lightgray;
	padding: 0.5rem 1rem;
	background: ${palette.black};
    // box-shadow:${palette.hover_color} 0px 0px 8px 0.5px !important ;
    border-bottom: 1px solid ${palette.yellow_border};
	z-index: 3;
`

const UserName = styled.div`
	color: ${palette.white};
	font-size: 20px;
	font-weight: 500;
`

const Profile = styled(Flexed)`
	&:hover ${UserName} {
		color: ${palette.hover_color};
	}
`

const ChatUserProfile = styled.div`
	width: 52px;
	height: 52px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const Img = styled.img`
	width: 52px;
	height: 52px;
	border-radius: 100%;
`

const Action = styled(Flexed)`
	position: relative;
`
const Close = styled(Link)`
	display: flex;
	align-items: center;
`

const DropDown = styled.div`
	width: 145px;
	height: auto;
	display: ${({ active }) => (active ? 'block' : 'none')};
	position: absolute;
	z-index: 10;
	top: 2.5rem;
	right: 0;
	overflow: hidden;
	border: 1px solid ${palette.yellow_border};
	border-radius: 16px;
    background: ${palette.black};
    color: ${palette.white};
	padding: 8px 0;
`

const DropList = styled.span`
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 1.429;
	color: ${({ active }) => (active ? palette.yellow_theme : palette.white)};
	&:hover {
		background: ${palette.yellow_gradient};
		cursor: pointer;
	}
`

const Dots = styled(BsThreeDotsVertical)`
    color: ${palette.gray};
    color: ${({ active }) => (active ? palette.yellow_border  : palette.white)};
    &:hover{
        color: palette.yellow_border;
    }
`

const CloseIcon = styled(MdOutlineClose)`
    color: ${palette.white};
    &:hover{
        color: palette.yellow_border;
    }
`

const ChatContent = styled(Flexed)`
    padding: 0;
	height: calc(100vh - 75px);

    & > div:first-child{
        width: 100%;
    }
`

const ChatProfile = styled.div`
	width: 188px;
	height: 188px;
	position: relative;
	cursor: pointer;
	& img {
		width: 170px;
		height: 170px;
		border: 4px solid ${palette.white};
		border-radius: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
`

const Heading = styled.div`
	margin-top: 20px;
	color: ${palette.dark_gray};
	font-size: 22px;
	font-weight: 500;
`

const Text = styled.div`
	margin-top: 20px;
	color: ${palette.dark_gray};
	font-size: 16px;
	font-weight: 500;
	line-height: 1.6;
	text-align: center;
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: ${palette.sea_green};
	margin: auto;
	padding: 0.7rem 1rem;
	width: 105px;
	color: ${palette.white};
	border-radius: 2rem;
	border: none;
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-size: 1rem;
	letter-spacing: 0.02em;
`

const Send = styled(IoSend)`
	font-size: 1.4rem;
	cursor: pointer;
	color: ${({ value }) => (value ? palette.Btn_dark_green : palette.silver)};
`

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`

const Icon = styled(Flexed)`
	position: absolute;
	right: 0.4rem;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: 1;
	${media.xs`bottom:-6rem;`}
`

const SendMessageButton = styled.svg`
	color: ${({ active }) => active ? palette.yellow_theme : palette.light_gray};
	cursor: ${({ active }) => active ? 'pointer' : ''};
	pointer-events: ${({ active }) => (active ? 'default' : 'none')};
	height: 40px;
`


const ChatProvider = styled(WeavyProvider)`
	width: 100%;
`

const ChatWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;

	@media screen and (min-width: 0px) and (max-width: 767px) {
		flex-direction: column-reverse;
	}
`

const ChatList = styled.div`
	width: 18rem;
    background: ${palette.default_theme};
	@media screen and (min-width: 0px) and (max-width: 767px) {
		width: 100%;
	}
`

const ChatWindow = styled.div`
	// width: calc(100% - 19rem);
    width: 100%;
	overflow: hidden;
    & > .wy-appbars{
        background: black;
        color: white;
        box-shadow: none;
        height: 54.4px;
        border-bottom: none;
    }
    & > .wy-appbar-text{
        color: white !important;
    }
    & > .wy-pane-body{
        height: calc(100vh - 75px);
    }
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

const StyledModal = Modal.styled`
  width: 90%;
  /* height: 18rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  ${palette.black};
  padding: 2rem;
  border-radius: 0.5rem;
  position: relative;
  border: 1px solid ${palette.yellow_border};
  ${media.md`
	width: 30rem;
    `}
  ${media.lg`
		left: -12rem;
	`}
`

const ModelHeading = styled.h2`
	text-align: center;
	font-weight: 400;
    color: ${palette.yellow_border};
`

const ModelDescription = styled.span`
	margin: 1rem;
	text-align: center;
	display: flex;
	justify-content: center;
	opacity: 0.8;
    line-height: 1.5;
    color: ${palette.text};
`

const ChooseList = styled.div`
	position: relative;
	display: flex;
	/* justify-content: center; */
	border-radius: 40px;
	cursor: pointer;
	padding: 0.65rem 1.5rem;
	/* width: 20rem; */
	border: 1px solid ${palette.yellow_border};
    background: ${({ active }) => active ? palette.yellow_gradient : palette.black};
    color: ${palette.white};
	text-align: center;
	margin-top: 0.6rem;
	overflow: scroll;
	text-align: center;
	align-items: center;
	&:hover{
        background: ${palette.yellow_gradient};
    }
`

const ButtonDiv = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`

const ModelIcons = styled.img`
	width: 1rem;
	height: 1rem;
`

const Span = styled.p`
	font-size: 16px;
	text-align: center;

`

const ContinueButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
	min-width: 40px;
	min-height: 40px;
	height: 40px;
	padding: 5px 20px;
	border-radius: calc(40px / 2);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	opacity: ${({ opacity }) => opacity ? opacity : 0.3};
	background: ${palette.btn_gradient};
	border: none;
	color: white;
	font-size: 1rem;
	:hover{
		background:${palette.btn_hover_gradient};
    text-decoration: none;
	}
`

const CustomReportModalBackground = styled(BaseModalBackground)`
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(10px);
    ${media.lg`
	left: 25rem;
    `}
`;

export default WeavyChat
