import React from 'react'
import styled from 'styled-components'

const Loader = () => {
	return (
		<Loading ><div class="dots-loading"></div></Loading>
	)
}

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
`
export default Loader