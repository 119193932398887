import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import './setting.css'
import { useCollapse } from 'react-collapsed'
import { ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import NotificationSettings from './NotificationSettings/NotificationSettings'
import StickyHeader from '../StickyHeader/StickyHeader'
import { addSnozeData, deleteUserAccount, getAdvanceFilterAffMode, getAdvanceFilterDateMode, getAffAdvanceFilterData, getDateAdvanceFilter, getUser } from '../../apis/apis'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Flexed, Spacer, CustomSwitch, Text } from '../../styled/shared'
import { palette } from '../../styled/colors'
import { IoIosArrowForward } from "react-icons/io";
import RangeSliderCustom from '../Common/RangeSlider'
import AdvanceFilters from './AdvanceFilters'
import { CommonEnum } from '../../utils'
import { setUserDetails } from '../../redux/actions/authActions'
import { useDispatch } from 'react-redux'
import { media } from 'styled-bootstrap-grid'

const Settings = () => {
	const _ref = useRef(null)
	const _dispatch = useDispatch()
	const [isOpen, setIsOpen] = useState(false)
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const _navigate = useNavigate()
	const JobModal = (e) => setIsOpen(!isOpen)
	const [applyFilters, setApplyFilters] = useState(false)
	const [verifiedOnly, setVerifiedOnly] = useState(false)
	const [verifiedOnlyAff, setVerifiedOnlyAff] = useState(false)
	const [aff, setAff] = useState(false)
	const [date, setDate] = useState(false)
	const [ischooseModOpen, setIschooseModOpen] = useState(false)
	const [snozeCheckBox, setSnozeCheckBox] = useState(false)
	const [autoSpotLight, setAutoSpotLight] = useState(false)
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [location, setLocation] = useState('')
	const [recall, setRecall] = useState(false)
	const [time, setTime] = useState('')
	const [indefinitelyWithAwayStatus, setIndefinitelyWithAwayStatus] = useState(false)
	const [reasonSnoze, setReasonSnooze] = useState(false)
	const [snozeDataInfo, setSnozeDataInfo] = useState([])
	const [mode, setMode] = useState('')
	const [advanceFilterInfo, setAdvanceFilterInfo] = useState([])
	const [user, setUser] = useState([])
	const getFilterData = (data) => data
	const [interestFilter, setInterestFilter] = useState([])
	const [ageFrom, setAgeFrom] = useState(18)
	const [ageTo, setAgeTo] = useState(80)
	const [language, setLanguage] = useState('English (United States)');
	const [openLanguageDrop, setOpenLanguageDrop] = useState(false)
	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
	const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false)
	const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
	const [locationLoading, setLocationLoading] = useState(false)
	const [isMianloading, setIsMianloading] = useState(false);

	const getUserInfo = async () => {
		setIsMianloading(true)
		let response = await getUser()
		setInterestFilter(response?.data?.data?.user?.dete_gender);
		setUser(response?.data?.data?.user)
		setAgeFrom(response?.data?.data?.user?.ageFrom)
		setAgeTo(response?.data?.data?.user?.ageTo)
		setMode(response?.data?.data?.user?.mode)
		setAutoSpotLight(response?.data?.data?.user?.auto_spotlight)
		setSnozeDataInfo(response?.data?.data?.user?.snoze_setting)
		setVerifiedOnly(response?.data?.data?.user?.filters?.is_verified_profile)
		if (response?.data?.data?.user?.mode === 'aff') {
			advanceFilterAffMode()
		} else {
			advanceFilterDateModeData()
		}

		if (response?.data?.data?.user?.mode === 'aff') {
			setAff(true)
		} else {
			setAff(false)
		}
		if (response?.data?.data?.user?.mode === 'date') {
			setDate(true)
		} else {
			setDate(false)
		}
		if (response?.data?.data?.user?.snoze_setting?.length > 0) {
			setSnozeCheckBox(true)
		}
		setIsMianloading(false)
	}

	const advanceFilterAffMode = async () => {
		let response = await getAdvanceFilterAffMode()
		let arr = []
		const affAdvanceFilter = await getAffAdvanceFilterData()
		response.data.data?.map((res) => {
			if (res?.title === 'Relationship') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.relation_ship })
			} else if (res?.title === 'Drinking') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.drinking })
			} else if (res?.title === 'Smoking') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.smoking })
			} else if (res?.title === 'New To Area') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.new_to_area })
			} else if (res?.title === 'Have Kids') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.have_kids })
			} else if (res?.title === 'Star sign') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.star_sign })
			} else if (res?.title === 'Exercise') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.exercise })
			} else if (res?.title === 'Religion') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.religion })
			} else if (res?.title === 'Looking for') {
				arr.push({ ...res, selected: affAdvanceFilter?.data?.data?.data?.looking_for_aff })
			} else {
				arr.push(res)
			}
		})
		setAdvanceFilterInfo(arr)
	}

	const advanceFilterDateModeData = async () => {
		let response = await getAdvanceFilterDateMode()
		let filterDate = await getDateAdvanceFilter()
		let arr = []
		response?.data?.data?.map((res) => {
			if (res?.title === 'Exercise') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.exercise })
			}
			else if (res?.title === 'Education level') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.education_level })
			} else if (res?.title === 'Drinking') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.drink })
			} else if (res?.title === 'Smoking') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.smoke })
			} else if (res?.title === 'Looking for') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.looking_for_data })
			} else if (res?.title === 'Kids') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.kid })
			} else if (res?.title === 'Star sign') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.star_sign })
			} else if (res?.title === 'Politics') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.politics })
			} else if (res?.title === 'Religion') {
				arr.push({ ...res, selected: filterDate?.data?.data?.data?.religion })
			} else if (res?.title === 'Height') {
				if (filterDate?.data?.data?.data?.min_height && filterDate?.data?.data?.data?.min_height) {
					arr.push({ ...res, selected: filterDate?.data?.data?.data?.min_height + ' ' + filterDate?.data?.data?.data?.max_height })
				}
				else {
					arr.push({ ...res, selected: 100 + ' ' + 220 })
				}
			} else {
				arr.push(res)
			}
		})
		setAdvanceFilterInfo(arr)
	}
	useEffect(() => {
		getUserInfo()
	}, [recall])

	const snoozeData = ['24 hours', '72 hours', 'A week', 'Indefinitely']
	const reason = ["✈️ I’m travelling", "📝 I’m focused on work", "🔌 I’m on a digital detox", "❤️ I’m prioritising myself", "🚫 No thanks"]

	const doSaveMode = async () => {
		setIsMianloading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					mode: date ? 'date' : 'aff',
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					setIsOpen(false)
					getUserInfo()
					setIsMianloading(false)
				}
			})
			.catch((error) => {
				setIsMianloading(false)
				console.error(error)
			})
	}

	const isActive = (info, index) => {
		// advanceFilter[index].subMenuValue.push(info)
	}


	const logout = () => {
		sessionStorage.removeItem('authorization')
		_dispatch(setUserDetails())
		_navigate('/')
	}

	const getCurrentLatLng = async () => {
		navigator.geolocation.getCurrentPosition(
			async (position) => {
				setLocationLoading(true)
				setLat(position.coords.latitude)
				setLng(position.coords.longitude)
				await getCurrentAddress(position.coords.latitude, position.coords.longitude)
				setLocationLoading(false)
			},
			function (error) {
				// eslint-disable-next-line eqeqeq
				if (error.code == error.PERMISSION_DENIED) {
					setIsLocationModalOpen(true);
					Timer()
				} else {
				}
			},
			{ timeout: 5000, enableHighAccuracy: true }
		)
	}

	const getCurrentAddress = async (lat, lng) => {
		await axios
			.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(lat)},${parseFloat(lng)}&sensor=true&key=${process.env.REACT_APP_MAP_API_KEY}`)
			.then((response) => {
				setLocation(response.data.results[6].formatted_address)
				doSaveLocation(response.data.results[6].formatted_address, lat, lng)
			})
			.catch((error) => {
				setLocation('')
			})
	}

	const doSaveLocation = async (location, lat, lng) => {
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					location: location,
					lat: lat,
					lng: lng
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
				}
			})
			.catch((error) => {
				console.error(error)
			})
		await getUserInfo()
	}

	const deleteUserAccountInfo = async () => {
		await deleteUserAccount(user?.id)
		sessionStorage.removeItem('authorization')
		_dispatch(setUserDetails())
		_navigate('/')
	}

	const saveFilter = async (gander) => {
		let _gender_list = []
		if (gander === 'Men') {
			_gender_list.push('Man')
			setInterestFilter(_gender_list)
		}
		if (gander === 'Women') {
			_gender_list.push('Woman')
			setInterestFilter(_gender_list)
		}
		if (gander === 'Everyone') {
			_gender_list.push('Man', 'Woman', 'Nonbinary')
			setInterestFilter(_gender_list)
		}
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					ageFrom: ageFrom,
					ageTo: ageTo,
					dete_gender: _gender_list
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}
	console.log(interestFilter, interestFilter.length)

	const addTimeInSnooze = (title) => {
		setTime(title)
		setReasonSnooze(true)
	}

	const addReasonInSnooze = async (reasonSnoze) => {
		setReasonSnooze(false)
		setIschooseModOpen(false)
		await addSnozeData(time, reasonSnoze)
		setSnozeCheckBox(true)
		getUserInfo()
	}

	const deleteSnoze = async () => {
		if (snozeCheckBox) {
			let user = await getUser()
			let snozedata = user.data.data.user.snoze_setting
			if (snozedata.length > 0) {
				await addSnozeData()
				setSnozeCheckBox(false)
			}
			getUserInfo()
		}
	}

	const chooseMod = async (e) => {
		setIschooseModOpen(!ischooseModOpen)
		setTime('')
		setReasonSnooze(false)
		setIndefinitelyWithAwayStatus(false)
	}

	const logoutModalToggle = async (e) => {
		setIsLogoutModalOpen(!isLogoutModalOpen)
	}

	const deleteAccountModalToggle = async (e) => {
		setIsDeleteAccountModalOpen(!isDeleteAccountModalOpen)
	}

	const spotLightHandler = async (e) => {
		setAutoSpotLight(e.target.checked)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}

		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					auto_spotlight: e.target.checked
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const handleCheckboxChange = async () => {
		setVerifiedOnly(!verifiedOnly)
		setVerifiedOnlyAff(!verifiedOnlyAff)
		if (mode === 'date') {
			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}

			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_date_filter_advance`,
					{
						verified_profile: !verifiedOnly,
						is_verified_profile: !verifiedOnly,
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		} else {
			const _token = sessionStorage.getItem('authorization')
			const config = {
				headers: { authorization: `bearer ${_token}` }
			}
			await axios
				.post(
					`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_aff_filter_advance`,
					{
						verified_profile: !verifiedOnlyAff
					},
					config
				)
				.then((response) => {
					if (response.data.success === true) {
					}
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}

	useEffect(() => {
		function handleClickOutside(event) {
			if (_ref.current && !_ref.current.contains(event.target)) {
				setOpenLanguageDrop(false)
			}
		}

		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const Timer = () => {
		setTimeout(autoHideLocationModal, 10000);
	}

	const autoHideLocationModal = () => {
		setIsLocationModalOpen(false);
	}

	return (
		<>
			<MainParent>
				<StickyHeader headerText={'Settings'} />
				<Content>
					<Choose onClick={JobModal}>
						<div>Choose mode</div>
						<Flexed gap={1}>
							<Text textTransform='capitalize' type='normal' color='white'>{user?.mode == 'aff' ? 'DFF' : 'Date'}</Text>
							<RightArrow />
						</Flexed>
					</Choose>

					<Choose active={snozeCheckBox}>
						<SnoozAction onClick={() => { snozeCheckBox ? deleteSnoze() : chooseMod() }} />
						<div>Snooze</div>
						<CustomSwitch checked={snozeCheckBox}>
							<input type="checkbox" checked={snozeCheckBox} />
							<div></div>
						</CustomSwitch>
					</Choose>
					{snozeDataInfo?.length === 0 ? <SnoozInfo type='xsmall' color='text'>Turning this on will hide you from all of {CommonEnum.AppName}'s modes for as long as you choose. Your existing matches will remain accessible.</SnoozInfo> : <SnoozInfo type='xsmall' color='text'>{`You are invisible ${snozeDataInfo[0]?.time === 'A week' ? 'for another 7 days' : snozeDataInfo[0]?.time === '72 hours' ? 'for another 3 days' : (snozeDataInfo[0]?.time === 'Indefinitely' && snozeDataInfo[0]?.reason !== 'indefinitelyNoReason') ? 'for another 7 days' : (snozeDataInfo[0]?.time === 'Indefinitely' && snozeDataInfo[0]?.reason === 'indefinitelyNoReason' ? 'until you deactivate invisible mode' : `for another ${snozeDataInfo[0]?.time}`)}. ${(snozeDataInfo[0]?.reason === '🚫 No thanks' || snozeDataInfo[0]?.reason === 'indefinitelyNoReason') ? 'Your existing matches will remain accessible.' : `You set your away status to “${snozeDataInfo[0]?.reason}”.`}`}</SnoozInfo>}

					<Spacer height={0.5} />

					<Box isExpanded={isExpanded} {...getToggleProps({
						onClick: () => { !isExpanded && setExpanded(!isExpanded) },
					})}>
						<BoxHeader justify='space-between' align='center' {...getToggleProps({
							onClick: () => setExpanded((prevExpanded) => !prevExpanded),
						})}>
							<div>
								<Heading>Filter</Heading>
							</div>
							<DownArrow active={isExpanded} />
						</BoxHeader>
						<InnerContent  {...getCollapseProps()}>
							<Spacer height={1.5} />
							{mode === 'date' && (<>
								<Text type='normal' margin='0rem 0rem 0.75rem 0rem' color='text'>I’m interested in...</Text>
								<InterestedInWrapper>
									<InterestedInLabel active={interestFilter?.includes('Woman') && interestFilter?.length === 1} onClick={() => { saveFilter('Women'); }}>Women</InterestedInLabel>
									<InterestedInLabel active={(interestFilter?.includes('Man') || interestFilter?.includes('Nonbinary')) && interestFilter?.length === 1} onClick={() => { saveFilter('Men'); }}>Men</InterestedInLabel>
									<InterestedInLabel active={(interestFilter?.includes('Woman') || interestFilter?.includes('Man') || interestFilter?.includes('Nonbinary')) && interestFilter?.length > 1} onClick={() => { saveFilter('Everyone'); }}>Everyone</InterestedInLabel>
								</InterestedInWrapper>
								<Spacer height={1} />
							</>)
							}

							<AgeHeading>Age</AgeHeading>
							{isExpanded && <SliderWraper>
								<RangeSliderCustom valueFrom={ageFrom} setValueFrom={setAgeFrom} valueTo={ageTo} setValueTo={setAgeTo} saveFilter={() => saveFilter(interestFilter)} />
							</SliderWraper>}
						</InnerContent>
					</Box>

					<AdvanceFilters mode={mode} applyFilters={applyFilters} setApplyFilters={setApplyFilters} verifiedOnly={verifiedOnly} handleCheckboxChange={handleCheckboxChange} verifiedOnlyAff={verifiedOnlyAff} advanceFilterInfo={advanceFilterInfo} isActive={isActive} getFilterData={getFilterData} setRecall={setRecall} recall={recall} />

					<NotificationSettings />

					<MarginTop>
						<Heading>Locations</Heading>
						<LocationChoose onClick={getCurrentLatLng}>
							<CustomDiv>{user?.location}</CustomDiv>

							{!locationLoading ?
								<Refresh data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M26.667 2.5a1.5 1.5 0 00-1.494 1.356L25.168 4v3.919l-.843-.795A12.153 12.153 0 003.834 16a12.167 12.167 0 0023.487 4.47 1.506 1.506 0 10-2.803-1.1 9.167 9.167 0 11-2.107-9.923l.203.206.81.846h-4.757a1.5 1.5 0 00-.145 2.993l.145.007h8c.781 0 1.42-.593 1.493-1.355l.007-.145V4a1.5 1.5 0 00-1.5-1.5z" fill="currentColor"></path></Refresh>
								:
								<LocationLoading className="gray-dots-loading-small"></LocationLoading>
							}
						</LocationChoose>
					</MarginTop>

					<MarginTop>
						<Heading>Languages</Heading>
						<LanguageChoose active={openLanguageDrop} ref={_ref} onClick={() => {
							setOpenLanguageDrop(!openLanguageDrop)
						}}>
							<CustomDiv>
								{language}
							</CustomDiv>
							<DownArrow />
							<DropDown active={openLanguageDrop}>
								<DropList
									active={language === 'English (United States)'}
									onClick={() => {
										setLanguage('English (United States)')
									}}>
									English (United States)
								</DropList>
								<DropList
									active={language === 'Deutsch'}
									onClick={() => {
										setLanguage('Deutsch')
									}}>
									Deutsch
								</DropList>
								<DropList
									active={language === 'Français'}
									onClick={() => {
										setLanguage('Français')
									}}>
									Français
								</DropList>
								<DropList
									active={language === 'Français (Canada)'}
									onClick={() => {
										setLanguage('Français (Canada)')
									}}>
									Français (Canada)
								</DropList>
								<DropList
									active={language === 'Español (España)'}
									onClick={() => {
										setLanguage('Español (España)')
									}}>
									Español (España)
								</DropList>
								<DropList
									active={language === 'Italiano'}
									onClick={() => {
										setLanguage('Italiano')
									}}>
									Italiano
								</DropList>
							</DropDown>
						</LanguageChoose>
					</MarginTop>

					<MarginTop>
						<Choose onClick={() => { setIsLogoutModalOpen(true) }}>
							<div>
								Log out
							</div>
						</Choose>

						<Choose onClick={() => { setIsDeleteAccountModalOpen(true) }}>
							<div>
								Delete Account
							</div>
						</Choose>
					</MarginTop>
				</Content>

				<Footer>
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: palette.hover_color, fontSize: '2rem' }}><img src={CommonEnum.logo} alt={CommonEnum.AppName} height={'10%'} width={'5%'} style={{ marginRight: '0.5rem' }} /> {CommonEnum.AppName}</div>

					<FooterText>Created with 💛</FooterText>
				</Footer>
			</MainParent>

			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={isOpen} onBackgroundClick={JobModal} onEscapeKeydown={JobModal}>
					<div>
						<ModelHeading>Choose mode</ModelHeading>

						<Text isCentered type='normal' color='text' margin='0.5rem 0rem' >
							{CommonEnum.AppName}'s for making all kinds of connections! You’ll be able to switch <br /> modes once you’re all set up.
						</Text>


						<DateBox active={date} onClick={() => { setDate(true); setAff(false) }}>
							<DateInfo for="click">
								<p>
									date
								</p>
								<Text margin='0.5rem 0rem 0rem 0rem' type='normal'>Find that spark in an empowered community</Text>
							</DateInfo>

							<div id="click">
								{date ?
									<Check data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z" fill="currentColor"></path></Check>
									:
									<UnCheck data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></UnCheck>
								}
							</div>
						</DateBox>

						<AffBox active={aff} onClick={() => { setAff(true); setDate(false) }}>
							<DateInfo>
								<p >dff</p>
								<Text margin='0.5rem 0rem 0rem 0rem' type='normal'>Make new friends at every stage of your life</Text>
							</DateInfo>
							<div id="click">
								{aff ?
									<Check data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z" fill="currentColor"></path></Check>
									:
									<UnCheck data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></UnCheck>
								}
							</div>
						</AffBox>

						<Flexed justify='center' margin='1.5rem 0rem 0rem 0rem'>
							<Button onClick={doSaveMode}>Continue with {date ? 'Date' : 'dff'}</Button>
						</Flexed>

					</div>
				</StyledModal>
			</ModalProvider>
			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={ischooseModOpen} onBackgroundClick={chooseMod} onEscapeKeydown={chooseMod}>
					<div>
						{(time !== 'Indefinitely') ?
							<>
								<ModelHeading>Snooze</ModelHeading>

								{!reasonSnoze ? <Text isCentered type='normal' color='text' margin='0.5rem 0rem 1rem 0rem'> How long do you want to be invisible for? </Text>
									:
									<Text isCentered type='normal' color='text' margin='0.5rem 0rem 1rem 0rem'> Do you want to set a status for your existing matches <br /> while you’re away? </Text>
								}

								{!reasonSnoze ? <>
									{snoozeData?.map((elm) => (
										<SnoozeBox onClick={() => addTimeInSnooze(elm)}>{elm}</SnoozeBox>
									))}
								</>
									:
									<>{reason?.map((elm) => (
										<SnoozeBox onClick={() => addReasonInSnooze(elm)}>{elm}</SnoozeBox>
									))}
									</>
								}
							</>
							:
							<>
								{indefinitelyWithAwayStatus === false ?
									<>
										<ModelHeading>Why not start with a week?</ModelHeading>
										<Text isCentered type='normal' color='text'>If you select a week or less you can also set an away status</Text>
										<Flexed direction='column' align='center' margin='2rem 0rem 0rem 0rem' gap={1} >
											<CustomButton onClick={() => setIndefinitelyWithAwayStatus(true)}>OK</CustomButton>
											<CancelButton onClick={() => { addReasonInSnooze('indefinitelyNoReason') }}>No thanks</CancelButton>
										</Flexed>
									</>
									:
									<>
										<ModelHeading>Snooze</ModelHeading>
										<Text isCentered type='normal' color='text'>Do you want to set a status for your existing matches <br /> while you’re away?</Text>
										{reason?.map((elm) => (
											<SnoozeBox onClick={() => { addReasonInSnooze(elm); setIndefinitelyWithAwayStatus(false) }}>{elm}</SnoozeBox>
										))}
									</>
								}
							</>
						}
					</div>
				</StyledModal>
			</ModalProvider>
			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={isLogoutModalOpen} onBackgroundClick={logoutModalToggle} onEscapeKeydown={logoutModalToggle}>
					<div>
						<ModelHeading>Are you sure you want to log out of <br /> {CommonEnum.AppName}?</ModelHeading>
						<Flexed direction='column' align='center' margin='1rem 0rem 0rem 0rem' gap={1} >
							<CustomButton onClick={logout}>Logout</CustomButton>
							<CancelButton onClick={() => { setIsLogoutModalOpen(false) }}>Cancel</CancelButton>
						</Flexed>
					</div>
				</StyledModal>
			</ModalProvider>
			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={isDeleteAccountModalOpen} onBackgroundClick={deleteAccountModalToggle} onEscapeKeydown={deleteAccountModalToggle}>
					<div>
						<ModelHeading>Are you sure?</ModelHeading>
						<Text type='normal' color='text'>
							Deleting your profile to create a new account may affect who you see on the platform, and we want you to have the best experience possible.
						</Text>
						<Flexed direction='column' align='center' margin='1rem 0rem 0rem 0rem' gap={1} >
							<CustomButton onClick={deleteUserAccountInfo}>Delete Account</CustomButton>
							<CancelButton onClick={() => { setIsDeleteAccountModalOpen(false) }}>Cancel</CancelButton>
						</Flexed>
					</div>
				</StyledModal>
			</ModalProvider>
			<ModalProvider>
				<LocationModal id="modelWrap" isOpen={isLocationModalOpen}>
					<div>
						<Body>
							<Spacer height={0.5} />
							<img src='/icons/location_permission.png' alt='location_permission' />
							<Spacer height={1.5} />
						</Body>
						<ProgressBar>
							<Bar />
						</ProgressBar>
						<Body>
							<ModelHeading>We need your permission</ModelHeading>
							<Text type='normal' color='text'>
								{"To enable your location please go to: 🔒 in your browser > Location > Allow"}
							</Text>
							<Flexed direction='column' align='center' margin='1rem 0rem 0rem 0rem' gap={1} >
								<CancelButton onClick={() => { setIsLocationModalOpen(false); }}>Got it</CancelButton>
							</Flexed>
						</Body>
					</div>
				</LocationModal>
			</ModalProvider>
			{isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const SnoozeBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1.2rem;
	margin-top: 0.5rem;
	cursor: pointer;
	border: 1px solid ${palette.yellow_border};
	height: 40px;
	color: ${palette.text};
	font-size: 1rem;
	font-weight: 500;
	&:hover{
		background: ${palette.yellow_gradient};
		border: 1px solid ${palette.yellow_border};
	}
`

const MarginTop = styled.div`
	margin-top: 2rem;
	padding: 0rem 1rem;
	color: white;
`

const MainParent = styled.div`
	width: 100%;
	overflow: scroll;
	background-color: ${palette.black};
	margin-top:4.48rem;
${media.lg`
width: calc(100% - 400px);
`}
`

const Content = styled.div`
	width: 480px;
	margin: 0 auto;
	padding: 0 32px 32px 32px;
	z-index: 9999;

${media.xs`
width: 100%;
`}
${media.sm`
width: 100%;
`}

${media.md`
width: 480px;
`}
${media.lg`
width: 480px;
`}
`

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
	line-height: 2.3rem;
	color: ${palette.yellow_theme};
`

const DateBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	padding: 1rem;
	border-radius: 20px;
	color: white;
	cursor: pointer;
	background: ${({ active }) => active ? palette.yellow_theme : palette.gray_100};
	&:hover{
		background: ${({ active }) => active ? palette.yellow_gradient : palette.yellow_gradient};
	}
	&:hover p{
        color: white;
	}
    &:hover div{
        color: white;
	}
	& p {
		color: ${({ active }) => active ? palette.white : palette.yellow_theme};
		font-size: 28px;
	}
	& div {
		color: ${({ active }) => active ? palette.white : palette.dark_gray};
	}
`

const AffBox = styled(DateBox)`
	background: ${({ active }) => active ? palette.dark_sea_green : palette.gray_100};
	&:hover{
		background: ${({ active }) => active ? palette.dark_sea_green : palette.dark_sea_green};
	}
	& p {
		color: ${({ active }) => active ? palette.white : palette.dark_sea_green};
		font-size: 28px;
	}
	& div {
		color: ${({ active }) => active ? palette.white : palette.dark_gray};
	}
`;

const DateInfo = styled.p`
	color: black;
`

const Button = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: white;
	background: ${palette.yellow_theme};
	border: none;
	font-size: 16px;
	cursor: pointer;
	&:hover{
        background: ${palette.yellow_theme_hover};
	}
`

const RightArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    color: ${palette.light_gray};
`;

const Choose = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1.2rem;
	margin-top: 1rem;
	cursor: pointer;
	border: 1px solid ${({ active }) => active ? palette.hover_color : palette.hover_color};
	height: 40px;
	color: ${palette.dark_gray};
	font-size: 1rem;
	font-weight: 500;
	position: relative;
	background: ${({ active }) => active ? palette.hover_color : ''};
	color: ${({ active }) => active ? palette.gray_200 : palette.white};
	&:hover{
		background: ${palette.yellow_gradient};
		border: 1px solid ${palette.hover_color};
	}
	&:hover div{
		// color: ${palette.black};
	}
	&:hover ${RightArrow}{
		// color: ${palette.gray};
	}
`

const SnoozAction = styled.div`
	position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
`

const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	border: 0.5px solid ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	&:hover {
		border: 0.5px solid ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
`

const DownArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    /* color: ${palette.light_gray}; */
	transform: ${({ active }) => active ? 'rotate(-90deg)' : 'rotate(90deg)'};
	transition: all 0.3s ease;
`;

const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${DownArrow}{
		color: ${({ isExpanded }) => isExpanded ? '' : palette.text};
	}
`

const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
	font-size: 20px;
	color: ${palette.text};
`

const StyledModal = Modal.styled`
	width: 90%;
	border-radius: 8px;
  	padding: 2rem !important;
  	background-color: ${palette.black};
	border: 1px solid ${palette.yellow_border};
	${media.md`
        width: 600px;
    `}
`

const LocationModal = styled(StyledModal)`
	width: 600px;
	border-radius: 8px;
  	padding: 0rem !important;
  	background-color: ${palette.black};
	border: 1px solid ${palette.yellow_border};
`

const Body = styled.div`
	padding: 2rem !important;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Footer = styled.footer`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	flex-direction: column;
	margin-bottom: 1rem;
`
const FooterText = styled.p`
	margin-top: 1rem;
	font-size: 13px;
	color: ${palette.hover_color};
`

const SnoozInfo = styled(Text)`
	margin-top: 12px;
	padding: 0px 20px;
	letter-spacing: 0.04em;
`;

const InnerContent = styled.section`
	position:relative;
`;

const InterestedInWrapper = styled.div`
	box-shadow: inset 0 0 0 1px ${palette.yellow_border};
	border-radius: 20px !important;
	background: ${palette.black};
	-webkit-transition: -webkit-box-shadow .3s ease-out;
	transition: -webkit-box-shadow .3s ease-out;
	transition: box-shadow .3s ease-out;
	transition: box-shadow .3s ease-out,-webkit-box-shadow .3s ease-out;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
`

const InterestedInLabel = styled.label`
	box-shadow:  ${({ active }) => active && 'inset 0 0 0 1px #ffc629'};
	border-radius:  ${({ active }) => active && '20px'};
	color:  ${({ active }) => active && '#'};
	background:${({ active }) => active && palette.yellow_gradient};
	display: flex;
	align-items: center;
		justify-content: center;
		flex: 0 1 100%;
		min-width: 0;
		height: 40px;
		padding: 4px 20px;
		transition: color .2s;
`

const AgeHeading = styled.div`
	padding: 1rem;
	padding-top: 0rem;
	font-weight: 400;
	color: ${palette.text};
`

const SliderWraper = styled.div`
	padding: 1.4rem 1rem 1.8rem 1rem;
	width: 100%;
	border: 1px solid ${palette.yellow_border};
	background: ${palette.black};
	border-radius: 20px;
	gap: 1.8rem;
	display: inline-grid;
	:hover {
		border: 1px solid ${palette.yellow_border};
		background: ${palette.black};
	}
`

const Refresh = styled.svg`
	height: 24px;
	color: ${palette.light_gray};
	cursor:pointer;
`;

const LocationChoose = styled(Choose)`
	&:hover ${Refresh}{
		color: ${palette.text};
	}
`;

const LanguageChoose = styled(Choose)`
	position: relative;
	border: 1px solid ${({ active }) => active ? palette.hover_color : palette.hover_color};
	& svg{
	color: ${({ active }) => active ? palette.text : palette.text};
}
	&:hover ${DownArrow} {
	color: ${({ active }) => active ? palette.text : palette.text};
}
`;

const DropDown = styled.div`
width: 100%;
height: auto;
display: ${({ active }) => (active ? 'block' : 'none')};
position: absolute;
z-index: 10;
top: 2.6rem;
left: 0;
overflow: hidden;
border: 1px solid ${palette.yellow_border};
border-radius: 16px;
background: ${palette.black};
padding: 8px 0;
max-height: 210px;
overflow: auto;
`

const DropList = styled.span`
display: flex;
align-items: center;
height: 32px;
padding: 0 16px;
font-size: 14px;
line-height: 1.429;
color: ${({ active }) => (active ? palette.hover_color : palette.text)};
	&:hover {
	background: ${palette.yellow_gradient};
	cursor: pointer;
	color: ${({ active }) => (active ? palette.text : palette.text)};
}
`

const Check = styled.svg`
height: 24px;
`;

const UnCheck = styled.svg`
height: 24px;
color: ${palette.light_gray};
`;

const CancelButton = styled.button`
padding: 0.5rem 1.1rem;
border-radius: 20px;
color: ${palette.hover_color};
background: transparent;
cursor: pointer;
border: none;
font-size: 16px;
min-width: 140px;
height: 42px;
    &:hover{
	background: #00000003;
}
`

const CustomButton = styled(Button)`
min-width: 140px;
height: 42px;
background: ${palette.btn_gradient};
&:hover{
	background: ${palette.btn_hover_gradient};
}
`
const progressBarAnimation = keyframes`
0%{
	width: 100%;
};
25%{
	width: 75%;
};
50%{
	width: 50%;
};
75%{
	width: 25%;
};
100%{
	width: 0%;
};
	`;

const ProgressBar = styled.div`
width: 100%;
height: 2px;
background: ${palette.light_gray};

`;

const Bar = styled.div`
width: 0%;
height: 2px;
background: ${palette.yellow_theme};
animation: ${progressBarAnimation} 10s linear;
`;

const LocationLoading = styled.div`
position: absolute;
right: 1rem;
`;

const Loading = styled.div`
position: fixed;
background: #00000082;
width: 100%;
height: 100%;
z-index: 9999;
display: flex;
justify-content: center;
align-items: center;
top: 0;
`
const CustomDiv = styled.div`

${media.xs`
font-size:0.7rem
`}
${media.sm`
font-size:0.7rem
`}
${media.md`
font-size:1rem
`}
${media.lg`
font-size:1rem
`}
`

export default Settings
