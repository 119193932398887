import React from 'react'
import styled from 'styled-components'
import ShopCarousel from '../components/ShopCarousel/ShopCarousel'
import ShopPleasure from '../components/ShopPleasure/ShopPleasure'
import ShopCardWraper from '../components/ShopCards/ShopCardWraper'
import IRL from '../components/IRL/IRL'
import DownlaodWrapper from '../components/IRL/DownlaodWrapper/DownlaodWrapper'
import Footer from '../components/Footer/Footer'

const Shop = () => {
    return (
        <>
            <ShopCarousel />
            <ShopPleasure />
            <ShopCardWraper />
            <IRL />
            <DownlaodWrapper />
            <Footer />
        </>
    )
}

export default Shop





