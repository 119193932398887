import React, { useEffect, useRef, useState } from 'react'
import { useCollapse } from 'react-collapsed'
import Modal from 'styled-react-modal'
import styled from 'styled-components'
import { ModalProvider } from 'styled-react-modal'
import './educationcollapse.css'
import { media } from 'styled-bootstrap-grid'
import { addEducation, addUserWork, getUserEducation, getUserWork } from '../../apis/apis'
import WorkAndEducationList from './MyBasicCollapse/WorkAmdEducationlist'
import EducationList from './MyBasicCollapse/EducationList'
import { IoIosArrowForward } from 'react-icons/io'
import { palette } from '../../styled/colors'
import { Flexed, Spacer, Text } from '../../styled/shared'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
export const yearList = [
	{ val: 1953, year: 1953 },
	{ val: 1954, year: 1954 },
	{ val: 1955, year: 1955 },
	{ val: 1956, year: 1956 },
	{ val: 1957, year: 1957 },
	{ val: 1958, year: 1958 },
	{ val: 1959, year: 1959 },
	{ val: 1960, year: 1960 },
	{ val: 1961, year: 1961 },
	{ val: 1962, year: 1962 },
	{ val: 1963, year: 1963 },
	{ val: 1964, year: 1964 },
	{ val: 1965, year: 1965 },
	{ val: 1966, year: 1966 },
	{ val: 1967, year: 1967 },
	{ val: 1968, year: 1968 },
	{ val: 1969, year: 1969 },
	{ val: 1970, year: 1970 },
	{ val: 1971, year: 1971 },
	{ val: 1972, year: 1972 },
	{ val: 1973, year: 1973 },
	{ val: 1974, year: 1974 },
	{ val: 1975, year: 1975 },
	{ val: 1976, year: 1976 },
	{ val: 1977, year: 1977 },
	{ val: 1978, year: 1978 },
	{ val: 1979, year: 1979 },
	{ val: 1980, year: 1980 },
	{ val: 1981, year: 1981 },
	{ val: 1982, year: 1982 },
	{ val: 1983, year: 1983 },
	{ val: 1984, year: 1984 },
	{ val: 1985, year: 1985 },
	{ val: 1986, year: 1986 },
	{ val: 1987, year: 1987 },
	{ val: 1989, year: 1989 },
	{ val: 1990, year: 1990 },
	{ val: 1991, year: 1991 },
	{ val: 1992, year: 1992 },
	{ val: 1993, year: 1993 },
	{ val: 1994, year: 1994 },
	{ val: 1995, year: 1995 },
	{ val: 1996, year: 1996 },
	{ val: 1997, year: 1997 },
	{ val: 1998, year: 1998 },
	{ val: 1999, year: 1999 },
	{ val: 2000, year: 2000 },
	{ val: 2001, year: 2001 },
	{ val: 2002, year: 2002 },
	{ val: 2003, year: 2003 },
	{ val: 2004, year: 2004 },
	{ val: 2005, year: 2005 },
	{ val: 2006, year: 2006 },
	{ val: 2007, year: 2007 },
	{ val: 2008, year: 2008 },
	{ val: 2009, year: 2009 },
	{ val: 2010, year: 2010 },
	{ val: 2011, year: 2011 },
	{ val: 2012, year: 2012 },
	{ val: 2013, year: 2013 },
	{ val: 2014, year: 2014 },
	{ val: 2015, year: 2015 },
	{ val: 2016, year: 2016 },
	{ val: 2017, year: 2017 },
	{ val: 2018, year: 2018 },
	{ val: 2019, year: 2019 },
	{ val: 2020, year: 2020 },
	{ val: 2021, year: 2021 },
	{ val: 2022, year: 2022 },
	{ val: 2023, year: 2023 },
	{ val: 2024, year: 2024 },
	{ val: 2025, year: 2025 },
	{ val: 2026, year: 2026 },
	{ val: 2027, year: 2027 },
	{ val: 2028, year: 2028 },
	{ val: 2029, year: 2029 },
	{ val: 2030, year: 2030 },
	{ val: 2031, year: 2031 },
	{ val: 2032, year: 2032 },
	{ val: 2033, year: 2033 }
]

const EducationCollapse = () => {
	const _yearRef = useRef(null)
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [isOpen, setIsOpen] = useState(false)
	const [jobTitle, setJobTitle] = useState('')
	const [company, setCompany] = useState('')
	const [institution, setInstitution] = useState('')
	const [year, setYear] = useState('')
	const [secIsOpen, secSetIsOpen] = useState(false)
	const [secIsOpenEditWork, secSetIsOpenEditWork] = useState(false)
	const [educationData, setEducationData] = useState([])
	const [workData, setWorkData] = useState([])
	const [refresh, setRefresh] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [openYearDrop, setOpenYearDrop] = useState(false)

	const JobModal = (e) => setIsOpen(!isOpen)
	const WorkModal = (e) => setIsOpen(!secIsOpenEditWork)
	const EduModel = (e) => secSetIsOpen(!secIsOpen)

	const addUserWorkinfo = async () => {
		setIsLoading(true)
		await addUserWork(jobTitle, company)
		await getUserWorkInfo()
		setIsOpen(false)
		setJobTitle('')
		setCompany('')
		setIsLoading(false)
	}

	const addEducationInfo = async () => {
		setIsLoading(true)
		await addEducation(institution, year)
		await getUserAducationInfo()
		secSetIsOpen(false)
		setIsLoading(false)
		setInstitution('')
		setYear('')
	}

	const getUserWorkInfo = async () => {
		let user = await getUserWork()
		setWorkData(user.data)
	}

	const getUserAducationInfo = async () => {
		let user = await getUserEducation()
		setEducationData(user.data)
	}

	useEffect(() => {
		getUserWorkInfo()
		getUserAducationInfo()
	}, [refresh])

	useEffect(() => {
		function handleClickOutside(event) {
			if (_yearRef.current && !_yearRef.current.contains(event.target)) {
				setOpenYearDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<>
			<Box
				isExpanded={isExpanded}
				{...getToggleProps({
					onClick: () => {
						!isExpanded && setExpanded(!isExpanded)
					}
				})}>
				<BoxHeader
					justify="space-between"
					align="center"
					{...getToggleProps({
						onClick: () => setExpanded((prevExpanded) => !prevExpanded)
					})}>
					<Heading {...getToggleProps()}> My Work & Education</Heading>
					<RightArrow active={isExpanded} />
				</BoxHeader>
				<InnerContent {...getCollapseProps()}>
					<Text margin="1rem 0rem 0rem 0rem" type="normal" color="text">
						Your job
					</Text>
					{workData?.map((item) => (
						<WorkAndEducationList item={item} setRefresh={setRefresh} refresh={refresh}></WorkAndEducationList>
					))}

					<Job gapTop={workData?.length > 0} onClick={JobModal}>
						<svg height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
						</svg>

						<Text margin="0rem 0rem 0rem 0.5rem" type="normal">
							Add a job
						</Text>
					</Job>

					<Spacer height={0.5} />
					<Text margin="1rem 0rem 0rem 0rem" type="normal" color="text">
						Your education
					</Text>

					<div>
						{educationData?.map((item) => (
							<EducationList item={item} setRefresh={setRefresh} refresh={refresh}></EducationList>
						))}
					</div>

					<Institution gapTop={educationData?.length > 0} onClick={EduModel}>
						<svg onClick={WorkModal} height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
						</svg>
						<Text margin="0rem 0rem 0rem 0.5rem" type="normal">
							Add an institution
						</Text>
					</Institution>
				</InnerContent>

				<ModalProvider>
					<div>
						<StyledModal isOpen={isOpen} onBackgroundClick={JobModal} onEscapeKeydown={JobModal}>
							<div>
								<ModelHeading>Add a Job</ModelHeading>
								<div>
									<Input
										placeholder="Title"
										name="title"
										onChange={(e) => {
											setJobTitle(e.target.value)
										}}
									/>
									<Input
										placeholder="Company"
										name="company"
										onChange={(e) => {
											setCompany(e.target.value)
										}}
									/>
									<Center>
										<Button disabled={jobTitle === '' || company === '' || isLoading} isLoading={isLoading} onClick={addUserWorkinfo}>
											{isLoading ? <div className="white-dots-loading-small"></div> : 'Save'}
										</Button>
									</Center>
								</div>
							</div>
						</StyledModal>
					</div>
				</ModalProvider>

				<ModalProvider>
					<div>
						<StyledModal isOpen={secIsOpen} onBackgroundClick={EduModel} onEscapeKeydown={EduModel}>
							<div>
								<ModelHeading>Add a Education</ModelHeading>

								<div>
									<Input
										placeholder="Institution"
										name="institution"
										onChange={(e) => {
											setInstitution(e.target.value)
										}}
									/>

									<SelectWrapper
										ref={_yearRef}
										onClick={() => {
											setOpenYearDrop(!openYearDrop)
										}}>
										<Select>
											{year ? year : 'Graduation year'} {openYearDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
										</Select>
										<DropDown active={openYearDrop}>
											{yearList.map((data) => {
												return (
													<DropList
														active={data.val === year}
														onClick={() => {
															setYear(data.val)
														}}>
														{data.year}
													</DropList>
												)
											})}
										</DropDown>
									</SelectWrapper>

									<Center>
										<Button disabled={institution === '' || year === '' || isLoading} isLoading={isLoading} onClick={addEducationInfo}>
											{isLoading ? <div className="white-dots-loading-small"></div> : 'Save'}
										</Button>
									</Center>
								</div>
							</div>
						</StyledModal>
					</div>
				</ModalProvider>
			</Box>
		</>
	)
}

const RightArrow = styled(IoIosArrowForward)`
	font-size: 22px;
	/* color: ${palette.light_gray}; */
	transform: ${({ active }) => (active ? 'rotate(-90deg)' : 'rotate(90deg)')};
	transition: all 0.3s ease;
`

const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	border: 0.5px solid  ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color:  ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	&:hover {
		border: 0.5px solid  ${palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
`
const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	};
	/* &:hover{
		color: ${({ isExpanded }) => palette.black};
	} */
`
const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
`

const Job = styled.div`
	padding: 0.5rem 1rem;
	cursor: pointer;
	border: 1px solid ${palette.yellow_border};
	border-radius: 20px;
	padding: 0.1rem 1rem;
	height: 40px;
	display: flex;
	align-items: center;
	background: ${palette.black};
	margin-top: ${({ gapTop }) => (gapTop ? '1rem' : '0.5rem')};
	& svg{
		color: ${palette.text};
		cursor: pointer;
	} 
	&:hover{
		background: ${palette.yellow_gradient};
	} 
	&:hover svg{
		color: ${palette.text};
	} 
`
const Institution = styled(Job)``
const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`
const Input = styled.input`
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	padding: 0.7rem 1rem;
	margin-top: 1rem;
	width: 100%;
	font-size: 1rem;
	outline: none;
	height: 42px;
	background: ${palette.black};
	color: ${palette.text};
	border: 1px solid ${palette.yellow_border};

	&:hover {
		border: 1px solid ${palette.yellow_border};
	}
	&:focus {
		border: 1px solid ${palette.yellow_border};
	}
	${media.sm`width: 30rem;`}
`
export const SelectWrapper = styled.div`
	position: relative;
`

export const Select = styled.div`
	display: flex;
	width: 100%;
	background: ${palette.black};
	align-items: center;
	justify-content: space-between;
	border: 1px solid ${palette.yellow_border};
	border-radius: 20px;
	padding: 0.7rem 1rem;
	margin-top: 1rem;
	font-size: 1rem;
	height: 42px;
	cursor: pointer;
	&:hover {
		border: 1px solid ${palette.yellow_border};
	}
`

export const DropDown = styled.div`
	width: 100%;
	max-height: 13rem;
	display: ${({ active }) => (active ? 'block' : 'none')};
	position: absolute;
	z-index: 10;
	top: 3rem;
	left: 0;
	overflow: hidden;
	border: 1px solid ${palette.yellow_border};
	border-radius: 16px;
	background: ${palette.black};
	padding: 8px 0;
	overflow: auto;
`

export const DropList = styled.span`
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 1.429;
	color: ${({ active }) => (active ? palette.yellow_theme : palette.white)};
	&:hover {
		background: ${palette.yellow_gradient};
		cursor: pointer;
	}
`

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
`

const StyledModal = Modal.styled`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  padding: 2rem;
  border-radius: 10px;
  background-color: ${palette.black};
	border:1px solid ${palette.yellow_border};
`
export const Button = styled.button`
	padding: 0.5rem 1.5rem;
	position: relative;
	border-radius: 20px;
	color: white;
	margin-top: 2rem;
	background: ${palette.btn_gradient};
	cursor: pointer;
	border: none;
	height: 40px;
	width: 78.39px;
	opacity: ${({ disabled, isLoading }) => (isLoading ? '1' : disabled ? '0.5' : '1')};
	cursor: ${({ disabled, isLoading }) => (isLoading ? 'pointer' : disabled ? 'no-drop' : 'pointer')};
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: ${palette.btn_hover_gradient};
	}
`
const InnerContent = styled.section`
	position: relative;
`

export default EducationCollapse
