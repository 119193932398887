import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import { media } from 'styled-bootstrap-grid'

const ShopTextWraper = ({ heading, text }) => {

    return (
        <>
            <Wraper>
                <Heading>{heading}</Heading>
                <Text>{text}</Text>
            </Wraper>
        </>
    )
}


const Wraper = styled.div`
display: flex;
justify-content:center;
align-items:center;
flex-direction: column;
background: ${palette.hover_color};
height: 50vh;
padding: 1.5rem;
margin-bottom: 2rem;

@media(max-width: 900px){

    height: auto;
}

`

const Heading = styled.div`
    font-weight: 600;
    text-align: center;
    color: ${palette.black};
    font-size: 1.5rem;
    line-height: 1.5;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`

const Text = styled.div`
    line-height: 2;
    font-size: 20px;
    text-align:center;
    font-size: 16px;
    padding-top:1rem;
    ${media.sm`
        width: 90%;
    `}
    ${media.md`
        padding-top:1.5rem;
        width: 80%;
        font-size: 18px;
    `}
    ${media.lg`
        padding-top:2rem;
        width: 68%;
        font-size: 24px;
`}
`

export default ShopTextWraper