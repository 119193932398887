import axios from 'axios'
import { GoogleGenerativeAI } from '@google/generative-ai'
import { toast } from 'react-toastify'
import { CommonEnum } from '../utils'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_AI_API_KEY ? process.env.REACT_APP_AI_API_KEY : 'AIzaSyA_1opl_8mbmqBU_pDWk74TvXgtq0EvX-s')

export const doGenerateAiResponse = async (_text, images) => {
	try {
		if (_text) {
			const model = genAI.getGenerativeModel({ model: images?.length || images ? 'gemini-pro-vision' : 'gemini-pro' })

			const result = await model.generateContent(images?.length ? [_text, ...images] : images ? [_text, images] : _text)
			const response = await result.response
			const text = response.text()
			return text
		}
	} catch (error) {
		if (error?.message == '[GoogleGenerativeAI Error]: Candidate was blocked due to RECITATION') {
			toast.error('Please try searching again by choosing a different image.')
		} else {
			toast.error(error?.message)
		}
		console.log('error', error)
	}
}

export const contactUsEmail = async (data) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${CommonEnum.contactUsServerUrl}users/contact-us`, data, config)
		.then((res) => {
			response = res
		})
		.catch((error) => {
			console.error(error)
			response = error?.response
		})
	return response
}

export const getUser = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/profile`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
			response = error?.response
		})
	return response
}

export const userRegister = async (phone) => {
	let response = []
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/register`, { phone: phone, first_name: '', gender: '', date_of_birth: '' })
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const verifyRegisterCode = async (phone, verificationCode) => {
	let response = []
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/verify_register_code`, { phone: phone, verification_code: verificationCode })
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addUserInChat = async (id, user) => {
	const environment = process.env.REACT_APP_PUBLIC_WEAVY_URL
	const apiKey = process.env.REACT_APP_PUBLIC_WEAVY_APIKEY
	let name = user?.first_name && user?.first_name
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	try {
		const headers = {
			Authorization: `Bearer ${apiKey}`,
			'Content-Type': 'application/json'
		}

		await axios
			.post(
				`${environment}/api/users`,
				{
					uid: id + 'a',
					name: name,
					expires_in: 7200
				},
				{ headers }
			)
			.then((res) => {
				console.log(res)
			})
			.catch((err) => {
				console.log(err)
			})

		await axios.post(
			`${environment}/api/users/${id + 'a'}/tokens`,
			{
				name: name,
				expires_in: 7200
			},
			{ headers }
		)

		const userResponse = await axios.put(
			`${environment}/api/users/${id + 'a'}`,
			{ name: name, picture: process.env.REACT_APP_PUBLIC_IMAGE_URL + '/' + user?.images[0] },
			{ headers }
		)

		await axios
			.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`, { chat_id: userResponse?.data?.id }, config)
			.then((res) => {
				console.log('res?.data', res?.data)
			})
			.catch((error) => {
				console.error(error)
			})

		console.log('userResponse', userResponse)
		return userResponse
	} catch (error) {
		console.log('Error:', error)
	}
}


export const getAllInterests = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/interests`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateUserInterests = async (interest_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_user_intrest`, { interest_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}


export const addUserWork = async (title, company) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_user_work`, { title: title, company: company, show_on_profile: true }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getUserWork = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_user_work`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addEducation = async (institution, graduation_year) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_education`, { institution: institution, graduation_year: graduation_year, show_on_profile: true }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const getUserEducation = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_education`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllUserProfile = async (page, date_mode, filters) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_all_user_profile?page=${page}`, { date_mode: date_mode, filters }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const likeUserProfile = async (profile_id, mode, super_swap) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/like_user_profile`, { profile_id: profile_id, mode: mode, super_swap }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const unLikeProfile = async (profile_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/un_like_user_profile`, { profile_id: profile_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const removeUserImage = async (image) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/remove_image`, { image: image }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const reportUser = async (profile_id, reason) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/report_user`, { profile_id: profile_id, reason: reason }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllChatUser = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_chat_users`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const allBothSideLikesUser = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_all_bothside_like_users`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const allUserIntrestedInYou = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/list_of_all_interested_user`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getSpecficUserInfo = async (u_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_specfic_user_info`, { u_id: u_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteUserAccount = async (u_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/delete_user_profile`, { u_id: u_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAffAdvanceFilterData = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_advance_aff_filter_data`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getDateAdvanceFilter = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_advance_date_filter`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addSnozeData = async (time, reason) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_user_snoze_data`, { time: time, reason: reason }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const addProfilePrompt = async (profile_prompts_id, mode, ans) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_profile_prompt`, { profile_prompts_id: profile_prompts_id, mode: mode, ans: ans }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const listOfProfilePrompt = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/profile_prompt_list`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateProfilePrompt = async (ans, prompt_id, change_prompt_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_profile_prompt`, { ans: ans, prompt_id: prompt_id, change_prompt_id: change_prompt_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
export const updateUserWork = async (title, company, work_id, checked) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_user_work`, { title: title, company: company, work_id: work_id, show_on_profile: checked }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const updateUserEducation = async (institution, graduation_year, education_id, checked) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update_education`, { institution: institution, graduation_year: graduation_year, education_id: education_id, show_on_profile: checked }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteUserEducation = async (education_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/delete_education`, { education_id: education_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const deleteUserWorkData = async (work_id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/delete_user_work`, { work_id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const listOfUserNotIntrested = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/user_who_not_intrested_in_me`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllExpireUserList = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/all_expire_user`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const rematchUserProfile = async (id) => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/rematch_user`, { id }, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const socialLogin = async (provider, social_id, email) => {
	let response = []
	await axios
		.post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/social_login`, { provider: provider, social_id: social_id, email: email })
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllMybasicAffMode = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_my_basic_aff`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAllMybasicDateMode = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_my_basic_Date`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAdvanceFilterAffMode = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_advance_filter_data_aff_mode`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}

export const getAdvanceFilterDateMode = async () => {
	let response = []
	const _token = sessionStorage.getItem('authorization')
	const config = {
		headers: { authorization: `bearer ${_token}` }
	}
	await axios
		.get(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/get_advance_filter_data_date_mode`, config)
		.then((res) => {
			response = res?.data
		})
		.catch((error) => {
			console.error(error)
		})
	return response
}
