import React from 'react'
import styled from 'styled-components'
import { palette } from '../../styled/colors'

const ShopCard = ({ card }) => {
    return (
        <>
            <Wraper>
                <Image src={card?.image} alt={card?.name} />
                <ImageOnHover src='https://bumble.shop/cdn/shop/products/performance-rope-hat-975231_1512x.jpg?v=1644003631' alt={card?.name} />
                <Text>{card?.name}</Text>
                <Price>${card?.price}</Price>
            </Wraper>
        </>
    )
}
const ImageOnHover = styled.img`
width: 100%;
border-radius: 20px;
display:none;
`
const Wraper = styled.div`
margin-top: 1rem;

`

const Image = styled.img`
width: 100%;
border-radius: 20px;
`

const Text = styled.p`
color: ${palette.text};
font-weight:bold;
padding-top: .5rem;

`
const Price = styled.p`
padding-top: .5rem;
color: ${palette.text};

`
export default ShopCard