import React, { useState, useEffect, useRef } from 'react'
import Modal from 'styled-react-modal'
import styled from 'styled-components'
import { ModalProvider } from 'styled-react-modal'
import { media } from 'styled-bootstrap-grid'
import { deleteUserEducation, updateUserEducation } from '../../../apis/apis'
import { palette } from '../../../styled/colors'
import { Flexed, Text } from '../../../styled/shared'
import { DropDown, DropList, Select, SelectWrapper, yearList } from '../EducationCollapse'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'


const EducationList = ({ item, setRefresh, refresh }) => {
	const _yearRef = useRef(null)
	const [isOpen, setIsOpen] = useState(false)
	const [institution, setInstitution] = useState(item.institution)
	const [year, setYear] = useState(item.graduation_year)
	const [checked, setChecked] = useState(item?.show_on_profile)
	const [isLoading, setIsLoading] = useState(false)
	const [isDeleteLoading, setIsDeleteLoading] = useState(false)
	const [openYearDrop, setOpenYearDrop] = useState(false)

	const WorkModal = (e) => {
		setIsOpen(!isOpen)
	}

	const updateEducationInfo = async (checked) => {
		setIsLoading(true)
		await updateUserEducation(institution, year, item.id, checked)
		setRefresh(!refresh)
		setIsOpen(false)
		setIsLoading(false)
	}

	const removeEducation = async () => {
		setIsDeleteLoading(true)
		await deleteUserEducation(item.id)
		setRefresh(!refresh)
		setIsOpen(false)
		setIsDeleteLoading(false)
	}
	useEffect(() => {
		setInstitution(item.institution)
		setYear(item.graduation_year)
		setChecked(item.show_on_profile)
	}, [item])

	useEffect(() => {
		function handleClickOutside(event) {
			if (_yearRef.current && !_yearRef.current.contains(event.target)) {
				setOpenYearDrop(false)
			}
		}
		window.addEventListener('click', handleClickOutside)
		return () => {
			window.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<>
			<Main key={item.id} isLoading={isLoading}>
				<EditIcon isLoading={isLoading} onClick={WorkModal} data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.933 22.4v.933h.934l10-10-.934-.933-10 10zM5.6 20L18 7.6a1.333 1.333 0 011.867 0l4.666 4.8a1.289 1.289 0 010 1.867L12.267 26.533a2.667 2.667 0 01-1.867.8H6.267A1.333 1.333 0 014.933 26v-4.133c0-.667.267-1.334.667-1.867zM22.267 3.467c.666-.8 2-.8 2.666 0L28.8 7.2A2 2 0 1126 10l-3.733-3.733c-.934-.934-.934-2.267 0-2.8z" fill="currentColor"></path></EditIcon>
				<Wrapper isLoading={isLoading} checked={!checked} onClick={() => { if (!checked) { updateEducationInfo(true) } }}>
					<Flexed align='center' gap={0.9}>
						{checked ?
							<Check data-origin="pipeline" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" fill={palette.brown_rgb}></path><path d="M17.407 7.532a.75.75 0 111.06 1.06L11.28 15.78a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 111.06-1.06l3.22 3.22 6.657-6.658z" fill="#fff"></path></Check>
							:
							<UnCheck data-origin="pipeline" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="12" fill="#ffffff"></circle><circle cx="12" cy="12" r="11" fill="#000"></circle></UnCheck>
						}
						<div>
							<Text type='normal' color='yellow_border'>{item.institution}</Text>
							<Text type='normal' color='text'>{item.graduation_year}</Text>
						</div>
					</Flexed>
					<Place />
				</Wrapper>
				{isLoading && <CardLoading className='dots-loading'></CardLoading>}
			</Main>
			<ModalProvider>
				<div>
					<StyledModal isOpen={isOpen} onBackgroundClick={WorkModal} onEscapeKeydown={WorkModal}>
						<div>
							<ModelHeading>Edit institution </ModelHeading>

							<div>
								<div>
									<Input
										placeholder="Title"
										name="title"
										value={institution}
										onChange={(e) => {
											setInstitution(e.target.value)
										}}
									/>
									<SelectWrapper ref={_yearRef} onClick={() => {
										setOpenYearDrop(!openYearDrop)
									}}>
										<Select>
											{year ? year : 'Graduation year'} 	 {openYearDrop ? <IoIosArrowUp /> : <IoIosArrowDown />}
										</Select>
										<DropDown active={openYearDrop}>
											{yearList.map((data) => {
												return (
													<DropList
														active={data.val === year}
														onClick={() => { setYear(data.val) }}
													>
														{data.year}
													</DropList>
												)
											})}
										</DropDown>
									</SelectWrapper>
									<Flexed gap={1} align='center' justify='center' margin='2rem 0rem 0rem 0rem'>
										<RemoveButton isDeleteLoading={isDeleteLoading} onClick={removeEducation}>
											{isDeleteLoading ? <div className="yellow-dots-loading-small"></div> :
												'Remove institution'
											}
										</RemoveButton>
										<Button disabled={(institution === '' || year === '' || isLoading)} isLoading={isLoading} onClick={() => { updateEducationInfo(checked) }}>
											{isLoading ? <div class="white-dots-loading-small"></div> :
												'Save'
											}
										</Button>
									</Flexed>
								</div>
							</div>
						</div>
					</StyledModal>
				</div>
			</ModalProvider>
		</>
	)
}
const StyledModal = Modal.styled`
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: whitesmoke;
	padding: 2rem;
	border-radius: 10px;
`

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
`
const Input = styled.input`
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 20px;
	padding: 0.7rem 1rem;
	margin-top: 1rem;
	width: 100%;
	font-size: 1rem;
	outline: none;
	height: 42px;
	border: 1px solid ${palette.light_gray};
	&:hover{
		border: 1px solid #c6c6c6;
    }
	&:focus{
		border: 1px solid ${palette.yellow_theme};
	}
	${media.sm`width: 30rem;`}
`

const Check = styled.svg`
	height: 24px;
`
const UnCheck = styled(Check)`
`
const Place = styled.div`
	width:1rem;
	height: 1rem;
`;

const EditIcon = styled.svg`
	color: ${palette.gray};
	height: 24px;
	cursor:pointer;
	display: none;
	position: absolute;
	top: 1.5rem;
	right: 1rem;
	z-index: 2;
	filter: ${({ isLoading }) => isLoading ? 'blur(1px)' : ''};
`

const CardLoading = styled.div`
	top: 0;
	bottom: 0;
	margin: auto;
	right: 0;
	left: 0;
`;
const Main = styled.section`
	position:relative;
	&:hover ${EditIcon}{
		display: block;
	}
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 20px;
	gap: 1rem;
	padding: 0.8rem 1rem;
	margin-top: 1rem;
	border: 1px solid ${palette.hover_color};
	min-height: 56px;
	background: ${palette.black};
	cursor: ${({ checked }) => checked ? 'pointer' : ''};
	&:hover{
		/* border: 1px solid #c6c6c6; */
		background: ${({ checked }) => checked ? palette.black : palette.black};
	}
	filter: ${({ isLoading }) => isLoading ? 'blur(1px)' : ''};
`

const Button = styled.button`
	padding: 0.5rem 1.5rem;
	position: relative;
	border-radius: 20px;
	color: white;
	background: ${palette.yellow_theme};
	cursor: pointer;
	border: none;
	height: 40px;
	width: 78.39px;
	opacity: ${({ disabled, isLoading }) => isLoading ? '1' : disabled ? '0.5' : '1'};
	cursor: ${({ disabled, isLoading }) => isLoading ? 'pointer' : disabled ? 'no-drop' : 'pointer'};
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover{
		background: ${palette.yellow_theme_hover};
	}
`

const RemoveButton = styled.button`
	padding: 0.5rem 1.5rem;
	border-radius: 20px;
	color: ${palette.yellow_theme};
    background: ${({ isDeleteLoading }) => isDeleteLoading ? '#00000003' : 'transparent'};
	cursor: pointer;
	border: none;
	height: 40px;
	font-size: 16px;
	width: 179.62px;
	display: flex;
	align-items: center;
	justify-content: center;
    &:hover{
        background: #00000003;
    }
`

export default EducationList
