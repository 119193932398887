import * as types from '../actions/types'

const initialState = {
	userDetails: null,
	appContentReloads: false,
	userLikesMe: null
}

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case types.USER_DETAILS:
			return {
				...state,
				userDetails: action.value
			}
		case types.APP_CONTENT_RECORD:
			return {
				...state,
				appContentReloads: action.value
			}
		case types.USER_WHO_LIKES_ME:
			return {
				...state,
				userLikesMe: action.value
			}
		default:
			return state
	}
}
