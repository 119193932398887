import React, { useState, useRef, useEffect } from 'react'
import Modal, { ModalProvider } from 'styled-react-modal'
import styled from 'styled-components'
import { palette } from '../../styled/colors'
import '../../styled/profile.css'
import { Flexed, Text } from '../../styled/shared'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { media } from 'styled-bootstrap-grid'
import { getUser } from '../../apis/apis'
import { BsPerson } from 'react-icons/bs'
import { Card1, Card2, Grid } from '../Profile/Profile'

const PreviewProfileModal = ({ isOpen, onClose, userData }) => {
    const card = useRef()
    const [mode, setMode] = useState(null)
    const [user, setUserData] = useState()
    useEffect(() => {
        getUserData()
    }, [userData])

    const getUserData = async () => {
        let user = await getUser()
        setUserData(user?.data?.data?.user)
        setMode(user?.data?.data?.user?.mode)
    }

    return (
        <>
            <ModalProvider>
                <StyledModal isOpen={isOpen} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
                    <ImageGrid ref={card} id="profileCarousel">
                        <Carousel axis={'vertical'} infiniteLoop={false} showArrows={true} showStatus={false} stopOnHover={true} swipeable={true} useKeyboardArrows={true} emulateTouch={true}>
                            <Wrapper>
                                <Grid divide={true}>
                                    <Card1 lg='6'>
                                        <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[0]}`} width={'100%'} height={'100%'} alt="" />
                                    </Card1>
                                    <Card2 lg='6'>
                                        <Flexed direction="column" gap={0.5}>
                                            <Flexed gap={0.4} align="center" justify='center'>
                                                <Heading>{user?.first_name},</Heading>
                                                <Heading>{user?.age_group}</Heading>
                                                {user?.is_verify_admin && (
                                                    <>
                                                        <Flexed align="center">
                                                            <VerifiedIcon>
                                                                <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16z" fill={palette.hover_color}></path>
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M10.586 14.443a1.795 1.795 0 00-2.539-.106 1.802 1.802 0 00-.106 2.542l4.535 4.94c.698.76 1.89.777 2.61.036l8.938-9.2a1.801 1.801 0 00-.034-2.545 1.795 1.795 0 00-2.541.035l-7.614 7.837-3.25-3.539z"
                                                                        fill="#fff"></path>
                                                                </svg>
                                                            </VerifiedIcon>
                                                            <VerifiedText>
                                                                Photo <br /> verified
                                                            </VerifiedText>
                                                        </Flexed>
                                                    </>
                                                )}
                                            </Flexed>
                                            <QuoteIcon>
                                                <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </QuoteIcon>
                                        </Flexed>
                                    </Card2>
                                </Grid>
                            </Wrapper>

                            <Wrapper>
                                <UserInfo>
                                    <Flexed justify="center" width="100%" gap={0.4}>
                                        <QuoteIcon>
                                            <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </QuoteIcon>
                                        <InfoHeading>About {user?.first_name}</InfoHeading>
                                    </Flexed>
                                    {user?.mode === 'aff' ? <InfoText>{user?.bio_aff}</InfoText> : <InfoText>{user?.bio_date}</InfoText>}
                                    <Flexed margin="1rem 0rem 0rem 0rem" flexWrap="wrap" gap={0.5} justify="center">
                                        {user?.height && mode == 'date' ? <Tags gap={0.2} align="center">
                                            <TagsIcon src="./height.png" alt="tagIcons" />
                                            {user?.height ? <TagText>{user?.height} cm</TagText> : ''}
                                        </Tags> : ""}
                                        {user?.exercise ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="./exercise.png" alt="tagIcons" />
                                                <TagText>{user?.exercise}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.education_level && mode == 'date' ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="./education.png" alt="tagIcons" />
                                                <TagText>{user?.education_level}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.have_kids ? (
                                            <Tags gap={0.2} align="center">
                                                <TagText>{user?.have_kids}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.drinking ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="./drinking.png" alt="tagIcons" />
                                                <TagText>{user?.drinking}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.politic ? (
                                            <Tags gap={0.2} align="center">
                                                <TagText>{user?.politic}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.smoking ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="/icons/smoking.png" alt="tagIcons" />
                                                <TagText>{user?.smoking}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.gender ? (
                                            <Tags gap={0.2} align="center">
                                                <BsPerson />
                                                <TagText>{user?.gender}</TagText>
                                            </Tags>
                                        ) : null}
                                        {/* { <Tags gap={0.2} align="center">
                                            <TagsIcon src="./intentions.png" alt="tagIcons" />
                                            <TagText>Spmething casual</TagText>
                                        </Tags> */}
                                        {user?.star_sign ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="./starSign.png" alt="tagIcons" />
                                                <TagText>{user?.star_sign}</TagText>
                                            </Tags>
                                        ) : null}
                                        {user?.religion ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="./religion.png" alt="tagIcons" />
                                                <TagText>{user?.religion}</TagText>
                                            </Tags>
                                        ) : null}

                                        {user?.relation_ship && mode === 'aff' && (
                                            <Tags gap={0.2} align="center">
                                                <TagText>{user?.relation_ship}</TagText>
                                            </Tags>
                                        )}
                                        {user?.new_to_area && mode === 'aff' && (
                                            <Tags gap={0.2} align="center">
                                                <TagText>{user?.new_to_area}</TagText>
                                            </Tags>
                                        )}
                                        {mode === 'aff' ? (
                                            user?.looking_for_aff ? (
                                                <Tags gap={0.2} align="center">
                                                    <TagsIcon src="./religion.png" alt="tagIcons" />
                                                    <TagText>{user?.looking_for_aff}</TagText>
                                                </Tags>
                                            ) : null
                                        ) : user?.looking_for_date ? (
                                            <Tags gap={0.2} align="center">
                                                <TagsIcon src="./religion.png" alt="tagIcons" />
                                                <TagText>{user?.looking_for_date}</TagText>
                                            </Tags>
                                        ) : null}
                                    </Flexed>
                                </UserInfo>
                            </Wrapper>

                            {(user?.images[1] || user?.profile_prompt?.filter((user) => user.mode === mode)[0]) ?
                                <Wrapper>
                                    <Grid divide={user?.images[1] && user?.profile_prompt
                                        ?.filter((user) => user.mode === mode)[0]}>
                                        {(user?.images[1]) &&
                                            <Card1 lg='6'>
                                                <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[1]}`} width={'100%'} height={'100%'} alt="" />
                                            </Card1>
                                        }
                                        {user?.profile_prompt
                                            ?.filter((user) => user.mode === mode)[0] && <Card2 lg='6'>
                                                <Flexed direction="column" align="center" width="100%">
                                                    <Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
                                                        <Section>
                                                            <MiniHeading>{user?.profile_prompt
                                                                ?.filter((user) => user.mode === mode)[0]?.profile_prompt_data?.profile_prompts}</MiniHeading>
                                                            <Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
                                                                {user?.profile_prompt
                                                                    ?.filter((user) => user.mode === mode)[0]?.ans}
                                                            </Text2>
                                                        </Section>
                                                    </Flexed>

                                                </Flexed>
                                            </Card2>
                                        }
                                    </Grid>
                                </Wrapper>
                                :
                                <Wrapper>
                                    <Card1 lg='6'>
                                        <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[0]}`} width={'100%'} height={'100%'} alt="" />
                                    </Card1>
                                </Wrapper>
                            }
                            {(user?.images[2] || user?.profile_prompt?.filter((user) => user.mode === mode)[1]) ?
                                <Wrapper>
                                    <Grid divide={user?.images[2] && user?.profile_prompt
                                        ?.filter((user) => user.mode === mode)[1]}>
                                        {(user?.images[2]) &&
                                            <Card1 lg='6'>
                                                <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[2]}`} width={'100%'} height={'100%'} alt="" />
                                            </Card1>
                                        }
                                        {user?.profile_prompt
                                            ?.filter((user) => user.mode === mode)[1] &&
                                            <Card2 lg='6'>
                                                <Flexed direction="column" align="center" width="100%">
                                                    <Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
                                                        <Section>
                                                            <MiniHeading>{user?.profile_prompt
                                                                ?.filter((user) => user.mode === mode)[1]?.profile_prompt_data?.profile_prompts}</MiniHeading>
                                                            <Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
                                                                {user?.profile_prompt
                                                                    ?.filter((user) => user.mode === mode)[1]?.ans}
                                                            </Text2>
                                                        </Section>
                                                    </Flexed>

                                                </Flexed>
                                            </Card2>
                                        }
                                    </Grid>
                                </Wrapper>
                                :
                                <Wrapper>
                                    <UserInfo>
                                        <Flexed justify="center" width="100%" gap={0.4}>
                                            <QuoteIcon>
                                                <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </QuoteIcon>
                                            {user?.is_verify_admin ? (
                                                <>
                                                    <Flexed align="center">
                                                        <VerifiedIcon>
                                                            <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16z" fill={palette.hover_color}></path>
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M10.586 14.443a1.795 1.795 0 00-2.539-.106 1.802 1.802 0 00-.106 2.542l4.535 4.94c.698.76 1.89.777 2.61.036l8.938-9.2a1.801 1.801 0 00-.034-2.545 1.795 1.795 0 00-2.541.035l-7.614 7.837-3.25-3.539z"
                                                                    fill="#fff"></path>
                                                            </svg>
                                                        </VerifiedIcon>
                                                        <VerifiedText>
                                                            Photo <br /> verified
                                                        </VerifiedText>
                                                    </Flexed>
                                                </>
                                            ) :
                                                <Text fontSize={0.688} color='gray' fontWeight={600} lineHeight={0.7}>
                                                    Photo <br /> not verified
                                                </Text>
                                            }
                                        </Flexed>
                                    </UserInfo>
                                </Wrapper>
                            }


                            {(user?.images[3] && user?.images[4]) ?
                                <Wrapper>
                                    <Grid divide={true}>
                                        <Card1 lg='6'>
                                            <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[3]}`} width={'100%'} height={'100%'} alt="" />
                                        </Card1>
                                        <Card1 lg='6'>
                                            <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[4]}`} width={'100%'} height={'100%'} alt="" />
                                        </Card1>
                                    </Grid>
                                </Wrapper>
                                :
                                (user?.images[3] || user?.profile_prompt?.filter((user) => user.mode === mode)[2]) ?
                                    <Wrapper>
                                        <Grid divide={user?.images[3] && user?.profile_prompt
                                            ?.filter((user) => user.mode === mode)[2]}>
                                            {user?.images[3] && <Card1 lg='6'>
                                                <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[3]}`} width={'100%'} height={'100%'} alt="" />
                                            </Card1>}
                                            <Card2 lg='6'>
                                                <Flexed direction="column" align="center" width="100%">
                                                    <Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
                                                        <Section>
                                                            <MiniHeading>{user?.profile_prompt
                                                                ?.filter((user) => user.mode === mode)[2]?.profile_prompt_data?.profile_prompts}</MiniHeading>
                                                            <Text2 fontSize="20px" margin="0.5rem 0rem 0rem 0rem">
                                                                {user?.profile_prompt
                                                                    ?.filter((user) => user.mode === mode)[2]?.ans}
                                                            </Text2>
                                                        </Section>
                                                    </Flexed>

                                                </Flexed>
                                            </Card2>
                                        </Grid>
                                    </Wrapper>
                                    :
                                    <Wrapper>
                                        <UserInfo>
                                            <Flexed justify="center" width="100%" gap={0.4}>
                                                <QuoteIcon>
                                                    <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm3.893-10.667c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.267-4.08-2.356 0-4.266 1.827-4.266 4.08 0 1.85 1.289 3.413 3.055 3.912.384.108 1.22 1.38-.23 2.675zm-10.667 0c4.215 0 5.707-3.994 5.707-6.587 0-2.55-1.91-4.08-4.266-4.08-2.357 0-4.267 1.827-4.267 4.08 0 1.85 1.289 3.413 3.056 3.912.383.108 1.22 1.38-.23 2.675z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </QuoteIcon>
                                                <InfoHeading>A positive attitude, a balanced extroversion and confidence </InfoHeading>
                                            </Flexed>
                                        </UserInfo>
                                    </Wrapper>

                            }

                            <Wrapper>
                                <Grid divide={true}>
                                    <Card1 lg='6'>
                                        <img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[5] ? user?.images[5] : user?.images[4] ? user?.images[4] : user?.images[3] ? user?.images[3] : user?.images[2] ? user?.images[2] : user?.images[1] ? user?.images[1] : user?.images[0]}`} width={'100%'} height={'100%'} alt="" />
                                    </Card1>
                                    <Card2 lg='6'>
                                        <Flexed direction="column" align="center" width="100%">
                                            <Flexed justify="center" margin="0rem 0rem 1rem 0rem" width="100%" gap={0.4}>
                                                <QuoteIcon>
                                                    <svg data-origin="pipeline" viewBox="0 0 32 32" fill="none">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zM9.608 13.72c-.259 3.7 5.772 10.168 6.141 10.194.37.026 6.825-5.569 7.084-9.27.259-3.7-2.492-6.906-6.144-7.161-3.652-.256-6.822 2.537-7.08 6.237zM16 16a2.133 2.133 0 110-4.267A2.133 2.133 0 0116 16z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </QuoteIcon>
                                                <InfoHeading>{`${user?.first_name}'s location`}</InfoHeading>
                                            </Flexed>
                                            <UserLocation>{user?.location}</UserLocation>
                                            {user?.distance || user?.distance == 0 ? <UserLocation>~ {user?.distance?.toFixed(2)} km away</UserLocation> : ''}
                                        </Flexed>
                                    </Card2>
                                </Grid>
                            </Wrapper>
                        </Carousel>
                    </ImageGrid>
                </StyledModal>
            </ModalProvider>
        </>
    )
}

const StyledModal = Modal.styled`
    width: 100%;
    top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    gap:1.25rem;
    padding: 2rem;
    position: relative;
    ${media.md`
    width: 906.716px;
    `}
    ${media.xxl` width: 1050px;`}
`
const ImageGrid = styled.div`
	width: 100%;
	border-radius: 14px;
`



const QuoteIcon = styled.div`
	width: 12px;
	height: 12px;
`

const VerifiedIcon = styled.div`
	width: 20px;
	height: 20px;
`

const VerifiedText = styled.div`
	font-weight: 600;
	margin-left: 8px;
	color: ${palette.hover_color};
	text-align: left;
	font-size: 11px;
	line-height: 1.09;
`

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	background: ${palette.yellow_border};
	padding: 2.2rem;
	height: 100%;
	justify-content: center;
	gap: 1rem;
`

const InfoHeading = styled.div`
	font-weight: 600;
	font-size: 14px;
	color: #454650;
	line-height: 1.3;
`

const InfoText = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 1.2;
	overflow: hidden;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	max-width: 100%;
	min-height: 0;
	text-align: center;
	color: #454650;
`

const Heading = styled.div`
	letter-spacing: -0.02em;
	font-size: min(24px + 8 * (100vw - 480px)/960, 32px);
	font-weight: 500;
	color: #454650;
`

const Tags = styled(Flexed)`
	color: white;
	background: ${palette.btn_gradient};
	height: 28px;
	padding: 0 12px 0 7px;
	border-radius: 14px;
`

const TagsIcon = styled.img`
	width: 20px !important;
	height: 20px;
	color: white;
	filter:  grayscale(0%) brightness(500%);
`

const TagText = styled.div`
	font-size: 12px;
	line-height: 1.334;
`
export const UserLocation = styled.div`
	color: #282828;
	font-size: 20px;
	line-height: 1.2;
	margin-bottom: 0.2rem;
`

const Wrapper = styled.div`
	position: relative;
	background: ${palette.btn_gradient};
	height: calc(100vh - 170px);
	border-radius: 24px;
	overflow: hidden;
`

const Section = styled.div`
	background: ${palette.yellow_border};
	padding: 1rem;
	&:not(last-child) {
		margin-bottom: 1px;
	}
`
const MiniHeading = styled.div`
	font-size: 14px;
	color: #454650;
	line-height: 1.3;
`
const Text2 = styled.div`
	font-size: 16px;
	font-size: ${({ fontSize }) => (fontSize ? fontSize : '16px')};
	color: #454650;
	line-height: 1.5;
	margin: ${({ margin }) => margin};
`


export default PreviewProfileModal
