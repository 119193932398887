import React from 'react'
import styled from 'styled-components'
import { palette } from '../styled/colors';


const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
        <>
            <Time > {value} {type}</Time>
        </>
    )
}

const Time = styled.span`
    font-weight: 600;
    color: ${palette.yellow_border};
`;

export default DateTimeDisplay
