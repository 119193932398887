import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../components/Logo/Logo'
import styled, { keyframes } from 'styled-components'
import { Container, Row, Col,media } from 'styled-bootstrap-grid'
import { palette } from '../styled/colors'
import Modal from 'styled-react-modal'
import axios from 'axios'
import { BorderWrapper, Flexed, OverLay, Spacer, Text } from '../styled/shared'
import { getUser } from '../apis/apis'
import RegisterStepper from '../components/RegisterStepper'
import { CommonEnum } from '../utils'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const ChooseMode = () => {
    const _navigate = useNavigate()
    const [aff, setAff] = useState(false)
    const [date, setDate] = useState(false)
    const [isMianloading, setIsMianloading] = useState(false);

    const doSaveMode = async () => {
        setIsMianloading(true)
        const _token = sessionStorage.getItem('authorization')
        const config = {
            headers: { authorization: `bearer ${_token}` }
        }
        await axios
            .post(
                `${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
                {
                    mode: date ? 'date' : 'aff',
                    last_screen: 'login',
                    is_new: true
                },
                config
            )
            .then((response) => {
                if (response.data.success === true) {
                    setIsMianloading(false)
                    _navigate('/location')
                }
            })
            .catch((error) => {
                setIsMianloading(false)
                console.error(error)
            })
    }

    const getUserinfo = async () => {
        setIsMianloading(true)
        let user = await getUser()
        if (user?.data?.data?.user?.mode) {
            if (user?.data?.data?.user?.mode === 'date') {
                setDate(true); setAff(false);
            }
            else if (user?.data?.data?.user?.mode === 'aff') {
                setAff(true); setDate(false)
            }
        }
        setIsMianloading(false);
    }

    useEffect(() => {
        getUserinfo()
    }, [])

    return (
        <>
            <Wrapper>
            <OverLay/>
                <Container>
                    <Row justifyContent="center">
                    <Col sm={9} md={9} lg={7}>
                            <BorderWrapper>
                                <RegisterStepper progress={90} goBack={() => { _navigate('/gender') }} />
                                <Heading>Choose a mode to get started</Heading>
                                <Spacer height={1.3} />
                                <Text type='normal' isCentered color='text'>
                                    {CommonEnum.AppName}'s for making all kinds of connections! You'll be able to switch modes once you're all set up.
                                </Text>
                                <Spacer height={0.2} />


                                <DateBox active={date} onClick={() => { setDate(true); setAff(false) }}>
                                    <DateInfo for="click">
                                        <p>
                                            date
                                        </p>
                                        <Text margin='0.5rem 0rem 0rem 0rem' type='normal'>Find that spark in an empowered community</Text>
                                    </DateInfo>

                                    <div id="click">
                                        {date ?
                                            <Check data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z" fill="currentColor"></path></Check>
                                            :
                                            <UnCheck data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></UnCheck>
                                        }
                                    </div>
                                </DateBox>

                                <AffBox active={aff} onClick={() => { setAff(true); setDate(false) }}>
                                    <DateInfo>
                                        <p >dff</p>
                                        <Text margin='0.5rem 0rem 0rem 0rem' type='normal'>Make new friends at every stage of your life</Text>
                                    </DateInfo>
                                    <div id="click">
                                        {aff ?
                                            <Check data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm-5.57-17.652a1.818 1.818 0 10-2.677 2.461l4.589 4.99a1.818 1.818 0 002.641.038l9.045-9.296a1.818 1.818 0 10-2.606-2.536l-7.705 7.918-3.288-3.575z" fill="currentColor"></path></Check>
                                            :
                                            <UnCheck data-origin="pipeline" viewBox="0 0 32 32" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-4a12 12 0 000-24C9.373 4 4 9.373 4 16s5.373 12 12 12z" fill="currentColor"></path></UnCheck>
                                        }
                                    </div>
                                </AffBox>
                                <Flexed justify='center' margin='1.5rem 0rem 0rem 0rem'>
                                    <Button onClick={doSaveMode} disabled={date === false && aff === false}>Continue with {date ? 'Date' : 'Dff'}</Button>
                                </Flexed>

                                <Spacer height={2} />
                            </BorderWrapper>
                        </Col>
                    </Row>
                </Container>
            </Wrapper>
            {isMianloading && <Loading ><div class="dots-loading"></div></Loading>}
        </>
    )
}

const Wrapper = styled.div`
    position: relative;
    padding: 0rem 0rem 1rem 0rem;
    background: url('/home_cover.webp');
    width: 100%;
    background-size: cover;
    background-position: center;
    background-reapete: center;
    background-repeat: no-repeat;
    min-height: calc(100vh - 60px);
`

const Heading = styled.div`
    font-size: 25px;
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
    line-height:1.5;
    color: ${palette.yellow_border};
    ${media.xs`
        font-size: 18px;
    `}
`

const Wrap = styled.div`
margin-top: 3.5rem;
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	cursor: pointer;
	background: ${palette.btn_gradient};
	color: ${palette.white};
	margin: auto;
	padding: 0.8rem 1rem;
	width: 150px;
	border-radius: 20px;
	border: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
	cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
    &hover{
        background: ${palette.btn_hover_gradient};
    }
`

const DateBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1rem;
	padding: 1rem;
	border-radius: 20px;
	color: white;
	cursor: pointer;
	background: ${({ active }) => active ? palette.yellow_gradient : palette.gray_100};
	&:hover{
		background: ${({ active }) => active ? palette.yellow_gradient : palette.yellow_gradient};
	}
    &:hover p{
        color: white;
	}
    &:hover div{
        color: white;
	}
	& p {
		color: ${({ active }) => active ? palette.white : palette.yellow_theme};
		font-size: 28px;
	}
	& div {
		color: ${({ active }) => active ? palette.white : palette.dark_gray};
	}
`

const AffBox = styled(DateBox)`
	background: ${({ active }) => active ? palette.dark_sea_green : palette.gray_100};
	&:hover{
		background: ${({ active }) => active ? palette.dark_sea_green : palette.dark_sea_green};
	}
	& p {
		color: ${({ active }) => active ? palette.white : palette.dark_sea_green};
		font-size: 28px;
	}
	& div {
		color: ${({ active }) => active ? palette.white : palette.dark_gray};
	}
`;

const DateInfo = styled.p`
	color: black;
`

const Check = styled.svg`
	height:  24px;
`;

const UnCheck = styled.svg`
	height:  24px;
	color: ${palette.light_gray};
`;

export default ChooseMode
