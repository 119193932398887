
import React from 'react'
import styled from 'styled-components'
import { palette } from '../../../styled/colors'
import { CommonEnum } from '../../../utils'
import { Text } from '../../../styled/shared'
import { media } from 'styled-bootstrap-grid'

const DownlaodWrapper = () => {
    return (
        <>
            <DownloadWraper>
                <CustomHeading>GOOD PEOPLE ARE <br /> ALL AROUND YOU</CustomHeading>
                <CustomText>Download {CommonEnum.AppName} and start making meaningful connections.</CustomText>
                <Button>Download now</Button>
            </DownloadWraper>
        </>
    )
}


const DownloadWraper = styled.div`
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    background: ${palette.hover_color};
    padding: 2rem 0rem;

`

const CustomHeading = styled(Text)`
    font-size:24px;
    line-height:2rem;
    font-weight:600;
    text-align: center;
    margin-bottom: 1rem;
    ${media.sm`
    font-size:2.5rem;
    line-height:4rem;
    font-weight:600;
    text-align: left;
    `};
    ${media.md`
    font-size:3.2rem;
    line-height:4rem;
    font-weight:600;
    text-align: left;
    `};
    ${media.lg`
    font-size:3.6rem;
    line-height:4.5rem;
    font-weight:800;
    text-align: left;
    `};
`;

const CustomText = styled.p`
    text-align:center;
    margin-top: 2rem;
    letter-spacing: 0px;
    font-size:1rem;
    line-height:1.5rem;
    font-weight:500;
    text-align: center;
    ${media.sm`
    font-size:1.5rem;
    line-height:2rem;
    font-weight:500;
    text-align: left;
    `};
    ${media.md`
    font-size:1.5rem;
    line-height:2rem;
    font-weight:500;
    text-align: left;
    `};
    ${media.lg`
    font-size:2rem;
    line-height:2.5rem;
    font-weight:500;
    text-align: left;
    `};
`

const Button = styled.div`
text-align: center;
border-radius: 40px;
background: ${palette.btn_gradient};
padding: .8rem 3rem;
cursor: pointer;
color: white;
text-decoration: none;
font-weight: 700;
margin-top: 2rem;
&:hover{
    background: ${palette.btn_hover_gradient};
}

`

export default DownlaodWrapper