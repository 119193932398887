import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useRouter from '../Common/UseRouterHook';
import { setUserDetails } from '../../redux/actions/authActions';
import { palette } from '../../styled/colors';

const UserDropdown = ({ user }) => {
    const _dispatch = useDispatch()
    const _navigate = useRouter()

    const logout = () => {
        sessionStorage.removeItem('authorization')
        _dispatch(setUserDetails())
        _navigate.navigate('/')
    }
    return (
        <DropdownContainer>
            <UserIcon src={user?.images?.length ? process.env.REACT_APP_PUBLIC_IMAGE_URL + '/' + user?.images[0] : ''} alt="User Icon" />
            <UserName>{user?.first_name}</UserName>
            <DropdownContent>
                <DropdownItem onClick={() => _navigate.navigate('/settings')} active={_navigate.pathname == '/settings'}>Settings</DropdownItem>
                <DropdownItem onClick={() => _navigate.navigate('/edit-profile')} active={_navigate.pathname == '/edit-profile'}>Edit Profile</DropdownItem>
                <DropdownItem onClick={logout}>Logout</DropdownItem>
            </DropdownContent>
        </DropdownContainer>
    );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  /* align-items: center;
  display: flex; */
`;

const UserIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserName = styled.span`
  font-size: 16px;
  font-weight: bold;
  position: relative;
  color: ${palette.text};
    top: -0.6rem;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${palette.black};
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  right:0;
  overflow: hidden;
  ${DropdownContainer}:hover & {
    display: block;
  }
  border: 1px solid ${palette.yellow_border}
`;

const DropdownItem = styled.span`
  color: ${palette.white};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  cursor: pointer;
  background: ${({ active }) => active ? 'linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%);' : ''};
  &:hover {
    background: linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%);
  }
`;

export default UserDropdown;
