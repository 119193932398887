import React, { useState, useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { getPassedTime } from '../../hooks'
import moment from 'moment';

const BothSideLikesWrapper = ({ userInfo }) => {
    let percentTimePassed
    const [chatTimer, setChatTimer] = useState(0)
    const [startIn, setStartIn] = useState(0)
    const [expiresIn, setExpiresIn] = useState(0)

    useEffect(() => {
        const startTime = userInfo?.updatedAt;
        const startTimeMoment = moment(startTime);
        const secondsStartTime = startTimeMoment.unix() * 1000;
        setStartIn(secondsStartTime)
        const endTime = userInfo?.expiresIn;
        const endTimeMoment = moment(endTime);
        const secondsEndTime = endTimeMoment.unix() * 1000;
        setExpiresIn(secondsEndTime)
    }, [])

    percentTimePassed = getPassedTime(startIn, expiresIn)
    useEffect(() => {
        setChatTimer(percentTimePassed)
    }, [percentTimePassed])

    return (
        <CircularProgressbar
            styles={buildStyles({
                pathColor: '#dcdcdc',
                trailColor: '#ffc629'
            })}
            value={chatTimer}
            circleRatio={1}
        />
    )
}

export default BothSideLikesWrapper
