import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Flexed } from '../styled/shared'
import { palette } from '../styled/colors'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { MdOutlineClose } from 'react-icons/md'
import 'react-circular-progressbar/dist/styles.css'
import ChatSideProfile from '../components/chat/ChatSideProfile'
import { getSpecficUserInfo, getUser, rematchUserProfile, reportUser, unLikeProfile } from '../apis/apis'
import { useLocation, useNavigate } from 'react-router-dom'
import { appContentReload } from '../redux/actions/authActions'
import { useDispatch, useSelector } from 'react-redux'
import EmojiInput from 'react-input-emoji'
import { media } from 'styled-bootstrap-grid'
import moment from 'moment'
import axios from 'axios'
import CountdownTimer from '../components/CountdownTimer'
import BothSideLikesWrapper from '../components/Sidebaar/BothSideLikesWrapper'
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'

const Connections = () => {
    // let percentTimePassed
    const environment = process.env.REACT_APP_PUBLIC_WEAVY_URL
    const apiKey = process.env.REACT_APP_PUBLIC_WEAVY_APIKEY
    const dispatch = useDispatch()
    const [openSideProfile, setOpenSiddeProfile] = useState(false)
    const [openActionDrop, setOpenActionDrop] = useState(false)
    const [myComment, setMyComment] = useState('')
    const _ref = useRef(null)
    const [mode, setMode] = useState('')
    const [currentUserId, setCurrentUserId] = useState('')
    const _navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const appContentReloads = useSelector((state) => state.auth.appContentReloads)
    const [chatId, setChatId] = useState('')
    const [domyToken, setDomeToken] = useState('')
    const [user, setUser] = useState([])
    const [openUnMatchModel, setOpenUnMatchModal] = useState(false)
    const [saveReason, setSaveReason] = useState({ id: null, reason: '' })
    const JobModal = (e) => { setOpenUnMatchModal(!openUnMatchModel) }
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            if (_ref.current && !_ref.current.contains(event.target)) {
                setOpenActionDrop(false)
            }
        }
        window.addEventListener('click', handleClickOutside)
        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [])

    const getUserinfo = async () => {
        setLoading(true)
        const response = await getSpecficUserInfo(id)
        if (response?.success) {
            setUser(response?.data?.data?.user)
            get(response?.data?.data?.user)
            creatingChatUser(response?.data?.data?.user)
            setLoading(false)
        }
    }
    const currentUserInfo = async () => {
        let response = await getUser()
        if (response?.success) {
            setCurrentUserId(response?.data?.data?.user?.id)
            setMode(response.data.data.user?.mode)
        }
    }

    useEffect(() => {
        getUserinfo()
        currentUserInfo()
    }, [id])

    const reportUserinfo = async (id) => {
        await reportUser(id)
    }

    const rematchUser = async () => {
        await rematchUserProfile(user?.id)
        dispatch(appContentReload(!appContentReloads))
        _navigate('/app')
    }

    const handleEmojiChange = (emoji) => {
        setMyComment(myComment + emoji)
    }

    const get = async (user) => {
        const headers = {
            Authorization: `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
        }
        await axios
            .get(`${environment}/api/users/${user?.id}a`, { headers })
            .then((response) => {
                setChatId(response?.data?.id)
            })
            .catch((error) => {
                console.log('createdUse Error:', error)
            })
    }

    const creatingChatUser = async (user) => {
        const url = `${environment}/api/users/${user?.id}a/tokens`
        const data = { expires_in: 7200 }
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`
        }
        await axios
            .post(url, data, { headers })
            .then(async (response) => {
                const accessToken = response.data.access_token
                setDomeToken(accessToken)
            })
            .catch((error) => {
                console.log('createdUse Error:', error)
            })
    }

    const creatChatG = async () => {
        const url = `${environment}/api/conversations`
        const data = {
            name: 'one-to-one',
            members: [chatId]
        }
        const headers = {
            Authorization: `Bearer ${domyToken}`,
            'Content-Type': 'application/json'
        }
        await axios
            .post(url, data, { headers })
            .then((response) => {
                const create = response.data
                sendMessage(create.id)
            })
            .catch((error) => {
                console.log('create chatChannel Error:', error)
            })
    }
    const sendMessage = async (id) => {
        const url = `${environment}/api/apps/${id}/messages`
        const data = { text: myComment }
        const headers = {
            Authorization: `Bearer ${domyToken}`,
            'Content-Type': 'application/json'
        }
        await axios
            .post(url, data, { headers })
            .then((response) => {
                console.log('send message:-', response.data)
                setMyComment('')
            })
            .catch((error) => {
                console.log('send message Error:', error)
            })
    }

    const doCompliment = async () => {
        const data = { profile_id: user?.id, compliment: myComment }
        const _token = sessionStorage.getItem('authorization')
        const config = {
            headers: { authorization: `bearer ${_token}` }
        }
        try {
            await axios
                .post(`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/add_compliment`, data, config)
                .then(async (response) => {
                    if (response.data.success === true) {
                        await creatChatG()
                        await getUserinfo()
                        dispatch(appContentReload(!appContentReloads))
                    } else {
                    }
                })
                .catch((error) => {
                })
        } catch (error) {
            console.log('error', error.message)
        }
    }

    let unMatchSeasonList = [
        {
            id: 1,
            icon: '/heart.svg',
            name: 'I’m just not interested'
        },
        {
            id: 2,
            icon: '/heart.svg',
            name: 'Using DFF to date'
        },
        {
            id: 3,
            icon: '/uncomfert.svg',
            name: 'Made me uncomfortable'
        },
        {
            id: 4,
            icon: '/chat.png',
            name: 'Abusive or threatening'
        },
        {
            id: 5,
            name: 'Inappropriate content',
            icon: '/warning.svg'
        },
        {
            id: 6,
            name: 'Spam or scam',
            icon: '/flag.png'
        },

        {
            id: 7,
            name: 'Stolen photo',
            icon: '/stolen-photo.svg'
        }
    ]

    const mybasic = async (id, reason) => {
        await unLikeProfile(id)
        setSaveReason({ id: null, reason: '' })
        JobModal()
        _navigate('/app')
    }
    return (
        <>{user ? <>
            <Wrapper open={openSideProfile}>
                <ChatHeader>
                    <Profile
                        pointer
                        align="center"
                        gap={0.8}
                        margin="0.5rem 0rem 0.5rem 0rem"
                        onClick={() => {
                            setOpenSiddeProfile(!openSideProfile)
                        }}>
                        <ChatUserProfile>{user?.images && user?.images.length && <Img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[0]}`} alt="" />}</ChatUserProfile>
                        <Flexed direction="column" gap={0.2}>
                            <UserName> {user?.first_name}</UserName>
                        </Flexed>
                    </Profile>
                    <Flexed gap={0.5}>
                        <Action
                            align="center"
                            ref={_ref}
                            onClick={() => {
                                setOpenActionDrop(!openActionDrop)
                            }}>
                            <Dots active={openActionDrop} fontSize="1.5rem" cursor="pointer" />
                            <DropDown active={openActionDrop}>
                                <DropList onClick={() => setOpenUnMatchModal(true)}>Unmatch</DropList>
                                <DropList onClick={() => reportUserinfo(user?.id)}>Block and report</DropList>
                            </DropDown>
                        </Action>
                        <Close to="/app">
                            <CloseIcon fontSize="1.9rem" cursor="pointer" />
                        </Close>
                    </Flexed>
                </ChatHeader>
                <ChatContent direction="column" align="center">
                    <div></div>
                    <Flexed direction="column" align="center">
                        <ChatProfile
                            onClick={() => {
                                setOpenSiddeProfile(!openSideProfile)
                            }}>
                            {user?.is_compliment === 0 &&
                                <BothSideLikesWrapper userInfo={user} />
                            }


                            {user?.images && user?.images?.length && <Img src={`${process.env.REACT_APP_PUBLIC_IMAGE_URL}/${user?.images[0]}`} alt="" />}
                        </ChatProfile>
                        {moment(moment(user?.expiresIn).format()).isAfter(moment().format()) ?
                            <>
                                <Heading>Make a move</Heading>
                                <Text>You only have <CountdownTimer targetDate={user?.expiresIn} /> left to start a chat before they disappear forever.</Text>
                            </>
                            :
                            <>
                                <Heading>This match has expired</Heading>
                                <Text>{`Still interested? No problem, just click ‘Rematch’ to reset the clock`}</Text>
                                <Button onClick={rematchUser}>Rematch</Button>
                            </>
                        }
                    </Flexed>
                    {(moment(moment(user?.expiresIn).format()).isAfter(moment().format())) ? <InputWrapper>
                        <EmojiInput
                            fontSize="18px"
                            value={myComment}
                            onChange={setMyComment}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    e.stopPropagation()

                                }
                            }}
                            placeholder="Start chatting..."
                            onEmojiClick={handleEmojiChange}
                        />
                        <Icon direction="row" align="center" gap={3.65}>
                            <Flexed direction="row" align="center" gap={1.125}>
                                <SendMessageButton active={myComment?.length > 0} onClick={() => { doCompliment() }} data-origin="pipeline" viewBox="0 0 40 40" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24.886 14.438c.583-.3.646-.212.143.199l-10.23 8.394c-.446.322-.424.82-.424 1.344v4.063c0 .634.444 1.047.938.625l3.753-3.518 3.009 2.83c.236.22.512.158.627-.139l6.636-17.24c.115-.296-.046-.446-.354-.334l-18.08 6.531c-.311.114-.37.384-.134.605l2.268 2.13a.89.89 0 00.953.13l10.895-5.62z" fill="#fff"></path></SendMessageButton>
                            </Flexed>
                        </Icon>
                    </InputWrapper>
                        :
                        <div></div>
                    }
                </ChatContent>

            </Wrapper>
            <ChatSideProfile
                user={user}
                currentUserMode={mode}
                open={openSideProfile}
                close={() => {
                    setOpenSiddeProfile(!openSideProfile)
                }}
            />
        </>
            :
            null
        }
            <ModalProvider backgroundComponent={CustomReportModalBackground}>
                <div>
                    <StyledModal id="modelWrap" isOpen={openUnMatchModel} onBackgroundClick={JobModal} onEscapeKeydown={JobModal}>
                        <div>
                            <ModelHeading>Everything OK?</ModelHeading>
                            <ModelDescription>Help us keep the Hive safe by telling us if there’s a reason why you’ve unmatched. Don’t worry, this is anonymous.</ModelDescription>
                            {unMatchSeasonList.map((text) => (
                                <ChooseList active={saveReason.reason == text?.name}>
                                    <Flexed gap={0.5} align='start'>
                                        <ModelIcons src={text.icon} alt={text?.name} />
                                        <Span onClick={() => setSaveReason({ id: text?.id, reason: text?.name })}>{text?.name}</Span>
                                    </Flexed>
                                </ChooseList>
                            ))}
                            <ButtonDiv>
                                <ContinueButton opacity={user.id && saveReason.reason ? 1 : 0.5} onClick={() => user?.id && saveReason.reason && mybasic(user?.id, saveReason?.reason)}>Continue</ContinueButton>
                            </ButtonDiv>
                        </div>

                    </StyledModal>
                </div>
            </ModalProvider>
            {loading && <Loading ><div class="dots-loading"></div></Loading>}
        </>
    )
}

const Wrapper = styled.div`
    width: ${({ open }) => (open ? '100%' : '100%')};
    overflow: scroll;
    height: 100vh;
    ${media.lg`
        width: ${({ open }) => (open ? 'calc(100% - 702px)' : 'calc(100% - 400px)')};
    `}
    background: ${palette.black};
    transition: all 0.3s;
`

const ChatHeader = styled.div`
	position: sticky;
	top: 4.6rem;
	left: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 72px;
	border-bottom: 1px solid ${palette.yellow_border};
	padding: 0.5rem 1rem;
	background: ${palette.black};
	z-index: 3;
`

const UserName = styled.div`
	color: ${palette.yellow_border};
	font-size: 20px;
	font-weight: 500;
`

const Profile = styled(Flexed)`
	&:hover ${UserName} {
		color: ${palette.yellow_theme};
	}
`

const ChatUserProfile = styled.div`
	width: 52px;
	height: 52px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`

const Img = styled.img`
	width: 52px;
	height: 52px;
	border-radius: 100%;
`

const Action = styled(Flexed)`
	position: relative;
`
const Close = styled(Link)`
	display: flex;
	align-items: center;
`

const DropDown = styled.div`
	width: 145px;
	height: auto;
	display: ${({ active }) => (active ? 'block' : 'none')};
	position: absolute;
	z-index: 10;
	top: 2.5rem;
	right: 0;
	overflow: hidden;
	border: 1px solid ${palette.yellow_border};
	border-radius: 16px;
	background: ${palette.black};
	padding: 8px 0;
`

const DropList = styled.span`
	display: flex;
	align-items: center;
	height: 32px;
	padding: 0 16px;
	font-size: 14px;
	line-height: 1.429;
	color: ${({ active }) => (active ? palette.yellow_border : palette.white)};
	&:hover {
		background: ${palette.yellow_gradient};
		cursor: pointer;
	}
`

const Dots = styled(BsThreeDotsVertical)`
    color: ${({ active }) => (active ? palette.yellow_border : palette.text)};
    &:hover{
        color: ${palette.yellow_border};
    }
`

const CloseIcon = styled(MdOutlineClose)`
    color: ${palette.text};
    &:hover{
        color:${palette.yellow_border};
    }
`

const ChatContent = styled(Flexed)`
	padding: 1rem 1rem 0.5rem 1rem;
	height: calc(100vh - 75px);
    justify-content: space-between;
`

const ChatProfile = styled.div`
	width: 188px;
	height: 188px;
	position: relative;
	cursor: pointer;
	& img {
		width: 170px;
		height: 170px;
		border: 4px solid ${palette.white};
		border-radius: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
`

const Heading = styled.div`
	margin-top: 20px;
	color: ${palette.yellow_border};
	font-size: 22px;
	font-weight: 500;
`

const Text = styled.div`
	margin-top: 20px;
	color: ${palette.white};
	font-size: 16px;
	font-weight: 500;
	line-height: 1.6;
	text-align: center;
`

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: ${palette.btn_gradient};
	margin: auto;
	padding: 0.7rem 1rem;
	width: 105px;
	color: ${palette.white};
	border-radius: 2rem;
	border: none;
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-size: 1rem;
	letter-spacing: 0.02em;
    &:hover{
        background: ${palette.btn_hover_gradient};
    }
`

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`

const Icon = styled(Flexed)`
	position: absolute;
	right: 0.4rem;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: 1;
`

const SendMessageButton = styled.svg`
	color: ${({ active }) => active ? palette.yellow_theme : palette.light_gray};
	cursor: ${({ active }) => active ? 'pointer' : ''};
	pointer-events: ${({ active }) => (active ? 'default' : 'none')};
	height: 40px;
`

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
`

const StyledModal = Modal.styled`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.black};
  padding: 2rem;
  border-radius: 0.5rem;
  position: relative;
border: 1px solid ${palette.yellow_border};
${media.md`
	width: 30rem;
    `}
${media.lg`
		left: -12rem;
	`}
`

const ModelHeading = styled.h2`
	text-align: center;
	font-weight: 400;
    color: ${palette.yellow_border};
`

const ModelDescription = styled.span`
	margin: 1rem;
	text-align: center;
	display: flex;
	justify-content: center;
	opacity: 0.8;
    line-height: 1.5;
    color: ${palette.text};
`

const ChooseList = styled.div`
	position: relative;
	display: flex;
	/* justify-content: center; */
	border-radius: 40px;
	cursor: pointer;
	padding: 0.65rem 1.5rem;
	/* width: 20rem; */
	border: 1px solid ${palette.yellow_border};
    background: ${({ active }) => active ? palette.yellow_gradient : palette.black};
    color: ${palette.white};
	text-align: center;
	margin-top: 0.6rem;
	overflow: scroll;
	text-align: center;
	align-items: center;
	&:hover{
        background: ${palette.yellow_gradient};
    }
`

const ButtonDiv = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
`

const ModelIcons = styled.img`
	width: 1rem;
	height: 1rem;
`

const Span = styled.p`
	font-size: 16px;
	text-align: center;

`

const ContinueButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: top;
	min-width: 40px;
	min-height: 40px;
	height: 40px;
	padding: 5px 20px;
	border-radius: calc(40px / 2);
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	opacity: ${({ opacity }) => opacity ? opacity : 0.3};
	background: ${palette.btn_gradient};
	border: none;
	color: white;
	font-size: 1rem;
	:hover{
		background: ${palette.btn_hover_gradient};
    text-decoration: none;
	}
`

const CustomReportModalBackground = styled(BaseModalBackground)`
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(10px);
    ${media.lg`
	left: 25rem;
    `}
`;

export default Connections
