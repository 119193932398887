import React from 'react'
import styled from 'styled-components'
import { palette } from '../styled/colors'
import { Flexed } from '../styled/shared'
import { IoIosArrowBack } from "react-icons/io";

const RegisterStepper = ({ goBack, progress }) => {
    return (
        <Flexed justify='space-between' align='center'>
            <LeftArrow onClick={goBack} />

            <Wrapper>
                <ProgressBar>
                    <Bar progress={progress} />
                </ProgressBar>
            </Wrapper>

            <HiddenBlock></HiddenBlock>
        </Flexed>
    )
}

const LeftArrow = styled(IoIosArrowBack)`
    font-size: 26px;
    color: ${palette.yellow_border};
	transition: all 0.3s ease;
    cursor: pointer;
    &:hover{
        color: #535353;
    }
`;

const Wrapper = styled.div`
    width: 200px;
`;

const ProgressBar = styled.div`
	width: 100%;
	height: 4px;
	background: ${palette.light_gray};
    overflow: hidden;
    border-radius: 2px;
`;

const Bar = styled.div`
	width: ${({ progress }) => progress}%;
	height: 4px;
	background: ${palette.hover_color};
`;

const HiddenBlock = styled.div`
    width: 26px;
    height: 26px;
`;

export default RegisterStepper
