import React, { useEffect, useState, useRef } from 'react'
import { useCollapse } from 'react-collapsed'
import styled from 'styled-components'
import BasicList from './BasicList'
import { media } from 'styled-bootstrap-grid'
import { getAllMybasicAffMode, getAllMybasicDateMode, getUser } from '../../../apis/apis'
import axios from 'axios'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { ModalProvider } from 'styled-react-modal'
import Modal from 'styled-react-modal'
import { palette } from '../../../styled/colors'
import { IoIosArrowForward } from 'react-icons/io'
import { Flexed, Spacer, Text } from '../../../styled/shared'
import { genderList } from '../../../pages/Gender'

const MyBasicCollapse = () => {
	const _whereLiveRef = useRef(null)
	const _whereIamFromRef = useRef(null)
	const getBasicData = (data) => data
	const [secIsOpen2, secSetIsOpen2] = useState(false)
	const WhereIFromModel = (e) => secSetIsOpen2(!secIsOpen2)
	const [isExpanded, setExpanded] = useState(false)
	const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
	const [whereToLocation, setWhereToLocation] = useState(false)
	const [secIsOpen, secSetIsOpen] = useState(false)
	const EduModel = (e) => secSetIsOpen(!secIsOpen)
	const [usergender, setUserGender] = useState('')
	const [gender, setGender] = useState('')
	const [user, setUser] = useState([])
	const [recall, setRecall] = useState(false)
	const [place, setPlace] = useState('')
	const [whereFrom, setWhereFrom] = useState('')
	const [location, setLocation] = useState(false)
	const [basicAff, setBasicAff] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [openGenderModal, setOpenGenderModal] = useState(false)
	const [genderMore, setGenderMore] = useState('')

	useEffect(() => {
		if (user?.mode == 'aff') {
			getMybasicAffMode()
		} else {
			getMybasicDateMode()
		}
	}, [user])

	const getUserProfile = async () => {
		setIsLoading(true)
		const response = await getUser()
		setIsLoading(false)
		setUser(response?.data?.data?.user)
		setUserGender(response?.data?.data?.user?.gender)
		if (user?.mode === 'aff') {
			let arr = []
			basicAff?.map((res) => {
				if (res?.title === 'Exercise') {
					arr.push({ ...res, selected: response?.data?.data?.user?.exercise })
				} else if (res?.title === 'Drinking') {
					arr.push({ ...res, selected: user?.drinking })
				} else if (res?.title === 'Smoking') {
					arr.push({ ...res, selected: user?.smoking })
				} else if (res?.title === 'Looking for') {
					arr.push({ ...res, selected: user?.looking_for_aff })
				} else if (res?.title === 'Kids') {
					arr.push({ ...res, selected: user?.have_kids })
				} else if (res?.title === 'Star sign') {
					arr.push({ ...res, selected: user?.star_sign })
				} else if (res?.title === 'Religion') {
					arr.push({ ...res, selected: user?.religion })
				} else if (res?.title === 'Relationship') {
					arr.push({ ...res, selected: user?.relation_ship })
				} else if (res?.title === 'New To Area') {
					arr.push({ ...res, selected: user?.new_to_area })
				} else {
					arr.push(res)
				}
			})

			setBasicAff(arr)
		} else {
			let arr = []
			basicAff?.map((res) => {
				if (res?.title === 'Exercise') {
					arr.push({ ...res, selected: response?.data?.data?.user?.exercise })
				} else if (res?.title === 'Drinking') {
					arr.push({ ...res, selected: user?.drinking })
				} else if (res?.title === 'Smoking') {
					arr.push({ ...res, selected: user?.smoking })
				} else if (res?.title === 'Looking for') {
					arr.push({ ...res, selected: user?.looking_for_aff })
				} else if (res?.title === 'Kids') {
					arr.push({ ...res, selected: user?.have_kids })
				} else if (res?.title === 'Star sign') {
					arr.push({ ...res, selected: user?.star_sign })
				} else if (res?.title === 'Religion') {
					arr.push({ ...res, selected: user?.religion })
				} else if (res?.title === 'Relationship') {
					arr.push({ ...res, selected: user?.relation_ship })
				} else if (res?.title === 'New To Area') {
					arr.push({ ...res, selected: user?.new_to_area })
				} else if (res?.title === 'Politics') {
					arr.push({ ...res, selected: user?.politic })
				} else if (res?.title === 'Education level') {
					arr.push({ ...res, selected: user?.education_level })
				} else if (res?.title === 'Height') {
					arr.push({ ...res, selected: user?.height })
				} else {
					arr.push(res)
				}

			})

			setBasicAff(arr)
		}
		if (response?.data?.data?.user?.gender === 'Woman' || response?.data?.data?.user?.gender === 'Man') {
			setGender(response?.data?.data?.user?.gender)
		}
		else {
			setGenderMore(response?.data?.data?.user?.gender)
		}

	}

	const getMybasicAffMode = async () => {
		let data = await getAllMybasicAffMode()
		let arr = []
		data.data.data.basicAff?.map((res) => {
			if (res?.title === 'Exercise') {
				arr.push({ ...res, selected: user?.exercise })
			} else if (res?.title === 'Drinking') {
				arr.push({ ...res, selected: user?.drinking })
			} else if (res?.title === 'Smoking') {
				arr.push({ ...res, selected: user?.smoking })
			} else if (res?.title === 'Looking for') {
				arr.push({ ...res, selected: user?.looking_for_aff })
			} else if (res?.title === 'Kids') {
				arr.push({ ...res, selected: user?.have_kids })
			} else if (res?.title === 'Star sign') {
				arr.push({ ...res, selected: user?.star_sign })
			} else if (res?.title === 'Religion') {
				arr.push({ ...res, selected: user?.religion })
			} else if (res?.title === 'Politics') {
				arr.push({ ...res, selected: user?.politic })
			} else if (res?.title === 'Relationship') {
				arr.push({ ...res, selected: user?.relation_ship })
			} else if (res?.title === 'New To Area') {
				arr.push({ ...res, selected: user?.new_to_area })
			} else {
				arr.push(res)
			}
		})
		setBasicAff(arr)
	}

	const getMybasicDateMode = async () => {
		let data = await getAllMybasicDateMode()
		let arr = []
		data.data.data.basicDate?.map((res) => {
			if (res?.title === 'Exercise') {
				arr.push({ ...res, selected: user?.exercise })
			} else if (res?.title === 'Drinking') {
				arr.push({ ...res, selected: user?.drinking })
			} else if (res?.title === 'Smoking') {
				arr.push({ ...res, selected: user?.smoking })
			} else if (res?.title === 'Looking for') {
				arr.push({ ...res, selected: user?.looking_for_date })
			} else if (res?.title === 'Kids') {
				arr.push({ ...res, selected: user?.kid })
			} else if (res?.title === 'Star sign') {
				arr.push({ ...res, selected: user?.star_sign })
			} else if (res?.title === 'Religion') {
				arr.push({ ...res, selected: user?.religion })
			} else if (res?.title === 'Politics') {
				arr.push({ ...res, selected: user?.politic })
			} else if (res?.title === 'Education level') {
				arr.push({ ...res, selected: user?.education_level })
			} else if (res?.title === 'Height') {
				arr.push({ ...res, selected: user?.height })
			} else {
				arr.push(res)
			}
		})
		setBasicAff(arr)
	}
	useEffect(() => {
		getUserProfile()
	}, [recall])

	const genderhandler = async (gender) => {
		setIsLoading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}

		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					gender: gender
				},
				config
			)
			.then((response) => {
				if (response.data.success === true) {
					if (response.data.data.userdata?.gender === 'Woman' || response.data.data.userdata?.gender === 'Man') {
						setGender(response.data.data.userdata?.gender)
					}
					else {
						setGenderMore(response.data.data.userdata?.gender)
					}
					setIsLoading(false)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const addHomeTown = async () => {
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					home_town: place.label
				},
				config
			)
			.then(async (response) => {
				if (response.data.success === true) {
					await getUserProfile()
					setLocation(false)
				}
			})
			.catch((error) => {
				console.error(error)
				setLocation(false)
			})
	}
	const addWhereIFrom = async () => {
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					location_where_i_from: whereFrom.label
				},
				config
			)
			.then(async (response) => {
				if (response.data.success === true) {
					await getUserProfile()
					setWhereToLocation(false)

				}
			})
			.catch((error) => {
				console.error(error)
				setWhereToLocation(false)
			})
	}

	useEffect(() => {
		addHomeTown()

	}, [place])

	useEffect(() => {
		addWhereIFrom()

	}, [whereFrom])

	const removelocation = async () => {
		setIsLoading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					home_town: ''
				},
				config
			)
			.then(async (response) => {
				if (response.data.success === true) {
					EduModel()
					setIsLoading(false)
					await getUserProfile()
				}
			})
			.catch((error) => {
				EduModel()
				setIsLoading(false)
				console.error(error)
			})
	}

	const removeWhereFrom = async () => {
		setIsLoading(true)
		const _token = sessionStorage.getItem('authorization')
		const config = {
			headers: { authorization: `bearer ${_token}` }
		}
		await axios
			.post(
				`${process.env.REACT_APP_PUBLIC_BACKEND_HOST}/user/update`,
				{
					location_where_i_from: ''
				},
				config
			)
			.then(async (response) => {
				if (response.data.success === true) {
					WhereIFromModel()
					setIsLoading(false)
					await getUserProfile()
				}
			})
			.catch((error) => {
				console.error(error)
				WhereIFromModel()
				setIsLoading(false)
			})
	}


	const addWhereFrom = async () => {
		setWhereToLocation(true)
		WhereIFromModel()
	}

	const addlocation = async () => {
		setLocation(true)
		EduModel()
	}

	const genderModalToggle = async (e) => {
		setOpenGenderModal(!openGenderModal)
	}


	return (
		<>
			<Box isExpanded={isExpanded} {...getToggleProps({
				onClick: () => { !isExpanded && setExpanded(!isExpanded) },
			})}>
				<BoxHeader justify='space-between' align='center' {...getToggleProps({
					onClick: () => setExpanded((prevExpanded) => !prevExpanded),
				})}>
					<Heading {...getToggleProps()}>My Basics</Heading>
					<RightArrow active={isExpanded} />
				</BoxHeader>
				<InnerContent {...getCollapseProps()}>
					<Spacer height={1} />
					{basicAff?.map((data) => {
						return (
							<>
								<BasicList data={data} getBasicData={getBasicData} setRecall={setRecall} recall={recall} user={user} />
							</>
						)
					})}
					<Spacer height={1.5} />
					<Text type='normal' color='text'>Your gender</Text>
					<Wrap isActive={gender === 'Woman'} onClick={() => genderhandler('Woman')}>
						{gender === 'Woman' ?
							<Check data-origin="pipeline" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" fill={palette.brown_rgb}></path><path d="M17.407 7.532a.75.75 0 111.06 1.06L11.28 15.78a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 111.06-1.06l3.22 3.22 6.657-6.658z" fill="#fff"></path></Check>
							:
							<UnCheck data-origin="pipeline" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="12" fill="#fff"></circle><circle cx="12" cy="12" r="11" fill="#000"></circle></UnCheck>
						}
						<label htmlFor="select">Woman</label>
					</Wrap>
					<Wrap isActive={gender === 'Man'} onClick={() => genderhandler('Man')}>
						{gender === 'Man' ?
							<Check data-origin="pipeline" viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z" fill={palette.brown_rgb}></path><path d="M17.407 7.532a.75.75 0 111.06 1.06L11.28 15.78a.75.75 0 01-1.06 0l-3.75-3.75a.75.75 0 111.06-1.06l3.22 3.22 6.657-6.658z" fill="#fff"></path></Check>
							:
							<UnCheck data-origin="pipeline" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="12" fill="#fff"></circle><circle cx="12" cy="12" r="11" fill="#000"></circle></UnCheck>
						}
						<label htmlFor="select2">Man</label>
					</Wrap>

					<Spacer height={1.5} />
					<Text type='normal' color='text'>My places</Text>

					<Choose ref={_whereLiveRef} onClick={() => { (user?.location_where_i_from && !whereToLocation) ? WhereIFromModel() : setWhereToLocation(true) }}>
						{(whereToLocation === true) ? (
							<PlacesInputWrapper >
								<GooglePlacesAutocomplete
									apiKey={process.env.REACT_APP_MAP_API_KEY}
									selectProps={{
										placeholder: 'Add where I live',
										whereFrom,
										onChange: setWhereFrom,
										className: 'customGooglePlacesAutocomplete',
										// menuIsOpen: true,
										styles: {
											control: (base, state) => ({
												...base,
												backgroundColor: 'black',
												color: 'white',
												border: '1px solid #ccc',
												
												outline: 0,
												borderColor: state.isFocused ? 'none' : 'none',
												boxShadow: state.isFocused ? 'none' : 'none',
												'&:hover': {
												  borderColor: state.isFocused
													? '#fe5b60'
													: 'hsl(0deg 0% 26.27%)',
												}
											  }),
											  menu: (base) => ({
												...base,
												backgroundColor: 'black',
												color: 'white',
												border: '1px solid rgb(255, 174, 87)',
											  }),
											  option: (provided) => ({
												...provided,
												backgroundColor: 'black',
												color: 'white',
												'&:hover': {
													background: 'linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%)',
												},
												zIndex: '2',
												position: 'relative'
											}),
											singleValue: (provided) => ({
												...provided,
												position: 'relative',
												zIndex: '2',
												color: 'white',
											}),
										},
									}}


								/>
							</PlacesInputWrapper>
						) : <>
							<LocationText type='normal' color='text'>{user?.location_where_i_from ? user?.location_where_i_from : "Add where I live"}</LocationText>
							{user?.location_where_i_from ? <ArrowRight /> :
								<svg style={{ color: 'lightgray', cursor: 'pointer' }} height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
								</svg>
							}
						</>}
					</Choose>

					<Choose ref={_whereIamFromRef} onClick={() => { (user?.home_town && !location) ? EduModel() : setLocation(true) }}>
						{(location === true) ? (
							<PlacesInputWrapper >
								<GooglePlacesAutocomplete
									apiKey={process.env.REACT_APP_MAP_API_KEY}
									selectProps={{
										placeholder: "Add where I'm from",
										place,
										onChange: setPlace,
										className: 'customGooglePlacesAutocomplete',
										// menuIsOpen: true,
										styles: {
											control: (base, state) => ({
												...base,
												backgroundColor: 'black',
												color: 'white',
												border: '1px solid #ccc',
												
												outline: 0,
												borderColor: state.isFocused ? 'none' : 'none',
												boxShadow: state.isFocused ? 'none' : 'none',
												'&:hover': {
												  borderColor: state.isFocused
													? '#fe5b60'
													: 'hsl(0deg 0% 26.27%)',
												}
											  }),
											  menu: (base) => ({
												...base,
												backgroundColor: 'black',
												color: 'white',
												border: '1px solid rgb(255, 174, 87)',
											  }),
											  option: (provided) => ({
												...provided,
												backgroundColor: 'black',
												color: 'white',
												'&:hover': {
													background: 'linear-gradient(90deg, rgb(221, 128, 73) 0%, rgb(210, 159, 78) 100%, rgb(0, 212, 255) 100%)',
												},
												zIndex: '2',
												position: 'relative'
											}),
											singleValue: (provided) => ({
												...provided,
												position: 'relative',
												zIndex: '2',
												color: 'white',
											}),
										},
									}}
								/>
							</PlacesInputWrapper>
						) :
							<>
								<LocationText type='normal' color='text'>{user?.home_town ? user?.home_town : "Add where I’m from"}</LocationText>
								{user?.home_town ? <ArrowRight /> :
									<svg style={{ color: 'lightgray', cursor: 'pointer' }} height="20px" data-origin="pipeline" viewBox="0 0 32 32" fill="none">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M18 18v9.333a2 2 0 01-4 0V18H4.667a2 2 0 110-4H14V4.667a2 2 0 114 0V14h9.333a2 2 0 010 4H18z" fill="currentColor"></path>
									</svg>
								}
							</>
						}

					</Choose>

				</InnerContent>
			</Box>
			<ModalProvider>
				<div>
					<StyledModal isOpen={secIsOpen2} onBackgroundClick={WhereIFromModel} onEscapeKeydown={WhereIFromModel}>
						<div>
							<ModelHeading>How do you want to change your city?</ModelHeading>
							<Flexed margin='1rem 0rem 0rem 0rem' gap={1} align='center' justify='center'>
								<CancelButton onClick={removeWhereFrom}>Remove</CancelButton>
								<Button onClick={addWhereFrom}>Update</Button>
							</Flexed>
						</div>
					</StyledModal>
				</div>
			</ModalProvider>
			<ModalProvider>
				<div>
					<StyledModal isOpen={secIsOpen} onBackgroundClick={EduModel} onEscapeKeydown={EduModel}>
						<div>
							<ModelHeading>How do you want to change your city?</ModelHeading>
							<Flexed margin='1rem 0rem 0rem 0rem' gap={1} align='center' justify='center'>
								<CancelButton onClick={removelocation}>Remove</CancelButton>
								<Button onClick={addlocation}>Update</Button>
							</Flexed>
						</div>
					</StyledModal>
				</div>
			</ModalProvider>
			<ModalProvider>
				<StyledModal id="modelWrap" isOpen={openGenderModal} onBackgroundClick={genderModalToggle} onEscapeKeydown={genderModalToggle}>
					<div>
						<ModelHeading>Select your gender</ModelHeading>
						{genderList.map((gender) => {
							return (
								<ChooseGender onClick={() => { setGenderMore(gender); setGender(''); setOpenGenderModal(false) }}>
									{gender}
								</ChooseGender>
							)
						})}

					</div>
				</StyledModal>
			</ModalProvider>

			{isLoading && <Loading ><div class="dots-loading"></div></Loading>}
		</>
	)
}

const Choose = styled.div`
	cursor: pointer;
	border: 1px solid ${({ active }) => active ? palette.yellow_border : palette.yellow_border};
	border-radius: 20px;
	padding: 0.1rem 1rem;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${palette.black};
	color: ${palette.whitw};
	margin-top: 0.7rem;
	position: relative;
	z-index: auto;
	&:hover{
		border: 1px solid ${({ active }) => active ? palette.yellow_border : palette.yellow_border};
		background: ${palette.black};
	}
`

const RightArrow = styled(IoIosArrowForward)`
    font-size: 22px;
    /* color: ${palette.light_gray}; */
	transform: ${({ active }) => active ? 'rotate(-90deg)' : 'rotate(90deg)'};
	transition: all 0.3s ease;
`;


const Box = styled.div`
	padding: 2rem 1rem;
	text-align: left;
	border-radius: 20px;
	margin-top: 1rem;
	border: 0.5px solid  ${({ isExpanded }) => isExpanded ? palette.yellow_border : palette.yellow_border};
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	background: ${({ isExpanded }) => isExpanded ? palette.yellow_gradient : ''};
	color:  ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	&:hover {
		border: 0.5px solid  ${palette.yellow_border};
		background: ${palette.yellow_gradient};
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	}
`
const BoxHeader = styled(Flexed)`
	cursor: ${({ isExpanded }) => isExpanded ? '' : 'pointer'};
	&:hover ${RightArrow}{
		color: ${({ isExpanded }) => isExpanded ? palette.text : palette.text};
	};
	/* &:hover{
		color: ${({ isExpanded }) => palette.black};
	} */
`

const Heading = styled.h3`
	font-weight: 400;
	cursor: pointer;
`

const Wrap = styled.div`
	cursor: pointer;
	border: 1px solid ${({ isActive }) => isActive ? palette.yellow_border : palette.yellow_border};
	border-radius: 20px;
	display: flex;
	align-items: center;
	background: ${palette.black};
	margin-top: 0.7rem;
	height:66px;
	padding: 0.8rem 1rem;
	gap: 1rem;
	&:hover{
		border: 1px solid ${palette.hover_color};
		background: ${palette.black};
	}
`

const Input = styled.input`
	cursor: pointer;
	margin-left: 1rem;
	margin-right: 0.5rem;
`

const StyledModal = Modal.styled`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid ${palette.yellow_border};
  background: ${palette.black};
  ${media.xs`
  width: 90%;
    `}

`

const ModelHeading = styled.h2`
	text-align: center;
	padding-bottom: 1rem;
	font-weight: 400;
	color: ${palette.yellow_border};
`
const Center = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

const InnerContent = styled.section`
	position:relative;
`;

const Check = styled.svg`
	height: 24px;
`

const UnCheck = styled(Check)`
`

const PlacesInputWrapper = styled.div`
	width: 100%;
	position: relative;
	// z-index: 1;
	& > div > div{
		border: none !important;
		outline: none !important;
	}
	& > div > div:nth-child(3){
		box-shadow: none !important;
	}
	& > div > div > div span:nth-last-child(2){
		display:none;
	}
	& > div > div > div:nth-last-child {
		display:none;
	}
	& > div > div > div > div svg{
		display: none;
	}
	& > div > div > div{
		padding-right: 0px;
		padding-left: 0px;
	}
	& > div > div{
		// background:transparent;
	}
	& > div > div div input{
		color:white !important;
	}
	& #react-select-2-listbox{
		box-shadow: auto !important;
		background:black;
	}
`

const Button = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: white;
	background: ${palette.btn_gradient};
	border: none;
	font-size: 16px;
    cursor: pointer;
	&:hover{
		background: ${palette.btn_hover_gradient};
	}
`

const CancelButton = styled.button`
	padding: 0.5rem 1.1rem;
	border-radius: 20px;
	color: ${palette.hover_color};
    background: transparent;
	cursor: pointer;
	border: none;
	font-size: 16px;
    &:hover{
        background: #00000003;
    }
`

const ArrowRight = styled(IoIosArrowForward)`
    font-size: 22px;
    color: ${palette.light_gray};
`;

const Loading = styled.div`
	position: fixed;
	background: #00000082;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	top:0;
	bottom:0;
	right:0;
	left:0;
`

const LocationText = styled(Text)`
	width : 300px;
	overflow:hidden;
	display:inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
`

const MoreChoose = styled.div`
	width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
	border-radius: 20px;
	padding: 0.1rem 1rem;
	margin-top: 1rem;
	cursor: pointer;
	border: 1px solid ${palette.light_gray};
    height: 40px;
    color: ${palette.dark_gray};
	background: ${palette.white};
    font-weight: 500;
    &:hover{
        background: ${palette.gray_200};
        border: 1px solid #c6c6c6;
    }
    &:hover ${ArrowRight}{
        color: #c6c6c6;
    }
`

const ChooseGender = styled(Choose)`
	width: 100%;
`

export default MyBasicCollapse
