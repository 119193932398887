

import React from 'react'
import styled from 'styled-components'
import ShopCardWraper from './ShopCard/ShopCardWraper'
import { CommonEnum } from '../../utils'
import { palette } from '../../styled/colors'
import { media } from 'styled-bootstrap-grid'

const IRL = () => {

    return (
        <>
            <IRLWraper>
                <Heading>{CommonEnum.AppName} IRL</Heading>
                <Text>
                    We love seeing you make moves in {CommonEnum.AppName} gear. Tag @{CommonEnum.AppName}
                    <br/>
                    or
                    <br/> #{CommonEnum.AppName}shop for the chance to be featured.
                </Text>
                <ShopCardWraper />
            </IRLWraper>
        </>
    )
}


const IRLWraper = styled.div`
    padding: 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff7de;
    margin-top: 2rem;
    height: auto;

`
const Heading = styled.div`
    font-weight: 600;
    text-align: center;
    color: ${palette.black};
    font-size: 1.5rem;
    ${media.sm`
        font-size: 2rem;
    `}
    ${media.md`
        font-size: 2.5rem;
    `}
`
const Text = styled.div`
    text-align:center;
    padding-top:1.5rem;
    font-size: 18px;
    color: ${palette.black};
    line-height: 1.5;

`

export default IRL